function SlideCenturyClub(props) {
  const { rewind } = props

  let coursesCompleted = Number(rewind.achievements.completed_courses)
  let numLabel, firstMessage

  if (coursesCompleted >= 100) {
    numLabel = 100
  }
  if (coursesCompleted < 100 && coursesCompleted >= 75) {
    numLabel = 75
  }
  if (coursesCompleted < 75 && coursesCompleted >= 50) {
    numLabel = 50
  }

  let bgNumber

  if (coursesCompleted >= 100) {
    firstMessage = "You're in the Century Club!"
    bgNumber = 100
  }
  if (coursesCompleted < 100 && coursesCompleted >= 75) {
    firstMessage = "You're in Club 75!"
    bgNumber = 75
  }
  if (coursesCompleted < 75 && coursesCompleted >= 50) {
    firstMessage = "You're in Club 50!"
    bgNumber = 50
  }

  return (
    <div className="slide century">
      <div className={"background-tile club-" + bgNumber + "-bg"} />
      <div className="part first">
        <div className="disco-ball">
          <p>{numLabel}</p>
          <span />
          <span />
          <span />
        </div>
        <p className="textLarge">{firstMessage}</p>
      </div>

      <div className="secondWrapper">
        <div className="part second">
          <p className="textLarge">
            Congrats on completing
            <br />
            {numLabel}+ courses!
          </p>
        </div>
      </div>

      <div className="lamp">
        <div className="lava">
          <div className="blob"></div>
          <div className="blob"></div>
          <div className="blob"></div>
          <div className="blob"></div>
          <div className="blob"></div>
          <div className="blob"></div>
          <div className="blob"></div>
          <div className="blob"></div>
        </div>
      </div>
      <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
        <defs>
          <filter id="goo">
            <feGaussianBlur
              in="SourceGraphic"
              stdDeviation="10"
              result="blur"
            />
            <feColorMatrix
              in="blur"
              mode="matrix"
              values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7"
              result="goo"
            />
            <feBlend in="SourceGraphic" in2="goo" />
          </filter>
        </defs>
      </svg>
    </div>
  )
}

export default SlideCenturyClub
