import { Box, Checkbox, FormControlLabel, Grid } from "@mui/material"
import Loading from "@mobilemind/common/src/components/Loading"
import theme from "@mobilemind/common/src/theme/theme"
import { useEffect } from "react"
import {
  changeOnlyNew,
  increaseRecommendedPage,
} from "../../../store/reducers/externalPD"
import { useAppDispatch } from "../../../store/store"
import RecommendedCard from "./RecommendedCard"
import BottomScrollListener from "react-bottom-scroll-listener"

type ContainerProps = {
  recommendedEvents: any
  toggleModal: (args: boolean) => void
  openEvent: (args: any) => any
  fetchRecommendedEvents: (args: any) => any
}

const RecommendedEventsContainer = ({
  recommendedEvents,
  fetchRecommendedEvents,
  toggleModal,
  openEvent,
}: ContainerProps) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    !recommendedEvents.fetched && dispatch(fetchRecommendedEvents({}))
  }, [recommendedEvents, fetchRecommendedEvents, dispatch])

  function handleScroll() {
    if (recommendedEvents.currentPage < recommendedEvents.totalPages - 1) {
      dispatch(increaseRecommendedPage({}))
    }
  }

  return (
    <BottomScrollListener onBottom={handleScroll}>
      <div className="page events-layout recommended-events">
        <h2>
          <div style={{ flex: 1 }}>Recommended External Events</div>
          {!recommendedEvents.fetched && (
            <Loading message={"Getting recommended events..."} />
          )}
        </h2>

        <FormControlLabel
          style={{ marginTop: -10, marginLeft: 0 }}
          control={
            <Checkbox
              style={{
                marginRight: 5,
                color: theme.palette.secondary.main,
              }}
              checked={recommendedEvents.filters.onlyNew}
              onChange={(event) => {
                dispatch(changeOnlyNew(event.target.checked))
                dispatch(fetchRecommendedEvents({}))
              }}
            />
          }
          label="Only New Events"
        />

        <div className="recommended-grid">
          <Grid container spacing={3}>
            {recommendedEvents.data.map((event: any, index: number) => {
              return (
                <RecommendedCard
                  key={index}
                  animationDelay={index}
                  event={event}
                  onClick={() => {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    dispatch(toggleModal(true))
                    dispatch(openEvent(event))
                  }}
                />
              )
            })}
          </Grid>
        </div>
        {recommendedEvents.isFetchingMore && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: 3,
            }}
          >
            <Loading message={"Getting more events..."} />
          </Box>
        )}
      </div>
    </BottomScrollListener>
  )
}

export default RecommendedEventsContainer
