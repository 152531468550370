import { Component } from "react"
import { Card, Button } from "@mui/material"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"

import ProgressBar from "@mobilemind/common/src/components/ProgressBar"

class WidgetLearningPath extends Component {
  componentDidMount() {}

  generateMessage = (percentCompleted, coursesRemaining) => {
    let message
    if (percentCompleted > 80) {
      message = <>You're so close!.</>
    } else if (coursesRemaining === 1) {
      message = <>Only one more course to go!</>
    } else if (
      coursesRemaining > 1 &&
      percentCompleted >= 50 &&
      percentCompleted < 70
    ) {
      message = <>Over halfway there!</>
    } else if (percentCompleted > 0) {
      message = <>You're off to a great start!</>
    } else if (percentCompleted === 0) {
      message = <>Ready for action!</>
    }

    return message
  }

  render() {
    const { learningPath, label } = this.props.widget

    if (learningPath) {
      let courseTotal = learningPath.field_num_courses
      let numCompleted = learningPath.field_user_lp_completed_courses
      let percentCompleted = learningPath.field_lp_percent_complete
      let coursesRemaining = courseTotal - numCompleted

      return (
        <Card
          className="widget learningPath widget-dashboard"
          style={{ cursor: "pointer" }}
        >
          <Link to={"learning-path/" + learningPath.field_lp_id}>
            <div className="topContent">
              <div
                className="widgetIcon icon learningPath"
                style={{ zIndex: 5, top: 2, right: 0 }}
              />
              <div className={"widgetLabel success"}>{label}</div>
              <div
                className="pathImage"
                style={{
                  backgroundImage:
                    'url("' + learningPath.field_lp_image_uri + '")',
                }}
              />

              <h2>{learningPath.name}</h2>
              <div
                style={{ marginBottom: -5, marginTop: 10 }}
                className="pathStatus"
              >
                {numCompleted} / {courseTotal} Courses Completed
                <ProgressBar width={percentCompleted} />
              </div>
            </div>

            <p>{this.generateMessage(percentCompleted, coursesRemaining)}</p>

            <footer>
              <Button
                onClick={this.resumeLearningPath}
                className="button small"
              >
                Resume Learning Path
              </Button>
            </footer>
          </Link>
        </Card>
      )
    } else {
      return false
    }
  }
}

WidgetLearningPath.propTypes = {
  animationDelay: PropTypes.string,
  assessments: PropTypes.object,
  categories: PropTypes.object,
  courses: PropTypes.array,
  learningPath: PropTypes.object,
  session: PropTypes.object,
  widget: PropTypes.object,
}

export default WidgetLearningPath
