import moment from "moment"
import _ from "lodash"
import { convertEstimatedTime } from "@mobilemind/common/src/functions"
import pluralize from "pluralize"

export function hexToRgbA(hex) {
  var c
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("")
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]]
    }
    c = "0x" + c.join("")
    return (
      "rgba(" + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") + ",1)"
    )
  }
  throw new Error("Bad Hex")
}

/**
 * Get the user-facing name for a category when all we have is the category id
 * @param {array} allCategories All category entities
 * @param {string} id The id of the category for which to get the full name
 */

export function getCategoryLabel(allCategories, id) {
  if (allCategories.data.length) {
    let category = allCategories.data.find((cat) => cat.id === id)
    return category && category.attributes.name.toLowerCase()
  }
  return null
}

/**
 * Get the most recently updated User Learning Paths that's still in progress
 * @param {array} userLearningPaths All of the current user's user_learning_path entities
 */
export function getActiveUserLearningPath(userLearningPaths) {
  let recentInProgressUserLearningPath = _.orderBy(
    userLearningPaths.filter(
      (path) => path.attributes.field_status === "in_progress"
    ),
    (path) => {
      return moment(path.attributes.changed).format("YYYY-MM-DD HH:MM:SS")
    },
    ["desc"]
  )[0]

  return recentInProgressUserLearningPath
}

/**
 * Determine whether a course belongs to a category that the user's organization has chosen to exclude
 * @param {object} course The course to determine whether to exclude
 * @param {array} allCategories All category entities
 * @param {array} excludedCategories Categories the organization has chosen to exclude
 */

export function isCourseCategoryExcluded(
  course,
  allCategories,
  excludedCategories
) {
  const categoryId = Number(course.field_category_target_id)
  if (categoryId) {
    let excludedCategoryIds = excludedCategories.map((excludedCategory) => {
      let category = allCategories.find(
        (category) =>
          category.attributes.drupal_internal__tid ===
          excludedCategory.target_id
      )
      return category ? category.attributes.drupal_internal__tid : false
    })

    return excludedCategoryIds.includes(categoryId)
  }
  return false
}

/**
 * Determine if all assessments for all courses in learning path are either submitted or
 * complete, meaning no further action is necessary
 * @param {array} pathCourses All courses for the learning path
 * @param {array} assessments The user's assessments
 */
export function isLearningPathFinished(pathCourses, assessments) {
  let courseIds = pathCourses && pathCourses.map((course) => course.id)
  let pathAssessments =
    courseIds &&
    assessments.all.filter((assessment) =>
      courseIds.includes(assessment.relationships.field_course.data.id)
    )
  let inProgressAssessments =
    pathAssessments &&
    pathAssessments.filter(
      (assessment) => assessment.attributes.field_status === "in_progress"
    )
  let finished =
    pathAssessments &&
    courseIds &&
    pathAssessments.length === courseIds.length &&
    !inProgressAssessments.length

  return finished
}

/**
 * Return all courses required for a specific badge
 * @param {object} badge The badge entity
 * @param {object} courses All course entities available
 */
export function getCoursesForBadge(badge, courses) {
  let badgeCourses = badge.relationships.field_course.data.map(
    (badgeCourse) => {
      return courses.find((course) => course.id === badgeCourse.id)
    }
  )

  return badgeCourses
}

/**
 * Logic for displaying pace information for user goals in terms of either minutes per day/week,
 * or number of courses per day/week
 * @param {object} userGoal The user goal
 * @param {object} userLearningPath The user learning path
 * @param {object} learningPath The learning path
 */

export function calculateGoalPace(userGoal, userPath, learningPath) {
  if (userGoal && userGoal.goal && userPath && learningPath) {
    let minutesLeft

    const completedMinutes = userPath.attributes.field_total_time_spent
    if (completedMinutes) {
      minutesLeft =
        userGoal.goal.attributes.field_estimated_time - completedMinutes
    } else {
      minutesLeft = userGoal.goal.attributes.field_estimated_time
    }

    const completedCourseIds =
      userPath.relationships.field_courses_completed.data.map(
        (course) => course.id
      )
    const coursesRemaining = learningPath.courses.filter(
      (course) => !completedCourseIds.includes(course.id)
    )

    // The difference between the deadline and now, in months
    let monthsLeft = moment(userGoal.goal.attributes.field_goal_date).diff(
      moment(),
      "months"
    )

    // And in days
    let daysLeft =
      moment(userGoal.goal.attributes.field_goal_date).diff(moment(), "days") +
      1

    // The daily amount of minutes needed to meet the deadline
    let dailyPace = Math.ceil(minutesLeft / daysLeft)

    // If there's more than one month remaining in the goal
    if (monthsLeft >= 1) {
      // The amount of weeks we have left
      const weeksLeft = moment(userGoal.goal.attributes.field_goal_date).diff(
        moment(),
        "weeks"
      )
      // Minutes per week to meet pace
      const weeklyPace = Math.ceil(minutesLeft / weeksLeft)
      // Courses per week to meet pace
      const coursesPerWeek = Math.ceil(coursesRemaining.length / weeksLeft)

      // If there's more than an hour per week need to complete on time, return "X courses / week"
      if (weeklyPace > 60) {
        return (
          coursesPerWeek + " " + pluralize("course", coursesPerWeek) + " / week"
        )
      }
      // Otherwise just return minutes per week
      else {
        return convertEstimatedTime(weeklyPace) + " / week"
      }
    }

    // If there's less than a month remaining
    else {
      // If it's less than 5m / day, always show 5m / day
      if (dailyPace < 5) {
        return convertEstimatedTime(5) + " / day"
      }
      // Or the actual minutes if it's between 5m and 20m
      else if (dailyPace >= 5 && dailyPace <= 20) {
        return convertEstimatedTime(dailyPace) + " / day"
      }
      // If it's more than 20m / day, show "X courses / day"
      else if (dailyPace > 20) {
        const coursesPerDay = Math.ceil(coursesRemaining.length / daysLeft)
        return (
          coursesPerDay + " " + pluralize("course", coursesPerDay) + " / day"
        )
      }
    }
  }
  // Catch anything else
  return false
}
