import CountUp from "react-countup"

function SlideAchievements(props) {
  const coursesCompleted = new Array(
    Number(props.rewind.achievements.completed_courses)
  )
  const badgesEarned = new Array(
    Number(props.rewind.achievements.completed_badges)
  )
  const pathsCompleted = new Array(
    Number(props.rewind.achievements.completed_learning_paths)
  )

  const { rewind } = props

  return (
    <div className="slide achievements">
      <div className="background-tile trophy-bg" />
      <div className="part first">
        <p className="textLarge">
          And congrats on the achievements you’ve already earned!
        </p>
      </div>
      <div className="part second" style={{ animationDelay: 13.5 + "s" }}>
        <p className="textLarge header">
          {rewind.firstName}'s year by the numbers:
        </p>

        <div className="stats">
          <div className="groupWrapper">
            <div
              style={{ animationDelay: "6.5s" }}
              className="statGroup coursesCompleted"
            >
              <header>
                <strong>Courses Completed</strong>
                <span className="count">
                  <CountUp
                    useEasing={false}
                    delay={7.5}
                    start={0}
                    end={coursesCompleted.length}
                    duration={2}
                  />
                </span>
              </header>
              <ul>
                {Array.from(coursesCompleted.keys()).map((course, index) => {
                  let quotient = 2 / coursesCompleted.length
                  return (
                    <li
                      style={{ animationDelay: quotient * index + 7.5 + "s" }}
                      key={index}
                      className="icon laptop"
                    />
                  )
                })}
              </ul>
            </div>
          </div>

          <div className="groupWrapper">
            <div
              style={{ animationDelay: "7s" }}
              className="statGroup badgesEarned"
            >
              <header>
                <strong>Badges Earned</strong>
                <span className="count">
                  <CountUp
                    useEasing={false}
                    delay={7.5}
                    start={0}
                    end={badgesEarned.length}
                    duration={2}
                  />
                </span>
              </header>
              <ul>
                {Array.from(badgesEarned.keys()).map((badge, index) => {
                  let quotient = 2 / badgesEarned.length
                  return (
                    <li
                      style={{ animationDelay: quotient * index + 8 + "s" }}
                      key={index}
                      className="icon badge"
                    />
                  )
                })}
              </ul>
            </div>
          </div>

          <div className="groupWrapper">
            <div
              style={{ animationDelay: "7.5s" }}
              className="statGroup learningPaths"
            >
              <header>
                <strong>Learning Paths Completed</strong>
                <span className="count">
                  <CountUp
                    useEasing={false}
                    delay={7.5}
                    start={0}
                    end={pathsCompleted.length}
                    duration={2}
                  />
                </span>
              </header>
              <ul>
                {Array.from(pathsCompleted.keys()).map((path, index) => {
                  let quotient = 2 / pathsCompleted.length
                  return (
                    <li
                      style={{ animationDelay: quotient * index + 8.5 + "s" }}
                      key={index}
                      className="icon learningPath"
                    />
                  )
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SlideAchievements
