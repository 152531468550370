import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import {
  Button,
  Dialog,
  FormControl,
  Menu,
  MenuItem,
  TextField,
} from "@mui/material"

import moment from "moment"
import SanitizedHTML from "@mobilemind/common/src/components/SanitizedHTML"

import {
  updateRSVP,
  fetchSessionAttendance,
  fetchSessionAttendanceLog,
} from "../../../actions/index"

import { connect, useDispatch } from "react-redux"
import he from "he"
import ButtonSmall from "../../../components/ButtonSmall"
import classNames from "classnames"
import ButtonLarge from "@mobilemind/common/src/components/ButtonLarge"
import Loading from "@mobilemind/common/src/components/Loading"
import pluralize from "pluralize"
import theme from "@mobilemind/common/src/theme/theme"
import Values from "values.js"
import LearningBuddyMessage from "@mobilemind/common/src/components/LearningBuddyMessage"

import { checkIn } from "../eventSingle/activeEventSlice"

import Check from "../../../icons/Check"
import InputMask from "react-input-mask"
import { checkInReadyMessages } from "../../learningBuddy/buddyMessages"
import InlineBuddyMessage from "../../learningBuddy/InlineBuddyMessage"

const mapStateToProps = ({ session, activeEvent, categories }, ownProps) => {
  const sessionData = activeEvent.conferenceSessions.data.find((session) => {
    return session.session_id === ownProps.appointmentData.id
  })
  const { formattedEvents } = ownProps
  let rsvp = sessionData.rsvp_status

  const parallelSessions = [...formattedEvents].filter((session) => {
    if (session.id !== ownProps.appointmentData.id) {
      if (
        moment(ownProps.appointmentData.startDate)
          .add(1, "minute")
          .isBetween(moment(session.startDate), moment(session.endDate)) ||
        moment(ownProps.appointmentData.endDate)
          .subtract(1, "minute")
          .isBetween(moment(session.startDate), moment(session.endDate))
      ) {
        if (session.rsvp === "accept") {
          return session
        }
      }
    }

    return false
  })

  return {
    session,
    rsvp,
    parallelSessions,
    categories,
    activeEvent,
  }
}

function SessionTooltip({
  appointmentData,
  activeEvent,
  rsvp,
  session,
  categories,
  onClose,
  parallelSessions,
}) {
  const {
    conference,
    startDate,
    endDate,
    image,
    color,
    title,
    description,
    categoryId,
    maxCapacity,
    addOnId,
    drupal_internal__id,
    canRegister,
  } = appointmentData

  const dispatch = useDispatch()

  const [anchorEl, setAnchorEl] = useState()
  const [loading, setLoading] = useState(false)
  const [totalAttendees, setAttendees] = useState(false)
  const [buddyMessage, setBuddyMessage] = useState(null)
  const [checkInMessage, setCheckInMessage] = useState(null)
  const [checkInPeriod, setCheckInPeriod] = useState(null)
  const [joinCode, setJoinCode] = useState("")
  const [atCapacity, weAreAtCapacity] = useState(false)

  const [currentRSVP, setCurrentRSVP] = useState(rsvp)
  const [sessionUserEventUUID, setSessionUserEventUUID] = useState(
    activeEvent.activeSession?.sessionUserEventUUID
  )

  const [hasAttended, setAttended] = useState(appointmentData.attended)
  const [checkInWarning, showCheckInWarning] = useState(false)
  const history = useHistory()

  useEffect(() => {
    if (!buddyMessage) {
      const messages = [
        "Just a heads up, you're attending",
        "Reminder, you've also got",
        "Remember, you're going to",
        "Just a reminder, you're attending",
        "Psst! You're already attending",
        "Don't forget, you've got",
        "Busy day huh? You're also attending",
      ]

      let msgIndex = Math.floor(Math.random() * Math.floor(6))
      setBuddyMessage(messages[msgIndex])
      setCheckInMessage(
        checkInReadyMessages[
          Math.floor(
            Math.random() * Math.floor(checkInReadyMessages.length - 1)
          )
        ]
      )
      dispatch(fetchSessionAttendanceLog(addOnId)).then((response) => {
        if (response) {
          setCheckInPeriod(response.data.attributes.field_check_in_period)
        }
      })
    } else {
      setLoading(true)

      dispatch(fetchSessionAttendance(drupal_internal__id)).then((response) => {
        setLoading(false)
        setAttendees(Number(response.attendance_data.accept_events))
      })
    }
  }, [drupal_internal__id, buddyMessage, addOnId, dispatch, rsvp])

  const rsvpOpen = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleRSVPClick = (rsvp) => {
    handleClose()
    setLoading(true)

    dispatch(
      updateRSVP({
        appointmentData,
        rsvp,
        activeEvent,
        sessionUserEventUUID,
      })
    ).then((response) => {
      if (!response) {
        weAreAtCapacity(true)
      }
      setSessionUserEventUUID(response.id)
      setCurrentRSVP(rsvp)
      setLoading(false)
    })
  }

  const submitCheckIn = () => {
    setLoading(true)

    dispatch(
      checkIn({
        isSession: true,
        joinCode,
        drupal_internal__id,
        uuid: appointmentData.id,
      })
    ).then((response) => {
      if (response.payload.success) {
        setAttended(true)
        showCheckInWarning(false)
      } else {
        showCheckInWarning(true)
      }
      setLoading(false)
    })
  }

  const fullCategory = categories.data.find((cat) => cat.id === categoryId)
  const filledSeatsPercentage = totalAttendees / maxCapacity

  const showSeatsRemaining =
    maxCapacity > 0 && filledSeatsPercentage >= 0.8 && currentRSVP !== "accept"

  const allowAccess =
    (conference.sessionsLocked &&
      appointmentData.conferenceUserEvent &&
      appointmentData.conferenceUserEvent.attributes.field_rsvp) ||
    !conference.sessionsLocked

  let showRSVPButton = false
  if (maxCapacity > totalAttendees || !maxCapacity) {
    if (allowAccess) {
      showRSVPButton = true
    }
  }
  if (currentRSVP === "accept") {
    showRSVPButton = true
  }
  const titleContent = (
    <>
      {showRSVPButton && (
        <>
          {(canRegister || currentRSVP) && !hasAttended && (
            <>
              {!currentRSVP ? (
                <>
                  {!loading && !atCapacity && (
                    <div onClick={handleClick} className="rsvpButton">
                      <ButtonLarge>
                        <div className="icon paper-plane" />
                        RSVP
                      </ButtonLarge>
                    </div>
                  )}
                </>
              ) : (
                <div className="rsvpButton active">
                  <Button
                    className={classNames("button small", currentRSVP)}
                    onClick={handleClick}
                  >
                    {currentRSVP === "accept" && <span className="icon rsvp" />}
                    {currentRSVP === "decline" && (
                      <span className="icon rsvp-decline" />
                    )}
                    {currentRSVP === "tentative" && (
                      <span className="icon rsvp-tentative" />
                    )}
                  </Button>
                </div>
              )}
            </>
          )}
        </>
      )}

      {hasAttended && <span className="icon check" />}

      {loading && <Loading color="white" />}

      <div className="roomInfo">
        {appointmentData.fullRoom ? (
          <>
            <div className="icon room" />
            {appointmentData.fullRoom.attributes.name}
          </>
        ) : (
          <>
            {" "}
            <div className="icon video-chat" />
            Virtual Session
          </>
        )}
      </div>
      <h2>
        <div>{title}</div>
        <div className="dateTime">
          {moment(startDate).format("h:mma")} -{" "}
          {moment(endDate).format("h:mma")}
        </div>

        <div className="capsuleWrapper">
          <div className="capsule">
            <section>
              {fullCategory && (
                <>
                  {fullCategory && fullCategory.image && (
                    <div
                      className="categoryImage"
                      style={{
                        backgroundImage:
                          "url(" +
                          process.env.REACT_APP_API_URL +
                          fullCategory.image.attributes.uri.url +
                          ")",
                      }}
                    />
                  )}
                  {fullCategory.attributes.name}
                </>
              )}
            </section>

            {showSeatsRemaining && (
              <section>
                <span className="icon chair" />
                <span>
                  {maxCapacity - totalAttendees}{" "}
                  {pluralize("seat", maxCapacity - totalAttendees)} available
                </span>
              </section>
            )}
          </div>
        </div>
      </h2>
    </>
  )

  const isCheckInPeriodActive =
    checkInPeriod &&
    moment().isAfter(moment(checkInPeriod.value)) &&
    moment().isBefore(moment(checkInPeriod.end_value)) &&
    (!maxCapacity || maxCapacity > totalAttendees || rsvp === "accept")

  const hasFullJoinCode =
    joinCode.replaceAll("_", "").replaceAll("-", "").length === 8

  return (
    <Dialog id="sessionTooltipContainer" open={true} onClose={onClose}>
      <div className="sessionToolTip">
        {image && image.attributes ? (
          <div
            className="sessionImage"
            style={{
              backgroundImage:
                "url(" +
                process.env.REACT_APP_API_URL +
                image.attributes.uri.url +
                ")",
            }}
          >
            {titleContent}
          </div>
        ) : (
          <div className="sessionImage" style={{ backgroundColor: color }}>
            <>{titleContent}</>
          </div>
        )}

        <div className="inner">
          {atCapacity && (
            <div style={{ margin: "10px 0", display: "inline-block" }}>
              <InlineBuddyMessage
                message={
                  <p>
                    Well that's a bummer. There are no more seats available for
                    this session.
                  </p>
                }
              />
            </div>
          )}

          {isCheckInPeriodActive && !atCapacity && (
            <div className="flexRow" style={{ padding: "10px 0" }}>
              <div style={{ flex: 1 }}>
                <div
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    marginTop: 0,
                  }}
                  className="buddyContainer"
                >
                  <span
                    className={classNames(
                      "icon inline-buddy buddy-image",
                      session.user.learningBuddy &&
                        session.user.learningBuddy.attributes.name.toLowerCase()
                    )}
                  />
                  <div
                    id="inline-buddy-message"
                    className="buddy-message inline"
                    style={{
                      alignItems: "flex-start",
                      backgroundColor: new Values(theme.palette.secondary.main)
                        .tint(60)
                        .hexString(),
                    }}
                  >
                    <svg width="15px" height="30px" viewBox="0 0 15 30">
                      <g
                        id="Page-1"
                        stroke="none"
                        strokeWidth="1"
                        fill="none"
                        fillRule="evenodd"
                      >
                        <polygon
                          id="Rectangle"
                          fill={new Values(theme.palette.secondary.main)
                            .tint(60)
                            .hexString()}
                          transform="translate(15.000000, 15.000000) scale(-1, -1) rotate(-45.000000) translate(-15.000000, -15.000000) "
                          points="25 5 25 25 5 25"
                        ></polygon>
                      </g>
                    </svg>

                    {checkInWarning ? (
                      <p>
                        <strong>Oops!</strong> That doesn't look like a valid
                        join code.
                      </p>
                    ) : (
                      <>
                        {hasAttended ? (
                          <p>All set! You're checked in.</p>
                        ) : (
                          <p>
                            <strong>{checkInMessage}</strong> Enter your join
                            code here.
                          </p>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
              {!hasAttended && (
                <>
                  <FormControl>
                    <InputMask
                      mask="****-****"
                      onChange={(event) => {
                        setJoinCode(event.target.value)
                      }}
                      value={joinCode}
                    >
                      <TextField variant="outlined" label="Join Code" />
                    </InputMask>
                  </FormControl>

                  <div
                    style={{
                      pointerEvents: hasFullJoinCode ? "all" : "none",
                      opacity: hasFullJoinCode ? 1 : 0.3,
                    }}
                  >
                    <ButtonLarge
                      onClick={() => {
                        submitCheckIn()
                      }}
                    >
                      <Check />
                    </ButtonLarge>
                  </div>
                </>
              )}
            </div>
          )}

          {description && (
            <div className="description">
              <header>What to Expect</header>
              {description && <SanitizedHTML html={he.decode(description)} />}
            </div>
          )}

          {parallelSessions.length > 0 &&
            (maxCapacity > totalAttendees ||
              !maxCapacity ||
              rsvp === "accept") &&
            !atCapacity && (
              <LearningBuddyMessage
                message={
                  <p>
                    {buddyMessage} <strong>{parallelSessions[0].title}</strong>{" "}
                    from{" "}
                    <strong>
                      {moment(parallelSessions[0].startDate).format("h:mma")} -{" "}
                      {moment(parallelSessions[0].endDate).format("h:mma")}
                    </strong>{" "}
                    which overlaps with this session.
                  </p>
                }
              />
            )}
        </div>

        <footer style={{ justifyContent: allowAccess ? "flex-end" : "center" }}>
          {allowAccess && (
            <ButtonSmall
              onClick={() => {
                history.push(
                  "/events/event/" +
                    drupal_internal__id +
                    "/" +
                    conference.drupal_internal__id
                )
              }}
            >
              View More Details
            </ButtonSmall>
          )}
          {!allowAccess && <strong>RSVP to view details for this event</strong>}
        </footer>
      </div>

      <Menu
        id="rsvp-menu"
        anchorEl={anchorEl}
        open={rsvpOpen}
        className={rsvp && "hasRSVPd"}
        onClose={handleClose}
      >
        <MenuItem
          className={classNames(rsvp === "accept" && "active")}
          onClick={() => {
            handleRSVPClick("accept")
          }}
        >
          Accept
        </MenuItem>
        <MenuItem
          className={classNames(rsvp === "tentative" && "active")}
          onClick={() => {
            handleRSVPClick("tentative")
          }}
        >
          Tentative
        </MenuItem>
        <MenuItem
          className={classNames(rsvp === "decline" && "active")}
          onClick={() => {
            handleRSVPClick("decline")
          }}
        >
          Decline
        </MenuItem>
      </Menu>
    </Dialog>
  )
}

export default connect(mapStateToProps)(SessionTooltip)
