import { createSlice } from "@reduxjs/toolkit"

type InitialState = {
  activeItem: string
}

const initialState: InitialState = {
  activeItem: "learn",
}

export const navbar = createSlice({
  name: "navbar",
  initialState,
  reducers: {
    changeNavbar: (state, action) => {
      state.activeItem = action.payload
    },
  },
})

export const { changeNavbar } = navbar.actions

export default navbar.reducer
