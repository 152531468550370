import { Dialog, Button } from "@mui/material"
import "../styles/index.scss"
import { isIOS } from "react-device-detect"

function AppStoreModal({ collection }) {
  let appName = "MobileMind"
  let playStoreLink =
    "https://play.google.com/store/apps/details?id=com.mobilemind&hl=en_US&gl=US"
  let appStoreLink = "https://apps.apple.com/us/app/mobilemind/id1612844956"

  if (
    collection &&
    collection.label[0] &&
    collection.field_chrome_extension_link[0] &&
    collection.field_edge_extension_link[0]
  ) {
    appName = collection.label[0].value
    playStoreLink =
      collection.field_chrome_extension_link[0] &&
      collection.field_chrome_extension_link[0].value
    appStoreLink =
      collection.field_edge_extension_link[0] &&
      collection.field_edge_extension_link[0].value
  }

  return (
    <Dialog open={true} className="mobileAppSuggestion">
      <div className="dialog">
        <h2>
          <span className="icon responsive" />
          Nice device!
        </h2>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <p style={{ textAlign: "center" }}>
            For the best possible experience, you'll need to download{" "}
            <strong>
              {appName} for {isIOS ? <>iOS</> : <>Android</>}
            </strong>{" "}
            in the {isIOS ? <>App Store</> : <>Play Store</>}.
          </p>
          <a href={isIOS ? appStoreLink : playStoreLink}>
            <Button className="button large" style={{ marginLeft: 0 }}>
              {isIOS ? (
                <span className="icon app-store" />
              ) : (
                <span className="icon play-store" />
              )}
              Go To {isIOS ? <>App Store</> : <>Play Store</>}
            </Button>
          </a>
        </div>
      </div>
    </Dialog>
  )
}

export default AppStoreModal
