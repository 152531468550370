import { Component } from "react"
import { connect } from "react-redux"
import { Button } from "@mui/material"
import "../styles/sidebar.scss"
import { collapseSidebar, setSidebar, peekSidebar } from "../actions"
import PropTypes from "prop-types"
import theme from "@mobilemind/common/src/theme/theme"

import ExploreFilters from "../features/explore/ExploreFilters"
import BadgeFilters from "../features/badges/BadgeFilters"
import BadgeFiltersEarned from "../features/badges/BadgeFiltersEarned"
import LearningPathFilters from "../features/learningPaths/LearningPathFilters"

import { isMobile, isMobileOnly } from "react-device-detect"
import classNames from "classnames"

const mapStateToProps = ({ sidebar, assessments, goals, inProgress }) => {
  return {
    inProgress,
    goals,
    assessments,
    sidebar,
  }
}

const mapDispatchToProps = {
  collapseSidebar,
  peekSidebar,
  setSidebar,
}

function Sidebar(props) {
  return <SidebarContent {...props} theme={theme} />
}

class SidebarContent extends Component {
  state = {
    showCollapse: false,
    peek: false,
  }

  peek = (peek) => {
    if (!isMobile) {
      this.setState({ peek })
      this.props.peekSidebar(peek)
    }
  }

  render() {
    const { sidebar, currentPage, activeItem, theme } = this.props
    let feedbackCount = this.props.inProgress.inProgress.filter(
      (course) => course.field_user_assessment_comment
    ).length
    let inProgressCount = this.props.inProgress.inProgress.filter(
      (course) => !course.field_user_assessment_comment
    ).length
    let submittedCount = this.props.inProgress.submitted.length
    let savedCount = this.props.inProgress.inProgress.filter(
      (course) => course.field_user_assessment_status === "saved_for_later"
    ).length

    let isCollapsed = this.state.peek ? false : sidebar.isCollapsed

    const inProgressGoals = this.props.goals.data.filter(
      (userGoal) => userGoal.attributes.field_status === "in_progress"
    )

    return (
      <div
        onMouseEnter={() => this.setState({ showCollapse: true })}
        onMouseLeave={() => this.setState({ showCollapse: false })}
      >
        <div
          style={{ backgroundColor: theme.palette.primary.main }}
          data-collapsed={sidebar.isCollapsed}
          data-show={this.state.showCollapse}
          className={classNames("collapse", this.state.peek && "peek")}
          onClick={() => this.props.collapseSidebar(sidebar.isCollapsed)}
        >
          <span className="icon" />
        </div>
        <div
          onMouseEnter={() => {
            this.peek(true)
          }}
          onMouseLeave={() => {
            this.peek(false)
          }}
          id="sidebar"
          data-peek={this.state.peek}
          data-collapsed={isCollapsed}
        >
          {activeItem && (
            <ul>
              {sidebar.pages[currentPage].items.map((item) => {
                return (
                  <li
                    id={
                      "tour-sidebar-" +
                      item.title.replace(" ", "-").toLowerCase()
                    }
                    key={item.id}
                  >
                    <Button
                      style={{
                        backgroundColor:
                          sidebar.pages[currentPage].activeItem === item.id
                            ? theme.palette.secondary.main
                            : "transparent",
                      }}
                      onClick={() =>
                        this.props.setSidebar(currentPage, item.id)
                      }
                    >
                      <span id={item.id} className="icon" />
                      <span className="title">
                        {item.title}
                        {item.title === "Learning Paths" &&
                          currentPage !== "achievements" &&
                          inProgressGoals.length > 0 && (
                            <span
                              style={{
                                backgroundColor: theme.palette.secondary.main,
                              }}
                              className="icon timer"
                            />
                          )}
                      </span>

                      {currentPage === "inProgress" && (
                        <>
                          {item.id === "feedback" && feedbackCount > 0 && (
                            <span className="count">{feedbackCount}</span>
                          )}
                          {item.id === "in_progress" && inProgressCount > 0 && (
                            <span className="count">{inProgressCount}</span>
                          )}
                          {item.id === "submitted" && submittedCount > 0 && (
                            <span className="count">{submittedCount}</span>
                          )}
                          {item.id === "saved_for_later" && savedCount > 0 && (
                            <span className="count">{savedCount}</span>
                          )}
                        </>
                      )}
                    </Button>
                  </li>
                )
              })}
            </ul>
          )}
          <div id="tour-sidebar-filters">
            {!isMobileOnly &&
              activeItem &&
              sidebar.pages[currentPage].activeItem === "explore" && (
                <ExploreFilters />
              )}
            {!isMobileOnly &&
              activeItem &&
              sidebar.pages[currentPage].activeItem === "badges" && (
                <BadgeFilters />
              )}
            {!isMobileOnly &&
              activeItem &&
              sidebar.pages[currentPage].activeItem === "badgesEarned" && (
                <BadgeFiltersEarned />
              )}
            {!isMobileOnly &&
              activeItem &&
              (sidebar.pages[currentPage].activeItem === "learningPaths" ||
                sidebar.pages[currentPage].activeItem ===
                  "learningPathsCompleted") && <LearningPathFilters />}

            {currentPage === "course" && <>{this.props.content}</>}
          </div>
        </div>
      </div>
    )
  }
}

Sidebar.propTypes = {
  content: PropTypes.node,
  currentPage: PropTypes.string,
  sidebar: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar)
