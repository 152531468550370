import { Component } from "react"
import { connect } from "react-redux"

import Loading from "@mobilemind/common/src/components/Loading"
import { Link } from "react-router-dom"

import classNames from "classnames"
import he from "he"
import BottomScrollListener from "react-bottom-scroll-listener"

import { Button, Card, CardContent, Grid } from "@mui/material"
import BackArrow from "../../icons/BackArrow"

import theme from "@mobilemind/common/src/theme/theme"
import { setSidebar } from "../../actions"

import { convertEstimatedTime } from "@mobilemind/common/src/functions"
import Values from "values.js"
import ButtonSmall from "../../components/ButtonSmall"
import Export from "../../icons/Export"
import {
  getConferenceSessions,
  getEventsAttended,
  increaseCoursePage,
} from "../achievements/achievementsSlice"

import { format } from "date-fns"

import EventsAttendedFilters from "./EventsAttendedFilters"
import ConferenceSessionsModal from "@mobilemind/common/src/components/backpack/ConferenceSessionsModal"

const mapStateToProps = ({ session, achievements, sidebar, viewStyle }) => {
  return {
    session,
    sidebar,
    isExpanded: sidebar.mobileFiltersExpanded,
    filters: sidebar.pages.achievements.items.find(
      (item) => item.id === "completed"
    ).filters,
    events: achievements.events,
    viewStyle,
  }
}

const mapDispatchToProps = {
  increaseCoursePage,
  getEventsAttended,
  getConferenceSessions,
  setSidebar,
}

class EventsAttendedLayout extends Component {
  state = {
    isModalOpen: false,
    activeConference: null,
    activeConferenceSessions: {
      fetched: false,
      data: [],
    },
  }

  componentDidMount = () => {
    this.props.getEventsAttended()
  }

  getActiveConferenceSessions = async (event) => {
    let response = await this.props.getConferenceSessions(event)
    this.setState({ activeConferenceSessions: { fetched: true, data: [] } })
    if (!response.payload.rows.content) {
      this.setState({
        activeConferenceSessions: {
          fetched: true,
          data: response.payload.rows,
        },
      })
    }
  }

  handleScroll = () => {
    // const { currentPage, totalPages } = this.props.courses
    // // If we aren't already fetching more results
    // if (!this.props.courses.isFetching) {
    //   // And if there are more pages to fetch
    //   if (currentPage < totalPages - 1) {
    //     this.props.increaseCoursePage()
    //   }
    // }
  }

  exportEvents = () => {
    const url =
      process.env.REACT_APP_API_URL + "/user-events-learner-export/user"
    let windowParams =
      "left=200,top=200,width=767,height=450,personalbar=0,toolbar=0,scrollbars=0,resizable=0"
    window.open(url, "", windowParams)
  }

  goBack = () => {
    this.props.history.push("/backpack")
  }

  render() {
    const { events } = this.props
    const { fetched } = events
    const { isModalOpen, activeConference } = this.state

    let content = events.data.map((event) => {
      let isConference = event.field_award_credit_for_conferenc.length
        ? true
        : false
      let path = isConference
        ? "/events/conference/" + event.id_1
        : "/events/event/" + event.id_1

      const primary = new Values(theme.palette.primary.main)
      let backgroundColor = isConference
        ? primary.shade(15).hexString()
        : theme.palette.secondary.main

      return (
        <Grid key={event.id} item xs={3}>
          <Link to={path}>
            <Card className="event-card">
              <CardContent>
                <div
                  className="event-bg"
                  style={{
                    backgroundSize: event.field_event_image ? "cover" : 65,
                    backgroundPosition: "center",
                    backgroundImage:
                      event.field_event_image &&
                      "url(" +
                        process.env.REACT_APP_API_URL +
                        event.field_event_image +
                        ")",
                    backgroundColor,
                  }}
                >
                  {isConference && (
                    <div
                      className="conferenceLabel"
                      style={{ backgroundColor }}
                    >
                      Conference
                    </div>
                  )}
                </div>

                <div className="inner">
                  <div className="capsuleContainer">
                    {event.field_credit && (
                      <div
                        className="capsule"
                        style={{
                          backgroundColor: theme.palette.secondary.main,
                        }}
                      >
                        <div className="credit">
                          <span className="icon" />
                          <span>
                            {convertEstimatedTime(
                              event.field_credit.replace("min", "")
                            )}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>

                  <h2 style={{ marginTop: event.field_credit ? -5 : -15 }}>
                    {he.decode(event.name)}
                  </h2>

                  <footer>
                    {format(
                      new Date(event.field_event_date_time_value),
                      "MMMM do, yyyy"
                    )}
                  </footer>
                </div>
              </CardContent>
            </Card>
          </Link>
          {isConference && (
            <ButtonSmall
              onClick={() => {
                this.getActiveConferenceSessions(event)
                this.setState({
                  isModalOpen: true,
                  activeConference: event,
                })
              }}
            >
              View Sessions
            </ButtonSmall>
          )}
        </Grid>
      )
    })

    return (
      <div className={classNames("page eventsAttended", fetched && "loading")}>
        <div id="backpack-sidebar">
          <Button className="backButton" onClick={this.goBack}>
            <BackArrow />
            <span
              style={{ marginLeft: 10, color: theme.palette.primary.main }}
              className="buttonText"
            >
              Back
            </span>
          </Button>

          <EventsAttendedFilters />
        </div>

        <BottomScrollListener onBottom={this.handleScroll}>
          {isModalOpen && (
            <ConferenceSessionsModal
              getConferenceSessions={getConferenceSessions}
              conferenceId={activeConference.id}
              conference={activeConference}
              onClose={() => this.setState({ isModalOpen: false })}
            />
          )}

          {!fetched && (
            <Loading
              color={theme.palette.secondary.main}
              message={"Getting events..."}
            />
          )}

          <header style={{ position: "absolute", right: -20, top: -20 }}>
            <div className="exportLink">
              <ButtonSmall onClick={this.exportEvents} className="button small">
                <Export />

                <span style={{ marginLeft: 10 }}>Export</span>
              </ButtonSmall>
            </div>
          </header>

          <Grid container spacing={3}>
            {content}
          </Grid>
        </BottomScrollListener>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EventsAttendedLayout)
