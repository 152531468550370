import { Dialog, Button } from "@mui/material"
import "../styles/index.scss"

function ExtensionModal({ collection, googleURL, edgeURL }) {
  let extensionName = "MobileMind"
  if (
    collection &&
    collection.label[0] &&
    collection.field_chrome_extension_link[0] &&
    collection.field_edge_extension_link[0]
  ) {
    extensionName = collection.label[0].value
    googleURL = collection.field_chrome_extension_link[0].value
    edgeURL = collection.field_edge_extension_link[0].value
  }

  return (
    <Dialog open={true} className="extensionModal">
      <div className="dialog">
        <h2>One more step!</h2>
        <p>
          You'll need the <strong>{extensionName} Extension</strong> to use this
          app. Click one of the buttons below to go to the Chrome or Edge Web
          Store. Then click either the <strong>Add to Chrome</strong> (for
          Chrome) or <strong>Get</strong> (for Edge) button and refresh this
          page.
        </p>
        <p>
          Don't forget, you'll always need to use either the Google Chrome or
          Microsoft Edge browser.
        </p>
        <footer style={{ justifyContent: "center" }}>
          <a href={googleURL} target="_blank" rel="noopener noreferrer">
            <Button className="button large" style={{ marginLeft: 0 }}>
              <span className="icon chrome-logo" />
              Chrome Web Store
            </Button>
          </a>
          <a href={edgeURL} target="_blank" rel="noopener noreferrer">
            <Button className="button large">
              <span className="icon edge-logo" />
              Edge Web Store
            </Button>
          </a>
        </footer>
      </div>
    </Dialog>
  )
}

export default ExtensionModal
