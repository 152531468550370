import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { updateField, checkIn } from "./activeEventSlice"
import classNames from "classnames"

import { Button, TextField, FormControl } from "@mui/material"
import moment from "moment"
import InputMask from "react-input-mask"
import ButtonLarge from "@mobilemind/common/src/components/ButtonLarge"
import ButtonSmall from "../../../components/ButtonSmall"
import theme from "@mobilemind/common/src/theme/theme"
import Calendar from "../../../icons/Calendar"
import Values from "values.js"
import Check from "../../../icons/Check"
import pluralize from "pluralize"

import "@mobilemind/common/src/styles/tags.scss"
import TimeCapsule from "@mobilemind/common/src/components/TimeCapsule"
import { convertMinutesToHoursMinutes } from "@mobilemind/common/src/functions"
import { format } from "date-fns"
import { checkInReadyMessages } from "../../learningBuddy/buddyMessages"
import InlineBuddyMessage from "../../learningBuddy/InlineBuddyMessage"
import { RSVPMenu } from "./RSVPButton"

import StarWhiteIcon from "../../../img/star-white.svg"

function EventHeader(props) {
  const { session, activeEvent, userEvent } = props

  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = useState(null)
  const [checkInReadyMessage, setCheckInReadyMessage] = useState(null)

  useEffect(() => {
    if (!checkInReadyMessage) {
      setCheckInReadyMessage(
        checkInReadyMessages[
          Math.floor(
            Math.random() * Math.floor(checkInReadyMessages.length - 1)
          )
        ]
      )
    }
  }, [checkInReadyMessage])

  // Show the check in only if we're between the start and end datetimes, and if we need to enter a join code
  const checkInStart =
    activeEvent.attendanceMethod === "join_code" &&
    activeEvent.attendanceLog.attributes.field_check_in_period &&
    activeEvent.attendanceLog.attributes.field_check_in_period.value
  const checkInEnd =
    checkInStart &&
    activeEvent.attendanceLog.attributes.field_check_in_period.end_value

  const isCheckedIn =
    activeEvent.checkedInAttendees &&
    activeEvent.checkedInAttendees.find(
      (attendee) => attendee.id === props.session.user.id
    )

  let isMaxCapacity

  if (activeEvent.sessionMaxCapacity && activeEvent.totalAttendees) {
    // Only allow the user to register if the session max limit has not been reached, or if there is no limit
    // If we have a max capacity and we have at least 1 user RSVPd
    if (activeEvent.sessionMaxCapacity) {
      if (activeEvent.totalAttendees >= activeEvent.sessionMaxCapacity) {
        isMaxCapacity = true
      }
    }
  }

  // Check if the current user has RSVPd
  const hasRSVPd =
    (userEvent && userEvent.attributes.field_rsvp) || activeEvent.hasRSVPd

  const showCheckIn =
    checkInStart &&
    checkInEnd &&
    props.currentTime.isAfter(moment(checkInStart)) &&
    props.currentTime.isBefore(moment(checkInEnd)) &&
    (!hasRSVPd || hasRSVPd === "accept")

  let isRegistrationOpen, registrationWindowMessage
  // If we're allowing users to register until the event starts
  if (activeEvent.registrationWindow === "unlimited") {
    isRegistrationOpen = props.currentTime.isBefore(
      moment(activeEvent.startDate)
    )
  }
  // Otherwise we're allowing users to register within a specific window
  else if (activeEvent.registrationStarts && activeEvent.registrationEnds) {
    isRegistrationOpen =
      props.currentTime.isAfter(moment(activeEvent.registrationStarts)) &&
      props.currentTime.isBefore(moment(activeEvent.registrationEnds))

    if (props.currentTime.isBefore(moment(activeEvent.registrationStarts))) {
      registrationWindowMessage =
        "Registration begins " +
        moment(activeEvent.registrationStarts).format("MMMM Do") +
        " at " +
        moment(activeEvent.registrationStarts).format("h:mm A.")
    } else if (
      props.currentTime.isAfter(moment(activeEvent.registrationEnds))
    ) {
      registrationWindowMessage = "Registration has closed."
    } else if (
      props.currentTime.isBefore(moment(activeEvent.registrationEnds))
    ) {
      registrationWindowMessage =
        "Registration ends " +
        moment(activeEvent.registrationEnds).format("MMMM Do") +
        " at " +
        moment(activeEvent.registrationEnds).format("h:mm A.")
    }
  }

  const showRSVPButton = isRegistrationOpen && !isMaxCapacity && !hasRSVPd
  const filledSeatsPercentage =
    activeEvent.totalAttendees / activeEvent.sessionMaxCapacity

  const showSeatsRemaining =
    activeEvent.sessionMaxCapacity > 0 &&
    !activeEvent.atCapacity &&
    filledSeatsPercentage >= 0.8 &&
    userEvent?.attributes?.field_rsvp !== "accept"

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const rsvpOpen = Boolean(anchorEl)

  const { orgRoles, groupRoles } = session
  const canSchedule =
    orgRoles.includes("organization-admin") ||
    orgRoles.includes("organization-scheduler") ||
    groupRoles.includes("group-admin") ||
    groupRoles.includes("group-scheduler")

  let hqUrl = process.env.REACT_APP_HQ_URL + "/events/view/"
  let eventLabel

  if (props.activeEvent.bundle === "conference_event") {
    hqUrl += "session/" + activeEvent.drupal_internal__id
    eventLabel = "session"
  } else if (props.activeEvent.bundle === "event_base") {
    hqUrl += "event/" + activeEvent.drupal_internal__id
    eventLabel = "event"
  } else if (props.activeEvent.bundle === "conference") {
    hqUrl += "conference/" + activeEvent.drupal_internal__id
    eventLabel = "conference"
  }

  const eventCategory =
    activeEvent.eventCategory &&
    props.categories.data.find((cat) => cat.id === activeEvent.eventCategory.id)

  const primary = new Values(theme.palette.primary.main)
  let backgroundColor = primary.tint(50).hexString()
  if (activeEvent.bundle === "conference") {
    backgroundColor = primary.shade(15).hexString()
  }

  const showBuddyMessage =
    (hasRSVPd && hasRSVPd === "accept" && showCheckIn) ||
    !isRegistrationOpen ||
    (!hasRSVPd && showCheckIn)

  let startDate = activeEvent.startDate
  let endDate = activeEvent.endDate

  const showBummerSeatMessage =
    activeEvent.sessionMaxCapacity > 0 &&
    (isMaxCapacity ||
      activeEvent.atCapacity ||
      activeEvent.totalAttendees >= activeEvent.sessionMaxCapacity) &&
    hasRSVPd !== "accept"

  return (
    <>
      <h1
        className={classNames(
          "flexRow",
          !activeEvent.eventImage.file && "noImage"
        )}
        id={"eventHeader"}
        style={{
          justifyContent: "flex-end",
          backgroundImage:
            activeEvent.eventImage && activeEvent.eventImage.file
              ? "url(" +
                process.env.REACT_APP_API_URL +
                activeEvent.eventImage.file.attributes.uri.url +
                ")"
              : "none",
          backgroundPosition: "center",
          backgroundColor,
          backgroundSize: "cover",
        }}
      >
        {(activeEvent.pdCredit > 0 || eventCategory) && (
          <div className="capsuleWrapper">
            <div className="inner">
              {activeEvent.pdCredit > 0 && (
                <section>
                  <TimeCapsule
                    hours={
                      convertMinutesToHoursMinutes(activeEvent.pdCredit).hours
                    }
                    minutes={
                      convertMinutesToHoursMinutes(activeEvent.pdCredit).minutes
                    }
                  />{" "}
                </section>
              )}

              {eventCategory?.image && (
                <section>
                  <div className="categoryInfo">
                    <span
                      className={classNames("category-icon")}
                      style={{
                        backgroundImage:
                          "url(" +
                          process.env.REACT_APP_API_URL +
                          eventCategory.image.attributes.uri.url +
                          ")",
                      }}
                    ></span>
                    {activeEvent.eventCategory.attributes.name}
                  </div>
                </section>
              )}

              {activeEvent.difficulty !== "N/A" && (
                <section
                  style={{
                    marginTop: -4,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img src={StarWhiteIcon} alt="Star" width={20} />
                  {activeEvent.difficulty === "Intermediate" && (
                    <img src={StarWhiteIcon} alt="Star" width={20} />
                  )}
                  {activeEvent.difficulty === "Advanced" && (
                    <img src={StarWhiteIcon} alt="Star" width={20} />
                  )}
                </section>
              )}

              {showSeatsRemaining && (
                <section>
                  <div className="capacity-status">
                    <span
                      style={{ marginRight: 5, width: 20, height: 20 }}
                      className="icon chair"
                    />
                    {activeEvent.sessionMaxCapacity -
                      activeEvent.totalAttendees}{" "}
                    {pluralize(
                      "seat",
                      activeEvent.sessionMaxCapacity -
                        activeEvent.totalAttendees
                    )}{" "}
                    available
                  </div>
                </section>
              )}
            </div>
          </div>
        )}

        <div style={{ flex: 1, marginRight: 25 }}>
          <div className="title">
            {activeEvent.name}
            <span className="dateTime">
              {activeEvent.startDate.split("T")[0] ===
              activeEvent.endDate.split("T")[0] ? (
                <>
                  {format(new Date(startDate), "EEEE MMMM do h:mmaa")} -{" "}
                  {format(new Date(endDate), "h:mmaa")}
                </>
              ) : (
                <>
                  {format(new Date(startDate), "EEEE MMMM do h:mmaa")} -{" "}
                  {format(new Date(endDate), "EEEE MMMM do h:mmaa")}
                </>
              )}
            </span>
          </div>
        </div>

        {!props.conferenceId && canSchedule && (
          <a href={hqUrl}>
            <ButtonSmall className="button small">
              <Calendar />
              <span style={{ marginLeft: 10 }}>Manage {eventLabel}</span>
            </ButtonSmall>
          </a>
        )}

        {showRSVPButton &&
          !activeEvent.atCapacity &&
          (activeEvent.totalAttendees < activeEvent.sessionMaxCapacity ||
            !activeEvent.sessionMaxCapacity) && (
            <div
              style={{ marginLeft: 20 }}
              className={classNames("register-button")}
            >
              <ButtonLarge onClick={handleClick}>
                <span className="icon paper-plane" />
                RSVP
              </ButtonLarge>
              <RSVPMenu
                anchorEl={anchorEl}
                rsvpOpen={rsvpOpen}
                hasRSVPd={hasRSVPd}
                handleClose={() => {
                  setAnchorEl(null)
                }}
                userEvent={userEvent}
              />
            </div>
          )}

        {hasRSVPd && (
          <div className="rsvp-info">
            {isCheckedIn || activeEvent.isCheckedIn ? (
              <div className="icon check" />
            ) : (
              <>
                <Button
                  className={classNames(
                    "button small",
                    userEvent.attributes.field_rsvp
                  )}
                  onClick={handleClick}
                >
                  {userEvent &&
                    userEvent.attributes.field_rsvp === "accept" && (
                      <span className="icon rsvp" />
                    )}
                  {userEvent &&
                    userEvent.attributes.field_rsvp === "decline" && (
                      <span className="icon rsvp-decline" />
                    )}
                  {userEvent &&
                    userEvent.attributes.field_rsvp === "tentative" && (
                      <span className="icon rsvp-tentative" />
                    )}
                </Button>

                <div>
                  <RSVPMenu
                    anchorEl={anchorEl}
                    rsvpOpen={rsvpOpen}
                    hasRSVPd={hasRSVPd}
                    handleClose={() => {
                      setAnchorEl(null)
                    }}
                    userEvent={userEvent}
                  />
                </div>
              </>
            )}
          </div>
        )}
      </h1>

      {showBuddyMessage && (
        <div
          style={{
            marginBottom: activeEvent.currentTab === "attendees" ? -50 : 0,
          }}
          className="flexRow buddyContainer"
        >
          {!hasRSVPd &&
            !showCheckIn &&
            !props.currentTime.isAfter(moment(checkInEnd)) && (
              <InlineBuddyMessage
                message={
                  <strong
                    style={{ fontSize: 14, lineHeight: 1.4, marginRight: 5 }}
                  >
                    {registrationWindowMessage}
                  </strong>
                }
              />
            )}

          {(!isMaxCapacity || hasRSVPd) && (
            <>
              {activeEvent.attendanceMethod === "join_code" ? (
                <>
                  {
                    // If we are outside the check in window
                    !showCheckIn && (
                      <>
                        {
                          // If check in has closed
                          props.currentTime.isAfter(moment(checkInEnd)) ? (
                            <InlineBuddyMessage
                              message={
                                <span
                                  style={{
                                    fontSize: 14,
                                    lineHeight: 1.4,
                                    fontWeight: "normal",
                                  }}
                                >
                                  Check in has closed.
                                </span>
                              }
                            />
                          ) : (
                            // Otherwise check in has not begun
                            <>
                              {hasRSVPd && (
                                <InlineBuddyMessage
                                  message={
                                    <span
                                      style={{
                                        fontSize: 14,
                                        lineHeight: 1.4,
                                        fontWeight: "normal",
                                      }}
                                    >
                                      <strong style={{ marginRight: 5 }}>
                                        Check in begins{" "}
                                        {moment(checkInStart).format("MMMM Do")}{" "}
                                        at{" "}
                                        {moment(checkInStart).format("h:mm A")}.
                                      </strong>
                                      The {eventLabel} organizer will provide
                                      you the Join Code.
                                    </span>
                                  }
                                />
                              )}
                            </>
                          )
                        }
                      </>
                    )
                  }
                </>
              ) : (
                <InlineBuddyMessage
                  message={
                    <div
                      style={{
                        fontSize: 14,
                        lineHeight: 1.4,
                        fontWeight: "normal",
                      }}
                    >
                      <span className="icon check-black" />
                      You will be checked into this {eventLabel} by the event
                      organizer.
                    </div>
                  }
                />
              )}
            </>
          )}
          {showCheckIn && (!hasRSVPd || hasRSVPd === "accept") && (
            <>
              {!isCheckedIn && !activeEvent.isCheckedIn ? (
                <>
                  {activeEvent.showCheckInWarning ? (
                    <InlineBuddyMessage
                      message={
                        <p className="warning">
                          <strong>Oops!</strong> It looks like that isn't a
                          valid join code. Try again.
                        </p>
                      }
                    />
                  ) : (
                    <InlineBuddyMessage
                      message={
                        <p>
                          <strong>{checkInReadyMessage}</strong> Enter your Join
                          Code to check in to this {eventLabel}.{" "}
                          {showBummerSeatMessage && (
                            <>
                              There are no more seats available for this event.
                            </>
                          )}
                        </p>
                      }
                    />
                  )}
                </>
              ) : (
                <InlineBuddyMessage
                  message={
                    <div className="flexRow">
                      <span
                        style={{ width: 20, height: 20, marginRight: 10 }}
                        className="icon check"
                      />
                      <strong style={{ marginRight: 5 }}>All set!</strong>{" "}
                      You're checked in.
                    </div>
                  }
                />
              )}
            </>
          )}

          <div className="actions">
            {showCheckIn && (!hasRSVPd || hasRSVPd === "accept") && (
              <div className="flexRow">
                {!isCheckedIn && !activeEvent.isCheckedIn && (
                  <>
                    <FormControl>
                      <InputMask
                        mask="****-****"
                        onChange={(event) =>
                          dispatch(
                            updateField({
                              field: "joinCode",
                              value: event.target.value,
                            })
                          )
                        }
                        value={activeEvent.joinCode}
                      >
                        <TextField variant="outlined" label="Join Code" />
                      </InputMask>
                    </FormControl>

                    <ButtonLarge
                      onClick={() => {
                        dispatch(
                          checkIn({
                            userEvent:
                              userEvent ?? activeEvent.createdUserEvent,
                          })
                        )
                      }}
                      classes={classNames(!activeEvent.joinCode && "disabled")}
                    >
                      <Check />
                      <span style={{ marginLeft: 5 }}>Check In</span>
                    </ButtonLarge>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      )}

      {showBummerSeatMessage && !showBuddyMessage && (
        <div
          style={{
            marginBottom: activeEvent.currentTab === "attendees" ? -50 : 0,
          }}
          className="flexRow buddyContainer"
        >
          <InlineBuddyMessage
            message={
              <span>
                <strong>Well, that's a bummer.</strong> There are no more seats
                available for this event.
              </span>
            }
          />
        </div>
      )}
    </>
  )
}

export default EventHeader
