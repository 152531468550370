import theme from "@mobilemind/common/src/theme/theme"

function Export() {
  return (
    <svg width="20px" height="20px" viewBox="0 0 20 20">
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="export" fill={theme.palette.primary.main} fillRule="nonzero">
          <path
            d="M18.9092969,1.09074219 C18.2059375,0.387382813 17.2707422,0 16.2760156,0 L3.72398438,0 C1.67054687,0 0,1.67066406 0,3.72394531 L0,16.2760156 C0,18.3294141 1.670625,20 3.72398438,20 L16.2760156,20 C18.3294141,20 20,18.3294141 20,16.2760156 L20,3.72402344 C20,2.72929688 19.6126563,1.79410156 18.9092969,1.09074219 Z M6.47910156,8.49585938 C6.70789063,8.26707031 7.07886719,8.26707031 7.30773437,8.49585938 L9.4140625,10.6021875 L9.41398437,4.35152344 C9.41398437,4.02792969 9.67632812,3.76558594 9.99992187,3.76558594 C10.3235547,3.76558594 10.5858594,4.02792969 10.5858594,4.35152344 L10.5859375,10.6020312 L12.6921094,8.49578125 C12.9208984,8.26699219 13.2919141,8.26699219 13.5207422,8.49578125 C13.7495703,8.72457031 13.7495703,9.09558594 13.5207812,9.32441406 L10.4142969,12.4309766 C10.304375,12.5408984 10.1553516,12.6026172 9.99996094,12.6026172 C9.84460937,12.6026172 9.69554687,12.5408984 9.58570313,12.4309766 L6.47917969,9.32449219 C6.25027344,9.09570312 6.25027344,8.7246875 6.47910156,8.49585938 Z M15.6485156,16.234375 L4.3515625,16.234375 C4.02800781,16.234375 3.765625,15.9720312 3.765625,15.6484375 C3.765625,15.3248437 4.02800781,15.0625 4.3515625,15.0625 L15.6484766,15.0625 C15.9720313,15.0625 16.2344141,15.3248437 16.2344141,15.6484375 C16.2344141,15.9720312 15.9720703,16.234375 15.6485156,16.234375 Z"
            id="Shape"
          ></path>
        </g>
      </g>
    </svg>
  )
}

export default Export
