import classNames from "classnames"
import moment from "moment"
import Values from "values.js"
import theme from "@mobilemind/common/src/theme/theme"
import { Appointments } from "@devexpress/dx-react-scheduler-material-ui"

export function Appointment({
  children,
  data,
  onClick,
  classes,
  toggleVisibility,
  onAppointmentMetaChange,
  ...restProps
}) {
  const props = {
    isShaded: restProps.isShaded,
    resources: restProps.resources,
    style: restProps.style,
    type: restProps.type,
    currentView: restProps.currentView,
  }

  let appointmentTitle, backgroundColor, textColor

  const primary = new Values(theme.palette.primary.main)
  const secondary = new Values(theme.palette.secondary.main)

  if (data.isConference) {
    backgroundColor = primary.shade(15).hexString()
    textColor = "white"
  } else if (data.isExternal) {
    backgroundColor = secondary.shade(35).hexString()
    textColor = "white"
  } else {
    backgroundColor = primary.tint(50).hexString()
    textColor = theme.palette.primary.contrastText
  }

  if (data.isGoal) {
    backgroundColor = theme.palette.secondary.main
    textColor = theme.palette.secondary.contrastText
    appointmentTitle = <>Goal Deadline: {data.title}</>
  } else {
    appointmentTitle = data.title
  }

  let startTime = data.startDate
  let endTime = data.endDate

  return (
    <>
      {(data.rsvp === "Accept" || data.rsvp === "Tentative") && (
        <div
          className={classNames(
            "rsvpIndicator",
            data.rsvp.toLowerCase(),
            moment().isAfter(moment(data.endDate)) && "fade"
          )}
        >
          <span className={classNames("icon", data.rsvp.toLowerCase())} />
        </div>
      )}

      <Appointments.Appointment {...props}>
        <div
          className={classNames(
            "appointmentInner",
            data.isConference && "conference",
            data.rsvp === "decline" && "decline",
            data.rsvp === "accept" && "accept"
          )}
          style={{ backgroundColor }}
          onClick={({ target }) => {
            toggleVisibility()
            onAppointmentMetaChange({
              target: target.parentElement.parentElement,
              data,
            })
          }}
        >
          {!children ? (
            <>
              <div
                style={{
                  marginLeft: 2,
                  marginTop: 2,
                  color: textColor,
                  fontFamily: "Nunito",
                  fontSize: 11,
                  lineHeight: 1.1,
                  marginRight: 20,
                }}
              >
                {appointmentTitle}
              </div>
              {props.currentView !== "Month" && (
                <span
                  style={{
                    color: textColor,
                  }}
                  className="timeLabel"
                >
                  {moment(startTime).format("h:mma")} -{" "}
                  {moment(endTime).format("h:mma")}
                </span>
              )}
            </>
          ) : (
            <>
              <header>{children}</header>
            </>
          )}
        </div>
      </Appointments.Appointment>
    </>
  )
}
