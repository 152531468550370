import Export from "../icons/Export"
import "../styles/index.scss"
import ButtonSmall from "./ButtonSmall"

function ExportLink(props) {
  function exportReport(url) {
    let windowParams =
      "left=200,top=200,width=767,height=450,personalbar=0,toolbar=0,scrollbars=0,resizable=0"
    window.open(url, "", windowParams)
  }

  return (
    <div className="exportLink">
      <ButtonSmall
        onClick={() => {
          exportReport(props.exportURL)
        }}
      >
        <Export />
        <span style={{ marginLeft: 10 }}>Export</span>
      </ButtonSmall>
    </div>
  )
}

export default ExportLink
