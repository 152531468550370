import { Component } from "react"
import { connect } from "react-redux"
import { Card } from "@mui/material"
import { generateTimestamp } from "@mobilemind/common/src/functions/index"
import { Link } from "react-router-dom"
import { setSidebar, getBadge } from "../../actions"

const mapDispatchToProps = {
  setSidebar,
  getBadge,
}

class WidgetBadge extends Component {
  state = {
    message: "",
  }

  componentDidMount = async () => {
    this.setState({ message: this.generateMessage() })
  }

  handleClick = () => {
    this.props.setSidebar("achievements", "badgesEarned")
  }

  generateMessage = () => {
    let { badge } = this.props.widget

    let badgeName = badge && badge.name
    let timestamp = generateTimestamp(badge.earned_date).toLowerCase()

    // Randomly choose a congratulatory message to keep things fun and fresh
    let index = Math.floor(Math.random() * Math.floor(4))
    let options = [
      <>
        Woohoo! You earned the <strong>{badgeName}</strong> badge {timestamp}.
        What’s next?
      </>,
      <>
        Nice! You snagged the <strong>{badgeName}</strong> badge {timestamp}.
        Keep going!
      </>,
      <>
        Oh yeah! The <strong>{badgeName}</strong> badge is yours! What’s next?
      </>,
      <>
        Sweet! You earned the <strong>{badgeName}</strong> badge {timestamp}.
        Keep going!
      </>,
      <>
        Great job! You got the <strong>{badgeName}</strong> badge {timestamp}.
        What’s next?
      </>,
    ]

    return options[index]
  }

  render() {
    const { badge, label } = this.props.widget
    const { theme } = this.props

    if (badge) {
      return (
        <Card className="widget badgeEarned">
          <Link onClick={this.handleClick} to="/backpack/badges">
            <div
              className="widgetLabel success"
              style={{ backgroundColor: theme.palette.secondary.main }}
            >
              {label}
            </div>
            <div className="widgetIcon icon badge" />
            <div className="flexRow">
              <div className="imageWrapper">
                {badge && (
                  <>
                    <img
                      className="badgeImage"
                      alt={badge.field_badge_image_alt}
                      src={badge.field_badge_image_uri}
                    />
                    <span className="icon check" />
                  </>
                )}
              </div>
              <div className="badgeInfo">
                {this.state.message && <p>{this.state.message}</p>}
              </div>
            </div>
          </Link>
        </Card>
      )
    }
    return null
  }
}

export default connect(null, mapDispatchToProps)(WidgetBadge)
