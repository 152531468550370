import { Component } from "react"
import { connect } from "react-redux"

import "../styles/cards.scss"

import Sidebar from "./Sidebar"
import ExploreLayout from "../features/explore/ExploreLayout"
import BadgesLayout from "../features/badges/BadgesLayout"
import LearningPathsLayout from "../features/learningPaths/LearningPathsLayout"
import DashboardLayout from "../features/dashboard/DashboardLayout"
import PropTypes from "prop-types"

import { changeNavbar } from "../actions"
import { getLearningPaths } from "../features/learningPaths/learningPathsSlice"
import { getMandatedTraining } from "../features/mandatedTraining/mandatedTrainingSlice"
import theme from "@mobilemind/common/src/theme/theme"

const mapStateToProps = ({
  session,
  courses,
  categories,
  learningPaths,
  mandatedTraining,
  badges,
  assessments,
  sidebar,
}) => {
  return {
    categories,
    session,
    courses,
    learningPaths,
    mandatedTraining,
    badges,
    assessments,
    sidebar,
  }
}

const mapDispatchToProps = {
  changeNavbar,
  getLearningPaths,
  getMandatedTraining,
}

function LearnLayout(props) {
  return <LearnContainer {...props} theme={theme} />
}

class LearnContainer extends Component {
  state = {
    fetched: false,
    loadingMessage: "Building Your Dashboard...",
  }

  componentDidMount = () => {
    this.props.changeNavbar("learn")
    if (!this.props.learningPaths.hasFetched) {
      this.props.getLearningPaths()
    }
    if (!this.props.mandatedTraining.fetched) {
      this.props.getMandatedTraining()
    }
  }

  render() {
    const page = this.props.sidebar.pages.learn
    return (
      <div className="layout learn">
        <Sidebar
          theme={this.props.theme}
          currentPage="learn"
          activeItem={page.activeItem}
          items={page.items}
        />
        <>
          {page.activeItem === "dashboard" && <DashboardLayout />}
          {page.activeItem === "learningPaths" && <LearningPathsLayout />}
          {page.activeItem === "badges" && <BadgesLayout />}
          {page.activeItem === "explore" && (
            <ExploreLayout theme={this.props.theme} />
          )}
        </>
      </div>
    )
  }
}

LearnLayout.propTypes = {
  assessments: PropTypes.object,
  badges: PropTypes.object,
  courses: PropTypes.object,
  learningPaths: PropTypes.object,
  session: PropTypes.object,
  sidebar: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(LearnLayout)
