import { Component } from "react"
import { CSSTransition } from "react-transition-group"

import { connect } from "react-redux"
import classNames from "classnames"
import "../../styles/rewind.scss"
import mmImg from "../../img/mm-logo-large.svg"

import { isMobile } from "react-device-detect"

import rewindMusic from "./rewind-final-trimmed.mp3"

import {
  getUserName,
  getTimeSpent,
  getTopCategories,
  getAchievements,
  getGoGetter,
  getAllCategories,
  getDedication,
  getPerfect,
} from "./rewindSlice"

import SlideTimeSpent from "./SlideTimeSpent"
import SlideCategoryResults from "./SlideCategoryResults"
import SlideAchievements from "./SlideAchievements"
import SlideGoGetter from "./SlideGoGetter"
import SlideDedication from "./SlideDedication"
import SlideCenturyClub from "./SlideCenturyClub"
import SlidePerfection from "./SlidePerfection"
import SlideOutro from "./SlideOutro"
import SlideEncouragement from "./SlideEncouragement"

import ReactAudioPlayer from "react-audio-player"
import { uploadCertificateImage } from "../../actions"
import { withRouter } from "react-router-dom"
import Loading from "@mobilemind/common/src/components/Loading"
import ButtonLarge from "@mobilemind/common/src/components/ButtonLarge"

const mapStateToProps = ({ session, categories, rewind }, ownProps) => {
  return {
    userId: ownProps.match.params.uuid,
    rewind,
    session,
    categories,
  }
}

const mapDispatchToProps = {
  getUserName,
  getTimeSpent,
  getTopCategories,
  getAchievements,
  getAllCategories,
  getGoGetter,
  getDedication,
  getPerfect,
  uploadCertificateImage,
}

class RewindLayout extends Component {
  state = {
    currentSlide: 0,
    fetched: false,
  }

  componentDidMount() {
    const { userId } = this.props
    let promises = [
      this.props.getUserName(userId),
      this.props.getTimeSpent(userId),
      this.props.getTopCategories(userId),
      this.props.getAllCategories(userId),
      this.props.getAchievements(userId),
      this.props.getGoGetter(userId),
      this.props.getDedication(userId),
      this.props.getPerfect(userId),
    ]

    Promise.all(promises).then(async () => {
      this.setState({ fetched: true })
    })
  }

  componentDidUpdate(prevProps, prevState) {
    const { rewind } = this.props

    if (prevState.currentSlide < this.state.currentSlide) {
      let duration
      switch (this.state.currentSlide) {
        case 2:
          duration = 15000
          break
        case 3:
          duration = 12500
          break
        case 4:
          duration = 11000
          break
        case 5:
          duration = 9000
          break
        case 6:
          duration = 12000
          break
        case 7:
          duration = 10000
          break
        case 8:
          duration = 16500
          break
        default:
          duration = 8000
          break
      }

      setTimeout(() => {
        const showCenturySlide =
          rewind && rewind.achievements
            ? Number(rewind.achievements.completed_courses) >= 50
            : 72
        const showPerfectSlide = rewind
          ? Number(rewind.firstAttemptCount) >= 3
          : 22
        const showGoGetter =
          rewind && rewind.goGetter
            ? Number(rewind.goGetter.first_month) >= 3
            : 14

        // If we're not done
        if (this.state.currentSlide !== 9) {
          // Only show Go Getter if their first month is over 3
          if (this.state.currentSlide === 3) {
            if (showGoGetter) {
              this.setState({ currentSlide: 4 })
            } else {
              this.setState({ currentSlide: 5 })
            }
          }

          // Only show Perfect scores if they have at least 3 perfect first attempts
          else if (this.state.currentSlide === 5) {
            if (showPerfectSlide) {
              this.setState({ currentSlide: 6 })
            } else if (showCenturySlide) {
              this.setState({ currentSlide: 7 })
            } else if (rewind && rewind.needsEncouragement) {
              this.setState({ currentSlide: 8 })
            } else {
              this.setState({ currentSlide: 9 })
            }
          }

          // Only show "Club" slide if they have at least 50 courses completed
          else if (this.state.currentSlide === 6) {
            if (showCenturySlide) {
              this.setState({ currentSlide: 7 })
            } else if (rewind && rewind.needsEncouragement) {
              this.setState({ currentSlide: 8 })
            } else {
              this.setState({ currentSlide: 9 })
            }
          }
          // Don't finish just yet if they need encouragement
          else if (
            rewind &&
            Number(rewind.achievements.completed_courses) >= 50 &&
            this.state.currentSlide === 7
          ) {
            if (!rewind || !rewind.needsEncouragement) {
              this.setState({ currentSlide: 9 })
            } else {
              this.setState({ currentSlide: 8 })
            }
          } else {
            this.setState({ currentSlide: this.state.currentSlide + 1 })
          }
        }
      }, duration)
    }
  }

  render() {
    const { rewind, session, categories } = this.props
    let { currentSlide } = this.state

    return (
      <div
        className={classNames(
          "page rewind",
          isMobile && "mobileRewind",
          currentSlide === 1 && "slide-bg-1",
          currentSlide === 2 && "slide-bg-2",
          currentSlide === 3 && "slide-bg-3",
          currentSlide === 4 && "slide-bg-4",
          currentSlide === 5 && "slide-bg-5",
          currentSlide === 6 && "slide-bg-6",
          currentSlide === 7 && "slide-bg-7",
          currentSlide === 8 && "slide-bg-8",
          currentSlide === 9 && "slide-bg-9"
        )}
      >
        {!currentSlide && rewind.firstName && (
          <CSSTransition className="slide intro" timeout={3000}>
            <div className={"slide intro"}>
              <p>
                Hi {rewind.firstName}! Welcome to{" "}
                <strong>MobileMind Rewind</strong>. We know it's been a crazy
                year, but it's time to celebrate all you've accomplished. Let’s
                check out your growth over the past year!
                {isMobile && (
                  <>
                    <br />
                    <br />
                    You can tap the screen at any time to go back.
                  </>
                )}
              </p>

              <footer>
                {!this.state.fetched ? (
                  <Loading />
                ) : (
                  <ButtonLarge
                    onClick={() => {
                      this.setState({ currentSlide: 1 })
                    }}
                  >
                    Let's Go!
                  </ButtonLarge>
                )}
              </footer>
            </div>
          </CSSTransition>
        )}

        {/* TIME SPENT */}
        {currentSlide === 1 && (
          <CSSTransition className="timeSpent" timeout={3000}>
            <SlideTimeSpent rewind={rewind} />
          </CSSTransition>
        )}

        {/* CATEGORIES */}
        {currentSlide === 2 && (
          <CSSTransition className="categoryResults" timeout={3000}>
            <SlideCategoryResults rewind={rewind} categories={categories} />
          </CSSTransition>
        )}

        {/* ACHIEVEMENTS */}
        {currentSlide === 3 && (
          <CSSTransition className="achievements" timeout={3000}>
            <SlideAchievements rewind={rewind} session={session} />
          </CSSTransition>
        )}

        {/* GO GETTER */}
        {currentSlide === 4 && (
          <CSSTransition className="achievements" timeout={3000}>
            <SlideGoGetter rewind={rewind} />
          </CSSTransition>
        )}

        {/* DEDICATION */}
        {currentSlide === 5 && (
          <CSSTransition className="dedication" timeout={3000}>
            <SlideDedication rewind={rewind} />
          </CSSTransition>
        )}

        {/* PERFECT SCORES */}
        {currentSlide === 6 && (
          <CSSTransition className="perfection" timeout={3000}>
            <SlidePerfection rewind={rewind} />
          </CSSTransition>
        )}

        {/* CENTURY CLUB */}
        {currentSlide === 7 && (
          <CSSTransition className="achievements" timeout={3000}>
            <SlideCenturyClub rewind={rewind} />
          </CSSTransition>
        )}

        {/* ENCOURAGEMENT */}
        {currentSlide === 8 && (
          <CSSTransition className="encouragement" timeout={3000}>
            <SlideEncouragement />
          </CSSTransition>
        )}

        {/* OUTRO */}
        {currentSlide === 9 && (
          <CSSTransition className="outro" timeout={3000}>
            <SlideOutro
              uploadImage={this.props.uploadCertificateImage}
              rewind={rewind}
              session={session}
              categories={categories}
            />
          </CSSTransition>
        )}

        <img
          className={classNames(
            "icon mm-logo large",
            currentSlide === 1 && "slide-1",
            currentSlide === 2 && "slide-2",
            currentSlide === 3 && "slide-3",
            currentSlide === 4 && "slide-4",
            currentSlide === 5 && "slide-6",
            currentSlide === 6 && "slide-7",
            currentSlide === 7 && "slide-4",
            currentSlide === 8 && "slide-3",
            currentSlide === 9 && "slide-5"
          )}
          src={mmImg}
          alt="mobilemind"
        />

        {currentSlide > 0 && (
          <div style={{ opacity: 0, pointerEvents: "none" }}>
            <ReactAudioPlayer src={rewindMusic} autoPlay controls />
          </div>
        )}
      </div>
    )
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RewindLayout)
)
