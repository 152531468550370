import { Component } from "react"
import { connect } from "react-redux"
import "../../../styles/event-single.scss"
import { generateTimestamp } from "@mobilemind/common/src/functions/index"
import { fetchEventMessages } from "./activeEventSlice"
import SanitizedHTML from "@mobilemind/common/src/components/SanitizedHTML"

const mapDispatchToProps = {
  fetchEventMessages,
}

class EventUpdates extends Component {
  componentDidMount() {
    this.props.fetchEventMessages()
  }

  render() {
    const { activeEvent, isConference, isObservation } = this.props

    let eventLabel = "Event"
    if (isConference) {
      eventLabel = "Conference"
    }
    if (isObservation) {
      eventLabel = "Observation"
    }

    return (
      <div className="event-updates">
        <section>
          <h3>{eventLabel} Updates</h3>
          {activeEvent.messages.length > 0 ? (
            <ul>
              {activeEvent.messages.map((message) => {
                if (message.user) {
                  return (
                    <li key={message.id}>
                      <header>
                        <div className="userPictureWrapper">
                          <div
                            className="userPicture"
                            style={{
                              backgroundImage:
                                "url(" +
                                process.env.REACT_APP_API_URL +
                                message.attributes.field_user_image_path +
                                ")",
                            }}
                          />
                        </div>

                        <strong style={{ textTransform: "capitalize" }}>
                          {message.user.attributes.display_name
                            .toLowerCase()
                            .replace(".", " ")}
                        </strong>

                        <div className="timestamp">
                          Posted {generateTimestamp(message.attributes.created)}
                        </div>
                      </header>
                      <SanitizedHTML
                        html={message.attributes.field_message.value.replaceAll(
                          "<br>",
                          ""
                        )}
                      />
                    </li>
                  )
                }
                return null
              })}
            </ul>
          ) : (
            <p>
              <em>There are no updates to display.</em>
            </p>
          )}
        </section>
      </div>
    )
  }
}

export default connect(null, mapDispatchToProps)(EventUpdates)
