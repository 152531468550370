import React from "react"
import { Component } from "react"
import { connect } from "react-redux"

import {
  changeNavbar,
  sendMessage,
  createAssessment,
  getAssessmentsForCourses,
  deleteAssessment,
  collapseSidebar,
} from "../../actions"

import { checkCanRetakeCourse } from "@mobilemind/common/src/actions/mandatedTraining"

import {
  getUserLearningPaths,
  getCourse,
  updateAssessment,
  getQuiz,
  getAssessmentRevisions,
} from "@mobilemind/common/src/actions/course"

import { getMandatedTraining } from "../mandatedTraining/mandatedTrainingSlice"
import { generateTimestamp } from "@mobilemind/common/src/functions/index"
import classNames from "classnames"
import { generateVideoEmbedURL } from "@mobilemind/common/src/functions/index"
import { generateBuddyMessage } from "@mobilemind/common/src/functions/learningBuddy"
import { isMobile, isChrome, isEdge } from "react-device-detect"
import AssessmentRevisionModal from "../../components/AssessmentRevisionModal"
import theme from "@mobilemind/common/src/theme/theme"
import ButtonSmall from "../../components/ButtonSmall"
import SanitizedHTML from "@mobilemind/common/src/components/SanitizedHTML"
import Loading from "@mobilemind/common/src/components/Loading"
import Unauthorized from "@mobilemind/common/src/components/Unauthorized"
import ModalQuizResult from "../../components/ModalQuizResult"
import ButtonLarge from "@mobilemind/common/src/components/ButtonLarge"
import { getBadges } from "../badges/badgesSlice"
import "../../styles/course-single.scss"
import Pin from "../../icons/Pin"
import { withRouter } from "react-router-dom"
import QuizLayout from "./QuizLayout"
import { CourseRevisionHistory } from "./RevisionHistory"
import { CourseSidebar } from "./CourseSidebar"
import {
  generateCourseLayoutProps,
  generateMailToString,
  setShowStartButton,
  setShowRevisionHistory,
  setShowAsSaved,
  calculateRevisionHeight,
} from "./helperFunctions"

const mapStateToProps = (state, ownProps) => {
  return generateCourseLayoutProps({ state, ownProps })
}

const mapDispatchToProps = {
  changeNavbar,
  getCourse,
  getAssessmentsForCourses,
  checkCanRetakeCourse,
  getQuiz,
  getAssessmentRevisions,
  deleteAssessment,
  getBadges,
  createAssessment,
  updateAssessment,
  generateTimestamp,
  getMandatedTraining,
  getUserLearningPaths,
  collapseSidebar,
  sendMessage,
}

class CourseLayout extends Component {
  constructor(props) {
    super(props)
    this.quizRef = React.createRef()
  }

  state = {
    assessmentStarted: false,
    showSaveButton: true,
    showQuizResults: false,
    isQuizInView: false,
  }

  componentDidMount = async () => {
    const { badges, session } = this.props

    this.setState({
      congrats: generateBuddyMessage("congratulatory"),
      bummer: generateBuddyMessage("bummer"),
      encouragement: generateBuddyMessage("encouragement"),
    })

    this.props.changeNavbar("learn")
    if (!isMobile || window.innerWidth > 667) {
      this.props.collapseSidebar(true)
    }

    this.getCourseData(this.props.match.params.courseId)
    if (!session.fetched.userLearningPaths) {
      this.props.getUserLearningPaths(session.user)
    }

    if (!badges.fetched) {
      this.props.getBadges()
    }
  }

  getCourseData = async (courseId) => {
    this.props.getCourse(courseId, true).then((courseRequest) => {
      if (courseRequest && courseRequest.course) {
        this.getAssessmentForCourse(courseRequest.course.id)
        if (courseRequest.course.relationships.field_quiz.data) {
          this.props.getQuiz(courseRequest.quizId, courseRequest.course.id)
        }
        this.setState({ courseRequest })
      } else {
        this.setState({ unauthorized: true })
      }
    })
  }

  getAssessmentForCourse = async (courseId) => {
    // Get mandated training courses if we haven't already
    let allMandatedCourses = []
    if (!this.props.mandatedTraining.fetched) {
      await this.props.getMandatedTraining()
    }

    this.props.mandatedTraining.data.forEach((series) => {
      allMandatedCourses = allMandatedCourses.concat(series.rcs_courses)
    })

    const outOfComplianceTraining = this.props.mandatedTraining.data.find(
      (training) => {
        return training.rcs_courses.find((course) => {
          return (
            Number(course.id) ===
            this.props.activeCourse.attributes.drupal_internal__id
          )
        })
      }
    )

    let assessment = await this.props.getAssessmentsForCourses([courseId])
    const canRetakeCourse = await this.props.checkCanRetakeCourse(
      this.props.activeCourse.attributes.drupal_internal__id
    )

    assessment = assessment.reverse()

    if (assessment && assessment[0]) {
      this.setState({
        canRetakeCourse,
        outOfComplianceTraining,
      })
      await this.props.getAssessmentRevisions(
        assessment[0].attributes.drupal_internal__id
      )
      if (this.props.activeCourse) {
        const courseQuiz =
          this.props.activeCourse.attributes.field_submission_type === "Quiz"

        const historyEl = document.getElementById("revisionHistory")
        if (!courseQuiz && historyEl) {
          let revisionHeight = {
            total: historyEl.clientHeight - 30,
            firstChild:
              document.getElementById("latest") &&
              document.getElementById("latest").clientHeight,
          }

          this.setState({ revisionHeight })
        }
      }
    }
    this.setState({ assessmentFetched: true })
  }

  handleExtension = async ({ type, isStarting, learningPath }) => {
    const {
      activeUserLearningPath,
      activeCourse,
      learningPathId,
      mandatedTrainingId,
    } = this.props

    if (type === "Quiz") {
      setTimeout(() => {
        this.quizRef.current.scrollIntoView()
      }, 100)
      this.setState({ isQuizInView: true })
    } else {
      !isMobile &&
        (isChrome || isEdge) &&
        this.props.sendMessage({
          type: "openExtension",
        })
    }

    this.setState({ assessmentStarted: true })
    let course = { ...activeCourse }
    course.uuid = course.id

    if (isStarting) {
      // Determine if we're starting from a previously saved assessment
      let assessmentId, newAssessment
      if (!this.props.assessmentIsSaved) {
        newAssessment = await this.props.createAssessment(
          this.props.session,
          this.props.activeCourse,
          "in_progress"
        )
        assessmentId = newAssessment.id
      } else {
        this.setState({ isSaved: false })
        assessmentId = this.props.assessment.id

        await this.props.updateAssessment(assessmentId, "in_progress")
      }
    }

    !isMobile &&
      (isChrome || isEdge) &&
      type !== "Quiz" &&
      this.props.sendMessage({
        type: "startAssessment",
        learningPathId,
        mandatedTrainingId,
        assessment: this.props.assessment,
        assessmentId: this.props.assessment.id,
        categories: this.props.categories,
        activeCourse: course,
        courseRequest: this.state.courseRequest,
        userLearningPath: activeUserLearningPath,
        userLearningPaths: this.props.userLearningPaths,
        learningPath,
      })
  }

  resumeAssessment = () => {
    const { activeCourse } = this.props

    this.handleExtension({
      type: activeCourse.attributes.field_submission_type,
    })
  }

  startAssessment = async () => {
    const { activeUserLearningPath, activeCourse } = this.props

    this.handleExtension({
      type: activeCourse.attributes.field_submission_type,
      isStarting: true,
      learningPath:
        activeUserLearningPath && activeUserLearningPath.learningPath,
    })
  }

  save = async () => {
    this.setState({ isSaved: true, saveButtonClicked: true })
    await this.props.createAssessment(
      this.props.session,
      this.props.activeCourse,
      "saved_for_later"
    )
  }

  unsave = async () => {
    this.setState({ isSaved: false, saveButtonClicked: false })
    if (this.props.assessment.id) {
      await this.props.deleteAssessment(
        this.props.assessment.id,
        this.props.session
      )
    }
  }

  openAttachment = (file) => {
    const url = process.env.REACT_APP_API_URL + file.attributes.uri.url
    let windowParams =
      "left=200,top=200,width=880,height=600,personalbar=0,toolbar=0,scrollbars=0,resizable=0"
    window.open(url, "", windowParams)
  }

  render() {
    const {
      categories,
      activeCourse,
      relatedCourses,
      courseBadges,
      assessment,
      assessmentIsSaved,
      previousAssessments,
      session,
    } = this.props

    const {
      congrats,
      canRetakeCourse,
      assessmentFetched,
      bummer,
      encouragement,
      saveButtonClicked,
      assessmentStarted,
      isHistoryExpanded,
    } = this.state

    let isSubmitted = false,
      isCompleted = false

    if (this.props.unauthorized || this.state.unauthorized) {
      return <Unauthorized />
    }

    if (activeCourse) {
      const courseQuiz = activeCourse.quizEntity
      const correctAnswers =
        assessment &&
        assessment.quizAnswers &&
        assessment.quizAnswers.filter(
          (answer) => answer.attributes.field_correct
        )
      const quizQuestions = courseQuiz && courseQuiz.questions
      const quizPassed =
        assessment &&
        activeCourse.quiz &&
        courseQuiz &&
        Number(activeCourse.quiz.attributes.field_pass_rate) <=
          Number(assessment.attributes.field_quiz_score)

      const showSaveButton =
        session.fetched.userLearningPaths &&
        (!assessmentIsSaved || saveButtonClicked === false)

      const showAsSaved = setShowAsSaved({
        assessment,
        assessmentIsSaved,
        assessmentStarted,
        showSaveButton,
        saveButtonClicked,
      })

      const showStatus = (assessment && !assessmentIsSaved) || assessmentStarted

      // Figure out what the status of the assessment means for us
      const assessmentStatus = assessment && assessment.attributes.field_status
      if (assessmentStatus) {
        isSubmitted =
          assessmentStatus === "submitted" ||
          assessmentStatus === "grading_in_progress"
        isCompleted = assessmentStatus === "completed"
      }

      // Determine if we should allow them to start
      const showStartButton = setShowStartButton({
        assessmentFetched,
        assessment,
        canRetakeCourse,
        assessmentIsSaved,
      })

      const mailToString = generateMailToString({ activeCourse })

      const videoEmbed =
        activeCourse.attributes.field_video_link &&
        generateVideoEmbedURL(activeCourse.attributes.field_video_link)

      const showRevisionHistory = setShowRevisionHistory({
        assessment,
        courseQuiz,
        canRetakeCourse,
      })

      const revisionHeight = calculateRevisionHeight({
        revisionHeight: this.state.revisionHeight,
        isHistoryExpanded,
      })

      const fadeSave = !showSaveButton || saveButtonClicked || assessmentStarted

      return (
        <>
          {courseQuiz &&
            activeCourse.quiz &&
            activeCourse.quiz.attributes.field_show_missed_questions && (
              <ModalQuizResult
                quizEntity={activeCourse.quiz}
                fail={!quizPassed}
                correctAnswers={correctAnswers}
                assessment={assessment}
                quiz={courseQuiz}
                open={this.state.showQuizResults}
                onClose={() => {
                  this.setState({ showQuizResults: false })
                }}
              />
            )}

          {this.state.activeImage && (
            <AssessmentRevisionModal
              revision={this.state.activeImage}
              onClose={() => this.setState({ activeImage: null })}
            />
          )}

          <div className={classNames("page course-single")}>
            <div className="stickyHeader">
              <h1>{activeCourse.attributes.name}</h1>
              {!assessmentStatus && !assessmentStarted && !showAsSaved && (
                <ButtonSmall
                  classes={classNames("save", fadeSave && "fade")}
                  onClick={() => this.save()}
                >
                  <Pin />
                  <span style={{ marginLeft: 5 }}>Save for Later</span>
                </ButtonSmall>
              )}

              {showStatus && (
                <>
                  {
                    // We can't allow users to manipulate assessments outside of Chrome or without the extension
                    !isMobile &&
                      (isChrome || isEdge) &&
                      this.props.extension.version &&
                      assessmentStatus &&
                      assessmentStatus === "in_progress" &&
                      !this.state.isQuizInView && (
                        <ButtonLarge
                          onClick={this.resumeAssessment}
                          classes={"sticky"}
                        >
                          {activeCourse.attributes &&
                          activeCourse.attributes.field_submission_type ===
                            "Quiz" ? (
                            <>
                              <span className="icon quiz-icon" />
                              Retake Quiz
                            </>
                          ) : (
                            <>
                              <span className="icon rocket" />
                              Resume Challenge
                            </>
                          )}
                        </ButtonLarge>
                      )
                  }
                </>
              )}

              {showAsSaved && (
                <ButtonSmall onClick={this.unsave} classes={"unsave"}>
                  <div className="pin icon">
                    <Pin />
                  </div>
                  <span>Unsave</span>
                </ButtonSmall>
              )}
            </div>
            {showRevisionHistory && (
              <div
                className={classNames(
                  "assessmentStatus",
                  "panel",
                  isCompleted && "isCompleted",
                  isSubmitted && "isSubmitted",
                  assessment && !isSubmitted && !isCompleted && "started",
                  assessmentStarted && "new"
                )}
              >
                <CourseRevisionHistory
                  setState={this.setState.bind(this)}
                  isHistoryExpanded={isHistoryExpanded}
                  revisionHeight={revisionHeight}
                  assessment={assessment}
                  activeCourse={activeCourse}
                  courseQuiz={courseQuiz}
                  correctAnswers={correctAnswers}
                  quizQuestions={quizQuestions}
                />
              </div>
            )}

            <section className="content-section">
              <h3>
                <span className="icon idea" />
                Learn
              </h3>
              {activeCourse.attributes.field_course_objective && (
                <SanitizedHTML
                  html={activeCourse.attributes.field_course_objective}
                />
              )}
            </section>

            <section className="content-section">
              <h3>
                <span className="icon pencil-box" />
                Apply
              </h3>
              {activeCourse.attributes.field_course_content && (
                <SanitizedHTML
                  html={activeCourse.attributes.field_course_content}
                />
              )}
            </section>

            {videoEmbed && (
              <div className="videoWrapper">
                <iframe
                  title="Course Video"
                  width="675"
                  height="380"
                  src={videoEmbed}
                  allow="autoplay; encrypted-media"
                  allowFullScreen={true}
                ></iframe>
              </div>
            )}

            {activeCourse.attachments &&
              activeCourse.attachments.length > 0 && (
                <section className="content-section">
                  <h3>
                    <span className="icon crates" />
                    Attachments
                  </h3>
                  {activeCourse.attachments.map((file) => {
                    let extension = file.attributes.filename.split(".").pop()

                    return (
                      <div
                        key={file.id}
                        onClick={() => {
                          this.openAttachment(file)
                        }}
                        className="attachment"
                      >
                        {extension !== "png" &&
                        extension !== "jpg" &&
                        extension !== "jpeg" ? (
                          <span
                            className={classNames("icon", extension)}
                          ></span>
                        ) : (
                          <span
                            className={classNames("icon image")}
                            style={{
                              backgroundImage:
                                "url(" +
                                process.env.REACT_APP_API_URL +
                                file.attributes.uri.url +
                                ")",
                            }}
                          />
                        )}
                        {file.attributes.filename}
                      </div>
                    )
                  })}
                </section>
              )}

            {showStartButton &&
              this.props.extension.version &&
              !assessmentStarted && (
                <ButtonLarge
                  id="startAssessment"
                  className={classNames("button large primary")}
                  onClick={this.startAssessment}
                  showStartButton
                >
                  {activeCourse.attributes.field_submission_type === "Quiz" ? (
                    <>
                      <span className="icon quiz-icon"></span>Begin Quiz
                    </>
                  ) : (
                    <>
                      <span className="icon rocket"></span>Start Challenge
                    </>
                  )}
                </ButtonLarge>
              )}

            <CourseSidebar
              previousAssessments={previousAssessments}
              activeCourse={activeCourse}
              categories={categories}
              assessment={assessment}
              mailToString={mailToString}
              courseBadges={courseBadges}
              relatedCourses={relatedCourses}
              assessmentFetched={assessmentFetched}
              classNames={classNames}
              bummer={bummer}
              goBack={() => this.props.history.goBack()}
              encouragement={encouragement}
              congrats={congrats}
              outOfComplianceTraining={this.state.outOfComplianceTraining}
              canRetakeCourse={canRetakeCourse}
            />

            {activeCourse.attributes.field_additional_resources && (
              <section className="content-section">
                <h3>
                  <span className="icon crates" />
                  Additional Resources
                </h3>
                <SanitizedHTML
                  html={
                    activeCourse.attributes.field_additional_resources.value
                  }
                />
              </section>
            )}

            {activeCourse.attributes.field_support_email &&
              activeCourse.attributes.field_support_email.length > 0 && (
                <section className="content-section">
                  <h3>
                    <span className="icon help" />
                    Need Help?
                  </h3>
                  <p>
                    Reach out to the following email address if you have
                    questions about this challenge:
                  </p>
                  <a href={mailToString} className="supportEmail">
                    <ButtonSmall>
                      {activeCourse.attributes.field_support_email}
                    </ButtonSmall>
                  </a>
                </section>
              )}
          </div>

          {activeCourse.attributes &&
            activeCourse.attributes.field_submission_type === "Quiz" && (
              <div style={{ paddingTop: 60 }} ref={this.quizRef}>
                <QuizLayout
                  assessment={assessment}
                  isQuizInView={this.state.isQuizInView}
                  activeCourse={activeCourse}
                />
              </div>
            )}
        </>
      )
    } else {
      return (
        <Loading
          color={theme.palette.secondary.main}
          fullPage={true}
          message="Getting course…"
        />
      )
    }
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CourseLayout)
)
