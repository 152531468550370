import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import fetchWrapper from "@mobilemind/common/src/functions/fetchWrapper"
import qs from "qs"
import moment from "moment"

export const getUserGoals = createAsyncThunk(
  "goalsSlice/getUserGoals",
  async (args, thunkAPI) => {
    let query = {
      filter: {
        "field_user.id": thunkAPI.getState().session.user.id,
      },
      include: "field_goal",
    }

    if (args && args.status) {
      query.filter.field_status = args.status
    }

    let response = await fetchWrapper.get(
      "/api/user_goal_entity/user_goal_entity?" + qs.stringify(query)
    )
    let data = await response.json()

    let userGoals = data.data

    // Set them up for adding to Sync calendar
    userGoals.forEach((userGoal) => {
      userGoal.goal = data.included.find(
        (goal) => goal.id === userGoal.relationships.field_goal.data.id
      )

      if (userGoal.goal) {
        userGoal.startDate = moment(
          userGoal.goal.attributes.field_goal_date
        ).startOf("day")
        userGoal.endDate = moment(userGoal.goal.attributes.field_goal_date).add(
          1,
          "day"
        )
      }

      userGoal.title = userGoal.goal && userGoal.goal.attributes.name
      userGoal.drupal_internal__id = userGoal.attributes.drupal_internal__id
    })

    return data
  }
)

export const goalsSlice = createSlice({
  name: "goalsSlice",
  initialState: {
    data: [],
    fetched: false,
  },
  extraReducers: {
    [getUserGoals.pending]: (state) => {
      state.fetched = false
    },
    [getUserGoals.fulfilled]: (state, action) => {
      state.data = action.payload.data
      state.fetched = true
    },
  },
})

export default goalsSlice.reducer
