import SubmitTicketLayout from "@mobilemind/common/src/components/SubmitTicketLayout"

function SubmitTicket() {
  return (
    <SubmitTicketLayout
      returnButtonText="Continue Learning"
      portalId={"20989892"}
      formId={"b2c3de60-5994-4526-8620-317526d3aa6d"}
    />
  )
}

export default SubmitTicket
