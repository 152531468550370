import { useDispatch } from "react-redux"
import { TextField, FormControl } from "@mui/material"
import { updateAttendeeSearch } from "./activeEventSlice"
import "../../../styles/event-single.scss"
import Loading from "@mobilemind/common/src/components/Loading"
import UserInitial from "@mobilemind/common/src/components/UserInitial"
import theme from "@mobilemind/common/src/theme/theme"

function EventAttendees(props) {
  const dispatch = useDispatch()
  const { activeEvent } = props

  return (
    <div className="event-attendees">
      <section>
        <header>
          <h3>
            Participants
            {activeEvent.sessionMaxCapacity > 0 && (
              <div className="capacity-status">
                <span
                  style={{ marginRight: 5, width: 20, height: 20 }}
                  className="icon chair"
                />
                {activeEvent.atCapacity ? (
                  <strong>
                    {activeEvent.sessionMaxCapacity.toLocaleString()}
                  </strong>
                ) : (
                  <strong>{activeEvent.totalAttendees.toLocaleString()}</strong>
                )}
                /
                <strong>
                  {activeEvent.sessionMaxCapacity.toLocaleString()}
                </strong>
                <span
                  style={{
                    textTransform: "uppercase",
                    fontWeight: "normal",
                    position: "relative",
                    fontSize: 14,
                    top: -5,
                    left: 5,
                  }}
                >
                  Seats filled
                </span>
              </div>
            )}
          </h3>
          <div className="inputSearch">
            <span className="icon search" />
            <FormControl>
              <TextField
                variant="outlined"
                label="Search"
                value={activeEvent.attendeeSearch}
                onChange={(event) =>
                  dispatch(
                    updateAttendeeSearch({
                      field: "attendeeSearch",
                      value: event.target.value,
                    })
                  )
                }
              />
            </FormControl>
          </div>
        </header>

        {activeEvent.attendees && (
          <ul className="attendee-list">
            {!activeEvent.attendees.content &&
              activeEvent.attendees.map((attendee, index) => {
                return (
                  <li key={index}>
                    <div className="userPictureWrapper">
                      {!attendee.user_picture.includes("default_temp") ? (
                        <div
                          className="userPicture"
                          style={{
                            backgroundImage:
                              "url(" +
                              process.env.REACT_APP_API_URL +
                              attendee.user_picture +
                              ")",
                          }}
                        />
                      ) : (
                        <UserInitial
                          size={40}
                          initials={
                            attendee.field_first_name.substring(0, 1) +
                            attendee.field_last_name.substring(0, 1)
                          }
                        />
                      )}
                    </div>
                    <span className="name">
                      {attendee.field_first_name}{" "}
                      {attendee.field_last_name.substring(0, 1)}.
                    </span>
                    <span className="status">
                      {attendee.field_rsvp === "Accept" && (
                        <>
                          Attending
                          <span className="icon rsvp" />
                        </>
                      )}
                      {!attendee.field_rsvp && (
                        <>
                          No Response
                          <span className="icon rsvp-tentative" />
                        </>
                      )}
                      {attendee.field_rsvp === "Tentative" && (
                        <>
                          Tentative
                          <span className="icon rsvp-tentative" />
                        </>
                      )}
                      {attendee.field_rsvp === "Decline" && (
                        <>
                          Declined
                          <span className="icon rsvp-decline" />
                        </>
                      )}
                    </span>
                  </li>
                )
              })}
            {activeEvent.attendeesSearching && (
              <Loading
                color={theme.palette.secondary.main}
                message="Getting participants..."
              />
            )}
          </ul>
        )}

        {activeEvent.attendees && activeEvent.attendees.content && (
          <p>No participants match your search.</p>
        )}

        {activeEvent.totalAttendees > 10 && (
          <p>+{activeEvent.totalAttendees - 10} more</p>
        )}

        {!activeEvent.totalAttendees && activeEvent.attendeeSearch && (
          <p>
            <em>No participants match your search.</em>
          </p>
        )}
      </section>
    </div>
  )
}

export default EventAttendees
