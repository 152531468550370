import { Component } from "react"
import { connect } from "react-redux"
import Tour from "reactour"

import { Dialog, Button } from "@mui/material"
import ReactGA from "react-ga4"

import { setSidebar, setProductTourStarted, endProductTour } from "../actions"
import PropTypes from "prop-types"
import LearningBuddyMessage from "@mobilemind/common/src/components/LearningBuddyMessage"
import theme from "@mobilemind/common/src/theme/theme"
import ButtonSmall from "./ButtonSmall"
import ButtonLarge from "./ButtonLarge"
import { isBefore } from "date-fns"

const mapStateToProps = ({ session, productTour }) => {
  return {
    session,
    productTour,
  }
}

const mapDispatchToProps = {
  setSidebar,
  endProductTour,
  setProductTourStarted,
}

function ProductTour(props) {
  return <ProductTourInner {...props} theme={theme} />
}

class ProductTourInner extends Component {
  state = {
    isTourOpen: false,
    isDialogOpen: false,
  }

  componentDidMount = () => {
    if (
      (!this.props.session.user.attributes.field_product_tour_started &&
        !this.props.productTour.hasStarted) ||
      isBefore(
        new Date(this.props.session.user.attributes.field_product_tour_started),
        new Date(2023, 2, 28)
      )
    ) {
      this.setState({ isDialogOpen: true })
    } else {
      this.setState({ isTourOpen: true })
    }
    if (process.env.REACT_APP_LEARN_GA4_ID) {
      ReactGA.initialize(process.env.REACT_APP_LEARN_GA4_ID, { debug: true })
    }
  }

  componentDidUpdate = (prevProps) => {
    const { productTour, session } = this.props

    if (prevProps.productTour.isStarted !== productTour.isStarted) {
      if (
        (productTour.isStarted &&
          session.user.attributes.field_product_tour_started) ||
        productTour.trigger
      ) {
        this.setState({ isTourOpen: true })
        this.props.setSidebar("learn", "dashboard")
      }
    }

    if (
      !this.state.isDialogOpen &&
      !this.state.isTourOpen &&
      !session.user.attributes.field_product_tour_started &&
      !this.props.productTour.hasStarted &&
      !productTour.trigger
    ) {
      this.setState({ isDialogOpen: true })
    }
  }

  onStepChange = (currentStep) => {
    const { session } = this.props
    const leaderboardsVisibility =
      session.group &&
      session.group.field_teacher_leaderboard_visibi &&
      session.group.field_teacher_leaderboard_visibi[0] &&
      session.group.field_teacher_leaderboard_visibi[0].value

    if (currentStep === 0) {
      this.props.setSidebar("learn", "dashboard")
    }
    if (currentStep === 1) {
      this.props.setSidebar("learn", "learningPaths")
    }
    if (currentStep === 2) {
      this.props.setSidebar("learn", "learningPaths")
    }
    if (currentStep === 3) {
      this.props.setSidebar("learn", "badges")
    }
    if (currentStep === 4) {
      this.props.setSidebar("learn", "explore")
    }
    if (
      currentStep === 10 ||
      (leaderboardsVisibility === "none" && currentStep === 9)
    ) {
      this.props.setSidebar("learn", "dashboard")
    }
  }

  startTour = () => {
    ReactGA.event({
      category: "productTour",
      action: "click",
      label: "Product Tour Started",
    })
    this.setState({
      isTourOpen: true,
      isDialogOpen: false,
    })
    this.props.setProductTourStarted(this.props.session)
  }

  closeTour = (message) => {
    if (message) {
      ReactGA.event({
        category: "productTour",
        action: "click",
        label: message,
      })
    }
    this.props.endProductTour()
    this.props.setProductTourStarted(this.props.session)
  }

  goToNext = (nextStep, goTo) => {
    if (nextStep === "Finished") {
      this.closeTour("Product Tour Finished")
    } else {
      ReactGA.event({
        category: "productTour",
        action: "click",
        label: "Step " + nextStep,
      })
      goTo(nextStep - 1)
    }
  }

  render() {
    const { session } = this.props
    const { isTourOpen, isDialogOpen } = this.state
    const leaderboardsVisibility =
      session.group &&
      session.group.field_teacher_leaderboard_visibi &&
      session.group.field_teacher_leaderboard_visibi[0] &&
      session.group.field_teacher_leaderboard_visibi[0].value

    let groupLabel, orgLabel

    if (session.user.attributes.field_subgroup) {
      groupLabel = session.user.attributes.field_subgroup
    }
    if (session.group) {
      orgLabel = session.group.label[0].value
    }

    const leaderboardLabel =
      leaderboardsVisibility === "org" ? orgLabel : groupLabel

    let totalSteps = 11
    if (leaderboardsVisibility === "none") {
      totalSteps = 10
    }

    const tourSteps = [
      {
        selector: "#tour-sidebar-dashboard button",
        content: ({ goTo }) => (
          <LearningBuddyMessage
            message={
              <div>
                <span>
                  The <strong>Dashboard</strong> has course updates, community
                  events, and news from your organization.
                </span>
                <footer className="flexEnd">
                  <ButtonSmall classes={"disabled"}>Back</ButtonSmall>
                  <div className="step">1 / {totalSteps}</div>
                  <ButtonSmall
                    onClick={() => {
                      this.goToNext(2, goTo)
                    }}
                  >
                    Next
                  </ButtonSmall>
                </footer>
              </div>
            }
          />
        ),
        style: { marginLeft: 10 },
      },
      {
        selector: "#tour-sidebar-learning-paths button",
        content: ({ goTo }) => (
          <LearningBuddyMessage
            message={
              <div>
                <span>
                  <strong>Learning Paths</strong> are collections of themed
                  courses designed to get you on your way to rapidly mastering
                  skills and earning badges.
                </span>
                <footer>
                  <ButtonSmall onClick={() => goTo(0)}>Back</ButtonSmall>
                  <div className="step">2 / {totalSteps}</div>
                  <ButtonSmall
                    onClick={() => {
                      this.goToNext(3, goTo)
                    }}
                  >
                    Next
                  </ButtonSmall>
                </footer>
              </div>
            }
          />
        ),
        style: { marginLeft: 10 },
      },
      {
        selector: "#tour-sidebar-filters",
        content: ({ goTo }) => (
          <LearningBuddyMessage
            message={
              <div>
                <span>
                  Use <strong>Filters</strong> in the sidebar to control the
                  content you see.
                </span>
                <footer>
                  <ButtonSmall onClick={() => goTo(1)} className="button small">
                    Back
                  </ButtonSmall>
                  <div className="step">3 / {totalSteps}</div>
                  <ButtonSmall
                    onClick={() => {
                      this.goToNext(4, goTo)
                    }}
                    className="button small"
                  >
                    Next
                  </ButtonSmall>
                </footer>
              </div>
            }
          />
        ),
        style: { marginLeft: 10 },
      },
      {
        selector: "#tour-sidebar-earn-badges button",
        content: ({ goTo }) => (
          <LearningBuddyMessage
            message={
              <div>
                <span>
                  <strong>Earn Badges</strong> by successfully completing
                  micro-course challenges. Use this tab to track badges you
                  haven't earned yet.
                </span>
                <footer>
                  <ButtonSmall onClick={() => goTo(2)} className="button small">
                    Back
                  </ButtonSmall>
                  <div className="step">4 / {totalSteps}</div>
                  <ButtonSmall
                    onClick={() => {
                      this.goToNext(5, goTo)
                    }}
                    className="button small"
                  >
                    Next
                  </ButtonSmall>
                </footer>
              </div>
            }
          />
        ),
        style: { marginLeft: 10 },
      },
      {
        selector: "#tour-sidebar-explore button",
        content: ({ goTo }) => (
          <LearningBuddyMessage
            message={
              <div>
                <span>
                  The <strong>Explore</strong> tab lets you dive into the full
                  micro-course library. Search for courses, or filter by
                  Category or Learning Path.
                </span>
                <footer>
                  <ButtonSmall onClick={() => goTo(3)} className="button small">
                    Back
                  </ButtonSmall>
                  <div className="step">5 / {totalSteps}</div>
                  <ButtonSmall
                    onClick={() => {
                      this.goToNext(6, goTo)
                    }}
                    className="button small"
                  >
                    Next
                  </ButtonSmall>
                </footer>
              </div>
            }
          />
        ),
        style: { marginLeft: 10 },
      },
      {
        selector: "#tour-navbar-in-progress",
        content: ({ goTo }) => (
          <LearningBuddyMessage
            message={
              <div>
                <span>
                  Use the <strong>In Progress</strong> page to track the courses
                  you’ve saved, started, or submitted, and view feedback from
                  reviewers.
                </span>
                <footer>
                  <ButtonSmall onClick={() => goTo(4)} className="button small">
                    Back
                  </ButtonSmall>
                  <div className="step">6 / {totalSteps}</div>
                  <ButtonSmall
                    onClick={() => {
                      this.goToNext(7, goTo)
                    }}
                    className="button small"
                  >
                    Next
                  </ButtonSmall>
                </footer>
              </div>
            }
          />
        ),
        style: { margin: "60px 0px" },
      },
      {
        selector: "#tour-navbar-events",
        content: ({ goTo }) => (
          <LearningBuddyMessage
            message={
              <div>
                <span>
                  <strong>Events</strong> contains your entire schedule of both
                  in-person and virtual training events from{" "}
                  {this.props.session.group.label[0].value}. You can also create
                  and track requests for External PD credit.
                </span>
                <footer>
                  <ButtonSmall onClick={() => goTo(5)} className="button small">
                    Back
                  </ButtonSmall>
                  <div className="step">7 / {totalSteps}</div>
                  <ButtonSmall
                    onClick={() => {
                      this.goToNext(8, goTo)
                    }}
                    className="button small"
                  >
                    Next
                  </ButtonSmall>
                </footer>
              </div>
            }
          />
        ),
        style: { margin: "60px 0px" },
      },
      {
        selector: "#tour-navbar-backpack",
        content: ({ goTo }) => (
          <LearningBuddyMessage
            message={
              <div>
                <span>
                  <>
                    Your <strong>Backpack</strong> is a customizable report of
                    everything you've done across the entire platform, including
                    External PD, and can be downloaded or shared.
                  </>
                </span>
                <footer>
                  <ButtonSmall onClick={() => goTo(6)} className="button small">
                    Back
                  </ButtonSmall>
                  <div className="step">8 / {totalSteps}</div>
                  <ButtonSmall
                    onClick={() => {
                      this.goToNext(9, goTo)
                    }}
                    className="button small"
                  >
                    Next
                  </ButtonSmall>
                </footer>
              </div>
            }
          />
        ),
        style: { margin: "60px 0" },
      },
      {
        selector: ".navbar .profilePicture",
        content: ({ goTo }) => (
          <LearningBuddyMessage
            message={
              <div>
                <span>
                  Use the <strong>Profile Menu</strong> to update your personal
                  information and picture.
                </span>
                <footer>
                  {leaderboardsVisibility !== "none" ? (
                    <>
                      <ButtonSmall
                        onClick={() => goTo(8)}
                        className="button small"
                      >
                        Back
                      </ButtonSmall>
                      <div className="step">10 / {totalSteps}</div>
                      <ButtonSmall
                        onClick={() => {
                          this.goToNext(11, goTo)
                        }}
                        className="button small"
                      >
                        Next
                      </ButtonSmall>
                    </>
                  ) : (
                    <>
                      <ButtonSmall
                        onClick={() => goTo(7)}
                        className="button small"
                      >
                        Back
                      </ButtonSmall>
                      <div className="step">9 / {totalSteps}</div>
                      <ButtonSmall
                        onClick={() => {
                          this.goToNext(10, goTo)
                        }}
                        className="button small"
                      >
                        Next
                      </ButtonSmall>
                    </>
                  )}
                </footer>
              </div>
            }
          />
        ),
        style: { margin: "50px 0px 0px -10px" },
      },
      {
        selector: "#tour-mm-logo",
        content: ({ goTo }) => (
          <LearningBuddyMessage
            message={
              <div>
                <span>
                  You can click this logo at any time to go back to your
                  dashboard.
                </span>
                <footer>
                  {leaderboardsVisibility !== "none" ? (
                    <>
                      <ButtonSmall
                        onClick={() => goTo(10)}
                        className="button small"
                      >
                        Back
                      </ButtonSmall>
                      <div className="step">11 / {totalSteps}</div>
                    </>
                  ) : (
                    <>
                      <ButtonSmall
                        onClick={() => goTo(8)}
                        className="button small"
                      >
                        Back
                      </ButtonSmall>
                      <div className="step">10 / {totalSteps}</div>
                    </>
                  )}
                  <ButtonSmall
                    onClick={() => {
                      this.goToNext("Finished", goTo)
                    }}
                    className="button small"
                  >
                    Finish
                  </ButtonSmall>
                </footer>
              </div>
            }
          />
        ),
        style: { margin: "50px 0px 0px" },
      },
    ]

    // Insert the leaderboard step if it's visible
    if (leaderboardsVisibility && leaderboardsVisibility !== "none") {
      tourSteps.splice(8, 0, {
        selector: "#tour-navbar-leaderboards",
        content: ({ goTo }) => (
          <LearningBuddyMessage
            message={
              <div>
                <span>
                  Check out the Course, Badge, and Time Spent{" "}
                  <strong>Leaderboards</strong> to see where you rank in{" "}
                  {leaderboardLabel}.
                </span>
                <footer>
                  <ButtonSmall onClick={() => goTo(7)} className="button small">
                    Back
                  </ButtonSmall>
                  <div className="step">9 / {totalSteps}</div>
                  <ButtonSmall
                    onClick={() => {
                      this.goToNext(10, goTo)
                    }}
                    className="button small"
                  >
                    Next
                  </ButtonSmall>
                </footer>
              </div>
            }
          />
        ),
        style: { margin: "60px 10px" },
      })
    }

    const newFeatures = session.user.attributes.field_product_tour_started

    return (
      <>
        <Dialog open={isDialogOpen}>
          <div className="dialog">
            {newFeatures ? (
              <h2>We've been busy!</h2>
            ) : (
              <h2>Let's show you around.</h2>
            )}

            <div className="flexRow">
              <div className="thumbnail">
                {newFeatures ? (
                  <div className="icon new-features" />
                ) : (
                  <div className="icon map" />
                )}
              </div>
              {!newFeatures ? (
                <p>
                  To help you get the most out of <strong>MobileMind</strong>,
                  we’ve put together a short tour of the app. If you want to
                  skip the tour, you can start it later at any time from the
                  Profile menu.
                </p>
              ) : (
                <p>
                  We've made some awesome new changes around here. Take a look
                  at what's new in <strong>MobileMind.</strong>
                </p>
              )}
            </div>
            <footer>
              <Button
                className="button secondary large"
                onClick={() => {
                  this.closeTour("Product Tour Skipped")
                }}
              >
                Skip for now
              </Button>

              <ButtonLarge onClick={this.startTour}>Start Tour</ButtonLarge>
            </footer>
          </div>
        </Dialog>
        <Tour
          steps={tourSteps}
          isOpen={isTourOpen}
          startAt={0}
          disableKeyboardNavigation={false}
          showNavigationNumber={true}
          showNavigation={false}
          showNumber={false}
          maskClassName="tour-mask"
          getCurrentStep={(currentStep) => this.onStepChange(currentStep)}
          showButtons={false}
          maskSpace={0}
          onRequestClose={() => {
            this.closeTour("Product Tour Closed")
          }}
        />
      </>
    )
  }
}

ProductTour.propTypes = {
  productTour: PropTypes.object,
  session: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductTour)
