import { Component } from "react"
import { connect } from "react-redux"
import classNames from "classnames"
import { Link } from "react-router-dom"
import pluralize from "pluralize"
import SanitizeHTML from "@mobilemind/common/src/components/SanitizedHTML"
import UserInitial from "@mobilemind/common/src/components/UserInitial"

import "../../../styles/event-single.scss"

const mapStateToProps = ({ assessments, categories, activeEvent }) => {
  return {
    assessments: assessments.all,
    categories,
    activeEvent,
  }
}

function CourseList(props) {
  return (
    <div className="eventEntityWrapper">
      <h3>{props.title}</h3>
      {props.title === "Prerequisite Courses" && (
        <p>
          The event organizer has requested you complete the following{" "}
          {pluralize("course", props.courses)} before attending this event.
        </p>
      )}
      {props.title === "Related Courses" && (
        <p>
          The organizer recommends the following related{" "}
          {pluralize("course", props.courses)}.
        </p>
      )}
      {props.courses.map((course) => {
        const category = props.categories.data.find(
          (cat) =>
            course.relationships.field_category.data &&
            cat.id === course.relationships.field_category.data.id
        )
        const assessmentForCourse = props.assessments.find(
          (assessment) =>
            assessment.relationships.field_course.data.id === course.id
        )
        const status =
          assessmentForCourse && assessmentForCourse.attributes.field_status

        return (
          <Link
            to={"/course/" + course.attributes.drupal_internal__id}
            key={course.id}
            className="course"
          >
            {category && (
              <span
                className="category-icon"
                style={{
                  backgroundImage:
                    "url(" +
                    process.env.REACT_APP_API_URL +
                    category.image.attributes.uri.url +
                    ")",
                }}
              />
            )}
            <span className="title">{course.attributes.name}</span>
            {assessmentForCourse && (
              <div className="status">
                {status === "in_progress" && <span className="icon laptop" />}
                {status === "saved_for_later" && <span className="icon pin" />}
                {status === "submitted" && <span className="icon inbox" />}
                {status === "completed" && <span className="icon check" />}
              </div>
            )}
          </Link>
        )
      })}
    </div>
  )
}

class EventDetails extends Component {
  openAttachment = (file) => {
    const url = process.env.REACT_APP_API_URL + file.file
    let windowParams =
      "left=200,top=200,width=880,height=600,personalbar=0,toolbar=0,scrollbars=0,resizable=0"
    window.open(url, "", windowParams)
  }

  render() {
    const { activeEvent, categories, assessments } = this.props

    const {
      preRequisiteCourses,
      preRequisiteLP,
      replacementCourses,
      badgeAwarded,
      attachments,
      personnel,
      notes,
      helpfulLinks,
      supportContact,
    } = activeEvent

    return (
      <div className="event-details">
        <section>
          <h3>Description</h3>
          <SanitizeHTML html={activeEvent.description} />
        </section>

        {personnel.length > 0 && (
          <section>
            <h3>Presenters</h3>
            {personnel.map((presenter) => {
              return (
                <div
                  key={presenter.id}
                  className="flexRow presenter"
                  style={{
                    alignItems: presenter.attributes.field_bio
                      ? "flex-start"
                      : "center",
                  }}
                >
                  {presenter.attributes.field_user_image_path &&
                  !presenter.attributes.field_user_image_path.includes(
                    "default_temp"
                  ) ? (
                    <span
                      className="presenterImage"
                      style={{
                        backgroundImage:
                          "url(" +
                          process.env.REACT_APP_API_URL +
                          presenter.attributes.field_user_image_path +
                          ")",
                      }}
                    />
                  ) : (
                    <UserInitial
                      size={70}
                      fontSize={32}
                      containerStyle={{}}
                      initials={
                        presenter.attributes.field_first_name[0].toUpperCase() +
                        presenter.attributes.field_last_name[0].toUpperCase()
                      }
                    />
                  )}
                  <div className="presenterInfo">
                    <strong>
                      {presenter.attributes.field_first_name}{" "}
                      {presenter.attributes.field_last_name}
                    </strong>
                    <span>{presenter.attributes.field_bio}</span>
                  </div>
                </div>
              )
            })}
          </section>
        )}

        {(preRequisiteCourses.length > 0 || replacementCourses.length > 0) && (
          <section className="flexRow" style={{ alignItems: "flex-start" }}>
            {preRequisiteCourses.length > 0 && (
              <CourseList
                categories={categories}
                assessments={assessments}
                title={"Prerequisite Courses"}
                courses={preRequisiteCourses}
              />
            )}

            {replacementCourses.length > 0 && (
              <CourseList
                categories={categories}
                assessments={assessments}
                title={"Related Courses"}
                courses={replacementCourses}
              />
            )}
          </section>
        )}

        {preRequisiteLP && (
          <section className="eventEntityWrapper">
            <h3>Prerequisite Learning Path</h3>
            <p>
              The event organizer has requested you complete the following
              learning path before attending this event.
            </p>

            <Link
              className="course"
              style={{ color: "black" }}
              to={
                "/learning-path/" +
                preRequisiteLP.attributes.drupal_internal__id
              }
            >
              <span
                style={{ width: 25, height: 25, marginRight: 10 }}
                className="icon learningPath color"
              />
              <strong>{preRequisiteLP.attributes.name}</strong>
            </Link>
          </section>
        )}

        {badgeAwarded && (
          <section className="badgeAwarded">
            <h3>Badge Awarded</h3>
            <p>Attend this event to earn the following badge:</p>
            <div className="flexRow course">
              <div
                className="badge"
                style={{
                  backgroundImage:
                    "url(" +
                    process.env.REACT_APP_API_URL +
                    badgeAwarded.included[0].attributes.uri.url +
                    ")",
                }}
              />
              <strong>{badgeAwarded.data.attributes.name}</strong>
            </div>
          </section>
        )}

        {attachments.length > 0 && (
          <section>
            <h3>Attachments</h3>
            {attachments.map((file) => {
              return (
                <div
                  key={file.id}
                  onClick={() => {
                    this.openAttachment(file)
                  }}
                  className="attachment"
                >
                  {file.extension !== "png" &&
                  file.extension !== "jpg" &&
                  file.extension !== "jpeg" ? (
                    <span className={classNames("icon", file.extension)} />
                  ) : (
                    <span
                      className={classNames("icon image")}
                      style={{
                        backgroundImage:
                          "url(" +
                          process.env.REACT_APP_API_URL +
                          file.file +
                          ")",
                      }}
                    />
                  )}
                  {file.filename}
                </div>
              )
            })}
          </section>
        )}
        {notes && (
          <section>
            <h3>Event Notes</h3>
            <SanitizeHTML html={notes} />
          </section>
        )}
        {helpfulLinks && (
          <section>
            <h3>Helpful Links</h3>
            <SanitizeHTML html={helpfulLinks} />
          </section>
        )}
        {supportContact && (
          <section>
            <h3>Support Contact</h3>
            <SanitizeHTML html={supportContact} />
          </section>
        )}
      </div>
    )
  }
}

export default connect(mapStateToProps)(EventDetails)
