import { Component } from "react"
import { connect } from "react-redux"

import CourseListItemCompleted from "./CourseListItemCompleted"
import CoursesCompletedFilters from "./CoursesCompletedFilters"

import ViewToggle from "../../components/ViewToggle"
import CourseCard from "./CourseCard"

import Loading from "@mobilemind/common/src/components/Loading"

import _ from "lodash"
import classNames from "classnames"

import BottomScrollListener from "react-bottom-scroll-listener"

import { Grid, Button } from "@mui/material"
import { isMobileOnly } from "react-device-detect"
import theme from "@mobilemind/common/src/theme/theme"
import ExportLink from "../../components/ExportLink"
import BackArrow from "../../icons/BackArrow"

import { getAssessmentsByStatus, setSidebar } from "../../actions"

import {
  increaseCoursePage,
  getCompletedCourses,
} from "../achievements/achievementsSlice"

import pluralize from "pluralize"
import ButtonSmall from "../../components/ButtonSmall"
import Inbox from "../../icons/Inbox"

import "../../styles/backpack.scss"

const mapStateToProps = ({
  session,
  achievements,
  sidebar,
  assessments,
  categories,
  viewStyle,
}) => {
  let sortedCategories = _.sortBy(categories.data, (category) => {
    return category.attributes.name
  })

  return {
    session,
    sidebar,
    isExpanded: sidebar.mobileFiltersExpanded,
    filters: sidebar.pages.achievements.items.find(
      (item) => item.id === "completed"
    ).filters,
    completedAssessments: assessments.completed,
    submittedAssessments: assessments.submitted,
    categories: sortedCategories,
    courses: achievements.courses,
    viewStyle,
  }
}

const mapDispatchToProps = {
  getCompletedCourses,
  increaseCoursePage,
  getAssessmentsByStatus,
  setSidebar,
}

class CoursesCompletedLayout extends Component {
  componentDidMount = () => {
    this.props.getCompletedCourses()
    this.props.getAssessmentsByStatus("submitted")
  }

  goToSubmitted = () => {
    this.props.setSidebar("inProgress", "submitted")
    this.props.history.push("in-progress")
  }

  handleScroll = () => {
    const { currentPage, totalPages } = this.props.courses

    // If we aren't already fetching more results
    if (!this.props.courses.isFetching) {
      // And if there are more pages to fetch
      if (currentPage < totalPages - 1) {
        this.props.increaseCoursePage()
      }
    }
  }

  goBack = () => {
    this.props.history.push("/backpack")
  }

  render() {
    const { courses, submittedAssessments, viewStyle } = this.props
    const { hasFetched, isFetching, isFetchingMore } = courses

    const exportURL = process.env.REACT_APP_API_URL + "/course-report/user"

    let content = (
      <>
        {courses.data.length > 0 &&
          courses.data.map((course, index) => {
            let animationDelay = index * 0.08 + "s"
            if (courses.currentPage) {
              let reduction = courses.currentPage * 25
              animationDelay = (index - reduction) * 0.08 + "s"
            }

            if (course && viewStyle === "grid") {
              return (
                <CourseCard
                  animationDelay={animationDelay}
                  key={course.id}
                  course={course}
                  status={"completed"}
                />
              )
            } else if (course) {
              return (
                <CourseListItemCompleted
                  animationDelay={animationDelay}
                  key={course.id}
                  course={course}
                  status={"completed"}
                />
              )
            } else {
              return false
            }
          })}
      </>
    )

    return (
      <div
        className={classNames(
          "page completedCourses",
          isFetching && "loading",
          hasFetched && "hasFetched"
        )}
      >
        <div id="backpack-sidebar">
          <Button className="backButton" onClick={this.goBack}>
            <BackArrow />
            <span
              style={{ marginLeft: 10, color: theme.palette.primary.main }}
              className="buttonText"
            >
              Back
            </span>
          </Button>

          <CoursesCompletedFilters />
        </div>

        <BottomScrollListener onBottom={this.handleScroll}>
          {!hasFetched && (
            <Loading
              color={theme.palette.secondary.main}
              message={"Getting completed courses..."}
            />
          )}

          {hasFetched && (
            <>
              <ViewToggle />
              <div className="itemCount">
                <span style={{ color: theme.palette.primary.main }}>
                  {courses.total ? courses.total : 0}
                </span>{" "}
                Completed {pluralize("Course", courses.total)}
              </div>

              {submittedAssessments.length > 0 && (
                <div style={{ position: "absolute", right: 140, top: 5 }}>
                  <ButtonSmall onClick={() => this.goToSubmitted()}>
                    <Inbox size={20} />
                    <span style={{ marginLeft: 10 }}>
                      View {submittedAssessments.length} Submitted
                    </span>
                  </ButtonSmall>
                </div>
              )}

              <ExportLink exportURL={exportURL} />
            </>
          )}

          {isMobileOnly && (
            <div className="mobileFilters">
              <CoursesCompletedFilters />
            </div>
          )}

          {viewStyle === "grid" ? (
            <div
              className={classNames(
                "course-library course-grid",
                isMobileOnly && "mobile-list",
                isMobileOnly && this.props.isExpanded && "isExpanded"
              )}
            >
              <Grid container spacing={3}>
                {content}
              </Grid>
            </div>
          ) : (
            <ul
              className={classNames(
                "course-library course-list",
                isMobileOnly && "mobile-list",
                isMobileOnly && this.props.isExpanded && "isExpanded"
              )}
            >
              {content}
            </ul>
          )}
          {isFetchingMore && (
            <div
              className={classNames(
                "loadingMore",
                this.props.sidebar.isCollapsed && "collapsed"
              )}
            >
              <Loading
                color={theme.palette.secondary.main}
                fullPage={true}
                message={"Getting more courses..."}
              />
            </div>
          )}
        </BottomScrollListener>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CoursesCompletedLayout)
