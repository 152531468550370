import { useSelector } from "react-redux"
import { RootState, useAppDispatch } from "../../store/store"

import { Dialog } from "@mui/material"

import { setExternalModal } from "../../store/reducers/backpack"

import TimeCapsule from "@mobilemind/common/src/components/TimeCapsule"
import { generateTimestamp } from "@mobilemind/common/src/functions"

import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"

import ReviewerQuote from "@mobilemind/common/src/components/ReviewerQuote"
import SanitizedHTML from "@mobilemind/common/src/components/SanitizedHTML"
import RatingStar from "@mobilemind/common/src/icons/RatingStar"
import classNames from "classnames"
import { format } from "date-fns"

import "../../styles/backpack.scss"

const ExternalApprovalModal = (props: any) => {
  const { isModalOpen, activeRequest } = useSelector(
    (state: RootState) => state.backpack.external
  )
  const { categories, session } = useSelector((state: RootState) => state)

  const dispatch = useAppDispatch()

  return (
    <Dialog
      onClose={() => {
        dispatch(setExternalModal({ open: false }))
      }}
      id="external-pd-approved-modal"
      open={isModalOpen}
    >
      {activeRequest && (
        <div
          className="request-list-item"
          style={{ animationDelay: 0.2 + "s" }}
        >
          <Accordion expanded={true}>
            <AccordionSummary>
              <div className="flexRow">
                <span className="icon external-pd color" />

                <div style={{ flex: 1, marginLeft: 10 }}>
                  <h3>{activeRequest.title}</h3>
                  <span className="timestamp">
                    {format(
                      new Date(activeRequest.field_start_date_value),
                      "MMMM do, yyyy"
                    )}
                  </span>
                </div>
                <strong
                  className="requested-time"
                  style={{
                    color: "black",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span
                    style={{ width: 25, height: 25, marginRight: 5 }}
                    className="icon check color"
                  />
                  Approved{" "}
                  {generateTimestamp(activeRequest.field_reviewed_date_value)}
                </strong>
              </div>
            </AccordionSummary>

            <AccordionDetails>
              <div
                className="request-content"
                style={{ display: "flex", padding: "10px 20px" }}
              >
                <div className="column" style={{ flex: 1 }}>
                  {activeRequest.description__value && (
                    <section>
                      <header>Description</header>
                      <SanitizedHTML html={activeRequest.description__value} />
                    </section>
                  )}

                  {activeRequest.field_learner_comment_value && (
                    <section>
                      <header>Your Comments</header>
                      <div className="flexRow">
                        <SanitizedHTML
                          html={activeRequest.field_learner_comment_value}
                        />
                      </div>
                    </section>
                  )}

                  {activeRequest.reviewer_data &&
                    activeRequest.reviewer_data[0] &&
                    activeRequest.field_rev_comment_value && (
                      <section>
                        <header>Reviewer Comments</header>

                        <div className="reviewer-comments">
                          <ReviewerQuote
                            groupLabel={session.group.label[0].value}
                            imageSrc={
                              activeRequest.reviewer_data[0].reviewer_pic
                            }
                            firstName={
                              activeRequest.reviewer_data[0].reviewer_first_name
                            }
                            lastName={
                              activeRequest.reviewer_data[0].reviewer_last_name
                            }
                            text={activeRequest.field_rev_comment_value}
                          />
                        </div>
                      </section>
                    )}
                </div>
                <div className="column">
                  <section className="categoryContainer">
                    <header>PD Categories</header>
                    {activeRequest.category_data.map((category: any) => {
                      let imageSrc
                      const fullCategory = categories.data.find(
                        (cat: any) => cat.id === category.category_uuid
                      )
                      if (fullCategory) {
                        imageSrc =
                          fullCategory.image &&
                          fullCategory.image.attributes.uri.url
                      }

                      const totalCategoryMinutes = Number(
                        category.category_time_spent.replace("min", "")
                      )
                      const categoryHours =
                        totalCategoryMinutes >= 60
                          ? Math.floor(
                              totalCategoryMinutes / 60
                            ).toLocaleString()
                          : 0
                      const categoryMinutes = totalCategoryMinutes % 60

                      const currentValue = categories.data.find(
                        (cat: any) => cat.id === category.category_uuid
                      )
                      const parent =
                        currentValue &&
                        categories.data.find(
                          (cat: any) =>
                            cat.id ===
                            currentValue.relationships.parent.data[0].id
                        )
                      const parentParent =
                        parent &&
                        categories.data.find(
                          (cat: any) =>
                            cat.id === parent.relationships.parent.data[0].id
                        )

                      return (
                        <li key={category.category_uuid}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: 5,
                            }}
                          >
                            <span
                              className="span category-icon"
                              style={{
                                backgroundImage:
                                  "url(" +
                                  process.env.REACT_APP_API_URL +
                                  imageSrc +
                                  ")",
                              }}
                            />
                            <span className="title">
                              {category.category_name}
                            </span>
                            <TimeCapsule
                              hours={categoryHours}
                              minutes={categoryMinutes}
                            />
                          </div>

                          <div className="category-breadcrumbs">
                            {parentParent && (
                              <span className="parent">
                                {parentParent.attributes.name}
                              </span>
                            )}
                            {parentParent && parent && (
                              <span className="caret" />
                            )}
                            {parent && (
                              <span className="parent">
                                {parent.attributes.name}
                              </span>
                            )}
                          </div>
                        </li>
                      )
                    })}
                  </section>

                  <section className="ratingContainer">
                    <header>Your Rating</header>
                    <div className="flexRow">
                      {[1, 2, 3, 4, 5].map((value) => {
                        return (
                          <RatingStar
                            key={value}
                            selected={activeRequest.field_rating_value >= value}
                          />
                        )
                      })}
                    </div>
                  </section>

                  {activeRequest.attachments_url.length > 0 && (
                    <section>
                      <header>Attachments</header>
                      <ul className="pd-attachment-list">
                        {activeRequest.attachments_url.map((file: any) => {
                          return (
                            <li key={file}>
                              <a href={file} target="_blank" rel="noreferrer">
                                {!file.includes(".png") &&
                                !file.includes(".jpg") &&
                                !file.includes(".jpeg") ? (
                                  <span
                                    className={classNames(
                                      "icon",
                                      file.split(".").pop()
                                    )}
                                  />
                                ) : (
                                  <span
                                    className={classNames("icon image")}
                                    style={{
                                      backgroundImage: "url(" + file + ")",
                                    }}
                                  />
                                )}
                                {file.split("/").pop().replaceAll("%20", " ")}
                              </a>
                            </li>
                          )
                        })}
                      </ul>
                    </section>
                  )}
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      )}
    </Dialog>
  )
}

export default ExternalApprovalModal
