import { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"

import {
  Select,
  MenuItem,
  FormControl,
  TextField,
  Button,
  InputLabel,
} from "@mui/material"

import { isMobile } from "react-device-detect"
import classNames from "classnames"
import { isMMContentHidden } from "@mobilemind/common/src/functions"

import "../../styles/sidebar.scss"
import "../../styles/categories.scss"

import CategorySelect from "@mobilemind/common/src/components/CategorySelect"
import {
  updateCourseFilters,
  resetCourseFilters,
} from "../achievements/achievementsSlice"

const mapStateToProps = ({ session, achievements, categories, sidebar }) => {
  return {
    categories,
    session,
    sidebar: sidebar,
    isExpanded: sidebar.mobileFiltersExpanded,
    filters: achievements.courses.filters,
  }
}

const mapDispatchToProps = {
  updateCourseFilters,
  resetCourseFilters,
}

class CoursesCompletedFilters extends Component {
  updateFilters = (event, key) => {
    window.scrollTo(0, 0)

    let filters = { ...this.props.filters }

    if (key === "category") {
      filters[key] = event
    } else {
      filters[key] = event.target.value
    }

    this.props.updateCourseFilters(filters)
  }

  resetFilters = () => {
    window.scrollTo(0, 0)

    this.props.updateCourseFilters(
      {
        search: "",
        category: null,
        org: "any",
        sort: "dateNewest",
      },
      "completed"
    )
  }

  toggleExpand = () => {
    this.props.resetCourseFilters()
  }

  render() {
    const { sort, search, category, org } = this.props.filters
    const { session, categories } = this.props

    let collectionLabel =
      session.collection && session.collection.label
        ? session.collection.label[0].value
        : "MobileMind"

    let orgId =
      session.group && session.group.uuid && session.group.uuid[0].value

    return (
      <form
        className={classNames(
          "filters coursesComplete",
          this.props.sidebar.isCollapsed && "collapsed",
          isMobile && this.props.isExpanded && "expanded"
        )}
      >
        <header style={{ marginBottom: 0 }}>
          <strong>Filter Courses</strong>
          {(search || category || org !== "any" || sort !== "dateNewest") && (
            <Button className="button small" onClick={this.resetFilters}>
              Reset
            </Button>
          )}
        </header>

        <FormControl>
          <div className="flexRow inputSearch">
            <span className="icon search" />
            <TextField
              variant="outlined"
              label="Search"
              value={search}
              onChange={(event) => {
                this.updateFilters(event, "search")
              }}
            />
          </div>
        </FormControl>

        <CategorySelect
          categories={categories}
          label={"Category"}
          onChange={(newCategory) => {
            this.updateFilters(newCategory, "category")
          }}
          currentValue={category}
          allowedLevels={3}
        />

        {orgId && !isMMContentHidden(session) && (
          <FormControl className="inputSelect source">
            <InputLabel id="labelSource">Source</InputLabel>
            <Select
              labelId="labelSource"
              id="sourceSelect"
              value={org}
              onChange={(event) => {
                this.updateFilters(event, "org")
              }}
            >
              <MenuItem key={"any"} value={"any"}>
                Any
              </MenuItem>
              <MenuItem key={"mobilemind"} value={"mobilemind"}>
                {collectionLabel}
              </MenuItem>
              <MenuItem key={"my_org"} value={"my_org"}>
                {session.group.label[0].value}
              </MenuItem>
            </Select>
          </FormControl>
        )}

        <header style={{ marginTop: 10, marginBottom: 0 }}>
          <strong>Sort Courses</strong>
        </header>

        <FormControl>
          <InputLabel style={{ marginLeft: -15 }}>Sort By</InputLabel>
          <Select
            value={sort}
            onChange={(event) => {
              this.updateFilters(event, "sort")
            }}
          >
            <MenuItem value={"dateNewest"}>
              Date Completed (Newest First)
            </MenuItem>
            <MenuItem value={"dateOldest"}>
              Date Completed (Oldest First)
            </MenuItem>
            <MenuItem value={"name"}>Course Name</MenuItem>
          </Select>
        </FormControl>

        <div className="toggle" onClick={this.toggleExpand}>
          <span className="icon" />
        </div>
      </form>
    )
  }
}

CoursesCompletedFilters.propTypes = {
  filters: PropTypes.object,
  isExpanded: PropTypes.bool,
  sidebar: PropTypes.object,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CoursesCompletedFilters)
