import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import "../../styles/certificate.scss"

import Loading from "@mobilemind/common/src/components/Loading"

import { fetchSingleEventAttended } from "@mobilemind/common/src/actions/backpack"
import EventCertificate from "@mobilemind/common/src/components/backpack/certificate/EventCertificate"

function EventCertificateLayout(props) {
  const { session, categories } = useSelector((state) => state)
  const { eventId } = props.match.params
  const { user } = session

  const [userEvent, setUserEvent] = useState(null)
  const [fetched, setFetched] = useState(false)
  const [category, setCategory] = useState(null)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!fetched && categories.data.length) {
      dispatch(fetchSingleEventAttended({ eventId })).then((response) => {
        setUserEvent(response.rows[0])

        setCategory(
          categories.data.find(
            (cat) =>
              cat.attributes.drupal_internal__tid ===
              Number(response.rows[0].field_event_category)
          )
        )
      })
      setFetched(true)
    }
  }, [dispatch, categories.data, fetched, eventId])

  return (
    <>
      {userEvent ? (
        <EventCertificate category={category} event={userEvent} user={user} />
      ) : (
        <Loading fullPage={true} message="Generating certificate..." />
      )}
    </>
  )
}

export default EventCertificateLayout
