import { createRef, Component } from "react"
import { Dialog, Button } from "@mui/material"
import { Link } from "react-router-dom"
import Loading from "@mobilemind/common/src/components/Loading"
import * as html2canvas from "html2canvas"
import { convertEstimatedTime } from "@mobilemind/common/src/functions/index"
import classNames from "classnames"
import { isMobile } from "react-device-detect"
import moment from "moment"

class SlideOutro extends Component {
  constructor(props) {
    super(props)
    this.onePageRef = createRef()
    this.state = {
      isOpen: false,
    }
  }

  generateShareImage = async () => {
    const { session } = this.props
    const currentRef = this.onePageRef.current

    let image
    // Convert the DOM node to a PNG
    let canvas = await html2canvas(currentRef)
    image = canvas.toDataURL("image/png")

    const url = "/api/user/user/" + session.user.id + "/field_rewind_image"
    const filename =
      session.user.attributes.field_last_name +
      "-" +
      moment().format("YYYY") +
      "-rewind-" +
      moment().format("HH:mm") +
      ".png"
    this.props.uploadImage(image, url, filename)
  }

  shareToSocial = async (type) => {
    const { session } = this.props
    const currentRef = this.onePageRef.current

    let image
    this.setState({ loadingImage: true })

    // Convert the DOM node to a PNG
    let canvas = await html2canvas(currentRef)
    image = canvas.toDataURL("image/png")

    this.setState({ shareImage: image })

    const url = "/api/user/user/" + session.user.id + "/field_rewind_image"

    const filename =
      session.user.attributes.field_last_name +
      "-" +
      moment().format("YYYY") +
      "-rewind-" +
      moment().format("HH:mm") +
      ".png"

    let uploadedImage = await this.props.uploadImage(image, url, filename)
    let shareUrl =
      process.env.REACT_APP_API_URL + uploadedImage.attributes.uri.url

    let windowParams =
      "left=0,top=0,width=550,height=450,personalbar=0,toolbar=0,scrollbars=0,resizable=0"

    const socialTag = type === "twitter" ? "@MobileMindEDU" : "@MobileMind"
    let text = "Check out my 2020-2021 MobileMind Rewind! " + socialTag

    this.setState({ loadingImage: false })

    if (type === "facebook") {
      window.open(
        "http://facebook.com/sharer/sharer.php?u=" +
          encodeURIComponent(shareUrl) +
          "&quote=" +
          encodeURIComponent(text),
        "",
        windowParams
      )
    } else if (type === "twitter") {
      window.open(
        "http://twitter.com/share?url=" +
          encodeURIComponent(shareUrl) +
          "&text=" +
          encodeURIComponent(text),
        "",
        windowParams
      )
    } else if (type === "linkedIn") {
      window.open(
        "http://www.linkedin.com/shareArticle?mini=true&url=" +
          encodeURIComponent(shareUrl),
        "sharer",
        windowParams
      )
    }
  }

  render() {
    const { rewind } = this.props
    const categories = rewind.allCategories

    const { topCategories } = rewind
    const topCategory = topCategories[0]

    const rockstarContent = (
      <>
        <div className={classNames("rockstar", isMobile && "isMobile")}>
          <div className="eyes">
            <div className="eye" />
            <div className="eye" />
          </div>
          <div className="mouth" />
        </div>
        <p className="textLarge">
          You’re a total rockstar and have taken control of your professional
          learning journey this year!
        </p>
      </>
    )

    const learnNext = (
      <>
        <p className="textLarge">
          What will you learn next, {rewind.firstName}?
        </p>
        {!isMobile && (
          <ul
            className={classNames(
              "categoryList",
              rewind.needsEncouragement && "needsEncouragement"
            )}
          >
            {categories.map((category, index) => {
              return (
                <li key={index}>
                  <span
                    className="categoryImage"
                    style={{
                      backgroundImage:
                        "url(" +
                        process.env.REACT_APP_API_URL +
                        category.image +
                        ")",
                    }}
                  />
                </li>
              )
            })}
          </ul>
        )}
      </>
    )

    const howFar = (
      <>
        <p className="textLarge">We can't wait to see how far you go!</p>
        {!isMobile && (
          <footer
            className={classNames(
              rewind.needsEncouragement && "needsEncouragement"
            )}
          >
            {/* <Button
              onClick={() => {
                this.setState({ isOpen: true })
                setTimeout(() => {
                  this.generateShareImage()
                }, 1500)
              }}
              className="button white large"
            >
              <span className="icon share" />
              Share Your MobileMind Rewind
            </Button> */}
            <Link to="/">
              <Button className="button large primary">
                Back to MobileMind
              </Button>
            </Link>
          </footer>
        )}
      </>
    )

    return (
      <>
        <Dialog
          className="rewind-modal"
          open={this.state.isOpen}
          onClose={() => {
            this.setState({ isOpen: false })
          }}
        >
          <div
            className={classNames(
              "loadingOverlay",
              this.state.loadingImage && "active"
            )}
          >
            <Loading message="Preparing MobileMind Rewind..." />
          </div>

          <div ref={this.onePageRef}>
            <div className="onePager">
              <ul className="achievements">
                <li>
                  <span>{rewind.firstName}'s Rewind</span>
                  <header>
                    <span className="icon rewind" />
                    2021-2022
                  </header>
                </li>

                <li>
                  <span>Courses Completed</span>
                  <header>
                    <span className="icon laptop" />
                    {rewind.achievements.completed_courses}
                  </header>
                </li>
                <li>
                  <span>Badges Earned</span>
                  <header>
                    <span className="icon badge" />
                    {rewind.achievements.completed_badges}
                  </header>
                </li>
                <li>
                  <span>Learning Paths Completed</span>
                  <header>
                    <span className="icon learningPath" />
                    {rewind.achievements.completed_learning_paths}
                  </header>
                </li>
                <li>
                  <span>Preferred Learning Season</span>
                  <header>
                    <span className={"icon " + rewind.learningSeason} />
                    {rewind.learningSeason}
                  </header>
                </li>
                <li>
                  <span>Total Time Spent</span>
                  <header className="timeSpent">
                    <span className="icon clock" />
                    {convertEstimatedTime(rewind.timeSpent)}
                  </header>
                </li>

                <li>
                  <span>Favorite Category</span>
                  <header>
                    <span
                      className={
                        "icon category-icon " + topCategory.name.toLowerCase()
                      }
                    />
                    {topCategory.name}
                  </header>
                </li>
                <li>
                  <span>Courses Completed on First Try</span>
                  <header>
                    <span className={"icon check"} />
                    {rewind.firstAttemptCount}
                  </header>
                </li>
              </ul>
            </div>
          </div>

          <div className="shareLink">
            <div className="flexRow">
              <Button
                onClick={(type) => {
                  this.shareToSocial("facebook")
                }}
                className="button small"
              >
                <span className="icon facebook" />
                Facebook
              </Button>
              <Button
                onClick={(type) => {
                  this.shareToSocial("twitter")
                }}
                className="button small"
              >
                <span className="icon twitter" />
                Twitter
              </Button>
              <Button
                onClick={(type) => {
                  this.shareToSocial("linkedIn")
                }}
                className="button small"
              >
                <span className="icon linkedIn" />
                LinkedIn
              </Button>
            </div>
          </div>
        </Dialog>

        <div className="slide outro">
          <div className="background-tile rock-bg" />
          {!isMobile && (
            <div
              style={{
                position: "fixed",
                left: 0,
                right: 0,
                bottom: 0,
                top: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="firstWrapper">
                <div
                  className={classNames("part first", isMobile && "isMobile")}
                >
                  {rewind.needsEncouragement ? (
                    <>{learnNext}</>
                  ) : (
                    <>{rockstarContent}</>
                  )}
                </div>
              </div>

              <div
                className={classNames(
                  "secondWrapper",
                  !rewind.needsEncouragement && "needsAnimation"
                )}
              >
                <div className="part second">
                  {rewind.needsEncouragement ? <>{howFar}</> : <>{learnNext}</>}
                </div>
              </div>
              {!rewind.needsEncouragement && (
                <div className="thirdWrapper">
                  <div className="part third">{howFar}</div>
                </div>
              )}
            </div>
          )}
          {isMobile && (
            <div>
              {rewind.needsEncouragement ? (
                <>{learnNext}</>
              ) : (
                <>{rockstarContent}</>
              )}
              <div className="mobileSecond">
                {rewind.needsEncouragement ? (
                  <p>We can't wait to see how far you go!</p>
                ) : (
                  <p>What will you learn next, {rewind.firstName}?</p>
                )}
              </div>
            </div>
          )}
        </div>
      </>
    )
  }
}

export default SlideOutro
