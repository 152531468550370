import { Card, CardContent } from "@mui/material"
import TimeCapsule from "@mobilemind/common/src/components/TimeCapsule"
import moment from "moment"

import theme from "@mobilemind/common/src/theme/theme"
import classNames from "classnames"

import { setDSTDisplay } from "@mobilemind/common/src/functions"
import { setSidebarItem } from "../../../store/reducers/calendar"
import { useAppDispatch } from "../../../store/store"

interface CapsuleProps {
  animationDelay: number
  event: any
  totalTime?: number
  onClick: () => void
}

const RecommendedCard = ({
  animationDelay,
  event,
  totalTime,
  onClick,
}: CapsuleProps) => {
  const totalMinutes = totalTime ? totalTime : event.field_total_time_value
  const hours = totalMinutes >= 60 ? Math.floor(totalMinutes / 60) : 0
  const minutes = totalMinutes % 60

  const status = event.ext_usr_event_status

  const dispatch = useAppDispatch()

  let startTime = event.field_start_date_value

  if (!moment().isDST() && moment(startTime).isDST()) {
    startTime = moment(event.field_start_date_value).add(1, "hour")
  }

  return (
    <Card
      onClick={() => {
        if (status) {
          if (status === "accepted") {
            // Go to external backpack page
          } else if (status === "declined") {
            dispatch(setSidebarItem("Declined"))
          } else {
            dispatch(setSidebarItem("Pending"))
          }
        } else {
          onClick()
        }
      }}
      variant={"elevation"}
      className={classNames("card recommended-event", status && "hasRequest")}
      style={{ animationDelay: animationDelay + "s" }}
    >
      {status && (
        <div className="request-status">
          {status === "pending" && (
            <div className="status-label">
              <span className="icon external-pending" />
              Pending
            </div>
          )}
          {status === "declined" && (
            <div className="status-label">
              <span className="icon external-declined" />
              Declined
            </div>
          )}
          {status === "accepted" && (
            <div className="status-label accepted">
              <span className="icon external-accepted" />
              Approved
            </div>
          )}
        </div>
      )}
      <CardContent>
        <div style={{ overflow: "hidden", borderRadius: "10px 10px 0 0" }}>
          {event.image ? (
            <div
              className="image-wrapper"
              style={{ backgroundImage: "url(" + event.image + ")" }}
            ></div>
          ) : (
            <div className="image-wrapper">
              <span className="icon external-pd" />
              <div className="external-backdrop" />
              <div
                className="image-wrapper"
                style={{
                  backgroundColor: theme.palette.primary.main,
                  opacity: 0.8,
                }}
              ></div>
            </div>
          )}
        </div>

        <div className="inner">
          <header
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <strong>{event.title}</strong>
            <span
              style={{
                opacity: 0.6,
                fontSize: 12,
              }}
            >
              {event.field_start_date_value ? (
                <>
                  {moment(setDSTDisplay(event.field_start_date_value)).format(
                    "MMMM Do, YYYY"
                  )}
                  <br />
                  {moment(setDSTDisplay(startTime)).format("h:mma")}
                </>
              ) : (
                <>Available to Request</>
              )}
            </span>
          </header>
        </div>
        <div className={"capsule-wrapper"}>
          <TimeCapsule hours={hours} minutes={minutes} />
        </div>
      </CardContent>
    </Card>
  )
}

export default RecommendedCard
