import theme from "@mobilemind/common/src/theme/theme"

function BackArrow() {
  return (
    <>
      <svg width="20px" height="17px" viewBox="0 0 20 17">
        <g
          id="Page-1"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id="Artboard" transform="translate(-501.000000, -114.000000)">
            <g
              id="back"
              transform="translate(501.000000, 114.000000)"
              fill={theme.palette.primary.main}
              fillRule="nonzero"
            >
              <path
                d="M18.8757724,6.76495935 L18.9069919,6.77178862 L5.52390244,6.77178862 L9.73105691,2.55536585 C9.93707317,2.3495122 10.0500813,2.07065041 10.0500813,1.77796748 C10.0500813,1.48528455 9.93707317,1.20837398 9.73105691,1.00203252 L9.07642276,0.347073171 C8.87056911,0.141219512 8.59626016,0.027398374 8.30373984,0.027398374 C8.01105691,0.027398374 7.73658537,0.140406504 7.53073171,0.346260163 L0.318861789,7.55747967 C0.112195122,7.76414634 -0.00081300813,8.03943089 -4.35631962e-06,8.33227642 C-0.00081300813,8.62674797 0.112195122,8.90219512 0.318861789,9.10853659 L7.53073171,16.3204065 C7.73658537,16.5260976 8.01089431,16.6392683 8.30373984,16.6392683 C8.59626016,16.6392683 8.87056911,16.525935 9.07642276,16.3204065 L9.73105691,15.6654472 C9.93707317,15.4599187 10.0500813,15.1854472 10.0500813,14.8927642 C10.0500813,14.6002439 9.93707317,14.3402439 9.73105691,14.1345528 L5.47642276,9.89455285 L18.8907317,9.89455285 C19.4934959,9.89455285 19.9999956,9.37504065 19.9999956,8.77260163 L19.9999956,7.84626016 C19.9999956,7.24382114 19.4785366,6.76495935 18.8757724,6.76495935 Z"
                id="Shape"
              ></path>
            </g>
          </g>
        </g>
      </svg>
    </>
  )
}

export default BackArrow
