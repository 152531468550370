import { useEffect, useState } from "react"

import ButtonLarge from "@mobilemind/common/src/components/ButtonLarge"
import theme from "@mobilemind/common/src/theme/theme"
import classNames from "classnames"
import { Button, Dialog } from "@mui/material"
import InlineBuddyMessage from "../learningBuddy/InlineBuddyMessage"
import { useDispatch } from "react-redux"
import { submitQuiz } from "../../actions"
import LearningBuddyMessage from "@mobilemind/common/src/components/LearningBuddyMessage"
import Loading from "@mobilemind/common/src/components/Loading"

function QuizLayout({ activeCourse, isQuizInView, assessment }) {
  const [answerBank, updateAnswerBank] = useState([])

  const dispatch = useDispatch()

  useEffect(() => {
    if (activeCourse.quizEntity) {
      const initialAnswerBank = activeCourse.quizEntity.questions.map(
        (item, index) => {
          return {
            index,
            question: item.question,
            answers: item.answers,
            requireAll: item.require_all === "1" ? true : false,
            userAnswer: [],
          }
        }
      )
      updateAnswerBank(initialAnswerBank)
    }
  }, [activeCourse.quizEntity])

  function addRemoveAnswer({ question, answer, type }) {
    let existing = [...answerBank]

    existing.forEach((item) => {
      if (item.index === question.index) {
        if (type === "remove") {
          item.userAnswer = item.userAnswer.filter(
            (existingAnswer) => existingAnswer !== answer
          )
        } else {
          if (item.index === question.index) {
            item.userAnswer = item.userAnswer.concat([answer])
          }
        }
      }
    })

    updateAnswerBank(existing)
  }

  function toggleAnswer({ question, answer }) {
    let existing = [...answerBank]

    existing.forEach((item) => {
      if (item.index === question.index) {
        item.userAnswer = [answer]
      }
    })

    updateAnswerBank(existing)
  }

  function submit() {
    setIsSubmitting(true)
    dispatch(
      submitQuiz({
        answerBank,
        assessment,
      })
    ).then((response) => {
      window.location.reload()
    })
  }

  const [isSubmitting, setIsSubmitting] = useState(false)
  const quizIncomplete = answerBank.find((question) => !question.userAnswer)

  return (
    <>
      <Dialog id={"submit-modal"} open={isSubmitting}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Loading />
        </div>
        <LearningBuddyMessage
          message={
            <>
              <strong>
                Nicely done! Please don't navigate away from this page while I
                submit your quiz.
              </strong>{" "}
              <br />
              You'll be able to see your results in just a moment.
            </>
          }
        />
      </Dialog>

      <div className={classNames("panel quiz-panel", isQuizInView && "shift")}>
        <p>{activeCourse.attributes.field_teacher_instructions}</p>
        {activeCourse.quizEntity && (
          <InlineBuddyMessage
            message={
              <p>
                You'll need to score{" "}
                <strong>
                  {Number(activeCourse.quizEntity.pass_rate) * 100}%
                </strong>{" "}
                to pass this quiz.
              </p>
            }
          />
        )}
        <ul>
          {answerBank.map((question, index) => {
            return (
              <li key={question.question}>
                <header>
                  <span>{index + 1}</span>
                  {question.question}
                </header>
                <ul>
                  {question.answers.map((answer, index) => {
                    let isSelected = question.userAnswer.includes(answer)

                    return (
                      <li key={index}>
                        <Button
                          style={{
                            transition: ".6s",
                            fontWeight: isSelected ? "bold" : "normal",
                            backgroundColor: isSelected
                              ? theme.palette.secondary.main
                              : "#f0f0f0",
                          }}
                          onClick={() => {
                            // Toggle the answer if the answer doesn't have multiple correct answers?
                            // @todo ask kyle
                            if (!question.requireAll) {
                              toggleAnswer({
                                question,
                                answer,
                              })
                            } else {
                              addRemoveAnswer({
                                question,
                                answer,
                                type: isSelected ? "remove" : "add",
                              })
                            }
                          }}
                        >
                          {answer}
                        </Button>
                      </li>
                    )
                  })}
                </ul>
              </li>
            )
          })}
        </ul>
      </div>

      <footer className="quizFooter">
        {isQuizInView && !isSubmitting && (
          <div className="back">
            <ButtonLarge
              onClick={() => {
                window.scrollTo(0, 0)
              }}
            >
              Scroll to Top
            </ButtonLarge>
          </div>
        )}
      </footer>

      {isQuizInView && !quizIncomplete && !isSubmitting && (
        <footer className="quizFooter submitFooter">
          <ButtonLarge onClick={submit}>Submit Quiz</ButtonLarge>
        </footer>
      )}
    </>
  )
}

export default QuizLayout
