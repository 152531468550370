import { Component } from "react"
import { connect } from "react-redux"

import "../../styles/cards.scss"
import PropTypes from "prop-types"

import Sidebar from "../../components/Sidebar"
import InProgress from "./InProgress"
import { setSidebar } from "../../actions"
import { getInProgress } from "./inProgressSlice"
import theme from "@mobilemind/common/src/theme/theme"
import { withRouter } from "react-router-dom"

const mapStateToProps = ({ inProgress, sidebar }) => {
  return {
    currentTab: inProgress.currentTab,
    sidebar,
  }
}

const mapDispatchToProps = {
  setSidebar,
  getInProgress,
}

function InProgressLayout(props) {
  return <InProgressContent {...props} theme={theme} />
}

class InProgressContent extends Component {
  state = {
    fetched: false,
  }

  componentDidMount = async () => {
    await this.props.getInProgress({
      status: this.props.sidebar.pages.inProgress.activeItem,
    })

    this.setState({ fetched: true })
  }

  render() {
    const page = this.props.sidebar.pages.inProgress
    const { theme } = this.props

    return (
      <div className="layout in-progress">
        <Sidebar
          theme={theme}
          currentPage="inProgress"
          activeItem={page.activeItem}
          items={page.items}
        />
        <InProgress
          fetched={this.state.fetched}
          routeParams={this.props.match.params}
          currentTab={this.props.currentTab}
        />
      </div>
    )
  }
}

InProgressLayout.propTypes = {
  sidebar: PropTypes.object,
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InProgressLayout)
)
