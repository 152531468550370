import { Dialog } from "@mui/material"
import "../styles/index.scss"
import { generateTimestamp } from "@mobilemind/common/src/functions"

function ModalQuizResult(props) {
  const { open, onClose, quizEntity, quiz, correctAnswers, fail, assessment } =
    props

  return (
    <Dialog open={open} onClose={onClose} className="quizResultsModal">
      <div className="dialog">
        <h2>
          <span className="icon quiz" />
          <span className="title">Challenge Results</span>
          {assessment && assessment.attributes && (
            <span className="timestamp">
              Taken {generateTimestamp(assessment.attributes.changed)}
            </span>
          )}
        </h2>

        <ol>
          {assessment &&
            assessment.quizAnswers &&
            quiz.questions.map((question, index) => {
              return (
                <li key={index}>
                  <header>{question.question.replaceAll("***", '"')}</header>
                  <span>Your Answer</span>
                  <p>
                    {assessment.quizAnswers[index] &&
                      assessment.quizAnswers[index].attributes &&
                      assessment.quizAnswers[
                        index
                      ].attributes.field_quiz_answer.join(", ")}
                  </p>
                  {assessment.quizAnswers[index] &&
                  assessment.quizAnswers[index].attributes.field_correct ? (
                    <span className="icon check" />
                  ) : (
                    <>
                      <span className="icon incorrect" />
                      {quizEntity.attributes.field_show_correct_answers && (
                        <>
                          <span style={{ display: "block", marginTop: 10 }}>
                            Correct Answer
                          </span>
                          <p>
                            {assessment.quizAnswers[index] &&
                              assessment.quizAnswers[index].attributes
                                .field_correct_answer &&
                              assessment.quizAnswers[index].attributes
                                .field_correct_answer}
                          </p>
                        </>
                      )}
                    </>
                  )}
                </li>
              )
            })}
        </ol>
        {correctAnswers && (
          <div className="quizResults">
            <div className="flexRow">
              <div className="score">
                <strong>{correctAnswers.length}</strong> out of{" "}
                <strong>{quiz.questions.length}</strong> correct
              </div>
              <span className="status">
                {fail ? <>You didn't pass.</> : <>You passed!</>}
              </span>
            </div>

            <div className="progressBarWrapper">
              {assessment.quizAnswers.map((answer) => {
                return (
                  <div
                    style={{
                      height: 15,
                      flex: 1,
                      borderLeft:
                        assessment.quizAnswers.length > 1
                          ? "2px solid #ebebeb"
                          : "none",
                      backgroundColor: answer.attributes.field_correct
                        ? "#24DC91"
                        : "#f57f7f",
                    }}
                  />
                )
              })}
            </div>
          </div>
        )}
      </div>
    </Dialog>
  )
}

export default ModalQuizResult
