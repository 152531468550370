import { useState, useEffect } from "react"
import { connect, useDispatch } from "react-redux"
import "../../styles/certificate.scss"

import LearningPathCertificate from "@mobilemind/common/src/components/backpack/certificate/LearningPathCertificate"
import { getLearningPaths } from "../learningPaths/learningPathsSlice"
import Loading from "@mobilemind/common/src/components/Loading"
import { withRouter } from "react-router-dom"

const mapStateToProps = ({ session }, ownProps) => {
  return {
    user: session.user,
    learningPathId: ownProps.match.params.certificateId,
  }
}

function LearningPathCertificateLayout({ learningPathId, user }) {
  const [learningPath, setLearningPath] = useState(null)
  const [fetched, setFetched] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!fetched) {
      dispatch(getLearningPaths({ uuid: learningPathId })).then((response) => {
        setLearningPath(response.payload.lp_data[0])
      })
      setFetched(true)
    }
  }, [dispatch, fetched, learningPathId])

  const props = {
    learningPath,
    user,
  }

  return (
    <>
      {learningPath ? (
        <LearningPathCertificate {...props} />
      ) : (
        <Loading fullPage={true} message="Generating certificate" />
      )}
    </>
  )
}

export default withRouter(
  connect(mapStateToProps)(LearningPathCertificateLayout)
)
