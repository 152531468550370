import { Component } from "react"
import { Card } from "@mui/material"
import ButtonSmall from "../../components/ButtonSmall"

import PropTypes from "prop-types"

import LinkedIn from "../../icons/LinkedIn"
import Twitter from "../../icons/Twitter"
import Facebook from "../../icons/Facebook"

class WidgetSocial extends Component {
  render() {
    return (
      <Card
        className="widget social"
        style={{ animationDelay: this.props.animationDelay }}
      >
        <div className="widgetLabel">Social</div>

        <p>Connect with us and get the latest news from MobileMind!</p>

        <div className="flexRow">
          <div className="logo circle" />
          <div className="links">
            {/* {
              this.props.session.user.attributes.field_courses_completed > 0 &&
              <Link to={"/rewind/" + this.props.session.user.id}>
                <Button className="button small">
                  <div className="icon rewind-blue"></div>
                  Your Rewind ('21-'22)
                </Button>
              </Link>
            } */}
            <a
              target={"_blank"}
              rel="noopener noreferrer"
              href="https://twitter.com/mobilemindedu"
            >
              <ButtonSmall>
                <Twitter />
                <span style={{ marginLeft: 10 }}>Twitter</span>
              </ButtonSmall>
            </a>
            <a
              target={"_blank"}
              rel="noopener noreferrer"
              href="https://www.facebook.com/mobilemindedu/"
            >
              <ButtonSmall>
                <Facebook />
                <span style={{ marginLeft: 10 }}>Facebook</span>
              </ButtonSmall>
            </a>
            <a
              target={"_blank"}
              rel="noopener noreferrer"
              href="https://linkedin.com/company/mobilemind-/"
            >
              <ButtonSmall>
                <LinkedIn />
                <span style={{ marginLeft: 10 }}>LinkedIn</span>
              </ButtonSmall>
            </a>
          </div>
        </div>
      </Card>
    )
  }
}

WidgetSocial.propTypes = {
  animationDelay: PropTypes.string,
  widget: PropTypes.object,
}

export default WidgetSocial
