export const checkInReadyMessages = [
  "It's time!",
  "Time to check in!",
  "Hey, you made it!",
  "Glad to see you!",
  "You made it!",
  "Happy to see you!",
  "Good to see you!",
  "Let's check in!",
]
