import { Component } from "react"
import "@mobilemind/common/src/styles/learning-buddy.scss"
import classNames from "classnames"
import { connect } from "react-redux"
import { getBuddies } from "./learningBuddiesSlice"

import Loading from "@mobilemind/common/src/components/Loading"
import theme from "@mobilemind/common/src/theme/theme"

const mapStateToProps = ({ session, learningBuddies }) => {
  return {
    buddies: learningBuddies,
    buddy: session.user.learningBuddy,
  }
}

const mapDispatchToProps = {
  getBuddies,
}

function LearningBuddySelect(props) {
  return <LearningBuddies {...props} theme={theme} />
}

class LearningBuddies extends Component {
  state = {
    selectedBuddy: null,
  }

  componentDidMount() {
    if (!this.props.buddies.fetched) {
      this.props.getBuddies()
    }
  }

  render() {
    const { buddies, theme } = this.props

    return (
      <div className="flexRow buddy-select">
        {buddies.fetched ? (
          <>
            {buddies.data.map((buddy, index) => {
              return (
                <div
                  key={buddy.id}
                  style={{ animationDelay: index * 0.05 + "s" }}
                  className="grid-item"
                >
                  <div
                    onClick={() => {
                      this.setState({ selectedBuddy: buddy.id })
                      this.props.onSelect(buddy)
                    }}
                    className={classNames(
                      "buddy-item",
                      buddy.id === this.state.selectedBuddy && "active"
                    )}
                  >
                    <span
                      className={classNames(
                        "buddy-image",
                        buddy.attributes.name.toLowerCase().replace(" ", "")
                      )}
                    />
                    <span className="buddy-label">{buddy.attributes.name}</span>
                  </div>
                </div>
              )
            })}
          </>
        ) : (
          <Loading
            color={theme.palette.secondary.main}
            message={"Getting learning buddies..."}
          />
        )}
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LearningBuddySelect)
