import { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"

import {
  TextField,
  Checkbox,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  FormControlLabel,
} from "@mui/material"

import classNames from "classnames"
import ButtonSmall from "../../components/ButtonSmall"
import theme from "@mobilemind/common/src/theme/theme"

import "../../styles/sidebar.scss"

import { isMobile } from "react-device-detect"
import { isMMContentHidden } from "@mobilemind/common/src/functions"

import { updateFilters, resetFilters, getBadges } from "./badgesSlice"

const mapStateToProps = ({ session, badges, sidebar }) => {
  return {
    sidebar: sidebar,
    isExpanded: sidebar.mobileFiltersExpanded,
    filters: badges.filters,
    session,
  }
}

const mapDispatchToProps = {
  updateFilters,
  resetFilters,
  getBadges,
}

function BadgeFilters(props) {
  return <BadgeFiltersContent {...props} theme={theme} />
}

class BadgeFiltersContent extends Component {
  updateFilters = (event, key) => {
    let filters = { ...this.props.filters }
    let type = event.target.type

    filters[key] =
      type === "checkbox" ? event.target.checked : event.target.value

    if (key === "topCategory") {
      filters.subCategory = "any"
    }

    this.props.updateFilters(filters, "earnBadges")
  }

  toggleExpand = () => {
    this.props.updateFilters(this.props.isExpanded ? false : true, "mobile")
  }

  render() {
    const { search, org, remaining } = this.props.filters
    const { session, theme } = this.props
    let collectionLabel =
      session.collection && session.collection.label
        ? session.collection.label[0].value
        : "MobileMind"

    let orgId =
      session.group && session.group.uuid && session.group.uuid[0].value

    return (
      <form
        className={classNames(
          "filters badges",
          this.props.sidebar.isCollapsed && "collapsed",
          isMobile && this.props.isExpanded && "expanded"
        )}
      >
        <header>
          <strong>Filter Badges</strong>
          <ButtonSmall onClick={() => this.props.resetFilters()}>
            Reset
          </ButtonSmall>
        </header>
        <div className="flexRow inputSearch">
          <span className="icon search" />
          <TextField
            variant="outlined"
            label="Search"
            value={search}
            onChange={(event) => {
              this.updateFilters(event, "search")
            }}
          />
        </div>

        {orgId && !isMMContentHidden(session) && (
          <FormControl className="inputSelect source">
            <span className="icon source" />
            <InputLabel id="labelSource">Source</InputLabel>
            <Select
              labelId="labelSource"
              id="sourceSelect"
              value={org}
              onChange={(event) => {
                this.updateFilters(event, "org")
              }}
            >
              <MenuItem key={"any"} value={"any"}>
                Any
              </MenuItem>
              <MenuItem key={"mobilemind"} value={"mobilemind"}>
                {collectionLabel}
              </MenuItem>
              <MenuItem key={"my_org"} value={"my_org"}>
                {session.group.label[0].value}
              </MenuItem>
            </Select>
          </FormControl>
        )}

        <FormControlLabel
          control={
            <Checkbox
              style={{
                color: theme.palette.secondary.main,
              }}
              checked={remaining}
              onChange={(event) => {
                this.updateFilters(event, "remaining")
              }}
            />
          }
          label="Remaining Badges Only"
        />
        <div className="toggle" onClick={this.toggleExpand}>
          <span className="icon" />
        </div>
      </form>
    )
  }
}

BadgeFilters.propTypes = {
  filters: PropTypes.object,
  isExpanded: PropTypes.object,
  sidebar: PropTypes.object,
  sortedCategories: PropTypes.array,
}

export default connect(mapStateToProps, mapDispatchToProps)(BadgeFilters)
