import { Dialog } from "@mui/material"
import "../styles/index.scss"
import { generateTimestamp } from "@mobilemind/common/src/functions/index"

function AssessmentRevisionModal(props) {
  return (
    <Dialog
      open={true}
      onClose={() => {
        props.onClose()
      }}
    >
      <div className="dialog revisionModal">
        <img
          alt={props.revision.created}
          src={process.env.REACT_APP_API_URL + props.revision.image}
          style={{ borderRadius: 6 }}
        />
        <footer>
          Image submitted {generateTimestamp(props.revision.created)}
        </footer>
      </div>
    </Dialog>
  )
}

export default AssessmentRevisionModal
