import theme from "@mobilemind/common/src/theme/theme"
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
} from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers"
import moment from "moment"
import { useDispatch } from "react-redux"

import ButtonSmall from "../../components/ButtonSmall"
import { resetFilters, updateField, updateFilters } from "./leaderboardsSlice"

function SidebarLeaderboards(props) {
  const dispatch = useDispatch()

  const { filters } = props.leaderboards
  const { leaderboardType, group, dateFrom, dateTo } = filters
  const { session } = props

  let rewindStart =
    (session.group.field_rewind_begin &&
      session.group.field_rewind_begin[0] &&
      session.group.field_rewind_begin[0].value) ??
    moment("06/01/" + moment().format("YYYY"))
      .subtract(1, "year")
      .format()

  let rewindEnd =
    (session.group.field_rewind_end &&
      session.group.field_rewind_end[0] &&
      session.group.field_rewind_end[0].value) ??
    moment("05/31/" + moment().format("YYYY")).format()

  const visibility =
    session.group &&
    session.group.field_teacher_leaderboard_visibi[0] &&
    session.group.field_teacher_leaderboard_visibi[0].value

  function handleTabChange(event, newValue) {
    dispatch(updateField({ field: "dateOption", value: newValue }))
    if (newValue === "week") {
      dispatch(
        updateFilters({
          name: "dateFrom",
          value: JSON.stringify(moment().startOf("week").format()),
        })
      )
      dispatch(
        updateFilters({
          name: "dateTo",
          value: JSON.stringify(moment().endOf("week").format()),
        })
      )
    } else if (newValue === "month") {
      dispatch(
        updateFilters({
          name: "dateFrom",
          value: JSON.stringify(moment().startOf("month").format()),
        })
      )
      dispatch(
        updateFilters({
          name: "dateTo",
          value: JSON.stringify(moment().endOf("month").format()),
        })
      )
    } else if (newValue === "year") {
      dispatch(
        updateFilters({
          name: "dateFrom",
          value: JSON.stringify(moment().startOf("year").format()),
        })
      )
      dispatch(
        updateFilters({
          name: "dateTo",
          value: JSON.stringify(moment().endOf("year").format()),
        })
      )
    } else if (newValue === "schoolYear") {
      dispatch(
        updateFilters({
          name: "dateFrom",
          value: JSON.stringify(moment(rewindStart).format()),
        })
      )
      dispatch(
        updateFilters({
          name: "dateTo",
          value: JSON.stringify(moment(rewindEnd).format()),
        })
      )
    } else if (newValue === "custom") {
      dispatch(updateFilters({ name: "dateFrom", value: null }))
    } else {
      dispatch(updateFilters({ name: "dateFrom", value: null }))
      dispatch(
        updateFilters({
          name: "dateTo",
          value: JSON.stringify(moment().format()),
        })
      )
    }
  }

  return (
    <div id="sidebar">
      <form className="filters">
        <header>
          <strong>Filter</strong>
          <ButtonSmall
            className="button small"
            onClick={() => {
              dispatch(resetFilters())
            }}
          >
            <span style={{ color: "black" }}>Reset</span>
          </ButtonSmall>
        </header>

        <FormControl className="inputSelect leaderboardType">
          <InputLabel id="label-leaderboardType-select">Leaderboard</InputLabel>
          <Select
            labelId="label-leaderboardType-select"
            id="leaderboardType-select"
            value={leaderboardType}
            onChange={(event) =>
              dispatch(
                updateFilters({
                  name: "leaderboardType",
                  value: event.target.value,
                })
              )
            }
          >
            <MenuItem value={"courses-completed"}>Courses Completed</MenuItem>
            <MenuItem value={"badges-earned"}>Badges Earned</MenuItem>
          </Select>
        </FormControl>

        {visibility === "org" &&
          props.leaderboards.subGroups.data.length > 0 && (
            <FormControl className="inputSelect group">
              <InputLabel id="label-group-select">Group</InputLabel>
              <Select
                labelId="label-group-select"
                id="group-select"
                value={group}
                onChange={(event) =>
                  dispatch(
                    updateFilters({ name: "group", value: event.target.value })
                  )
                }
              >
                <MenuItem value={"all"}>All</MenuItem>
                {props.leaderboards.subGroups.data.map((group) => {
                  return (
                    <MenuItem key={group.id} value={group.id}>
                      {group.attributes.label}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          )}

        <Tabs
          className="dateTabs"
          value={props.leaderboards.dateOption}
          onChange={handleTabChange}
        >
          <Tab
            style={{
              backgroundColor:
                props.leaderboards.dateOption === "allTime"
                  ? theme.palette.secondary.main
                  : "transparent",
            }}
            value="allTime"
            label="All Time"
          />
          <Tab
            style={{
              backgroundColor:
                props.leaderboards.dateOption === "week"
                  ? theme.palette.secondary.main
                  : "white",
            }}
            value="week"
            label="This Week"
          />
          <Tab
            style={{
              backgroundColor:
                props.leaderboards.dateOption === "month"
                  ? theme.palette.secondary.main
                  : "white",
            }}
            value="month"
            label="This Month"
          />

          <Tab
            style={{
              backgroundColor:
                props.leaderboards.dateOption === "schoolYear"
                  ? theme.palette.secondary.main
                  : "white",
            }}
            value="schoolYear"
            label="School Year"
          />
          <Tab
            style={{
              backgroundColor:
                props.leaderboards.dateOption === "year"
                  ? theme.palette.secondary.main
                  : "white",
            }}
            value="year"
            label="This Year"
          />

          <Tab
            style={{
              backgroundColor:
                props.leaderboards.dateOption === "dateRange"
                  ? theme.palette.secondary.main
                  : "white",
            }}
            value="dateRange"
            label="Custom"
          />
        </Tabs>

        {props.leaderboards.dateOption === "dateRange" && (
          <div id="date-range-container">
            <DatePicker
              label="Date From"
              value={moment(dateFrom)}
              onChange={(date) =>
                dispatch(
                  updateFilters({
                    name: "dateFrom",
                    value: JSON.stringify(date),
                  })
                )
              }
            />
            <DatePicker
              label="Date To"
              value={moment(dateTo)}
              onChange={(date) =>
                dispatch(
                  updateFilters({
                    name: "dateTo",
                    value: JSON.stringify(date),
                  })
                )
              }
            />
          </div>
        )}
      </form>
    </div>
  )
}

export default SidebarLeaderboards
