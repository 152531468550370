import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import fetchWrapper from "@mobilemind/common/src/functions/fetchWrapper"
import qs from "qs"
import debounceThunk from "@mobilemind/common/src/functions/debounceThunk"

export const getExploreContent = createAsyncThunk(
  "exploreSlice/getExploreContent",
  async (args, thunkAPI) => {
    const { filters, currentPage } = thunkAPI.getState().explore
    const { isPartner } = thunkAPI.getState().session
    const { tags } = thunkAPI.getState()

    const url = isPartner
      ? "/api/mm_partner_portal/course_explore?"
      : "/api/course_entity/explore?"

    let query = {
      search: filters.searchQuery,
      status: filters.status,
      difficulty: filters.difficulty.toLowerCase(),
      lp: filters.learningPath,
      // Hide excluded items
      excluded: 1,
      // Only show released items
      released: 1,
      page: {
        offset: 25 * currentPage,
      },
    }

    // Handle tags
    let currentTagIds = []

    if (filters.selectedTags.length) {
      filters.selectedTags.forEach((tag) => {
        let fullTag = tags.data.find((full) => full.id === tag.id)
        currentTagIds.push(fullTag.attributes.drupal_internal__tid)
      })
      query.tags = currentTagIds.join()
    }

    if (filters.category) {
      query.category = filters.category.attributes.drupal_internal__tid
    }

    if (
      filters.source &&
      filters.source !== "any" &&
      filters.source !== "mobilemind"
    ) {
      query.org = "my_org"
    } else {
      query.org = filters.source
    }
    if (filters.remainingOnly) {
      query.status = "not_started"
    }

    let courseFetch = await fetchWrapper.get(url + qs.stringify(query))
    let fetchedCourses = await courseFetch.json()

    return {
      courses: fetchedCourses.data,
      total: fetchedCourses.total_records,
    }
  }
)

const debouncedGetExploreContent = debounceThunk(getExploreContent, 750)

export const updateExploreFilters = createAsyncThunk(
  "exploreSlice/updateExploreFilters",
  async (args, thunkAPI) => {
    thunkAPI.dispatch(debouncedGetExploreContent(args))
    return args
  }
)

export const resetExploreFilters = createAsyncThunk(
  "exploreSlice/resetExploreFilters",
  async (args, thunkAPI) => {
    const filters = {
      searchQuery: "",
      category: null,
      selectedTags: [],
      learningPath: "any",
      source: "any",
      difficulty: "any",
      remainingOnly: true,
    }

    thunkAPI.dispatch(getExploreContent(filters))
    return filters
  }
)

export const exploreSlice = createSlice({
  name: "exploreSlice",
  initialState: {
    courses: [],
    assessments: [],
    currentPage: 0,
    totalPages: 0,
    isFetching: true,
    filters: {
      selectedTags: [],
      searchQuery: "",
      category: null,
      learningPath: "any",
      source: "any",
      difficulty: "any",
      remainingOnly: true,
    },
  },
  reducers: {
    increaseCurrentPage: (state) => {
      state.currentPage++
    },
    filterLearningPathCourses: (state, action) => {
      state.filters[action.payload.name] = action.payload.value
    },
  },
  extraReducers: {
    [updateExploreFilters.fulfilled]: (state, action) => {
      state.isFetching = true

      if (action.payload.name !== "remainingOnly") {
        state.isFetching = true
      }
      if (action.payload.name === "tags") {
        if (action.payload.method === "add") {
          state.filters.selectedTags.push(action.payload.value)
        } else {
          state.filters.selectedTags.splice(action.payload.value, 1)
        }
      }
      if (action.payload === "increaseCurrentPage") {
        state.currentPage++
      } else {
        state.courses = []
        state.currentPage = 0
        state.totalPages = 0
      }

      state.totalPages = 0
      state.filters[action.payload.name] = action.payload.value
    },
    [resetExploreFilters.pending]: (state) => {
      state.isFetching = true
      state.courses = []
    },
    [resetExploreFilters.fulfilled]: (state, action) => {
      state.currentPage = 0
      state.filters = action.payload
    },
    [getExploreContent.fulfilled]: (state, action) => {
      state.hasFetched = true
      state.isFetching = false
      state.courses = state.currentPage
        ? state.courses.concat(action.payload.courses)
        : action.payload.courses
      state.totalPages = Math.ceil(action.payload.total / 50)
      state.total = action.payload.total
    },
  },
})

export const { increaseCurrentPage, filterLearningPathCourses } =
  exploreSlice.actions

export default exploreSlice.reducer
