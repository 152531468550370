import { isBefore, isAfter } from "date-fns"
import moment from "moment"

export function formatConferenceSessions({
  conference,
  rooms,
  userEvent,
  loadConferenceEvent,
}) {
  return conference.conferenceSessions.data.map((event) => {
    let canRegister = false
    if (event.reg_start && event.reg_end) {
      if (
        isBefore(new Date(), new Date(event.reg_end)) &&
        isAfter(new Date(), new Date(event.reg_start))
      ) {
        canRegister = true
      }
    } else if (isBefore(new Date(), new Date(event.session_end))) {
      canRegister = true
    } else if (event.rsvp_status) {
      canRegister = true
    }

    return {
      conference: conference,
      id: event.session_id,
      addOnId: event.addon_uuid,
      conferenceUserEvent: userEvent,
      rsvp: event.rsvp_status,
      canRegister,
      isSession: true,
      attended: Boolean(event.attended === "1"),
      sessionUserEventUUID: event.user_session_uuid,
      image: event.session_image && {
        attributes: {
          uri: {
            url: event.session_image,
          },
        },
      },
      tags: event.session_tags ?? "",
      isConference: false,
      isForConference: true,
      maxCapacity: Number(event.session_capacity),
      field_draft: false,
      field_archive: false,
      drupal_internal__id: Number(event.session_id),
      startDate: moment(event.session_start).subtract(
        moment().isDST() ? 0 : 1,
        "hours"
      )._i,
      endDate: moment(event.session_end).subtract(
        moment().isDST() ? 0 : 1,
        "hours"
      )._i,
      title: event.session_name,
      description: event.session_description ?? "",
      category: event.category_uuid ?? "None",
      roomId: event.room_uuid ?? "none",
      conferenceLocation:
        event.location && event.location[0] && event.location[0].uuid,
      fullRoom: rooms.find(
        (room) => event.room_uuid && room.id === event.room_uuid
      ),
      categoryId: event.category_uuid,
      color: event.session_hex ?? "#59C3E5",
      loadConferenceEvent: () => loadConferenceEvent(event),
    }
  })
}

export function filterConferenceSessions({ conference, formattedEvents }) {
  return formattedEvents.filter((event) => {
    const filters = conference.scheduleFilters
    const bySearch = filters.searchQuery
      ? event.title.toLowerCase().includes(filters.searchQuery.toLowerCase())
      : true

    const onlyMy = filters.onlyMy ? event.rsvp && event.rsvp === "accept" : true

    let byRoom =
      filters.roomId !== "any" ? event.roomId === filters.roomId : true
    if (filters.roomId === "All Rooms") {
      byRoom = true
    }

    const byCategory = filters.category
      ? event.category === filters.category.id
      : true

    let byLocation =
      conference.conferenceLocations.length > 1
        ? event.fullRoom?.relationships.field_location.data.id ===
          filters.locationId
        : true

    if (!event.fullRoom) {
      byLocation = true
    }

    let byTags = true
    if (filters.tags.length) {
      filters.tags.length &&
        filters.tags.forEach((selectedTag) => {
          if (!event.tags.includes(selectedTag.text)) {
            byTags = false
          }
        })
    }

    return bySearch && onlyMy && byRoom && byLocation && byCategory && byTags
  })
}
