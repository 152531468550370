import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { RootState } from "../../store/store"
import { changeNavbar } from "../../store/reducers/navbar"
import { withRouter } from "react-router-dom"
import "../../styles/rubric.scss"

import BackpackLayout from "@mobilemind/common/src/components/backpack/BackpackLayout"
import { getConferenceSessions } from "../achievements/achievementsSlice"

import {
  updateFilters,
  setDateMin,
  setDateMax,

  // Events
  setEventsCarousel,
  increaseEventsPage,

  //External
  setXPDCarousel,
  increaseXPDPage,
  setExternalModal,

  // Courses
  increaseCoursePage,
  setCourseCarousel,

  // Badges
  setBadgeCarousel,
  increaseBadgePage,

  // Learning Paths
  setLPCarousel,
  increaseLPPage,

  // Mandated Training
  increaseMandatedTrainingPage,
  setMandatedTrainingCarousel,

  // User Rubrics
  increaseRubricPage,
  setRubricCarousel,
  fetchSingleUserRubric,
  clearBackpackRubric,
} from "../../store/reducers/backpack"

import ExternalApprovalModal from "./ExternalApprovalModal"
import { useDispatch } from "react-redux"

const BackpackContainer = (props: any) => {
  const dispatch = useDispatch()
  const { backpack, session, categories } = useSelector(
    (state: RootState) => state
  )
  const history = useHistory()

  function goTo(location: string) {
    history.push(location)
  }

  const childProps = {
    // Events
    setEventsCarousel,
    increaseEventsPage,
    getConferenceSessions,

    //External
    setXPDCarousel,
    increaseXPDPage,
    setExternalModal,

    // Courses
    increaseCoursePage,
    setCourseCarousel,

    // Badges
    setBadgeCarousel,
    increaseBadgePage,

    // Learning Paths
    setLPCarousel,
    increaseLPPage,

    // Mandated Training
    increaseMandatedTrainingPage,
    setMandatedTrainingCarousel,

    // Rubrics
    increaseRubricPage,
    setRubricCarousel,
    fetchSingleUserRubric: (userRubricId: string) =>
      dispatch(fetchSingleUserRubric(userRubricId)),
    clearBackpackRubric: () => dispatch(clearBackpackRubric()),

    // State
    backpack,
    session,
    categories,

    // Other actions
    changeNavbar,
    updateFilters,
    setDateMin,
    setDateMax,
    goTo,
  }

  return (
    <>
      <ExternalApprovalModal />
      <BackpackLayout {...childProps} />
    </>
  )
}

export default withRouter(BackpackContainer)
