import theme from "@mobilemind/common/src/theme/theme"

function Filters({ active }) {
  return (
    <svg width="20px" height="20px" viewBox="0 0 20 20">
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="setting"
          transform="translate(0.000000, 0.000000)"
          fill={active ? theme.palette.primary.main : "#000000"}
          fillRule="nonzero"
        >
          <path
            d="M0.833333333,3.95854904 L3.11333333,3.95854904 C3.48102712,5.31144127 4.70928134,6.25043142 6.11124999,6.25043142 C7.51321865,6.25043142 8.74147287,5.31144127 9.10916666,3.95854904 L19.1666666,3.95854904 C19.6269039,3.95854904 20,3.585453 20,3.12521571 C20,2.66497842 19.6269039,2.29188238 19.1666666,2.29188238 L9.10916666,2.29188238 C8.74147287,0.938990144 7.51321865,0 6.11124999,0 C4.70928134,0 3.48102712,0.938990144 3.11333333,2.29188238 L0.833333333,2.29188238 C0.373096048,2.29188239 1.75611858e-08,2.66497842 1.75611858e-08,3.12521571 C1.75611858e-08,3.58545299 0.373096048,3.95854903 0.833333333,3.95854904 Z M6.11083333,1.66688238 C6.91624859,1.66688238 7.56916666,2.31980045 7.56916666,3.12521571 C7.56916666,3.93063097 6.91624859,4.58354904 6.11083333,4.58354904 C5.30541807,4.58354904 4.6525,3.93063097 4.6525,3.12521571 C4.65341846,2.3201812 5.30579882,1.66780084 6.11083333,1.66688238 Z"
            id="Shape"
          ></path>
          <path
            d="M19.1666666,9.16688237 L16.8866667,9.16688237 C16.5195717,7.81364792 15.2913087,6.87418768 13.8891667,6.87418768 C12.4870247,6.87418768 11.2587616,7.81364792 10.8916667,9.16688237 L0.833333333,9.16688237 C0.373096041,9.16688237 0,9.53997841 0,10.0002157 C0,10.460453 0.373096041,10.833549 0.833333333,10.833549 L10.8916667,10.833549 C11.2587616,12.1867835 12.4870247,13.1262437 13.8891667,13.1262437 C15.2913087,13.1262437 16.5195717,12.1867835 16.8866667,10.833549 L19.1666666,10.833549 C19.6269039,10.833549 20,10.460453 20,10.0002157 C20,9.53997841 19.6269039,9.16688237 19.1666666,9.16688237 L19.1666666,9.16688237 Z M13.8891667,11.458549 C13.0837514,11.458549 12.4308333,10.805631 12.4308333,10.0002157 C12.4308333,9.19480044 13.0837514,8.54188237 13.8891667,8.54188237 C14.6945819,8.54188237 15.3475,9.19480044 15.3475,10.0002157 C15.3465815,10.8052502 14.6942012,11.4576306 13.8891667,11.458549 Z"
            id="Shape"
          ></path>
          <path
            d="M19.1666666,16.0418824 L9.10916666,16.0418824 C8.74147287,14.6889901 7.51321865,13.75 6.11124999,13.75 C4.70928134,13.75 3.48102712,14.6889901 3.11333333,16.0418824 L0.833333333,16.0418824 C0.373096041,16.0418824 0,16.4149784 0,16.8752157 C0,17.335453 0.373096041,17.708549 0.833333333,17.708549 L3.11333333,17.708549 C3.48102712,19.0614413 4.70928134,20.0004314 6.11124999,20.0004314 C7.51321865,20.0004314 8.74147287,19.0614413 9.10916666,17.708549 L19.1666666,17.708549 C19.6269039,17.708549 20,17.335453 20,16.8752157 C20,16.4149784 19.6269039,16.0418824 19.1666666,16.0418824 L19.1666666,16.0418824 Z M6.11083333,18.333549 C5.30541807,18.333549 4.6525,17.680631 4.6525,16.8752157 C4.6525,16.0698004 5.30541807,15.4168824 6.11083333,15.4168824 C6.91624859,15.4168824 7.56916666,16.0698004 7.56916666,16.8752157 C7.56779046,17.6800602 6.91567786,18.3321728 6.11083333,18.333549 L6.11083333,18.333549 Z"
            id="Shape"
          ></path>
        </g>
      </g>
    </svg>
  )
}

export default Filters
