import { Component } from "react"
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import PropTypes from "prop-types"

import classNames from "classnames"
import { getActiveUserLearningPath } from "../functions"

import { Button } from "@mui/material"

import {
  updateAssessment,
  getUserLearningPaths,
} from "@mobilemind/common/src/actions/course"
import { sendMessage } from "../actions"
import Rating from "@mobilemind/common/src/components/Rating"
import { convertEstimatedTime } from "@mobilemind/common/src/functions"
import moment from "moment"
import { getCourse } from "@mobilemind/common/src/actions/course"

import trashImg from "../img/trash.svg"
import ButtonSmall from "./ButtonSmall"
import theme from "@mobilemind/common/src/theme/theme"
import Values from "values.js"

const mapStateToProps = ({ session, extension }) => {
  // Get the most recently updated User Learning Paths that's still in progress
  let activeUserLearningPath =
    session.userLearningPaths &&
    getActiveUserLearningPath(session.userLearningPaths)

  return {
    activeUserLearningPath,
    session,
    extension,
  }
}

const mapDispatchToProps = {
  updateAssessment,
  sendMessage,
  getUserLearningPaths,
  getCourse,
}

function CourseListItem(props) {
  return <ListItem {...props} theme={theme} />
}

class ListItem extends Component {
  state = {
    promptRemove: false,
  }

  remove = (id) => {
    this.setState({ promptRemove: false, removed: true })
    this.props.removeAssessment(id)
  }

  render() {
    let {
      course,
      theme,
      status,
      badge,
      assessmentId,
      isExcluded,
      currentPage,
      isNextUp,
      learningPathId,
    } = this.props
    let { promptRemove, removed } = this.state

    const hasNotReleased = moment().isBefore(
      moment(course.field_release_date_value)
    )

    let coursePath = "/course/" + course.id
    if (learningPathId) {
      coursePath = coursePath + "/" + learningPathId
    }

    let categoryLabel =
      course.field_category_name && course.field_category_name.toLowerCase()
    let level = course.field_level_value.toLowerCase()
    let isCompleted = status === "completed"
    let isInProgress = status === "in_progress"
    let isSaved = status === "saved_for_later"
    let isSubmitted = status === "grading_in_progress" || status === "submitted"

    const updated = moment(
      isCompleted
        ? course.field_user_assessment_completed_date
        : course.field_user_assessment_last_updated
    ).format("M/D/YYYY")

    let hasComments = course.field_user_assessment_comment
    let isRemovable =
      !hasComments && status === "in_progress" && currentPage !== "explore"

    let trashOffset = 165

    const rowContent = (
      <>
        {hasNotReleased && (
          <div
            style={{
              position: "absolute",
              zIndex: 4,
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              className="not-released-popover"
              style={{ backgroundColor: theme.palette.secondary.main }}
            >
              This course will be available on{" "}
              {moment(course.field_release_date_value).format("MMMM Do, YYYY")}
            </div>
          </div>
        )}
        <header>
          <div className="category-icon-wrapper">
            {course.field_category_image_uri && (
              <span
                className="category-icon"
                style={{
                  backgroundImage:
                    "url(" + course.field_category_image_uri + ")",
                }}
              />
            )}
          </div>
          <div className="course-label">
            <span className="category-label">{categoryLabel}</span>
            <div className="course-level">
              <Rating level={level} />
            </div>
            {badge && <span className="icon badge"></span>}
          </div>
          {isNextUp &&
            !isInProgress &&
            course.field_course_estimated_time_value && (
              <div
                className="estimatedTime"
                style={{ color: theme.palette.primary.main }}
              >
                <span className="icon clock" />
                {convertEstimatedTime(course.field_course_estimated_time_value)}
              </div>
            )}
        </header>
        <h2>{course.name}</h2>
        <footer className="course-details">
          <p>{course.field_course_objective_value}</p>
        </footer>
        {isInProgress && !removed && (
          <footer className="timestamp in-progress">
            {hasComments ? <>Updated {updated}</> : <>Started {updated}</>}
          </footer>
        )}
        {isExcluded && this.props.session.group && (
          <p className="excludedText">
            <strong>{this.props.session.group.label[0].value}</strong> has
            chosen to exclude <strong>{course.field_category_name}</strong>{" "}
            courses.
          </p>
        )}
        {isSaved && (
          <footer className="timestamp in-progress">
            {this.state.isStartingFromSave ? (
              <>Starting Challenge...</>
            ) : (
              <>Saved {updated}</>
            )}
          </footer>
        )}
        {isInProgress && hasComments && (
          <p className="feedback">
            <em>"{course.field_user_assessment_comment}"</em>
          </p>
        )}
        {isSubmitted && (
          <footer className="timestamp in-progress">Submitted {updated}</footer>
        )}
        {isCompleted && (
          <footer className="timestamp">Completed {updated}</footer>
        )}
      </>
    )

    return (
      <>
        {(!removed || this.props.currentPage === "learningPath") && (
          <li
            style={{
              animationDelay: this.props.animationDelay,
              borderLeftColor: theme.palette.secondary.main,
            }}
            className={classNames(
              "course-list-item",
              { isSaved: isSaved },
              { hasFeedback: hasComments && !isSubmitted },
              { isCompleted },
              { isExcluded },
              { hasNotReleased: hasNotReleased }
            )}
          >
            {!hasNotReleased && !isExcluded ? (
              <Link to={coursePath}>{rowContent}</Link>
            ) : (
              <div className="courseListItemDisabled">{rowContent}</div>
            )}

            {isSaved && !this.state.isStartingFromSave && (
              <Button
                onClick={() => {
                  this.remove(assessmentId)
                }}
                className="button small unsave"
              >
                <span className="icon pin" />
                Unsave
              </Button>
            )}
            {isRemovable && !removed && (
              <Button
                style={{
                  right: !this.props.extension.version ? 15 : trashOffset,
                }}
                onClick={() => {
                  this.setState({ promptRemove: true })
                }}
                className="button small remove"
              >
                <img src={trashImg} alt="remove" />
              </Button>
            )}
            <footer
              style={{
                backgroundColor: new Values(theme.palette.secondary.main)
                  .tint(70)
                  .hexString(),
              }}
              className={classNames("prompt", { active: promptRemove })}
            >
              <>
                <p>
                  <strong>
                    Are you sure you want to restart this challenge?
                  </strong>
                </p>
                <div className="flexRow">
                  <ButtonSmall
                    onClick={() => {
                      this.setState({ promptRemove: false })
                    }}
                    className="button small"
                  >
                    Cancel
                  </ButtonSmall>
                  <div style={{ width: 140 }}>
                    <ButtonSmall
                      onClick={() => {
                        this.remove(assessmentId)
                      }}
                      className="button small"
                    >
                      Yes, Restart
                    </ButtonSmall>
                  </div>
                </div>
              </>
            </footer>
          </li>
        )}
      </>
    )
  }
}

CourseListItem.propTypes = {
  activeUserLearningPath: PropTypes.object,
  animationDelay: PropTypes.string,
  assessment: PropTypes.object,
  category: PropTypes.object,
  course: PropTypes.object,
  currentPage: PropTypes.string,
  extension: PropTypes.object,
  learningPaths: PropTypes.array,
  session: PropTypes.object,
  status: PropTypes.string,
}

export default connect(mapStateToProps, mapDispatchToProps)(CourseListItem)
