function SlideEncouragement() {
  return (
    <div className="slide encouragement">
      <div className="background-tile coffee-bg" />
      <div className="firstWrapper">
        <div className="part first">
          <p className="textLarge">You're off to a great start!</p>
        </div>
      </div>

      <div className="secondWrapper">
        <div className="part second">
          <p className="textLarge">
            There are well over 150 MobileMind micro-learning opportunities with
            hands-on challenges, so you can find something that works for you!
          </p>
        </div>
      </div>
      <div className="thirdWrapper">
        <div className="part third">
          <div className="flipWrapper">
            <div className="flipper">
              <div className="front">
                <div className="icon certificate" />
              </div>
              <div className="back">
                <div className="icon badge" />
              </div>
            </div>
          </div>

          <p className="textLarge">
            From productivity tips to engagement strategies, earn badges and
            certificates anytime, anywhere!
          </p>
        </div>
      </div>
    </div>
  )
}

export default SlideEncouragement
