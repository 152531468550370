import { Component } from "react"
import { connect } from "react-redux"
import {
  updateProfile,
  uploadImage,
  updateUserPicture,
} from "@mobilemind/common/src/actions/user"

import moment from "moment"
import classNames from "classnames"
import Dropzone from "react-dropzone"
import PropTypes from "prop-types"

import profilePicImg from "../../img/profile-pic.svg"
import Loading from "@mobilemind/common/src/components/Loading"

const mapStateToProps = ({ session }, ownProps) => {
  return {
    session,
    userPicture: session.userPicture,
  }
}

const mapDispatchToProps = {
  uploadImage,
  updateUserPicture,
  updateProfile,
}

class ProfilePicture extends Component {
  constructor(props) {
    super(props)
    const { userPicture } = this.props
    let userPictureSrc = userPicture
      ? process.env.REACT_APP_API_URL + userPicture.attributes.uri.url
      : profilePicImg

    this.state = {
      userPictureSrc: userPictureSrc,
      isUploading: false,
    }
  }

  onDrop = (file) => {
    let { user } = this.props.session
    let uploaded = file[0] ? file[0] : file.target.files[0]

    if (uploaded) {
      // If it's an image
      if (uploaded.type.indexOf("image") > -1) {
        // Update the UI
        this.setState({
          isDragging: false,
          isUploading: true,
          userPicture: uploaded.preview,
        })

        // Read the file
        let reader = new FileReader()
        reader.readAsDataURL(uploaded)

        // Once we're done, get the file and name it
        reader.onloadend = async (event) => {
          let filename =
            user.id + "-" + new moment().unix() + "-" + uploaded.name

          // And upload it to the user
          let url = "/api/user/user/" + user.id + "/user_picture"
          let newUserPicture = await this.props.uploadImage(
            event.target.result,
            url,
            filename
          )

          await this.props.updateUserPicture(newUserPicture)
          this.setState({ isUploading: false })
        }
      }
    } else {
      this.setState({ isDragging: false, isUploading: false })
    }
  }

  render() {
    const { isUploading, isDragging } = this.state
    const { userPicture } = this.props

    return (
      <div className="profilePictureWrapper">
        {isUploading && <Loading />}
        <Dropzone
          accept="image/jpeg, image/png, image/jpg"
          onDragEnter={() => this.setState({ isDragging: true })}
          onDragLeave={() => this.setState({ isDragging: false })}
          onDrop={this.onDrop.bind(this)}
        >
          {({ getRootProps }) => (
            <div
              {...getRootProps()}
              className={classNames(
                "fileUpload userPictureUpload",
                { isDragging: isDragging },
                { isUploading: isUploading }
              )}
            >
              <span
                className="text"
                style={{ border: "6px solid white", borderRadius: "100%" }}
              >
                <span className="icon photo" />
              </span>
              <input onChange={this.onDrop.bind(this)} type="file" />
              {userPicture ? (
                <div
                  className="profilePicture userPicture"
                  style={{
                    backgroundImage:
                      "url(" +
                      process.env.REACT_APP_API_URL +
                      userPicture.attributes.uri.url +
                      ")",
                  }}
                />
              ) : (
                <img
                  alt="profilePicture"
                  className="profilePicture"
                  src={profilePicImg}
                />
              )}
            </div>
          )}
        </Dropzone>
        {/* {
          userPicture &&
          <div className="tooltip">
            <Button onClick={() => {
              this.props.removeImage()
            }} className="button small">Remove Profile Picture</Button>
          </div>
        } */}
      </div>
    )
  }
}

ProfilePicture.propTypes = {
  session: PropTypes.object,
  userPicture: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePicture)
