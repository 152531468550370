import { useEffect, useState } from "react"
import Loading from "@mobilemind/common/src/components/Loading"
import LearningBuddyMessage from "@mobilemind/common/src/components/LearningBuddyMessage"
import { useDispatch } from "react-redux"
import {
  fetchObservationRubric,
  clearObservationRubric,
} from "./activeEventSlice"

import "../../../styles/rubric.scss"
import { UserRubricResultsContainer } from "@mobilemind/common/src/components/UserRubricResultsContainer"

function EventRubric(props) {
  const { userEvent } = props
  const [fetched, setFetched] = useState(false)
  const dispatch = useDispatch()

  const { observationRubric } = props.activeEvent

  const userRubricId =
    userEvent?.relationships?.field_user_rubric?.data?.meta
      .drupal_internal__target_id

  useEffect(() => {
    if (!fetched) {
      if (userRubricId) {
        // fetch the user rubric from the new endpoint, then set rubric on the state and fetched: true
        dispatch(fetchObservationRubric({ userRubricId })).then(() => {
          setFetched(true)
        })
      } else {
        dispatch(clearObservationRubric())
        setFetched(true)
      }
    }
  }, [userRubricId, fetched, dispatch])

  return (
    <div className="event-updates event-rubric">
      <section>
        <h3>Observation Rubric</h3>

        {!fetched && (
          <div style={{ marginLeft: 15 }}>
            <Loading message={"Loading rubric..."} />
          </div>
        )}

        {fetched && !observationRubric?.id && (
          <LearningBuddyMessage message="The observer hasn't completed this rubric yet. Check back again later!" />
        )}

        {fetched && observationRubric?.id && (
          <UserRubricResultsContainer userRubric={observationRubric} />
        )}
      </section>
    </div>
  )
}

export default EventRubric
