import theme from "@mobilemind/common/src/theme/theme"

function Padlock() {
  return (
    <>
      <svg width="30px" height="30px" viewBox="0 0 398 512">
        <g
          id="Page-1"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="Dashboard-&gt;-Profile"
            transform="translate(-605.000000, -1311.000000)"
            fillRule="nonzero"
          >
            <g id="padlock" transform="translate(605.000000, 1311.000000)">
              <path
                d="M342.36,231.111 L342.36,150.969 C342.36,71.79 278.173,7.603 199.004,7.603 C119.825,7.603 55.638,71.79 55.638,150.969 L55.638,231.111 M107.336,231.111 L107.336,150.969 C107.336,100.416 148.461,59.301 199.004,59.301 C249.547,59.301 290.662,100.416 290.662,150.969 L290.662,231.111"
                id="Shape"
                fill="#D3DAE0"
              ></path>
              <g
                id="Group"
                transform="translate(55.000000, 7.000000)"
                fill="#899EA8"
              >
                <path
                  d="M152.366,0.852 C149.599,0.693 146.812,0.603 144.004,0.603 C64.825,0.603 0.638,64.79 0.638,143.969 L0.638,224.111 L17.363,224.111 L17.363,143.969 C17.363,67.598 77.08,5.183 152.366,0.852 Z"
                  id="Path"
                ></path>
                <path
                  d="M160.729,52.3 C157.91,52.3 155.121,52.435 152.366,52.685 C199.003,56.923 235.661,96.234 235.661,143.968 L235.661,224.11 L252.386,224.11 L252.386,143.968 C252.386,93.416 211.271,52.3 160.729,52.3 Z"
                  id="Path"
                ></path>
              </g>
              <rect
                id="Rectangle"
                fill={theme.palette.secondary.main}
                x="8.181"
                y="231.111"
                width="381.634"
                height="273.291"
              ></rect>
              <rect
                id="Rectangle"
                fill="#1BBA7A"
                x="8.181"
                y="231.111"
                width="33.45"
                height="273.291"
              ></rect>
              <path
                d="M240.053,342.103 C240.053,319.43 221.673,301.051 199.001,301.051 C176.329,301.051 157.949,319.43 157.949,342.103 C157.949,357.719 166.67,371.297 179.505,378.237 L172.899,428.768 L225.104,428.768 L218.498,378.237 C231.332,371.298 240.053,357.719 240.053,342.103 Z"
                id="Path"
                fill="#3E3D4C"
              ></path>
              <path
                d="M389.818,223.507 L349.963,223.507 L349.963,194.619 C349.963,190.42 346.559,187.016 342.36,187.016 L342.36,187.016 C338.161,187.016 334.757,190.42 334.757,194.619 L334.757,223.507 L298.264,223.507 L298.264,152.224 C298.264,97.574 254.624,52.23 199.977,51.703 C144.795,51.172 99.733,95.906 99.733,150.968 L99.733,223.507 L63.24,223.507 L63.24,152.464 C63.24,77.55 123.292,15.642 198.205,15.209 C273.425,14.774 334.757,75.844 334.757,150.969 L334.757,159.139 C334.757,163.338 338.161,166.742 342.36,166.742 L342.36,166.742 C346.559,166.742 349.963,163.338 349.963,159.139 L349.963,152.42 C349.963,68.974 282.873,0.231 199.427,0.000421704867 C115.989,-0.228 48.036,67.584 48.036,150.969 L48.036,223.508 L8.183,223.508 C3.984,223.508 0.58,226.912 0.58,231.111 L0.58,429.795 C0.58,433.994 3.984,437.398 8.183,437.398 L8.183,437.398 C12.382,437.398 15.786,433.994 15.786,429.795 L15.786,238.712 L382.215,238.712 L382.215,496.795 L15.786,496.795 L15.786,465.273 C15.786,461.074 12.382,457.67 8.183,457.67 L8.183,457.67 C3.984,457.67 0.58,461.074 0.58,465.273 L0.58,504.397 C0.58,508.596 3.984,512 8.183,512 L389.818,512 C394.017,512 397.421,508.596 397.421,504.397 L397.421,231.109 C397.421,226.911 394.017,223.507 389.818,223.507 Z M114.939,150.969 C114.939,104.615 152.65,66.904 199.004,66.904 C245.352,66.904 283.059,104.615 283.059,150.969 L283.059,223.508 L114.939,223.508 L114.939,150.969 Z"
                id="Shape"
                fill="#000000"
              ></path>
              <path
                d="M247.656,342.102 C247.656,315.274 225.829,293.447 199.001,293.447 C172.173,293.447 150.346,315.274 150.346,342.102 C150.346,358.201 158.266,373.097 171.329,382.126 L165.36,427.782 C165.076,429.952 165.741,432.139 167.185,433.783 C168.628,435.428 170.711,436.371 172.899,436.371 L225.104,436.371 C227.293,436.371 229.374,435.428 230.818,433.783 C232.261,432.139 232.926,429.952 232.643,427.782 L226.674,382.126 C239.735,373.098 247.656,358.202 247.656,342.102 Z M214.88,371.549 C212.11,373.048 210.55,376.098 210.958,379.223 L216.441,421.166 L181.559,421.166 L187.042,379.223 C187.451,376.099 185.89,373.048 183.12,371.549 C172.283,365.69 165.55,354.406 165.55,342.102 C165.55,323.658 180.556,308.652 199,308.652 C217.444,308.652 232.45,323.658 232.45,342.102 C232.451,354.406 225.718,365.69 214.88,371.549 Z"
                id="Shape"
                fill="#000000"
              ></path>
            </g>
          </g>
        </g>
      </svg>
    </>
  )
}

export default Padlock
