import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import fetchWrapper from "@mobilemind/common/src/functions/fetchWrapper"
import _ from "lodash"
import qs from "qs"
import debounceThunk from "@mobilemind/common/src/functions/debounceThunk"
import moment from "moment"

export const getSubGroups = createAsyncThunk(
  "leaderboardsSlice/getSubGroups",
  async (groupId, thunkAPI) => {
    let query = {
      filter: {
        "gid.id": groupId,
      },
      include: "entity_id",
    }

    let pages = 1
    let i = 0
    let subGroups = []

    while (i < pages) {
      query.page = {
        offset: i * 50,
      }

      let response = await fetchWrapper.get(
        "/api/group_content/organization-subgroup-group?" + qs.stringify(query)
      )
      let data = await response.json()
      pages = Math.ceil(data.meta.count / 50)

      if (data.included) {
        // Match groups with the drupal_internal__id of their entity_id relationship (Groups module...😵)
        data.data.forEach((group) => {
          let targetEntityId = data.included.find(
            (included) => included.attributes.label === group.attributes.label
          )
          if (targetEntityId) {
            group.entity_id = targetEntityId.attributes.drupal_internal__id
          }
          subGroups.push(group)
        })
      }

      i++
    }

    // Sort groups alphabetically
    let sortedGroups = _.orderBy(subGroups, [
      (group) => group.attributes.label.toUpperCase(),
    ])
    return sortedGroups
  }
)

export const getLeaderboard = createAsyncThunk(
  "leaderboardsSlice/getLeaderboard",
  async (args, thunkAPI) => {
    const filters = args ? args : thunkAPI.getState().leaderboards.filters

    const { currentPage, subGroups } = thunkAPI.getState().leaderboards
    const { group, leaderboardType, dateFrom, dateTo } = filters
    const { session } = thunkAPI.getState()

    const subGroupId = session.subgroup && session.subgroup.id[0].value

    const orgId = session.group && session.group.id[0].value
    const userId = session.user.id

    let baseUrl = "/api/leaderboard-"
    let url = baseUrl + leaderboardType + "/" + orgId + "?"

    let payload = {}

    let query = {
      sort_order: "DESC",
      page: currentPage,
    }

    let subGroup = subGroups.data.find((group) => group.id === filters.group)

    if (group !== "all") {
      url = baseUrl + leaderboardType + "/" + subGroup.entity_id + "?"
    }
    if (
      session.group &&
      session.group.field_teacher_leaderboard_visibi[0].value === "group" &&
      subGroupId
    ) {
      url = baseUrl + leaderboardType + "/" + subGroupId + "?"
    }

    let dateRange = {}

    if (dateFrom) {
      dateRange.min = dateFrom.replace('"', "").slice(0, 10)
    }
    if (dateTo) {
      dateRange.max = moment(dateTo.replace('"', "").slice(0, 10))
        .add(1, "day")
        .format("YYYY-MM-DD")
    }

    if (dateFrom || dateTo) {
      if (leaderboardType === "badges-earned") {
        query.created = dateRange
      } else {
        query.changed = dateRange
      }
    }

    let response = await fetchWrapper.get(url + qs.stringify(query))
    let data = await response.json()

    if (!data.rows.content) {
      data.rows.forEach((user, index) => {
        if (user.uuid === userId) {
          payload.userId = userId
          payload.userRank = index + 1 + currentPage * 50

          if (leaderboardType === "courses-completed") {
            payload.userScore = user.id
          } else if (leaderboardType === "badges-earned") {
            payload.userScore = Number(user.field_badge)
          }
        }
      })
      payload.data = data
    }
    return payload
  }
)

const debouncedGetLeaderboard = debounceThunk(getLeaderboard, 600)

export const updateFilters = createAsyncThunk(
  "leaderboardsSlice/updateFilters",
  async (args, thunkAPI) => {
    thunkAPI.dispatch(debouncedGetLeaderboard())
    return args
  }
)

export const resetFilters = createAsyncThunk(
  "leaderboardsSlice/resetFilters",
  async (args, thunkAPI) => {
    const filters = {
      leaderboardType: thunkAPI.getState().leaderboards.filters.leaderboardType,
      group: "all",
      dateFrom: null,
      dateTo: JSON.stringify(new Date()),
    }

    thunkAPI.dispatch(getLeaderboard(filters))
    return filters
  }
)

export const leaderboardsSlice = createSlice({
  name: "leaderboardsSlice",
  initialState: {
    data: [],
    currentPage: 0,
    userRank: 0,
    subGroups: {
      fetched: false,
      data: [],
    },
    filters: {
      leaderboardType: "courses-completed",
      group: "all",
      dateFrom: null,
      dateTo: JSON.stringify(new Date()),
    },
    dateOption: "allTime",
  },
  reducers: {
    updateField: (state, action) => {
      state[action.payload.field] = action.payload.value
    },
  },
  extraReducers: {
    [resetFilters.pending]: (state) => {
      state.data = []
      state.currentPage = 0
    },
    [resetFilters.fulfilled]: (state, action) => {
      state.filters = action.payload
    },
    [updateFilters.fulfilled]: (state, action) => {
      state.fetching = true

      if (action.payload.name === "increaseCurrentPage") {
        state.currentPage++
      } else {
        state.data = []
        state.currentPage = 0
        state.totalPages = 0
      }

      state.filters[action.payload.name] = action.payload.value
    },
    [getSubGroups.pending]: (state) => {
      state.subGroups.fetching = true
    },
    [getSubGroups.fulfilled]: (state, action) => {
      state.subGroups.data = action.payload
      state.subGroups.fetched = true
    },
    [getLeaderboard.pending]: (state) => {
      state.fetching = true
    },
    [getLeaderboard.fulfilled]: (state, action) => {
      state.fetching = false
      state.hasFetched = true

      let results =
        !action.payload.data || action.payload.data.rows.content
          ? []
          : action.payload.data.rows
      state.totalPages = action.payload.data
        ? action.payload.data.pager.total_pages
        : 0

      if (!state.currentPage) {
        state.data = results
      } else {
        results.forEach((row) => {
          let existing = state.data.some(
            (existing) => existing.uuid === row.uuid
          )
          if (!existing) {
            state.data.push(row)
          }
        })
      }

      // Handle user rank
      if (action.payload.userRank) {
        state.userRank = action.payload.userRank
        // In case of ties
        let i = 0
        let placeIndex

        // The current user
        const userRow = results.find(
          (row) => row.uuid === action.payload.userId
        )

        while (i < state.data.length) {
          // When we find the top ranked user with the same score
          if (
            Number(state.data[i].id) === Number(action.payload.userScore) ||
            Number(state.data[i].field_badge) ===
              Number(action.payload.userScore) ||
            Number(state.data[i].field_course_estimated_time) ===
              Number(action.payload.userScore)
          ) {
            // Update the user's rank to match theirs
            state.userRank = i + 1
            placeIndex = i
            // Stick them at the top of users with that score
            state.data = state.data.filter(
              (row) => row.uuid !== action.payload.userId
            )
            state.data.splice(placeIndex, 0, userRow)
            // End the loop
            i = state.data.length
          } else {
            i++
          }
        }
      }
    },
  },
})

export const { setLeaderboardType, increaseCurrentPage, updateField } =
  leaderboardsSlice.actions

export default leaderboardsSlice.reducer
