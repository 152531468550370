function Check() {
  return (
    <svg width="20px" height="20px">
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M10,0 C15.5228475,0 20,4.4771525 20,10 C20,15.5228475 15.5228475,20 10,20 C4.4771525,20 0,15.5228475 0,10 C0,4.4771525 4.4771525,0 10,0 Z M13.1757102,5.68658259 L8.74050269,10.1259465 L6.79905448,8.18267886 L4.69361389,10.2900925 L6.63506214,12.2333602 L8.74050269,14.3406283 L15.2810054,7.79399625 L13.1757102,5.68658259 Z"
          id="Combined-Shape"
          fill="#000000"
        ></path>
      </g>
    </svg>
  )
}

export default Check
