import Loading from "@mobilemind/common/src/components/Loading"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { RootState, useAppDispatch } from "../../../store/store"

import { fetchUnapprovedRequests } from "../../../store/reducers/externalPD"
import UnapprovedCard from "./UnapprovedCard"

interface PendingProps {
  status: string
}

const UnapprovedContainer = ({ status }: PendingProps) => {
  const { unapproved } = useSelector((state: RootState) => state.externalPD)
  const pendingList = unapproved.data.filter(
    (event: any) => event.field_status_value === status
  )

  const [activeItem, setActiveItem] = useState("")
  const dispatch = useAppDispatch()

  useEffect(() => {
    !unapproved.fetched && dispatch(fetchUnapprovedRequests())
  }, [unapproved.fetched, dispatch])

  return (
    <div className="page events-layout recommended-events">
      <h2>
        <div style={{ flex: 1 }}>{status} Requests</div>
        {!unapproved.fetched && (
          <Loading message={"Getting " + { status } + " requests..."} />
        )}
      </h2>
      <div className="pending-list">
        <ul>
          {pendingList.map((event: any, index: number) => {
            return (
              <UnapprovedCard
                key={index}
                activeItem={activeItem}
                onClick={() => {
                  event.id === activeItem
                    ? setActiveItem("")
                    : setActiveItem(event.id)
                }}
                animationDelay={index}
                event={event}
              />
            )
          })}
          {pendingList.length < 1 && (
            <em>You don't have any {status} requests.</em>
          )}
        </ul>
      </div>
    </div>
  )
}

export default UnapprovedContainer
