import { Component } from "react"
import { Card } from "@mui/material"
import PropTypes from "prop-types"
import ButtonSmall from "../../components/ButtonSmall"
import theme from "@mobilemind/common/src/theme/theme"
import { Link } from "react-router-dom"

class WidgetSupport extends Component {
  render() {
    return (
      <Card
        className="widget support"
        style={{ animationDelay: this.props.animationDelay }}
      >
        <div className="widgetLabel">Here For You</div>

        <p>Got questions? Let us know how we can help!</p>

        <div className="flexRow">
          <div>
            <div className="icon support" />
          </div>
          <div className="links">
            {theme.title === "MobileMind" && (
              <>
                <a
                  target={"_blank"}
                  rel="noopener noreferrer"
                  href="https://knowledge.mobilemind.io/what-are-some-terms-that-mobilemind-uses-frequently"
                >
                  <ButtonSmall>
                    <span className="icon terms"></span>MM Terminology
                  </ButtonSmall>
                </a>
                <a
                  target={"_blank"}
                  rel="noopener noreferrer"
                  href="https://knowledge.mobilemind.io/"
                >
                  <ButtonSmall>
                    <span className="icon book"></span>FAQs
                  </ButtonSmall>
                </a>
              </>
            )}

            <Link to="/submit-ticket">
              <ButtonSmall>
                <span className="icon ticket"></span>Submit a Ticket
              </ButtonSmall>
            </Link>
          </div>
        </div>
      </Card>
    )
  }
}

WidgetSupport.propTypes = {
  animationDelay: PropTypes.string,
  widget: PropTypes.object,
}

export default WidgetSupport
