import { Component } from "react"
import { Card } from "@mui/material"
import { Link } from "react-router-dom"
import { convertEstimatedTime } from "@mobilemind/common/src/functions"
import pluralize from "pluralize"

import classNames from "classnames"
import moment from "moment"

class WidgetGoal extends Component {
  calculateGoalPace = () => {
    const { goalPath } = this.props.widget

    const goalInfo = goalPath.lp_goal_info[0] && goalPath.lp_goal_info[0]

    if (goalInfo) {
      let minutesLeft

      const minutesPerCourse =
        Number(goalInfo.lp_goal_est_time_value) /
        Number(goalPath.field_num_courses)
      const completedMinutes =
        minutesPerCourse * Number(goalPath.field_user_lp_completed_courses)

      if (completedMinutes) {
        minutesLeft = Number(goalInfo.lp_goal_est_time_value) - completedMinutes
      } else {
        minutesLeft = Number(goalInfo.lp_goal_est_time_value)
      }

      const coursesRemaining =
        Number(goalPath.field_num_courses) -
        Number(goalPath.field_user_lp_completed_courses)

      // The difference between the deadline and now, in months
      let monthsLeft = moment(goalInfo.lp_goal_due_date_value).diff(
        moment(),
        "months"
      )

      // And in days
      let daysLeft =
        moment(goalInfo.lp_goal_due_date_value).diff(moment(), "days") + 1

      // The daily amount of minutes needed to meet the deadline
      let dailyPace = Math.ceil(minutesLeft / daysLeft)

      // If there's more than one month remaining in the goal
      if (monthsLeft >= 1) {
        // The amount of weeks we have left
        const weeksLeft = moment(goalInfo.lp_goal_due_date_value).diff(
          moment(),
          "weeks"
        )

        // Minutes per week to meet pace
        const weeklyPace = Math.ceil(minutesLeft / weeksLeft)

        // Courses per week to meet pace
        const coursesPerWeek = Math.ceil(coursesRemaining / weeksLeft)

        // If there's more than an hour per week need to complete on time, return "X courses / week"
        if (weeklyPace > 60) {
          return (
            coursesPerWeek +
            " " +
            pluralize("course", coursesPerWeek) +
            " / week"
          )
        }
        // Otherwise just return minutes per week
        else {
          return convertEstimatedTime(weeklyPace) + " / week"
        }
      }

      // If there's less than a month remaining
      else {
        // If it's less than 5m / day, always show 5m / day
        if (dailyPace < 5) {
          return convertEstimatedTime(5) + " / day"
        }
        // Or the actual minutes if it's between 5m and 20m
        else if (dailyPace >= 5 && dailyPace <= 20) {
          return convertEstimatedTime(dailyPace) + " / day"
        }
        // If it's more than 20m / day, show "X courses / day"
        else if (dailyPace > 20) {
          const coursesPerDay = Math.ceil(coursesRemaining / daysLeft)
          return (
            coursesPerDay + " " + pluralize("course", coursesPerDay) + " / day"
          )
        }
      }
    }
    // Catch anything else
    return false
  }

  render() {
    const { goalPath } = this.props.widget
    const goalInfo = goalPath.lp_goal_info[0] && goalPath.lp_goal_info[0]
    const { theme } = this.props
    let onTrack

    // The base daily pace in minutes per day
    const goalDuration = Number(goalInfo.lp_goal_duration_value)
    const daysElapsed =
      moment(goalInfo.lp_goal_due_date_value)
        .subtract(goalDuration, "days")
        .diff(moment(), "days") * -1

    const minutesPerDay = Math.ceil(
      goalInfo.lp_goal_est_time_value / goalDuration
    )

    const trackWidth =
      ((daysElapsed * minutesPerDay) / goalInfo.lp_goal_est_time_value) * 100

    if (goalPath.field_lp_percent_complete >= trackWidth) {
      onTrack = true
    }

    let goalDeadline = moment(goalPath.lp_goal_info[0].lp_goal_due_date_value)
    let goalOverdue = goalDeadline.isBefore(moment())
    let daysTilDue = goalDeadline.diff(moment(), "days")

    return (
      <Card
        className={classNames("widget learningPath userGoal")}
        style={{ animationDelay: this.props.animationDelay }}
      >
        <Link to={"learning-path/" + goalPath.field_lp_id}>
          <div className="topContent">
            <div
              className={"widgetLabel"}
              style={{ backgroundColor: theme.palette.primary.main }}
            >
              Goal
            </div>
            <div
              className="pathImage"
              style={{
                backgroundImage: 'url("' + goalPath.field_lp_image_uri + '")',
              }}
            />
          </div>

          <div className="goalBanner">
            <div className="goalBadge icon goal" />
            {!goalOverdue ? (
              <>
                {daysTilDue === 0 ? (
                  <span>Due Today</span>
                ) : (
                  <span>
                    {daysTilDue} {pluralize("Days", daysTilDue)} Left
                  </span>
                )}
              </>
            ) : (
              <span>Overdue</span>
            )}
          </div>

          <div className={classNames("bottomContent goal")}>
            <h2>{goalPath.name}</h2>

            <div className="flexRow status">
              <div className="speedometer">
                <span className={classNames("needle", onTrack && "onTrack")} />
              </div>
              {onTrack ? (
                <>You're on track!</>
              ) : (
                <>You're not on track to complete this goal.</>
              )}
            </div>
            <ul className="stats">
              <li>
                <span>On Track Pace</span>
                <strong style={{ color: theme.palette.primary.main }}>
                  {this.calculateGoalPace()}
                </strong>
              </li>
            </ul>

            <div className="count">
              {goalPath.field_user_lp_completed_courses
                ? goalPath.field_user_lp_completed_courses
                : 0}{" "}
              / {goalPath.field_num_courses} Courses Completed
            </div>
            <div className="progressBarGoal">
              <div
                className="goalProgress"
                style={{
                  width: goalPath.field_lp_percent_complete + "%",
                  backgroundColor: theme.palette.secondary.main,
                }}
              ></div>
              <div
                className="track"
                style={{
                  width: trackWidth + "%",
                  backgroundColor: theme.palette.primary.main,
                }}
              ></div>
            </div>
          </div>
        </Link>
      </Card>
    )
  }
}

export default WidgetGoal
