import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import fetchWrapper from "@mobilemind/common/src/functions/fetchWrapper"
import qs from "qs"

export const getInProgress = createAsyncThunk(
  "inProgressSlice/getInProgress",
  async (args, thunkAPI) => {
    const { isPartner } = thunkAPI.getState().session
    const url = isPartner
      ? "/api/mm_partner_portal/course_explore?"
      : "/api/course_entity/explore?"

    let status = "in_progress"

    let query = { status }

    let pages = 1
    let i = 0
    let response
    let inProgress = []
    let submitted = []
    let saved = []

    while (i < pages) {
      query.page = { offset: i * 25 }
      response = await fetchWrapper.get(url + qs.stringify(query))

      let data = await response.json()

      if (response.ok) {
        data.data.forEach((course) => {
          if (!inProgress.find((existing) => existing.id === course.id)) {
            inProgress.push(course)
          }
        })
        pages = Math.ceil(Number(data.total_records) / 25)
      }

      i++
    }

    query = { status: "submitted" }
    response = await fetchWrapper.get(url + qs.stringify(query))
    let submittedData = await response.json()

    if (response.ok) {
      submittedData.data.forEach((course) => {
        submitted.push(course)
      })
    }

    query = { status: "saved_for_later" }
    response = await fetchWrapper.get(url + qs.stringify(query))
    let savedData = await response.json()

    if (response.ok) {
      savedData.data.forEach((course) => {
        saved.push(course)
      })
    }

    return { inProgress, submitted, saved }
  }
)

export const inProgressSlice = createSlice({
  name: "inProgressSlice",
  initialState: {
    isFetching: true,
    currentTab: "in_progress",
    saved: [],
    inProgress: [],
    submitted: [],
  },
  reducers: {
    createUserLearningPath: (state, action) => {},
  },
  extraReducers: {
    [getInProgress.fulfilled]: (state, action) => {
      state.isFetching = false
      state.inProgress = action.payload.inProgress
      state.submitted = action.payload.submitted
      state.saved = action.payload.saved
    },
  },
})

export default inProgressSlice.reducer
