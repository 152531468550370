import qs from "qs"
import fetchWrapper from "../functions/fetchWrapper"

/**
 * Check a course against any Mandatory Training series to see if we can retake it
 * @param {array} courseId Id of the course to check for "retakeability"
 */
export function checkCanRetakeCourse(courseId) {
  return async (dispatch, getState) => {
    const { session } = getState()

    try {
      let response = await fetchWrapper.get(
        "/api/course_retake/" +
          session.user.attributes.drupal_internal__uid +
          "/" +
          courseId
      )
      if (response.ok) {
        let data = await response.json()
        return data.data.retake_course
      }
    } catch (err) {
      console.log("wewe")
      console.log(err)
    }
  }
}

export function getCompletedAssessmentsForCourse(courseUUID) {
  return async (dispatch, getState) => {
    const userId = getState().session.user.id

    const query = {
      filter: {
        "field_teacher.id": userId,
        "field_course.id": courseUUID,
        field_status: "completed",
      },
    }

    try {
      let response = await fetchWrapper.get(
        "/api/assessment_entity/assessment_entity?" + qs.stringify(query)
      )

      if (response.ok) {
        let data = await response.json()
        return data.data
      }
    } catch (err) {
      console.log(err)
      return "No RCS found"
    }
  }
}

export function createUserRCS({ series, isMobile }) {
  return async (dispatch, getState) => {
    const url = "/api/usr_renew_course_ser/user_renew_course"
    const { session } = getState()
    const token = isMobile ? session.mobileToken.csrf_token : session.token

    try {
      const title =
        "uid: " +
        session.user.attributes.drupal_internal__uid +
        " / RCS: " +
        series.rcs_id

      const body = {
        data: {
          type: "usr_renew_course_ser--user_renew_course",
          attributes: {
            title,
            description: null,
          },
          relationships: {
            uid: {
              data: {
                type: "user--user",
                id: session.user.id,
              },
            },
            field_rcs: {
              data: {
                type: "renew_course_ser--renew_course_ser",
                id: series.uuid,
              },
            },
            field_user: {
              data: {
                type: "user--user",
                id: session.user.id,
              },
            },
          },
        },
      }

      const response = await fetchWrapper.post(url, token, JSON.stringify(body))

      if (response.ok) {
        let data = await response.json()
        return data
      }
    } catch (err) {
      console.log(err)
    }
  }
}
