import { Component } from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import { Grid } from "@mui/material"

import classNames from "classnames"
import { isMobileOnly } from "react-device-detect"
import ProgressBar from "@mobilemind/common/src/components/ProgressBar"
import CertificateMenu from "@mobilemind/common/src/components/backpack/certificate/CertificateMenu"

import { generateTimestamp } from "@mobilemind/common/src/functions/index"
import { createBadgeCertificate } from "@mobilemind/common/src/actions/user"
import theme from "@mobilemind/common/src/theme/theme"

import pluralize from "pluralize"
import moment from "moment"

const mapStateToProps = ({ session, navbar }) => {
  return {
    navbar,
    session,
  }
}

const mapDispatchToProps = {
  createBadgeCertificate,
}
function BadgeItem(props) {
  return <BadgeItemInner {...props} theme={theme} />
}
class BadgeItemInner extends Component {
  handleClick = (id) => {
    this.props.setActiveBadge(id)
  }

  render() {
    let { session, badge, activeBadgeId, theme } = this.props

    let isActiveBadge = activeBadgeId === badge.id
    let badgeCourses = badge.user_course_info ? badge.user_course_info : []
    let computedHeight = badgeCourses.length * 45 + 168
    if (!badgeCourses.length) {
      computedHeight = 100
    }
    const finishedCourses = badgeCourses.filter(
      (course) => course.field_user_assessment_status === "completed"
    )

    let baseHeight = isMobileOnly ? 75 : 100

    return (
      <Grid
        item
        xs={3}
        className="badgeWrapper"
        style={{ animationDelay: this.props.animationDelay }}
      >
        <div
          style={{ height: isActiveBadge ? computedHeight : baseHeight }}
          onClick={() => {
            this.handleClick(badge.id)
          }}
          className={classNames(
            "badgeItem",
            { active: isActiveBadge },
            { completed: badge.earned_date }
          )}
        >
          <span
            onClick={() => {
              this.handleClick(badge.id, true)
            }}
            className="icon caret"
          />

          <div className="innerTop">
            <img src={badge.field_badge_image_uri} alt={badge.name} />

            {badge.earned_date && (
              <span
                className="icon check"
                style={{ animationDelay: this.props.animationDelay }}
              />
            )}
            <div className="details">
              <h2>{badge.name}</h2>
              {!badge.earned_date ? (
                <>
                  <p className="statusText">
                    {finishedCourses.length}/{badgeCourses.length}{" "}
                    {pluralize("Course", badgeCourses.length)} Completed
                  </p>
                </>
              ) : (
                <p className="statusText">
                  Earned {generateTimestamp(badge.earned_date)}
                </p>
              )}

              {!badge.earned_date && (
                <ProgressBar
                  theme={theme}
                  width={(finishedCourses.length / badgeCourses.length) * 100}
                  completed={finishedCourses.length}
                  total={badgeCourses.length}
                />
              )}
            </div>
          </div>

          <div className="innerBottom">
            {badgeCourses.length > 0 && (
              <header>
                {pluralize("Course", badgeCourses.length)}
                {badge.earned_date ? " Completed" : " Required"}
              </header>
            )}
            <ul>
              {badgeCourses.map((course) => {
                let coursePath = "/course/" + course.id

                const hasNotReleased = moment().isBefore(
                  moment(course.field_release_date)
                )

                const rowContent = (
                  <>
                    <div className="category-icon-wrapper">
                      <span
                        className="category-icon"
                        style={{
                          backgroundImage:
                            "url(" + course.field_category_image_uri + ")",
                        }}
                      />
                    </div>
                    <div className="title">{course.name}</div>
                    <div className="icon-wrapper">
                      {course.field_user_assessment_status && (
                        <span
                          className={classNames(
                            "icon",
                            course.field_user_assessment_status
                          )}
                        />
                      )}
                    </div>
                  </>
                )
                if (!hasNotReleased) {
                  return (
                    <li key={course.id}>
                      <Link to={coursePath}>{rowContent}</Link>
                    </li>
                  )
                } else {
                  return (
                    <li key={course.id} className="notReleased">
                      <div
                        className="not-released-popover"
                        style={{
                          backgroundColor: theme.palette.secondary.main,
                        }}
                      >
                        Available{" "}
                        <strong style={{ marginLeft: 4 }}>
                          {moment(course.field_release_date).format(
                            "MMMM Do, YYYY"
                          )}
                        </strong>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          opacity: 0.5,
                        }}
                      >
                        {rowContent}
                      </div>
                    </li>
                  )
                }
              })}
            </ul>
          </div>
        </div>

        {badge.earned_date && (
          <CertificateMenu
            label={"View Certificate"}
            user={session.user}
            badge={badge}
          />
        )}
      </Grid>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BadgeItem)
