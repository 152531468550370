import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import fetchWrapper from "@mobilemind/common/src/functions/fetchWrapper"
import { RCSExploreItem } from "@mobilemind/common/src/types/mandatedTraining"
import moment from "moment"

export const getMandatedTraining = createAsyncThunk(
  "mandatedTrainingSlice/getMandatedTraining",
  async () => {
    const url = "/api/rcs_explore"

    // Fetch all mandated training series and return
    let response = await fetchWrapper.get(url)
    if (response.ok) {
      let data = await response.json()

      data.data.forEach((series: RCSExploreItem) => {
        // The following variables are used to calculate compliance percentage
        const totalCourses = series.rcs_courses.length
        const totalInCompliance = series.rcs_courses.filter(
          (course) => course.in_compliance === true
        ).length

        series.percentComplete = Math.round(
          (totalInCompliance / totalCourses) * 100
        )
      })

      return data.data
    }
  }
)

export const mandatedTrainingSlice = createSlice({
  name: "mandatedTrainingSlice",
  initialState: {
    fetched: false,
    data: [],
    outOfCompliance: false,
  },
  reducers: {},
  extraReducers: {
    [getMandatedTraining.fulfilled.type]: (state: any, action: any) => {
      state.fetched = true

      if (action.payload) {
        action.payload.forEach((series: RCSExploreItem) => {
          const coursesRemaining = series.rcs_courses.filter(
            (course) => !course.in_compliance
          ).length
          if (coursesRemaining > 0 && moment().isAfter(series.open_date)) {
            state.outOfCompliance = true
          }
        })

        state.data = action.payload
      }
    },
  },
})

export default mandatedTrainingSlice.reducer
