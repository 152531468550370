import { getActiveUserLearningPath } from "../../functions"
import { isMMContentHidden } from "@mobilemind/common/src/functions"

export function generateCourseLayoutProps({ state, ownProps }) {
  const {
    session,
    extension,
    courses,
    mandatedTraining,
    badges,
    assessments,
    categories,
    learningPaths,
  } = state

  let activeCourse = courses.courseData.find((course) => {
    return (
      course.attributes.drupal_internal__id ===
      Number(ownProps.match.params.courseId)
    )
  })

  let unauthorized = true

  unauthorized =
    activeCourse &&
    !activeCourse.relationships.field_organization.data &&
    isMMContentHidden(session)

  // If the course is a partner course, check to see if the org has the partnership
  if (activeCourse && activeCourse.relationships.field_partner.data) {
    let orgPartnershipIds =
      session.group.field_partner &&
      session.group.field_partner.map((partner) => partner.target_uuid)
    if (
      orgPartnershipIds.includes(
        activeCourse.relationships.field_partner.data.id
      )
    ) {
      unauthorized = false
    }
    // Also go through their groups and check those partnerships
    else {
      let groupPartnerIds = []
      !session.subgroups[0].data &&
        session.subgroups[0].forEach((group) => {
          if (
            group[0].field_partner &&
            group[0].field_partner[0] &&
            group[0].field_partner[0].target_uuid
          ) {
            groupPartnerIds.push(group[0].field_partner[0].target_uuid)
          }
        })

      if (
        groupPartnerIds.includes(
          activeCourse.relationships.field_partner.data.id
        )
      ) {
        unauthorized = false
      }
    }
  }

  let courseBadges = badges.data.filter((badge) => {
    return (
      activeCourse &&
      badge &&
      badge.user_course_info &&
      badge.user_course_info.length > 0 &&
      badge.user_course_info.find(
        (course) =>
          Number(course.id) ===
          Number(activeCourse.attributes.drupal_internal__id)
      )
    )
  })

  // Get the most recently updated User Learning Paths that's still in progress
  let activeUserLearningPath =
    session.userLearningPaths &&
    activeCourse &&
    getActiveUserLearningPath(session.userLearningPaths)

  let currentAssessment
  let allCourseAssessments =
    activeCourse &&
    assessments.all.filter(
      (assessment) =>
        assessment.relationships.field_course.data.id === activeCourse.id
    )

  if (allCourseAssessments) {
    currentAssessment = allCourseAssessments.reverse()[0]
  }

  allCourseAssessments =
    allCourseAssessments &&
    allCourseAssessments.filter((item, index) => {
      return (
        index ===
        allCourseAssessments.findIndex((obj) => {
          return JSON.stringify(obj) === JSON.stringify(item)
        })
      )
    })

  const previousAssessments =
    allCourseAssessments &&
    allCourseAssessments.filter(
      (assessment) => assessment.attributes.field_status === "completed"
    )

  let assessmentIsSaved =
    currentAssessment?.attributes.field_status === "saved_for_later"

  const relatedCourses =
    activeCourse?.relationships.field_related_courses.data.map((related) => {
      return courses.courseData.find((course) => course.id === related.id)
    })

  const learningPathId = ownProps.match.params.learningPathId
    ? Number(ownProps.match.params.learningPathId)
    : null

  const mandatedTrainingId = ownProps.location.search?.includes("?mt")
    ? Number(ownProps.location.search.replace("?mt=", ""))
    : null

  return {
    mandatedTrainingId,
    learningPathId,
    unauthorized,
    courses,
    relatedCourses,
    session,
    extension,
    mandatedTraining,
    categories,
    assessments,
    previousAssessments,
    assessment: currentAssessment,
    assessmentIsSaved,
    learningPaths,
    activeUserLearningPath,
    userLearningPaths: session.userLearningPaths,
    activeCourse,
    courseBadges,
    badges,
  }
}

export function setShowRevisionHistory({
  assessment,
  courseQuiz,
  canRetakeCourse,
}) {
  let showRevisionHistory =
    assessment && assessment.revisions && assessment.revisions.length > 0

  if (courseQuiz && assessment) {
    showRevisionHistory = true
  }
  if (
    canRetakeCourse &&
    assessment &&
    assessment.attributes.field_status === "completed"
  ) {
    showRevisionHistory = false
  }

  return showRevisionHistory
}

export function setShowStartButton(args) {
  const { assessmentFetched, assessment, canRetakeCourse, assessmentIsSaved } =
    args

  let showStartButton = assessmentFetched && (!assessment || assessmentIsSaved)

  if (
    assessmentFetched &&
    canRetakeCourse &&
    (!assessment || assessment.attributes.field_status === "completed")
  ) {
    showStartButton = true
  }
  if (assessmentFetched && !assessment) {
    showStartButton = true
  }
  return showStartButton
}

export function generateMailToString({ activeCourse }) {
  const organization = activeCourse.relationships.field_organization.data
  const { field_support_email } = activeCourse.attributes

  let mailToString
  if (!organization || (organization && field_support_email)) {
    if (!organization) {
      mailToString = "/submit-ticket"
    } else if (organization && field_support_email && field_support_email[0]) {
      mailToString = "mailto:" + field_support_email[0]
    } else {
      mailToString = "/submit-ticket"
    }
  }

  return mailToString
}

export function setShowAsSaved({
  assessment,
  assessmentIsSaved,
  assessmentStarted,
  showSaveButton,
  saveButtonClicked,
}) {
  let showAsSaved
  if (assessment) {
    if (assessmentIsSaved && !assessmentStarted) {
      showAsSaved = !showSaveButton
    } else {
      showAsSaved = false
    }
  } else {
    showAsSaved = saveButtonClicked
  }

  return showAsSaved
}

export function calculateRevisionHeight({ revisionHeight, isHistoryExpanded }) {
  if (revisionHeight) {
    if (isHistoryExpanded) {
      return revisionHeight.total
    } else {
      return revisionHeight.firstChild
    }
  } else {
    return "auto"
  }
}
