import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import fetchWrapper from "@mobilemind/common/src/functions/fetchWrapper"

export const getBuddies = createAsyncThunk(
  "learningBuddiesSlice/getBuddies",
  async (args, thunkAPI) => {
    let response = await fetchWrapper.get("/api/taxonomy_term/learning_buddy")
    let data = await response.json()

    let allBuddies = data.data
    // Only bring in level 1 buddies and up
    // In the future we can use getState().session.user to see the user's level and restrict it to under that
    // let filteredBuddies = data.data.filter(buddy => buddy.attributes.field_level)

    return allBuddies
  }
)

export const learningBuddiesSlice = createSlice({
  name: "learningBuddiesSlice",
  initialState: {
    data: [],
    fetched: false,
  },
  extraReducers: {
    [getBuddies.pending]: (state) => {
      state.fetched = false
    },
    [getBuddies.fulfilled]: (state, action) => {
      state.data = action.payload
      state.fetched = true
    },
  },
})

export default learningBuddiesSlice.reducer
