import pluralize from "pluralize"

function SlideTimeSpent(props) {
  const totalMinutes = props.rewind.timeSpent

  let hours = Math.floor(totalMinutes / 60)
  let minutes = totalMinutes % 60

  return (
    <div className="slide timeSpent">
      <div className="background-tile time-bg" />
      <div className="flexRow">
        <p className="textLarge">
          {hours > 25 ? (
            <>
              Despite what was likely another challenging school year, we're
              thrilled that you spent lots of time learning with us!
            </>
          ) : (
            <>
              Despite what was likely another challenging school year, you still
              managed to spend time learning and growing!
            </>
          )}
        </p>
        <div className="clockWrapper">
          <span className="icon clock"></span>
          <span className="icon hand minute"></span>
          <span className="icon hand hour"></span>
          <div className="time">
            <strong>{hours}</strong>
            {pluralize("Hour", hours)}
            <strong>{minutes}</strong>
            {pluralize("Minute", minutes)}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SlideTimeSpent
