import theme from "@mobilemind/common/src/theme/theme"

function RaisedHand() {
  return (
    <svg width="20px" height="20px" viewBox="0 0 11 15">
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="raised-hand"
          fill={theme.palette.primary.main}
          fillRule="nonzero"
        >
          <path
            d="M8.17332106,7.20723198 L7.33531776,8.04523529 C7.25610351,8.1244777 7.14860652,8.16901458 7.03654597,8.16901458 C5.52015099,8.16901458 4.29089355,9.39514514 4.29089355,10.9142163 C4.29089355,11.1475772 4.10170335,11.3369927 3.86834247,11.3369927 C3.63498159,11.3369927 3.44579139,11.1480279 3.44579139,10.914667 C3.44579139,8.96656566 4.98103215,7.41974701 6.85718712,7.32827878 L7.69234525,6.49312065 L7.69234525,2.08284233 C7.66062575,1.22847222 6.43449519,1.22912013 6.40308556,2.08284233 L6.40308556,5.87968916 C6.38229605,6.43973837 5.57857572,6.43931582 5.5579834,5.87968916 L5.5579834,0.640534644 C5.52629207,-0.213835473 4.30013334,-0.213187561 4.26872371,0.640534644 L4.26872371,5.87968916 C4.24793419,6.43973837 3.44421387,6.43931582 3.42362154,5.87968916 L3.42362154,1.60209188 C3.39193021,0.747721769 2.16577148,0.74836968 2.13436185,1.60209188 L2.13436185,5.87971733 C2.11357234,6.43976654 1.30985201,6.43934399 1.28925969,5.87971733 L1.28925969,4.48670727 C1.25756836,3.63233715 0.031409631,3.63298506 0,4.48670727 L0,9.99536487 C0,10.9916277 0.339054991,11.9582555 0.961444561,12.7362002 C0.961529071,12.7362566 0.961529071,12.7363129 0.961529071,12.7363129 L0.961529071,13.9964447 C0.961529071,14.2298056 1.15071928,14.4189958 1.38408015,14.4189958 L3.84614446,14.4189958 L5.82743014,14.4189958 C6.06079101,14.4189958 6.24998122,14.2298056 6.24998122,13.9964447 L6.24998122,12.4959095 C7.31207745,12.4959095 8.17306753,11.6349194 8.17306753,10.5728232 L10.5769888,8.1689019 C11.4528527,7.1229753 9.22764238,6.07414717 8.17332106,7.20723198 Z"
            id="Path"
          ></path>
        </g>
      </g>
    </svg>
  )
}

export default RaisedHand
