import { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
} from "@mui/material"

import ButtonSmall from "../../components/ButtonSmall"

import { isMobile } from "react-device-detect"
import classNames from "classnames"
import { isMMContentHidden } from "@mobilemind/common/src/functions"

import "../../styles/sidebar.scss"
import { updateFilters, resetFilters } from "./learningPathsSlice"
import { updateLearningPathFilters } from "../achievements/achievementsSlice"

const mapStateToProps = ({ session, achievements, learningPaths, sidebar }) => {
  let isAchievements,
    filters = learningPaths.filters
  if (window.location.href.includes("backpack")) {
    filters = achievements.learningPaths.filters
    isAchievements = true
  }

  return {
    isAchievements,
    sidebar,
    session,
    types: learningPaths.types.data,
    isExpanded: sidebar.mobileFiltersExpanded,
    filters,
  }
}

const mapDispatchToProps = {
  updateFilters,
  resetFilters,
  updateLearningPathFilters,
}

class LearningPathFilters extends Component {
  updateFilters = (event, field) => {
    if (this.props.isAchievements) {
      let filters = { ...this.props.filters }
      filters[field] = event.target.value
      this.props.updateLearningPathFilters(filters)
    } else {
      this.props.updateFilters({ field, value: event.target.value })
    }
  }

  resetFilters = () => {
    this.props.resetFilters()
  }

  render() {
    const { session, filters } = this.props
    let collectionLabel =
      session.collection && session.collection.label
        ? session.collection.label[0].value
        : "MobileMind"

    let orgId =
      session.group && session.group.uuid && session.group.uuid[0].value

    return (
      <form
        className={classNames(
          "filters learningPaths",
          this.props.sidebar.isCollapsed && "collapsed",
          isMobile && this.props.isExpanded && "expanded"
        )}
      >
        {!window.location.href.includes("backpack") ? (
          <>
            <header style={{ marginBottom: 0 }}>
              <strong>Filter Learning Paths</strong>
            </header>
            <div className="flexRow inputSearch">
              <span className="icon search" />
              <TextField
                variant="outlined"
                label="Search"
                value={filters.search}
                onChange={(event) => {
                  this.updateFilters(event, "search")
                }}
              />
            </div>

            <FormControl className="inputSelect type">
              <span className="icon learningPath" />
              <InputLabel id="label-type-select">Type</InputLabel>
              <Select
                labelId="label-type-select"
                id="type-select"
                value={filters.type}
                onChange={(event) => {
                  this.updateFilters(event, "type")
                }}
              >
                <MenuItem key={"any"} value={"any"}>
                  Any
                </MenuItem>
                {this.props.types &&
                  this.props.types.map((type) => {
                    return (
                      <MenuItem
                        key={type.attributes.drupal_internal__tid}
                        value={type.attributes.drupal_internal__tid}
                      >
                        {type.attributes.name}
                      </MenuItem>
                    )
                  })}
              </Select>
            </FormControl>

            <FormControl className="inputSelect status">
              <span className="icon check-black" />
              <InputLabel id="label-status-select">Status</InputLabel>
              <Select
                labelId="label-status-select"
                id="status-select"
                value={filters.status}
                onChange={(event) => {
                  this.updateFilters(event, "status")
                }}
              >
                <MenuItem value={"any"}>Any</MenuItem>
                <MenuItem value={"not_started"}>Not Started</MenuItem>
                <MenuItem value={"in_progress"}>In Progress</MenuItem>
                <MenuItem value={"completed"}>Completed</MenuItem>
              </Select>
            </FormControl>

            {orgId && !isMMContentHidden(session) && (
              <FormControl className="inputSelect source">
                <span className="icon source" />
                <InputLabel id="labelSource">Source</InputLabel>
                <Select
                  labelId="labelSource"
                  id="sourceSelect"
                  value={filters.org}
                  onChange={(event) => {
                    this.updateFilters(event, "org")
                  }}
                >
                  <MenuItem key={"any"} value={"any"}>
                    Any
                  </MenuItem>
                  <MenuItem key={"mobilemind"} value={"mobilemind"}>
                    {collectionLabel}
                  </MenuItem>
                  <MenuItem key={"my_org"} value={"my_org"}>
                    {session.group.label[0].value}
                  </MenuItem>
                </Select>
              </FormControl>
            )}
            <header style={{ marginBottom: 0, marginTop: 20 }}>
              <strong>Sort By</strong>
            </header>

            <FormControl className="sortLearningPaths">
              <Select
                labelId="label-status-select"
                id="status-select"
                value={filters.sort_by}
                onChange={(event) => {
                  this.updateFilters(event, "sort_by")
                }}
              >
                <MenuItem value={"status"}>Status</MenuItem>
                <MenuItem value={"name"}>Name</MenuItem>
                <MenuItem value={"created"}>Date Created</MenuItem>
              </Select>
            </FormControl>
          </>
        ) : (
          <>
            <header>
              <strong>Filter Learning Paths</strong>
            </header>

            <FormControl>
              <div className="flexRow inputSearch">
                <span className="icon search" />
                <TextField
                  variant="outlined"
                  label="Search"
                  value={filters.search}
                  onChange={(event) => {
                    this.updateFilters(event, "search")
                  }}
                />
              </div>
            </FormControl>

            <header style={{ marginBottom: 0, margin: 0 }}>
              {!window.location.href.includes("backpack") ? (
                <>
                  <strong>Filter Learning Paths</strong>
                  <ButtonSmall onClick={this.resetFilters}>Reset</ButtonSmall>
                </>
              ) : (
                <strong>Sort Learning Paths</strong>
              )}
            </header>
            <FormControl>
              <InputLabel style={{ marginLeft: -15 }}>Sort By</InputLabel>
              <Select
                value={filters.sort}
                onChange={(event) => {
                  this.updateFilters(event, "sort")
                }}
              >
                <MenuItem value={"newest"}>
                  Date Completed (Newest First)
                </MenuItem>
                <MenuItem value={"oldest"}>
                  Date Completed (Oldest First)
                </MenuItem>
                <MenuItem value={"name"}>Learning Path Name</MenuItem>
              </Select>
            </FormControl>
          </>
        )}

        <div className="toggle" onClick={this.toggleExpand}>
          <span className="icon" />
        </div>
      </form>
    )
  }
}

LearningPathFilters.propTypes = {
  filters: PropTypes.object,
  isExpanded: PropTypes.bool,
  sidebar: PropTypes.object,
  // All learning path types
  types: PropTypes.array,
}

export default connect(mapStateToProps, mapDispatchToProps)(LearningPathFilters)
