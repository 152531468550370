import classNames from "classnames"
import theme from "@mobilemind/common/src/theme/theme"
import Values from "values.js"
import { useSelector } from "react-redux"
import { RootState } from "../../store/store"

type BuddyProps = {
  message: string
}

function InlineBuddyMessage(props: BuddyProps) {
  const { session } = useSelector((state: RootState) => state)
  const { message } = props
  return (
    <div className="flexRow">
      <span
        className={classNames(
          "icon inline-buddy buddy-image",
          session.user.learningBuddy &&
            session.user.learningBuddy.attributes.name.toLowerCase()
        )}
      />
      <div
        id="inline-buddy-message"
        className="buddy-message inline"
        style={{
          alignItems: "flex-start",
          backgroundColor: new Values(theme.palette.secondary.main)
            .tint(60)
            .hexString(),
        }}
      >
        <svg width="15px" height="30px" viewBox="0 0 15 30">
          <g
            id="Page-1"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <polygon
              id="Rectangle"
              fill={new Values(theme.palette.secondary.main)
                .tint(60)
                .hexString()}
              transform="translate(15.000000, 15.000000) scale(-1, -1) rotate(-45.000000) translate(-15.000000, -15.000000) "
              points="25 5 25 25 5 25"
            ></polygon>
          </g>
        </svg>
        {message}
      </div>
    </div>
  )
}

export default InlineBuddyMessage
