import { Component } from "react"
import { connect } from "react-redux"

import {
  Select,
  MenuItem,
  FormControl,
  TextField,
  InputLabel,
} from "@mui/material"

import { isMobile } from "react-device-detect"
import classNames from "classnames"
import ButtonSmall from "../../components/ButtonSmall"
import "../../styles/sidebar.scss"

import {
  updateExternalFilters,
  getExternalApproved,
  resetCourseFilters,
} from "../achievements/achievementsSlice"

const mapStateToProps = ({ session, achievements, sidebar }) => {
  return {
    session,
    sidebar: sidebar,
    isExpanded: sidebar.mobileFiltersExpanded,
    filters: achievements.approvedRequests.filters,
  }
}

const mapDispatchToProps = {
  getExternalApproved,
  updateExternalFilters,
  resetCourseFilters,
}

class ExternalApprovalsFilters extends Component {
  componentDidMount() {
    this.props.getExternalApproved()
  }

  updateFilters = (event, key) => {
    window.scrollTo(0, 0)

    let filters = { ...this.props.filters }
    filters[key] = event.target.value

    this.props.updateExternalFilters(filters)
  }

  resetFilters = () => {
    window.scrollTo(0, 0)

    this.props.updateExternalFilters(
      {
        search: "",
        topCategory: "any",
        subCategory: "any",
        org: "any",
        sort: "dateNewest",
      },
      "completed"
    )
  }

  render() {
    const { sort, search } = this.props.filters

    return (
      <form
        className={classNames(
          "filters coursesComplete",
          this.props.sidebar.isCollapsed && "collapsed",
          isMobile && this.props.isExpanded && "expanded"
        )}
      >
        <header style={{ marginBottom: 9 }}>
          <strong>Filter Events</strong>
          {(search || sort !== "dateNewest") && (
            <ButtonSmall className="button small" onClick={this.resetFilters}>
              Reset
            </ButtonSmall>
          )}
        </header>
        <FormControl>
          <div className="flexRow inputSearch">
            <span className="icon search" />
            <TextField
              variant="outlined"
              label="Search"
              value={search}
              onChange={(event) => {
                this.updateFilters(event, "search")
              }}
            />
          </div>
        </FormControl>

        <header style={{ marginTop: 0, marginBottom: 0 }}>
          <strong>Sort Events</strong>
        </header>

        <FormControl>
          <InputLabel style={{ marginLeft: -15 }}>Sort By</InputLabel>
          <Select
            value={sort}
            onChange={(event) => {
              this.updateFilters(event, "sort")
            }}
          >
            <MenuItem value={"dateNewest"}>
              Date Attended (Newest First)
            </MenuItem>
            <MenuItem value={"dateOldest"}>
              Date Attended (Oldest First)
            </MenuItem>
            <MenuItem value={"title"}>Event Name</MenuItem>
          </Select>
        </FormControl>
      </form>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExternalApprovalsFilters)
