function SlideDedication(props) {
  const completeMonths = new Array(Number(props.rewind.consecutiveMonths))

  return (
    <div className="slide dedication">
      <div className={"season " + props.rewind.learningSeason}>
        <div className="cloud" />
        <div className="cloud" />
        <div className="cloud" />
        <div className="cloud" />
      </div>

      <div className="part first">
        {!props.rewind.needsEncouragement ? (
          <p className="textLarge">
            We’re blown away by your dedication! You’re amazing!
          </p>
        ) : (
          <p className="textLarge">
            We’re thrilled you chose to learn with us this year!
          </p>
        )}
      </div>

      <div className="secondWrapper">
        <div className="part second">
          {completeMonths.length > 3 && (
            <>
              <p className="textLarge">
                You had a {completeMonths.length}-month streak of completing at
                least one course!
              </p>

              <div className="flexRow" style={{ justifyContent: "center" }}>
                {Array.from(completeMonths.keys()).map((month, index) => {
                  return (
                    <div
                      key={index}
                      style={{ animationDelay: index * 0.2 + 6.1 + "s" }}
                      className="icon cal-complete"
                    />
                  )
                })}
              </div>
            </>
          )}

          {props.rewind.learningSeason && completeMonths.length < 4 && (
            <>
              <p className="textLarge">
                Your preferred learning season was clearly{" "}
                {props.rewind.learningSeason}!
              </p>

              {props.rewind.learningSeason && (
                <div
                  id="fallingObjects"
                  className={props.rewind.learningSeason}
                >
                  <i />
                  <i />
                  <i />
                  <i />
                  <i />
                  <i />
                  <i />
                  <i />
                  <i />
                  <i />
                  <i />
                  <i />
                  <i />
                  <i />
                  <i />
                  <i />
                  <i />
                  <i />
                  <i />
                  <i />
                  <i />
                  <i />
                  <i />
                  <i />
                  <i />
                  <i />
                  <i />
                  <i />
                  <i />
                  <i />
                  <i />
                  <i />
                  <i />
                  <i />
                  <i />
                  <i />
                  <i />
                  <i />
                  <i />
                  <i />
                  <i />
                  <i />
                  <i />
                  <i />
                  <i />
                  <i />
                  <i />
                  <i />
                  <i />
                  <i />
                  <i />
                  <i />
                  <i />
                  <i />
                  <i />
                  <i />
                  <i />
                  <i />
                  <i />
                  <i />
                  <i />
                  <i />
                  <i />
                  <i />
                  <i />
                  <i />
                  <i />
                  <i />
                  <i />
                  <i />
                  <i />
                  <i />
                  <i />
                  <i />
                  <i />
                  <i />
                  <i />
                  <i />
                  <i />
                  <i />
                  <i />
                  <i />
                  <i />
                  <i />
                  <i />
                  <i />
                  <i />
                  <i />
                  <i />
                  <i />
                  <i />
                  <i />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default SlideDedication
