import { Component } from "react"
import { Link } from "react-router-dom"

import moment from "moment"
import classNames from "classnames"
import { Button } from "@mui/material"
import SanitizedHTML from "@mobilemind/common/src/components/SanitizedHTML"
import { addToGoogleCalendar } from "../../../actions/emailIntegration"
import { connect } from "react-redux"
import he from "he"
import theme from "@mobilemind/common/src/theme/theme"
import ButtonSmall from "../../../components/ButtonSmall"
import Values from "values.js"

import {
  toggleRequestModal,
  claimRecommended,
} from "../../../store/reducers/externalPD"

import RaisedHand from "../../../icons/RaisedHand"

const mapStateToProps = ({ calendar }) => {
  return {
    googleEvents: calendar.googleEvents.data,
  }
}

const mapDispatchToProps = {
  addToGoogleCalendar,
  toggleRequestModal,
  claimRecommended,
}

function EventTooltip(props) {
  return <Tooltip {...props} theme={theme} />
}

class Tooltip extends Component {
  render() {
    const { appointmentData, theme } = this.props
    const {
      conferenceUserEvent,
      isAllDay,
      isConference,
      isObservation,
      conference,
      startDate,
      rsvp,
      endDate,
      image,
      title,
      description,
      location,
      drupal_internal__id,
      isGoal,
      isExternal,
      lp_name,
    } = appointmentData

    let isSingleDayEvent =
      moment(startDate).format("DD") === moment(endDate).format("DD") ||
      moment(startDate).format("DD") ===
        moment(endDate).subtract(1, "hour").format("DD")

    let isInGoogle =
      isGoal &&
      lp_name &&
      this.props.googleEvents.find((event) => {
        return event.summary === "Goal Deadline: " + lp_name
      })

    let backgroundColor,
      primary = new Values(theme.palette.primary.main)

    if (isConference) {
      backgroundColor = primary.shade(15).hexString()
    } else if (appointmentData.isSession) {
      backgroundColor = appointmentData.color
    } else if (appointmentData.isExternal) {
      const secondary = new Values(theme.palette.secondary.main)
      backgroundColor = secondary.shade(35).hexString()
    } else if (!isGoal) {
      backgroundColor = primary.tint(50).hexString()
    } else {
      backgroundColor = theme.palette.secondary.main
    }

    let startTime = moment(startDate).format("h:mma")
    let endTime = moment(endDate).format("h:mma")

    let viewURL = "/events/event/" + drupal_internal__id
    if (isConference) {
      viewURL = "/events/conference/" + drupal_internal__id
    }
    if (isObservation) {
      viewURL = "/events/observation/" + drupal_internal__id
    }

    return (
      <div
        className="eventTooltip"
        style={{ padding: image ? "190px 15px 20px" : "20px 15px 20px" }}
      >
        {image && image.attributes && (
          <div
            className="eventImage"
            style={{
              backgroundImage:
                "url(" +
                process.env.REACT_APP_API_URL +
                image.attributes.uri.url +
                ")",
            }}
          />
        )}
        {image && (
          <div
            className="eventImage"
            style={{
              backgroundImage:
                "url(" + process.env.REACT_APP_API_URL + image + ")",
            }}
          />
        )}

        <header style={{ paddingTop: isConference && !image ? 30 : 0 }}>
          {!isGoal ? (
            <span className={classNames("chip")} style={{ backgroundColor }} />
          ) : (
            <span className="icon goal" />
          )}
          {title}
          {isConference && (
            <div className="banner" style={{ backgroundColor }}>
              Conference
            </div>
          )}
        </header>

        <div className="flexRow meta">
          <span className="icon schedule" />
          {(!isAllDay || isAllDay === "False") && (
            <>
              {!isGoal ? (
                <>
                  {isSingleDayEvent ? (
                    <>
                      {moment(startDate).format("dddd, MMMM Do")}
                      <br />
                      {moment(startDate).format("h:mma") !==
                        moment(endDate).format("h:mma") && (
                        <>
                          {startTime} - {endTime}
                        </>
                      )}
                    </>
                  ) : (
                    <div
                      className="flexRow"
                      style={{
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <div style={{ marginBottom: 5 }}>
                        <span className="timeline" />
                        {moment(startDate).format("dddd, MMMM Do")}, {startTime}
                        <br />
                      </div>
                      <div>
                        <span />
                        {moment(endDate).format("dddd, MMMM Do")}, {endTime}
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <>Due {moment(startDate).format("dddd, MMMM Do")}</>
              )}
            </>
          )}
          {isAllDay && isAllDay !== "False" && (
            <>
              {isGoal ? (
                <>Due {moment(startDate).format("dddd, MMMM Do")}</>
              ) : (
                <>{moment(startDate).format("dddd, MMMM Do")}</>
              )}
            </>
          )}
        </div>

        {location && location.length > 0 && (
          <>
            {location.map((loc, index) => {
              return (
                <div key={index} className="flexRow meta">
                  <span className="icon location" />
                  <div>
                    <strong>{loc.name}</strong>
                    <br />
                    {loc.address_line1}
                    {loc.address_line2 && (
                      <>
                        <br />
                        <>{loc.address_line2}</>
                      </>
                    )}
                    <br />
                    {loc.city}, {loc.state} {loc.zip}
                  </div>
                </div>
              )
            })}
          </>
        )}

        {description && (
          <div className="description">
            {description && <SanitizedHTML html={he.decode(description)} />}
          </div>
        )}

        <footer style={isGoal ? { justifyContent: "space-between" } : {}}>
          {rsvp &&
            (!conference ||
              conferenceUserEvent.attributes.field_rsvp === "accept" ||
              conferenceUserEvent.attributes.field_rsvp === "tentative") && (
              <span className="rsvp">
                {rsvp === "Accept" && (
                  <>
                    Attending <span className="icon rsvp" />
                  </>
                )}
                {rsvp === "Decline" && (
                  <>
                    Declined <span className="icon rsvp-decline" />
                  </>
                )}
                {rsvp === "Tentative" && (
                  <>
                    Tentative <span className="icon rsvp-tentative" />
                  </>
                )}
              </span>
            )}

          {!isGoal && !isExternal && (
            <>
              {conference ? (
                <>
                  {conferenceUserEvent &&
                  (conferenceUserEvent.attributes.field_rsvp === "accept" ||
                    conferenceUserEvent.attributes.field_rsvp ===
                      "tentative") ? (
                    <Link
                      to={
                        "/events/event/" +
                        drupal_internal__id +
                        "/" +
                        conference.drupal_internal__id
                      }
                    >
                      <ButtonSmall>View Details</ButtonSmall>
                    </Link>
                  ) : (
                    <strong
                      style={{ fontSize: 12, display: "block", width: "100%" }}
                    >
                      RSVP to this conference to view this session.
                    </strong>
                  )}
                </>
              ) : (
                <Link to={viewURL}>
                  <ButtonSmall>View Details</ButtonSmall>
                </Link>
              )}
            </>
          )}
          {isGoal && (
            <>
              {!isInGoogle ? (
                <Button
                  onClick={() => {
                    this.props.addToGoogleCalendar(appointmentData)
                  }}
                  className="button small"
                >
                  Add to Google
                </Button>
              ) : (
                <span style={{ display: "flex" }}>
                  <span
                    style={{ width: 20, height: 20, marginRight: 5 }}
                    className="icon google-symbol"
                  />
                  <span style={{ fontWeight: "bold", fontSize: 12 }}>
                    Added to Google
                  </span>
                </span>
              )}

              <Link to={"/learning-path/" + drupal_internal__id}>
                <Button className={"button small"}>View Learning Path</Button>
              </Link>
            </>
          )}

          {isExternal && (
            <>
              {appointmentData.ext_usr_event_status ? (
                <>
                  {appointmentData.ext_usr_event_status === "declined" && (
                    <span
                      style={{ width: 20, height: 20, marginRight: 5 }}
                      className="icon external-declined"
                    />
                  )}
                  {appointmentData.ext_usr_event_status === "accepted" && (
                    <span
                      style={{ width: 20, height: 20, marginRight: 5 }}
                      className="icon external-accepted"
                    />
                  )}
                  {appointmentData.ext_usr_event_status === "pending" && (
                    <span
                      style={{ width: 20, height: 20, marginRight: 5 }}
                      className="icon external-pending"
                    />
                  )}
                  <span
                    style={{ fontWeight: "bold", textTransform: "capitalize" }}
                  >
                    Request {appointmentData.ext_usr_event_status}
                  </span>
                </>
              ) : (
                <Button
                  onClick={() => {
                    this.props.toggleRequestModal(true)
                    this.props.claimRecommended(appointmentData.fullEvent)
                  }}
                  className={"button small"}
                >
                  <div style={{ marginRight: 5, position: "relative", top: 3 }}>
                    <RaisedHand />
                  </div>
                  Request Credit
                </Button>
              )}
            </>
          )}
        </footer>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EventTooltip)
