import moment from "moment"
import { addressToString } from "@mobilemind/common/src/functions"
import { updateCalendarIntegrationSettings } from "./index"
import fetchWrapper from "@mobilemind/common/src/functions/fetchWrapper"
const { REACT_APP_GOOGLE_CLIENT_ID, REACT_APP_GOOGLE_API_KEY } = process.env

// Array of API discovery doc URLs for APIs used by the quickstart
const discoveryDocs = [
  "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
]

// Authorization scopes required by the API; multiple scopes can be included, separated by spaces.
const scope = "https://www.googleapis.com/auth/calendar"

export function generateExternalCalendarDescription(activeEvent) {
  let description,
    completeDescription = ""

  description =
    activeEvent.description &&
    activeEvent.description.replace(/(<([^>]+)>)/gi, "")

  if (activeEvent.location && activeEvent.location.attributes) {
    description +=
      "<p><strong>Location</strong><br/>" + activeEvent.location.attributes.name
    if (activeEvent.eventRoom) {
      description += activeEvent.eventRoom.attributes
        ? "<br/>" +
          activeEvent.eventRoom.attributes.field_room_number_name +
          "</p>"
        : "</p>"
    }
  }
  if (activeEvent.meetingLink) {
    description +=
      "<p><strong>Meeting Link</strong><br/>" + activeEvent.meetingLink + "</p>"
  }

  description +=
    '<p><em>For more information, <a href="' +
    process.env.REACT_APP_TEACHER_URL +
    "/events/event/" +
    activeEvent.drupal_internal__id +
    '"><strong>visit the event page on MobileMind.</strong></a></em></p>'
  completeDescription = "<strong>Description</strong><br/>" + description

  return completeDescription
}

export function addToGoogleCalendar(activeEvent, userEvent) {
  return async (dispatch, getState) => {
    const token = getState().session.token
    // Initialize all the things
    window.gapi.load("client:auth2", () => {
      window.gapi.client
        .init({
          apiKey: REACT_APP_GOOGLE_API_KEY,
          clientId: REACT_APP_GOOGLE_CLIENT_ID,
          discoveryDocs,
          scope,
        })
        .then((res) => {
          let completeDescription = ""
          let summary

          // If it's not a goal
          if (!activeEvent.isGoal) {
            summary = activeEvent.name
            completeDescription =
              generateExternalCalendarDescription(activeEvent)
          } else {
            summary = "Goal Deadline: " + activeEvent.lp_name
          }

          // Bring in the calendar
          window.gapi.client.load("calendar", "v3", () => {
            window.gapi.auth2.getAuthInstance().then(() => {
              let start = !activeEvent.allDay
                ? {
                    dateTime: activeEvent.startDate,
                    timeZone: "America/New_York",
                  }
                : {
                    date: moment(activeEvent.startDate).format("YYYY-MM-DD"),
                    timeZone: "America/New_York",
                  }

              let end = !activeEvent.allDay
                ? {
                    dateTime: activeEvent.endDate,
                    timeZone: "America/New_York",
                  }
                : {
                    date: moment(activeEvent.endDate).format("YYYY-MM-DD"),
                    timeZone: "America/New_York",
                  }

              // Build out the event object
              let eventBody = {
                summary: summary,
                description: completeDescription,
                start,
                end,
              }

              if (activeEvent.location) {
                eventBody.location = addressToString(
                  activeEvent.location.attributes.field_address
                )
              }

              let request = window.gapi.client.calendar.events.insert({
                calendarId: "primary",
                resource: eventBody,
              })

              function updateStore(event) {
                dispatch({
                  type: "activeEventSlice/addedToGoogle",
                  payload: true,
                })
                dispatch({
                  type: "calendarSlice/addedToGoogle",
                  payload: event,
                })
              }

              let googleEventId
              // Execute the request and get the created event back
              request.execute((event) => {
                googleEventId = event.id
                if (!event.code) {
                  updateStore(event)
                } else {
                  window.gapi.auth2
                    .getAuthInstance()
                    .signIn()
                    .then(() => {
                      request.execute((event) => {
                        googleEventId = event.id
                        if (!event.code) {
                          updateStore(event)
                        }
                      })
                    })
                }

                const bundle =
                  activeEvent.bundle === "event_base"
                    ? "user_event_base"
                    : activeEvent.bundle
                const userEventPatch = {
                  data: {
                    type: "mobile_mind_user_event--" + bundle,
                    id: userEvent.id,
                    attributes: {
                      field_google_calendar_event_id: googleEventId,
                    },
                  },
                }

                fetchWrapper.patch(
                  "/api/mobile_mind_user_event/" + bundle + "/" + userEvent.id,
                  token,
                  JSON.stringify(userEventPatch)
                )
              })
            })
          })
        })
    })
  }
}

export function initGoogleAuth() {
  return async (dispatch, getState) => {
    const { session } = getState()
    // Initialize all the things
    window.gapi &&
      window.gapi.load("client:auth2", () => {
        window.gapi.client
          .init({
            apiKey: REACT_APP_GOOGLE_API_KEY,
            clientId: REACT_APP_GOOGLE_CLIENT_ID,
            discoveryDocs,
            scope,
          })
          .then(() => {
            window.gapi.auth2
              .getAuthInstance()
              .signIn()
              .then((response) => {
                // Let the modal know we're good to go
                if (response) {
                  dispatch({
                    type: "calendarSlice/setIntegrationAuthSuccess",
                    payload: true,
                  })
                }

                const integrationSettings =
                  session.user.attributes.field_calendar_integration ?? []

                const settingsArray = [...integrationSettings, "google"]
                dispatch(updateCalendarIntegrationSettings({ settingsArray }))

                return response
              })
          })
      })
  }
}
