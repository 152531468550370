import { Button, Card } from "@mui/material"
import { useHistory } from "react-router-dom"
import { CircularProgressbarWithChildren } from "react-circular-progressbar"
import "react-circular-progressbar/dist/styles.css"
import moment from "moment"

import { getMandatedTraining } from "../mandatedTraining/mandatedTrainingSlice"
import { createUserRCS } from "@mobilemind/common/src/actions/mandatedTraining"

import { useDispatch } from "react-redux"
import theme from "@mobilemind/common/src/theme/theme"

function WidgetMandatedTraining({ mandatedTraining }) {
  const dispatch = useDispatch()
  const history = useHistory()
  return (
    <Card className="widget mandated-training">
      <div className="widgetLabel">Mandated Training</div>
      <div className="widgetIcon icon mandated-training" />

      <ul>
        {mandatedTraining.data.map((series) => {
          const hasOpened = moment().isAfter(moment(series.open_date))

          const coursesRemaining = series.rcs_courses.filter(
            (course) => !course.in_compliance
          ).length

          return (
            <li key={series.rcs_id}>
              <Button
                style={{ backgroundColor: "white", textTransform: "none" }}
                onClick={() => {
                  if (!series.urcs_data.urcs_id && hasOpened) {
                    dispatch(createUserRCS({ series })).then(() => {
                      dispatch(getMandatedTraining()).then(() => {})
                    })
                  } else {
                    history.push("/mandated-training/" + series.rcs_id)
                  }
                }}
              >
                <div style={{ flex: 1, marginRight: 10 }}>
                  <strong style={{ display: "block", marginBottom: -5 }}>
                    {series.name}
                  </strong>
                  <span
                    style={{ fontSize: 12, fontWeight: "bold", opacity: 0.5 }}
                  >
                    {!hasOpened && (
                      <>Opens {moment(series.open_date).fromNow()}</>
                    )}
                    {hasOpened && series.percentComplete < 100 && (
                      <>
                        Complete by{" "}
                        {moment(series.deadline_date).format("MMMM Do")}
                      </>
                    )}
                    {series.percentComplete === 100 && hasOpened && (
                      <span style={{ color: theme.palette.success.main }}>
                        Completed
                      </span>
                    )}
                  </span>
                </div>

                {hasOpened && (
                  <div>
                    {series.urcs_data.urcs_id ? (
                      <div className="progress-circle">
                        <div className="popover">
                          {coursesRemaining} Courses Remaining
                        </div>
                        {series.percentComplete !== 100 && (
                          <CircularProgressbarWithChildren
                            strokeWidth={12}
                            value={series.percentComplete}
                          >
                            <span className="progress-text">
                              {series.percentComplete}
                              <span>%</span>
                            </span>
                          </CircularProgressbarWithChildren>
                        )}
                      </div>
                    ) : (
                      <div
                        style={{
                          backgroundColor: theme.palette.secondary.main,
                          textAlign: "center",
                          fontSize: 10,
                          borderRadius: 45,
                          fontWeight: "bold",
                          width: 45,
                          height: 45,
                          textTransform: "uppercase",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          lineHeight: "10px",
                          paddingTop: 7,
                          padding: 5,
                        }}
                      >
                        Start
                      </div>
                    )}
                  </div>
                )}
              </Button>
            </li>
          )
        })}
      </ul>
    </Card>
  )
}

export default WidgetMandatedTraining
