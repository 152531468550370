import { Component } from "react"
import { connect, useDispatch } from "react-redux"
import CourseCard from "../../components/CourseCard"
import CourseListItem from "../../components/CourseListItem"
import ViewToggle from "../../components/ViewToggle"
import { Grid } from "@mui/material"
import { isMobileOnly, isMobile } from "react-device-detect"
import classNames from "classnames"
import ExploreFilters from "./ExploreFilters"
import Loading from "@mobilemind/common/src/components/Loading"
import BottomScrollListener from "react-bottom-scroll-listener"
import {
  getExploreContent,
  increaseCurrentPage,
  updateExploreFilters,
} from "./exploreSlice"

import "../../styles/cards.scss"
import pluralize from "pluralize"
import LearningBuddyMessage from "@mobilemind/common/src/components/LearningBuddyMessage"

const mapStateToProps = ({
  session,
  viewStyle,
  learningPaths,
  categories,
  sidebar,
  explore,
}) => {
  return {
    session,
    categories,
    isExpanded: sidebar.isExpanded,
    learningPaths,
    explore,
    viewStyle,
  }
}

const mapDispatchToProps = {
  getExploreContent,
  updateExploreFilters,
  increaseCurrentPage,
}

class ExploreLayout extends Component {
  async componentDidMount() {
    await this.props.getExploreContent()
  }

  render() {
    const props = this.props

    return <Explore {...props} />
  }
}

function Explore(props) {
  const { courses, total, filters, isFetching, hasFetched, currentPage } =
    props.explore
  const { viewStyle, categories, explore, learningPaths, theme } = props

  const activeLearningPath =
    filters.learningPath &&
    learningPaths.data.find(
      (path) => Number(path.field_lp_id) === Number(filters.learningPath)
    )

  const dispatch = useDispatch()

  function handleScroll() {
    // If we aren't already fetching more results
    if (!explore.isFetching) {
      // And if there are more pages to fetch
      if (currentPage < explore.totalPages - 1) {
        dispatch(updateExploreFilters("increaseCurrentPage"))
      }
    }
  }

  const content = (
    <>
      {courses &&
        courses.map((course, index) => {
          let categoryId = Number(course.field_category_target_id)
          let category = categories.data.find(
            (cat) => cat.attributes.drupal_internal__tid === categoryId
          )

          let categoryMatch = true
          let searchQueryMatch = true

          // If we have a learning path selected we'll filter on the front end
          if (filters.learningPath !== "any") {
            if (filters.topCategory !== "any") {
              // Include if the course matches the top category, or its category's parent is the top category
              categoryMatch =
                filters.topCategory === categoryId ||
                category.relationships.parent.data[0].id === filters.topCategory
            }
            if (filters.subCategory !== "any") {
              // Include if the course matches the selected sub category
              categoryMatch = filters.subCategory === categoryId
            }

            // Search query
            if (filters.searchQuery.length) {
              searchQueryMatch = course.name
                .toLowerCase()
                .includes(filters.searchQuery.toLowerCase())
            }
          }

          const delayModifier = index - 50 * currentPage
          const isNotExcluded = true

          if (isNotExcluded && categoryMatch && searchQueryMatch) {
            if (viewStyle === "list") {
              return (
                <CourseListItem
                  key={index}
                  animationDelay={0.035 * delayModifier + "s"}
                  course={course}
                  assessmentId={course.field_user_assessment_uuid}
                  category={category}
                  status={course.field_user_assessment_status}
                  currentPage="explore"
                />
              )
            }

            return (
              <CourseCard
                key={index}
                animationDelay={0.035 * delayModifier + "s"}
                course={course}
                assessmentId={course.field_user_assessment_uuid}
                category={category}
                status={course.field_user_assessment_status}
                currentPage="explore"
              />
            )
          }
          return null
        })}
    </>
  )

  return (
    <div
      className={classNames(
        "exploreLayout",
        hasFetched && "hasFetched",
        !isFetching && "isFetching"
      )}
    >
      <BottomScrollListener onBottom={handleScroll}>
        <ViewToggle />

        <div className={classNames("itemCount", isMobile && "mobile")}>
          {
            <>
              <span style={{ color: theme.palette.primary.main }}>
                {Number(total) ? Number(total) : 0}
              </span>
              Micro-{pluralize("Course", Number(total))}
            </>
          }
        </div>
        {isMobileOnly && (
          <div className="mobileFilters">
            <ExploreFilters />
          </div>
        )}

        {activeLearningPath && (
          <div className="learningPathInfo">
            <h2>
              <span className="icon learningPath"></span>
              {activeLearningPath.name}
            </h2>
            <p>{activeLearningPath.field_description_value}</p>
          </div>
        )}
        {courses && !courses.length && !isFetching && (
          <div className="bounceIn" style={{ marginTop: 60 }}>
            <LearningBuddyMessage
              message={<>Hmm, it looks like no courses match that criteria.</>}
            />
          </div>
        )}

        {viewStyle === "grid" ? (
          <div
            className={classNames(
              "course-library course-grid",
              isMobileOnly && "mobile-list",
              isMobileOnly && props.isExpanded && "isExpanded"
            )}
          >
            <Grid container spacing={3}>
              {content}
            </Grid>
          </div>
        ) : (
          <ul
            className={classNames(
              "course-library course-list",
              isMobileOnly && "mobile-list",
              isMobileOnly && props.isExpanded && "isExpanded"
            )}
          >
            {content}
          </ul>
        )}
        {isFetching && (
          <Loading
            color={theme.palette.secondary.main}
            fullPage={hasFetched ? true : false}
            message={"Getting courses..."}
          />
        )}
      </BottomScrollListener>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ExploreLayout)
