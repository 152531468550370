import LearningBuddyMessage from "@mobilemind/common/src/components/LearningBuddyMessage"

export const integrationMessages = {
  outlook: (
    <>
      <LearningBuddyMessage
        message={
          <>
            <strong>Okay, all set!</strong> I'll no longer automatically add
            events to your Outlook Calendar. If you'd like to remove
            MobileMind's access to your Outlook account, you'll need to revoke
            access in your Microsoft Account.
          </>
        }
      />
    </>
  ),
  google: (
    <>
      <LearningBuddyMessage
        message={
          <>
            <strong>Okay, all set!</strong> I'll no longer automatically add
            events to your Google Calendar.
            <br />
            <br />
            If you'd like to remove MobileMind's access to your Google account,
            you'll need to
            <strong>
              <a
                href="https://myaccount.google.com/permissions?continue=https%3A%2F%2Fmyaccount.google.com%2Fsecurity"
                rel="noreferrer"
                target="_blank"
              >
                {" "}
                review your Third-Party App settings
              </a>
            </strong>{" "}
            and manually remove <strong>Google Calendar access</strong> from
            MobileMind.
          </>
        }
      />
    </>
  ),
}
