// @ts-nocheck
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../store/store"
import Loading from "@mobilemind/common/src/components/Loading"
import { Button } from "@mui/material"
import BackArrow from "../../icons/BackArrow"

import ApprovedCard from "./ApprovedCard"
import ExternalApprovalsFilters from "./ExternalApprovalsFilters"
import { increaseXPDPage } from "../achievements/achievementsSlice"
import theme from "@mobilemind/common/src/theme/theme"
import BottomScrollListener from "react-bottom-scroll-listener"

const ExternalApprovalsLayout = (props: any) => {
  const { approvedRequests } = useSelector(
    (state: RootState) => state.achievements
  )
  const approvedList = approvedRequests.data
  const dispatch = useDispatch()

  const [activeItem, setActiveItem] = useState("")

  function goBack() {
    props.history.push("/backpack")
  }

  function handleScroll() {
    const { currentPage, totalPages } = approvedRequests
    // If we aren't already fetching more results
    if (!approvedRequests.isFetching) {
      // And if there are more pages to fetch
      if (currentPage < totalPages - 1) {
        dispatch(increaseXPDPage())
      }
    }
  }

  return (
    <div className="page events-layout recommended-events">
      <div id="backpack-sidebar">
        <Button className="backButton" onClick={goBack}>
          <BackArrow />
          <span
            style={{ marginLeft: 10, color: theme.palette.primary.main }}
            className="buttonText"
          >
            Back
          </span>
        </Button>
        <ExternalApprovalsFilters />
      </div>

      <h2>
        {!approvedRequests.fetched && (
          <Loading message={"Getting approved requests..."} />
        )}
      </h2>

      <BottomScrollListener onBottom={handleScroll}>
        <div className="pending-list" style={{ paddingBottom: 75 }}>
          <ul>
            {approvedList.map((event: any, index: number) => {
              return (
                <ApprovedCard
                  key={index}
                  activeItem={activeItem}
                  onClick={() => {
                    event.id === activeItem
                      ? setActiveItem("")
                      : setActiveItem(event.id)
                  }}
                  animationDelay={index}
                  event={event}
                />
              )
            })}
            {approvedRequests.fetched && approvedList.length < 1 && (
              <em>You don't have any approved requests.</em>
            )}
          </ul>
          {approvedRequests.isFetchingMore && (
            <div
              style={{
                marginTop: 15,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Loading message="Getting more external approvals..." />
            </div>
          )}
        </div>
      </BottomScrollListener>
    </div>
  )
}

export default ExternalApprovalsLayout
