import { Component } from "react"
import { Link } from "react-router-dom"
import { generateTimestamp } from "@mobilemind/common/src/functions/index"
import Rating from "@mobilemind/common/src/components/Rating"
import PropTypes from "prop-types"

class CourseListItemCompleted extends Component {
  render() {
    let { course } = this.props

    let categoryLabel =
      course.field_category_name && course.field_category_name.toLowerCase()
    let level = course.field_level_value.toLowerCase()
    let updated = generateTimestamp(course.field_user_assessment_completed_date)

    return (
      <li
        style={{ animationDelay: this.props.animationDelay }}
        className="course-list-item completed"
      >
        <Link to={"course/" + course.id}>
          <div className="category-icon-wrapper">
            <span
              className="category-icon"
              style={{
                backgroundImage: "url(" + course.field_category_image_uri + ")",
              }}
            />
            <span className="icon check" />
          </div>

          <div className="details">
            <div className="course-label">
              <span className="category-label">{categoryLabel}</span>
              <div className="course-level">
                <Rating level={level} />
              </div>
            </div>
            <h2>{course.name}</h2>
            <footer className="timestamp in-progress">
              Completed {updated}
            </footer>
            {course.field_user_assessment_comment && (
              <div className="feedback">
                <em>"{course.field_user_assessment_comment}"</em>
              </div>
            )}
          </div>
        </Link>
      </li>
    )
  }
}

CourseListItemCompleted.propTypes = {
  animationDelay: PropTypes.string,
  assessment: PropTypes.object,
  category: PropTypes.object,
  course: PropTypes.object,
  currentPage: PropTypes.string,
}

export default CourseListItemCompleted
