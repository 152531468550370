import theme from "@mobilemind/common/src/theme/theme"

function Inbox() {
  return (
    <svg width="21px" height="21px" viewBox="0 0 21 21">
      <title>Group</title>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Group"
          transform="translate(0.192913, -0.003937)"
          fill={theme.palette.primary.main}
          fillRule="nonzero"
        >
          <polygon
            id="Shape"
            points="8.34110729 4.85152386 8.34110729 11.7378513 11.6407362 11.7378513 11.6407362 4.85152386 13.5401544 4.85152386 9.99092174 0 6.44168913 4.85152386"
          ></polygon>
          <g id="Shape" transform="translate(0.000000, 13.166003)">
            <path
              d="M15.10657,0.00893256136 L15.10657,3.41918129 L4.87527346,3.41918129 L4.87527346,0.00893256136 L0.838560075,0.00893256136 C0.375850669,0.00893256136 0.000762019454,0.384021211 0.000762019454,0.846730616 L0.000762019454,6.57347614 C0.000762019454,6.99940748 0.34720129,7.34502085 0.774931449,7.34502085 L19.2069544,7.34502085 C19.6346845,7.34502085 19.9811238,6.99940748 19.9811238,6.57347614 L19.9811238,0.846730616 C19.9811238,0.384021211 19.6060351,0.00893256136 19.1433257,0.00893256136 L15.10657,0.00893256136 Z"
              id="Path"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default Inbox
