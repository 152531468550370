import { isMobile } from "react-device-detect"

// We can EVENTUALLY move all this to reducer slices in ./reducers
const state = {
  navbar: {
    activeItem: "learn",
  },
  extension: {},
  sidebar: {
    isCollapsed: isMobile,
    pages: {
      learn: {
        activeItem: "dashboard",
        items: [
          {
            title: "Dashboard",
            id: "dashboard",
          },
          {
            title: "Learning Paths",
            id: "learningPaths",
            filters: {
              type: "any",
              status: "any",
              source: "any",
            },
          },
          {
            title: "Earn Badges",
            id: "badges",
            filters: {
              searchQuery: "",
              topCategory: "any",
              subCategory: "any",
              source: "any",
              remainingOnly: true,
            },
          },
          {
            title: "Explore",
            id: "explore",
            filters: {
              searchQuery: "",
              topCategory: "any",
              subCategory: "any",
              learningPath: "any",
              source: "any",
              remainingOnly: true,
            },
          },
        ],
      },
      inProgress: {
        activeItem: "in_progress",
        items: [
          {
            title: "Saved for Later",
            id: "saved_for_later",
          },
          {
            title: "Started",
            id: "in_progress",
          },
          {
            title: "Feedback",
            id: "feedback",
          },
          {
            title: "Submitted",
            id: "submitted",
          },
        ],
      },
      achievements: {
        activeItem: "completed",
        items: [
          {
            title: "Courses Completed",
            id: "completed",
            filters: {
              searchQuery: "",
              topCategory: "any",
              subCategory: "any",
              source: "any",
              sort: "dateNewest",
            },
          },
          {
            title: "Badges Earned",
            id: "badgesEarned",
            filters: {
              sort: "dateNewest",
            },
          },
          {
            title: "Learning Paths",
            id: "learningPathsCompleted",
            filters: {
              sort: "dateNewest",
            },
          },
          {
            title: "Events Attended",
            id: "eventsAttended",
            filters: {
              sort: "dateNewest",
            },
          },
        ],
      },
    },
  },
  courses: {
    categories: {},
    courseData: [],
  },
  viewStyle: isMobile ? "list" : "grid",
}

export default state
