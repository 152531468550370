import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../store/store"
import qs from "qs"

import {
  fetchBackpack,
  fetchEventsAttended,
  fetchExternalApprovals,
  fetchCompletedCourses,
  fetchEarnedBadges,
  fetchMandatedTrainingHistory,
  fetchCompletedLPs,
} from "@mobilemind/common/src/actions/backpack"

import Loading from "@mobilemind/common/src/components/Loading"
import {
  startOfWeek,
  startOfMonth,
  endOfMonth,
  endOfWeek,
  startOfYear,
  endOfYear,
  format,
} from "date-fns"

import UserInitial from "@mobilemind/common/src/components/UserInitial"

import "../../styles/backpack-print.scss"
import "../../styles/backpack.scss"

import TimeCapsule from "@mobilemind/common/src/components/TimeCapsule"

import ContentRow from "@mobilemind/common/src/components/backpack/ContentRow"
import ButtonLarge from "@mobilemind/common/src/components/ButtonLarge"
import { useLocation } from "react-router-dom"
import { fetchUserRubrics } from "@mobilemind/common/src/actions/backpack"
import BackpackProps from "@mobilemind/common/src/types/backpack"

const BackpackPrintLayout = (props: any) => {
  const { backpack, session } = useSelector((state: RootState) => state)
  const dispatch = useDispatch()
  const location = useLocation()
  const [hasFetched, setFetched] = useState(false)
  let params = qs.parse(location.search.replace("?", ""))
  useEffect(() => {
    const filters = {
      dateRange: params.dateRange,
      dateMin: params.dateMin,
      dateMax: params.dateMax,
      category: params.category,
      print: true,
    }

    if (!hasFetched) {
      setFetched(true)
      dispatch(fetchCompletedLPs(filters))
      dispatch(fetchExternalApprovals(filters))
      dispatch(fetchEventsAttended(filters))
      dispatch(fetchEarnedBadges(filters))
      dispatch(fetchCompletedCourses(filters))
      dispatch(fetchUserRubrics(filters))
      dispatch(fetchMandatedTrainingHistory(filters))
      dispatch(fetchBackpack(filters))
    }
  }, [hasFetched, params, dispatch])

  const hours =
    backpack.totalTime >= 60
      ? Math.floor(backpack.totalTime / 60).toLocaleString()
      : 0
  const minutes = backpack.totalTime % 60
  let orderedSections: Array<
    | "events"
    | "external"
    | "courses"
    | "badges"
    | "learningPaths"
    | "userRubrics"
    | "mandatedTraining"
  > = [
    "events",
    "external",
    "courses",
    "badges",
    "learningPaths",
    "userRubrics",
    "mandatedTraining",
  ]

  if (!backpack.events.data.length) {
    orderedSections.push(
      orderedSections.splice(orderedSections.indexOf("events"), 1)[0]
    )
  }
  if (!backpack.external.data.length) {
    orderedSections.push(
      orderedSections.splice(orderedSections.indexOf("external"), 1)[0]
    )
  }
  if (!backpack.courses.data.length) {
    orderedSections.push(
      orderedSections.splice(orderedSections.indexOf("courses"), 1)[0]
    )
  }
  if (!backpack.badges.data.length) {
    orderedSections.push(
      orderedSections.splice(orderedSections.indexOf("badges"), 1)[0]
    )
  }
  if (!backpack.learningPaths.data.length) {
    orderedSections.push(
      orderedSections.splice(orderedSections.indexOf("learningPaths"), 1)[0]
    )
  }
  if (!backpack.mandatedTraining.data.length) {
    orderedSections.push(
      orderedSections.splice(orderedSections.indexOf("mandatedTraining"), 1)[0]
    )
  }

  const isReady =
    backpack.fetched &&
    backpack.courses.fetched &&
    backpack.badges.fetched &&
    backpack.learningPaths.fetched &&
    backpack.mandatedTraining.fetched &&
    backpack.events.fetched &&
    backpack.external.fetched

  let dateRange = params.dateRange
  let dateMin = params.dateMin
  let dateMax = params.dateMax

  let dateFromFormat = "MMMM Do"
  if (dateRange !== "all") {
    if (startOfWeek(new Date()) !== endOfWeek(new Date())) {
      dateFromFormat = "MMMM do yyyy"
    }
  }

  return (
    <div className="page backpack-layout print">
      {isReady && (
        <div id="floatingButton">
          <ButtonLarge onClick={() => window.print()}>
            <span className="icon document" />
            Download PDF
          </ButtonLarge>
        </div>
      )}

      {isReady && (
        <header id="report-header">
          <div className="flexRow" style={{ marginBottom: 20 }}>
            <div className="flexRow userContainer">
              <div
                style={{
                  position: "relative",
                  zIndex: 2,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {session.userPicture &&
                !session.userPicture.attributes.uri.url.includes(
                  "default_temp"
                ) ? (
                  <img
                    alt={"Profile"}
                    src={
                      process.env.REACT_APP_API_URL +
                      session.userPicture.attributes.uri.url
                    }
                    className="userPicture"
                  />
                ) : (
                  <UserInitial
                    fontSize={32}
                    size={80}
                    initials={
                      session.user.attributes.field_first_name
                        .charAt(0)
                        .toUpperCase() +
                      session.user.attributes.field_last_name
                        .charAt(0)
                        .toUpperCase()
                    }
                  />
                )}
                <div className="userInfo">
                  <strong>
                    {session.user.attributes.field_first_name}{" "}
                    {session.user.attributes.field_last_name}
                  </strong>
                  <span>{session.user.attributes.field_subgroup}</span>
                </div>
              </div>
            </div>

            <div className="timeInfo">
              <header className="date-header">
                {dateRange === "all" && <>All Time PD Credit</>}
                {dateRange === "week" && (
                  <>
                    {format(startOfWeek(new Date()), dateFromFormat)} -{" "}
                    {format(startOfWeek(new Date()), dateFromFormat)}
                  </>
                )}
                {dateRange === "month" && (
                  <>
                    {format(startOfMonth(new Date()), dateFromFormat)} -{" "}
                    {format(endOfMonth(new Date()), dateFromFormat)}
                  </>
                )}
                {dateRange === "year" && (
                  <>
                    {format(startOfYear(new Date()), dateFromFormat)} -{" "}
                    {format(endOfYear(new Date()), dateFromFormat)}
                  </>
                )}
                {dateRange === "custom" && (
                  <>
                    {dateMin ? (
                      <>
                        {format(
                          new Date(
                            JSON.parse(
                              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                              // @ts-ignore
                              dateMin
                            )
                          ),
                          "MMMM do yyyy"
                        )}
                      </>
                    ) : (
                      <>Beginning of Time</>
                    )}
                    {dateMax && (
                      <>
                        {" "}
                        -{" "}
                        {format(
                          new Date(
                            JSON.parse(
                              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                              // @ts-ignore
                              dateMax
                            )
                          ),
                          "MMMM do yyyy"
                        )}
                      </>
                    )}
                  </>
                )}
              </header>

              <strong className="totalTime">
                {Number(hours) > 0 && <>{hours}h</>} {minutes}m
              </strong>
            </div>
          </div>

          {
            <ul className="topCategories allCategories">
              {backpack.categoryData.map((cat: any) => {
                let categoryHours =
                  cat.total_time >= 60
                    ? Math.floor(cat.total_time / 60).toLocaleString()
                    : 0
                const categoryMinutes = cat.total_time % 60
                if (cat.tid && cat.total_time) {
                  return (
                    <li
                      key={cat.tid}
                      style={{
                        backgroundColor: "white",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <img
                        alt={"category"}
                        className="categoryIcon"
                        src={cat.category_image}
                      />
                      <div style={{ marginLeft: 5, flex: 1 }}>
                        <strong
                          style={{
                            display: "block",
                            marginBottom: -5,
                            position: "relative",
                            top: -2,
                          }}
                        >
                          {cat.category_name}
                        </strong>
                      </div>
                      <TimeCapsule
                        hours={Number(categoryHours)}
                        minutes={Number(categoryMinutes)}
                      />
                    </li>
                  )
                }
                return null
              })}
            </ul>
          }
        </header>
      )}

      {isReady ? (
        <>
          {orderedSections.map((section, index) => {
            let contentBase:
              | BackpackProps["events"]
              | BackpackProps["external"]
              | BackpackProps["courses"]
              | BackpackProps["badges"]
              | BackpackProps["mandatedTraining"]
              | BackpackProps["learningPaths"] = backpack.events
            let shouldReturn = true
            if (section === "external") {
              contentBase = backpack.external
            }
            if (section === "courses") {
              contentBase = backpack.courses
            }
            if (section === "badges") {
              contentBase = backpack.badges
            }
            if (section === "learningPaths") {
              contentBase = backpack.learningPaths
            }

            if (section === "userRubrics") {
              contentBase = backpack.userRubrics
              if (!backpack.userRubrics.data.length) {
                shouldReturn = false
              }
            }

            if (section === "mandatedTraining") {
              contentBase = backpack.mandatedTraining
              if (!backpack.mandatedTraining.data.length) {
                shouldReturn = false
              }
            }
            if (shouldReturn) {
              return (
                <ContentRow
                  {...props}
                  print={true}
                  key={index}
                  animationDelay={index}
                  total={contentBase.totalCompleted}
                  data={contentBase.data}
                  contentType={section}
                />
              )
            }
            return null
          })}
        </>
      ) : (
        <div
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="backpack-buddy">
            <div className="star" />
            <div className="star" />
            <div className="star" />
          </div>
          <Loading message={"Generating your backpack..."} />
        </div>
      )}
    </div>
  )
}

export default BackpackPrintLayout
