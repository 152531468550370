import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import fetchWrapper from "@mobilemind/common/src/functions/fetchWrapper"
import qs from "qs"
import debounceThunk from "@mobilemind/common/src/functions/debounceThunk"

export const getBadges = createAsyncThunk(
  "badgesSlice/getBadges",
  async (args, thunkAPI) => {
    const { search, remaining, org, sort } = args
      ? args
      : thunkAPI.getState().badges.filters
    const { navbar, session } = thunkAPI.getState()

    const url = session.isPartner
      ? "/api/mm_partner_portal/badges_explore?"
      : "/api/badges_entity/explore?"

    let earned,
      archive = false
    if (remaining) {
      earned = "not_earned"
    }

    let query = {
      earned,
      search,
      sort_by: "name",
      archive,
      courses: true,
    }

    if (org) {
      query.org = org
    }

    if (navbar.activeItem === "achievements") {
      query.search = ""
      query.archive = true
      query.org = ""
      if (sort !== "name") {
        query.sort_by = "earned_date"
        query.sort_order = sort === "dateNewest" ? "DESC" : "ASC"
      }
    }

    // If we're going to the next page, set the offset, otherwise clear it out
    if (args) {
      query.page = args.nextPage
        ? { offset: args.nextPage * 25 }
        : { offset: 0 }
    }

    let response = await fetchWrapper.get(url + qs.stringify(query))
    let data = await response.json()

    let badges = []
    data.badges_data.forEach((badge) => {
      if (!badges.find((existingBadge) => existingBadge.id === badge.id)) {
        badges.push(badge)
      }
    })

    return { data: badges, total: data.total_records }
  }
)

const debouncedGetBadges = debounceThunk(getBadges, 750)

export const updateFilters = createAsyncThunk(
  "badgesSlice/updateFilters",
  async (args, thunkAPI) => {
    thunkAPI.dispatch(debouncedGetBadges())
    return args
  }
)

export const resetFilters = createAsyncThunk(
  "badgesSlice/resetFilters",
  async (args, thunkAPI) => {
    const filters = {
      search: "",
      remaining: true,
      org: "any",
    }

    const currentFilters = thunkAPI.getState().badges.filters

    if (
      filters.search !== currentFilters.search ||
      filters.remaining !== currentFilters.remaining ||
      filters.org !== currentFilters.org
    ) {
      thunkAPI.dispatch(getBadges(filters))
      return filters
    }

    return null
  }
)

export const increasePage = createAsyncThunk(
  "badgesSlice/increasePage",
  async (args, thunkAPI) => {
    const { filters, currentPage } = thunkAPI.getState().badges

    const newFilters = { ...filters }
    newFilters.nextPage = currentPage
    thunkAPI.dispatch(debouncedGetBadges(newFilters))
    return true
  }
)

export const badgesSlice = createSlice({
  name: "badgesSlice",
  initialState: {
    data: [],
    fetched: false,
    isFetching: false,
    isFetchingMore: false,
    currentPage: 0,
    filters: {
      search: "",
      remaining: true,
      org: "any",
      sort: "dateNewest",
    },
  },
  reducers: {
    getSingle: (state, action) => {
      state.data.push(action.payload)
    },
  },
  extraReducers: {
    [updateFilters.fulfilled]: (state, action) => {
      state.currentPage = 0
      state.data = []
      state.isFetching = true
      state.fetched = false
      state.filters = action.payload
    },
    [resetFilters.fulfilled]: (state, action) => {
      if (action.payload) {
        state.filters = action.payload
      }
    },
    [increasePage.pending]: (state) => {
      state.isFetchingMore = true
      state.currentPage = state.currentPage + 1
    },
    [getBadges.pending]: (state) => {
      state.isFetching = true
    },
    [getBadges.fulfilled]: (state, action) => {
      const { search, org } = state.filters

      if (search === "" && org === "any") {
        state.total = Number(action.payload.total)
        state.totalPages = Math.ceil(Number(action.payload.total) / 25)
      }
      if (action.payload.data.length) {
        if (!state.currentPage) {
          state.data = action.payload.data
        } else {
          state.data = state.data.concat(action.payload.data)
        }
      }

      state.fetched = true
      state.isFetching = false
      state.isFetchingMore = false
    },
  },
})

export default badgesSlice.reducer
