import { Component } from "react"
import { connect, useDispatch } from "react-redux"
import { Table, TableHead, TableBody, TableRow, TableCell } from "@mui/material"
import moment from "moment"
import classNames from "classnames"
import { changeNavbar } from "../../actions"

import SidebarLeaderboards from "./SidebarLeaderboards"
import Loading from "@mobilemind/common/src/components/Loading"
import BottomScrollListener from "react-bottom-scroll-listener"
import pluralize from "pluralize"
import {
  getSubGroups,
  getLeaderboard,
  updateFilters,
} from "./leaderboardsSlice"
import theme from "@mobilemind/common/src/theme/theme"
import he from "he"

import "../../styles/reports.scss"

import UserLabel from "@mobilemind/common/src/components/UserLabel"

const mapStateToProps = ({ leaderboards, session }) => {
  const visibility =
    session.group &&
    session.group.field_teacher_leaderboard_visibi[0] &&
    session.group.field_teacher_leaderboard_visibi[0].value

  return {
    leaderboards,
    session,
    visibility,
  }
}

const mapDispatchToProps = {
  changeNavbar,
  getLeaderboard,
  getSubGroups,
}

class LeaderboardsLayout extends Component {
  componentDidMount() {
    const { leaderboards, session, visibility } = this.props

    if (visibility !== "none") {
      this.props.changeNavbar("leaderboards")
      this.props.getLeaderboard()
      if (!leaderboards.subGroups.fetched && !leaderboards.subGroups.fetching) {
        this.props.getSubGroups(session.group.uuid[0].value)
      }
    }
  }

  render() {
    if (
      this.props.visibility !== "none" &&
      this.props.leaderboards.subGroups.fetched
    ) {
      return <LeaderboardsReport {...this.props} />
    }

    return null
  }
}

function LeaderboardsReport(props) {
  const { leaderboards } = props
  const { filters } = leaderboards

  const dispatch = useDispatch()

  if (!leaderboards.subGroups.fetched && !leaderboards.subGroups.fetching) {
    dispatch(getSubGroups(props.session.group.uuid[0].value))
  }

  function handleScroll() {
    // If we aren't already fetching more results
    if (!leaderboards.fetching) {
      // And if there are more pages to fetch
      if (leaderboards.currentPage < leaderboards.totalPages - 1) {
        dispatch(updateFilters({ name: "increaseCurrentPage" }))
      }
    }
  }

  let currentUser = props.session.user
  let userRank = 0
  userRank =
    leaderboards.userRank &&
    moment.localeData().ordinal(props.leaderboards.userRank.toLocaleString()) +
      " place"

  if (leaderboards.fetching) {
    userRank = 0
  }
  if (filters.dateFrom) {
    userRank = 0
  }

  const currentDate = JSON.stringify(new Date()).substring(0, 11)
  if (!filters.dateTo.includes(currentDate)) {
    userRank = 0
  }

  return (
    <div className="page hasSidebar reports">
      <SidebarLeaderboards {...props} />

      <BottomScrollListener onBottom={handleScroll} />

      {props.leaderboards.hasFetched && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <div>
                  {filters.dateFrom ? (
                    <>
                      {moment(JSON.parse(filters.dateFrom)).format(
                        "MM/DD/YYYY"
                      )}{" "}
                    </>
                  ) : (
                    "Beginning of Time "
                  )}{" "}
                  - {moment(JSON.parse(filters.dateTo)).format("MM/DD/YYYY")}
                </div>
              </TableCell>
              <TableCell align={"right"}>
                <div className="flexRow userTotals">
                  {filters.leaderboardType === "badges-earned" && (
                    <>
                      You've Earned{" "}
                      <span
                        style={{ color: theme.palette.primary.main }}
                        className="count"
                      >
                        {currentUser.attributes.field_badges_earned.toLocaleString()}
                      </span>
                      {pluralize(
                        "Badge",
                        currentUser.attributes.field_badges_earned
                      )}
                    </>
                  )}
                  {filters.leaderboardType === "courses-completed" && (
                    <>
                      You've Completed{" "}
                      <span
                        style={{ color: theme.palette.primary.main }}
                        className="count"
                      >
                        {currentUser.attributes.field_courses_completed.toLocaleString()}
                      </span>
                      {pluralize(
                        "Course",
                        currentUser.attributes.field_courses_completed
                      )}
                    </>
                  )}
                </div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.leaderboards.data &&
              props.leaderboards.data.length > 0 &&
              props.leaderboards.data.map((user) => {
                return (
                  <TableRow
                    key={user.uid}
                    style={{
                      borderLeft:
                        currentUser &&
                        currentUser.id === user.uuid &&
                        "5px solid " + theme.palette.secondary.main,
                    }}
                  >
                    <TableCell>
                      <div className="flexRow">
                        <div className="flexRow">
                          <UserLabel
                            firstName={he.decode(user.field_first_name)}
                            lastName={he.decode(user.field_last_name)}
                            imagePath={
                              process.env.REACT_APP_API_URL + user.user_picture
                            }
                            subgroups={user.field_subgroup}
                          />
                        </div>
                      </div>
                    </TableCell>
                    <TableCell align={"right"}>
                      <div className="flexRow total">
                        {currentUser.id === user.uuid &&
                          props.leaderboards.userRank &&
                          userRank !== 0 && (
                            <div className="userRank">
                              <span className="rank">
                                <span
                                  className={classNames(
                                    "icon",
                                    props.leaderboards.userRank === 1 && "first"
                                  )}
                                />
                                {userRank && (
                                  <strong>You're in {userRank}!</strong>
                                )}
                              </span>
                            </div>
                          )}

                        <div className="courses-completed">
                          {filters.leaderboardType === "badges-earned" && (
                            <>
                              <span
                                style={{ color: theme.palette.primary.main }}
                              >
                                {user.field_badge}
                              </span>
                              <span className="icon badge" />
                            </>
                          )}

                          {filters.leaderboardType === "courses-completed" && (
                            <>
                              <span
                                style={{ color: theme.palette.primary.main }}
                              >
                                {user.id}
                              </span>
                              <span className="icon check" />
                            </>
                          )}
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
      )}
      {!props.leaderboards.data.length && !props.leaderboards.fetching && (
        <p className="empty">Whoops, no users match your filter criteria.</p>
      )}
      {props.leaderboards.fetching && (
        <Loading
          color={theme.palette.secondary.main}
          message={
            props.leaderboards.currentPage > 0
              ? "Getting more results..."
              : "Getting results..."
          }
        />
      )}
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(LeaderboardsLayout)
