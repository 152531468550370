import moment from "moment"
import theme from "@mobilemind/common/src/theme/theme"
import { Link } from "react-router-dom"

import {
  RCSExploreCourseItem,
  RCSExploreItem,
  URCSCourseItem,
} from "@mobilemind/common/src/types/mandatedTraining"

type CourseListProps = {
  orderedCourses: RCSExploreCourseItem[]
  activeTrainingSeries: RCSExploreItem
  trainingId: number
}

/**
 * A list of all courses and their current compliance status for the year
 * for a single Mandated Training entity.
 */
function MandatedTrainingCourseList({
  orderedCourses,
  activeTrainingSeries,
  trainingId,
}: CourseListProps) {
  return (
    <ul className="mandated-course-list">
      {orderedCourses.map((course: RCSExploreCourseItem) => {
        const urcs_courses: URCSCourseItem[] = activeTrainingSeries.urcs_data
          ? activeTrainingSeries.urcs_data.urcs_courses
          : []

        // We'll have to figure out if they are compliant, how long does that last
        // We'll look at the last completed assessment date and compare that to the
        // Specific retake cadence for the course
        let untilDate
        if (urcs_courses && urcs_courses.length) {
          const urcsCourseData = urcs_courses.find(
            (urcs_course: URCSCourseItem) => urcs_course.id === course.id
          )

          untilDate =
            urcsCourseData &&
            urcsCourseData.last_completed_date &&
            moment(urcsCourseData.last_completed_date)
              .add(Number(course.retake_cadence), "years")
              .format("YYYY")
        }

        return (
          <li key={course.id}>
            <Link to={"/course/" + course.id + "?mt=" + trainingId}>
              <div style={{ display: "flex", flex: 1 }}>
                <span
                  className="category-icon"
                  style={{
                    backgroundImage:
                      "url(" + course.course_category_image + ")",
                  }}
                />
                <div>
                  <strong className="title">{course.name}</strong>
                  <span className="category-label">{course.category_name}</span>
                </div>
              </div>
              {course.in_compliance ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-end",
                      flexDirection: "column",
                    }}
                  >
                    <strong
                      style={{
                        display: "block",
                        color: theme.palette.secondary.main,
                      }}
                    >
                      Completed
                    </strong>
                    {untilDate && (
                      <strong style={{ fontSize: 12 }}>
                        Compliant until {untilDate}
                      </strong>
                    )}
                  </div>
                  <span
                    style={{ width: 25, height: 25, marginRight: 6 }}
                    className="status-icon icon check"
                  />
                </>
              ) : (
                <>
                  <strong style={{ color: "#E49542" }}>Incomplete</strong>
                  <span className="status-icon icon overdue" />
                </>
              )}
            </Link>
          </li>
        )
      })}
    </ul>
  )
}

export default MandatedTrainingCourseList
