import { useEffect, useState } from "react"
import { isChrome, isEdge, isMobile } from "react-device-detect"
import ReactGA from "react-ga4"
import { useDispatch, useSelector } from "react-redux"
import { startOnboarding } from "../actions"
import { getCategories } from "../store/reducers/categories"
import BrowserModal from "./BrowserModal"
import ExtensionModal from "./ExtensionModal"
import Navbar from "./Navbar.js"
import Onboarding from "./Onboarding"
import ProductTour from "./ProductTour"

import { getTags } from "@mobilemind/common/src/actions/course"
import theme from "@mobilemind/common/src/theme/theme"
import { useLocation } from "react-router-dom"
import "../styles/cards.scss"
import "../styles/index.scss"
import AppStoreModal from "./AppStoreModal"

export const App = (props) => {
  const location = useLocation()
  const dispatch = useDispatch()
  const { onboarding, session, extension, productTour } = useSelector(
    (state) => state
  )
  const isCollapsed = useSelector(
    ({ sidebar }) => sidebar.isCollapsed && !sidebar.isPeeked
  )

  const [hasJobTitle] = useState(!!session.user.jobTitle)
  const [didLoad, setDidLoad] = useState(false)

  useEffect(() => {
    if (!didLoad) {
      // We need these everywhere
      dispatch(getCategories())
      dispatch(getTags({ isLearn: true }))

      /**
       * If they've got no job title, start onboarding, store it
       * in the state if they do in case they change it later
       */
      if (!hasJobTitle && !session.user.jobTitle) {
        dispatch(startOnboarding())
      }
      if (process.env.REACT_APP_LEARN_GA4_ID) {
        ReactGA.initialize(process.env.REACT_APP_LEARN_GA4_ID)
        ReactGA.gtag("set", "user_properties", {
          userid: session.user.id,
        })
      }
      if (process.env.REACT_APP_CLARITY_TAG) {
        // Set the custom ID for Clarity to the session user's ID.
        window.clarity("identify", session.user.id)
      }
      setDidLoad(true)
    } else {
      window.scrollTo(0, 0)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [didLoad, location])

  let versionWarning, showExtensionModal, showBrowserWarning
  if (!isMobile) {
    // If we aren't looking at certificates
    if (!location.pathname.startsWith("/certificate")) {
      if (
        (isChrome || isEdge) &&
        extension.messageAttemptFinished &&
        !extension.version
      ) {
        showExtensionModal = true
      }
      if (!isChrome && !isEdge) {
        showBrowserWarning = true
        showExtensionModal = false
      }
      // Hiding this message for now
      // else if (isChrome && extension.version !== process.env.REACT_APP_VERSION) {
      //   versionWarning = <>It looks like you don't have the latest version of the MobileMind Extension installed. Go to <strong>Window ... Extensions</strong>, enable <strong>Developer Mode</strong> in the top right corner, then click <strong>Update</strong> in the toolbar that appears.</>
      // }
    }
  }

  let showOnboarding = !hasJobTitle
  let showMain = hasJobTitle || !onboarding.started

  if (isMobile) {
    return (
      <div
        className="appWrapper"
        style={{
          height: "100vh",
          marginTop: -50,
          backgroundColor: theme.palette.primary.main,
        }}
      >
        <AppStoreModal collection={session.collection} />
      </div>
    )
  }

  return (
    <div className="appWrapper">
      {!isMobile && productTour.isStarted && <ProductTour />}

      {showOnboarding && <Onboarding />}

      {showExtensionModal && (
        <ExtensionModal
          collection={session.collection}
          googleURL={
            "https://chrome.google.com/webstore/detail/mobilemind-extension/" +
            process.env.REACT_APP_CHROME_EXTENSION_ID +
            "?hl=en"
          }
          edgeURL={
            "https://microsoftedge.microsoft.com/addons/detail/mobilemind/ppndgjiemledcgceaglfdmhnfpphelff"
          }
        />
      )}

      {!window.location.href.includes("mobilemind.io") &&
        !location.pathname.includes("print") &&
        !location.pathname.includes("certificate") && (
          <div className="poweredBy">
            <span className="logo circle" />
            Powered by MobileMind
          </div>
        )}

      {showBrowserWarning && <BrowserModal />}
      {showMain && (
        <>
          {!location.pathname.includes("certificate") &&
            !location.pathname.includes("rewind") &&
            !location.pathname.includes("print") && <Navbar theme={theme} />}
          <main
            data-collapsed={isCollapsed || location.pathname === "/profile"}
            data-is-tour-started={productTour.isStarted}
            data-is-print={location.pathname.includes("print")}
          >
            <div className="appInner">
              {versionWarning && (
                <div className="browserWarning">{versionWarning}</div>
              )}
              {props.children}
            </div>
          </main>
        </>
      )}
    </div>
  )
}

export default App
