import { useState, useRef, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import classNames from "classnames"
import moment from "moment"
import { Link } from "react-router-dom"

import _ from "lodash"

import Accordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"
import AccordionDetails from "@mui/material/AccordionDetails"
import { claimRecommended } from "../../../store/reducers/externalPD"

import { debouncedFetchCalendar } from "../../../store/reducers/calendar"

import SanitizedHTML from "@mobilemind/common/src/components/SanitizedHTML"
import Values from "values.js"

import he from "he"
import theme from "@mobilemind/common/src/theme/theme"

import ButtonSmall from "../../../components/ButtonSmall"
import LearningBuddyMessage from "@mobilemind/common/src/components/LearningBuddyMessage"

export function CalendarListView(props) {
  const [activeItem, setActiveItem] = useState(null)
  const [isFetchingPrev, setIsFetchingPrev] = useState(false)
  const [isFetchingLater, setIsFetchingLater] = useState(false)

  const secondary = new Values(theme.palette.secondary.main)

  const dispatch = useDispatch()
  const listRef = useRef(null)

  let sortedList = useSelector((state) => {
    let sorted = _.orderBy(
      state.calendar.data,
      (event) => event.startDate,
      "asc"
    )
    return sorted
  })

  const finalData = sortedList ? sortedList : []

  // Find the first event that's today or after
  const initialEvent = finalData.find((event) => {
    return moment(event.startDate).isAfter(moment().subtract(1, "day"))
  })

  const initialRef = useRef(null)

  // Then scroll to it once it's identified, but only once
  useEffect(() => {
    if (!props.calendar.searchQuery && initialRef.current) {
      listRef.current?.lastElementChild?.scrollIntoView()
    }
  }, [initialEvent, props.calendar.searchQuery])

  // Filter out un-accepted events if we've got "Only My Events" checked
  if (props.calendar.onlyMyEvents) {
    sortedList = sortedList.filter((event) => event.rsvp === "Accept")
  }

  if (props.calendar.selectedTags) {
    props.calendar.selectedTags.length &&
      props.calendar.selectedTags.forEach((selected) => {
        sortedList = sortedList.filter(
          (event) =>
            event.field_tags && event.field_tags.includes(selected.text)
        )
      })
  }

  async function fetchMore(direction) {
    let dateRange = {
      min: moment(finalData[0].startDate)
        .subtract(1, "month")
        .startOf("month")
        .format("YYYY-MM-DD"),
      max: moment(finalData[0].startDate).format("YYYY-MM-DD"),
    }
    if (direction === "prev") {
      setIsFetchingPrev(true)
    } else {
      setIsFetchingLater(true)

      dateRange = {
        min: moment(finalData[finalData.length - 1].startDate)
          .add(1, "month")
          .startOf("month")
          .format("YYYY-MM-DD"),
        max: moment(finalData[finalData.length - 1].startDate)
          .add(1, "month")
          .endOf("month")
          .format("YYYY-MM-DD"),
      }
    }

    // Refetch the calendar / events list with our new date range
    await dispatch(debouncedFetchCalendar({ dateRange, isFetchingMore: true }))
    setIsFetchingPrev(false)
    setIsFetchingLater(false)
  }

  return (
    <div className="calendarList">
      {finalData && finalData.length > 0 && (
        <ul ref={listRef}>
          <li
            style={{
              opacity: isFetchingPrev ? 0.5 : 1,
              pointerEvents: isFetchingPrev ? "none" : "all",
            }}
            className="fetchButtonContainer"
          >
            <ButtonSmall
              className={classNames("button large primary")}
              onClick={() => fetchMore("prev")}
            >
              Load Earlier Events
            </ButtonSmall>
          </li>

          {finalData.map((event, index) => {
            const primary = new Values(theme.palette.primary.main)

            /**
             * Determine if this event is "All Day"
             */
            let isAllDay =
              moment(event.startDate).format("h:mm A") ===
              moment(event.endDate).subtract(24, "hours").format("h:mm A")
            if (event.isGoal) {
              isAllDay = true
            }

            /**
             * Figure out the background color for the "chip" indicator
             * based on whether it's a conference, event, or goal
             */
            let backgroundColor = primary.tint(50).hexString()
            if (event.isGoal) {
              backgroundColor = theme.palette.secondary.main
            } else if (event.isConference) {
              backgroundColor = primary.shade(15).hexString()
            } else if (event.isExternal) {
              backgroundColor = secondary.shade(35).hexString()
            }

            /**
             * Determine where this item will link to
             */
            let linkPath = event.isConference
              ? "/events/conference/" + event.drupal_internal__id
              : "/events/event/" + event.drupal_internal__id
            if (event.isGoal) {
              linkPath = "learning-path/" + event.drupal_internal__id
            }
            if (event.isObservation) {
              linkPath = "/events/observation/" + event.drupal_internal__id
            }

            let isFirstOfMonth =
              !sortedList[index - 1] ||
              moment(event.startDate).format("MM") !==
                moment(sortedList[index - 1].startDate).format("MM")

            let isLastofMonth =
              !sortedList[index + 1] ||
              moment(event.startDate).format("MM") !==
                moment(sortedList[index + 1].startDate).format("MM")

            if (event.isEmptyMonth) {
              isFirstOfMonth = true
            }

            /**
             * Determine if we should show this based on current search
             */
            const searchCheck =
              !props.calendar.searchQuery ||
              (props.calendar.searchQuery &&
                event.title &&
                event.title
                  .toLowerCase()
                  .includes(props.calendar.searchQuery.toLowerCase()))

            let { startDate, endDate } = event

            if (moment().isDST() && !moment(startDate).isDST()) {
              startDate = moment(startDate).add(1, "hour")
              endDate = moment(endDate).add(1, "hour")
            } else if (!moment().isDST() && moment(startDate).isDST()) {
              startDate = moment(startDate).subtract(1, "hour")
              endDate = moment(endDate).subtract(1, "hour")
            }

            return (
              <>
                {isFirstOfMonth && (
                  <li className="month-header">
                    {event.startDate ? (
                      <>
                        {moment(event.startDate).isValid() ? (
                          <>{moment(event.startDate).format("MMMM YYYY")}</>
                        ) : (
                          <>No Date</>
                        )}
                      </>
                    ) : (
                      <>{moment(event.month).format("MMMM YYYY")}</>
                    )}
                  </li>
                )}
                {!event.isEmptyMonth ? (
                  <>
                    {searchCheck && (
                      <li
                        style={{ borderBottomWidth: isLastofMonth ? 0 : 1 }}
                        className={classNames(event.rsvp)}
                        ref={
                          initialEvent &&
                          initialEvent.drupal_internal__id ===
                            event.drupal_internal__id
                            ? initialRef
                            : null
                        }
                        key={index + event.startDate}
                      >
                        <Accordion
                          onClick={() => {
                            !event.isGoal &&
                              setActiveItem(index !== activeItem ? index : null)
                          }}
                          expanded={activeItem === index}
                        >
                          <AccordionSummary>
                            <div className="flexRow" style={{ flex: 1 }}>
                              {event.startDate &&
                                moment(event.startDate).isValid() && (
                                  <div className="dateTime">
                                    <div
                                      className="weekMonth"
                                      style={{ fontSize: 12 }}
                                    >
                                      {moment(event.startDate).format("ddd")}
                                      <br />
                                      {moment(event.startDate).format("MMM")}
                                    </div>
                                    <span className="date">
                                      {moment(event.startDate).format("D")}
                                    </span>
                                  </div>
                                )}

                              <div className="title" style={{ flex: 1 }}>
                                <header
                                  style={{
                                    fontSize: 14,
                                    marginBottom: 0,
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  {!event.isGoal ? (
                                    <div className="time">
                                      <span style={{ backgroundColor }} />
                                    </div>
                                  ) : (
                                    <span
                                      className="icon goal"
                                      style={{
                                        borderRadius: 0,
                                        marginRight: 10,
                                        width: 15,
                                        height: 15,
                                      }}
                                    />
                                  )}
                                  {event.title}
                                </header>

                                <div className="time">
                                  <span className="icon calendar" />

                                  {!event.isGoal ? (
                                    <>
                                      {!isAllDay &&
                                      moment(startDate).isValid() ? (
                                        <>
                                          {moment(startDate).format("h:mm A")} -{" "}
                                          {moment(endDate).format("h:mm A")}
                                        </>
                                      ) : (
                                        <>All Day</>
                                      )}
                                    </>
                                  ) : (
                                    <strong style={{ opacity: 0.5 }}>
                                      Goal Deadline
                                    </strong>
                                  )}
                                </div>
                              </div>
                              {event.rsvp && (
                                <div className="rsvp">
                                  <span
                                    className={
                                      "icon rsvp rsvp-" +
                                      event.rsvp.toLowerCase()
                                    }
                                  ></span>
                                </div>
                              )}

                              {!event.isExternal && (
                                <Link to={linkPath}>
                                  <ButtonSmall>
                                    {!event.isGoal ? (
                                      <>View Details</>
                                    ) : (
                                      <>Go to Learning Path</>
                                    )}
                                  </ButtonSmall>
                                </Link>
                              )}
                              {event.isExternal && (
                                <ButtonSmall
                                  onClick={() => {
                                    dispatch(claimRecommended(event.fullEvent))
                                  }}
                                >
                                  Request Credit
                                </ButtonSmall>
                              )}
                            </div>
                          </AccordionSummary>
                          {!event.isGoal && (
                            <AccordionDetails>
                              {event.description && (
                                <SanitizedHTML
                                  html={he.decode(event.description)}
                                />
                              )}
                            </AccordionDetails>
                          )}
                        </Accordion>
                      </li>
                    )}
                  </>
                ) : (
                  <li
                    style={{
                      padding: 20,
                      fontStyle: "italic",
                      fontSize: 14,
                      textAlign: "center",
                      borderBottomWidth: 0,
                    }}
                  >
                    No events for this month.
                  </li>
                )}
              </>
            )
          })}

          <li
            style={{
              opacity: isFetchingLater ? 0.5 : 1,
              pointerEvents: isFetchingLater ? "none" : "all",
            }}
            className="fetchButtonContainer"
          >
            <ButtonSmall
              className={classNames("button large primary")}
              onClick={() => fetchMore("next")}
            >
              Load More Events
            </ButtonSmall>
          </li>
        </ul>
      )}
      {finalData && finalData.length < 1 && props.calendar.fetched && (
        <div className="buddy-wrapper">
          <LearningBuddyMessage
            message={
              props.calendar.searchQuery.length ||
              props.calendar.selectedTags.length > 0
                ? "Hmm, it doesn't look like any events match that filter criteria."
                : "I've got no events to show you!"
            }
          />
        </div>
      )}
    </div>
  )
}
