import theme from "@mobilemind/common/src/theme/theme"

function Twitter() {
  return (
    <svg width="20px" height="20px" viewBox="0 0 20 20">
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="twitter" fill={theme.palette.primary.main} fillRule="nonzero">
          <path
            d="M18,0 L2,0 C0.9,0 0,0.9 0,2 L0,18 C0,19.1 0.9,20 2,20 L18,20 C19.1,20 20,19.1 20,18 L20,2 C20,0.9 19.1,0 18,0 Z M15.7,7.3 C15.6,11.9 12.7,15.1 8.3,15.3 C6.5,15.4 5.2,14.8 4,14.1 C5.3,14.3000392 7,13.8000392 7.9,13 C6.6,12.9 5.8,12.2 5.4,11.1 C5.8,11.2 6.2,11.1 6.5,11.1 C5.3,10.7 4.5,10 4.4,8.4 C4.7,8.6 5.1,8.7 5.5,8.7 C4.6,8.2 4,6.3 4.7,5.1 C6,6.5 7.6,7.7 10.2,7.9 C9.5,5.1 13.3000392,3.6 14.8000392,5.5 C15.5000392,5.4 16.0000392,5.1 16.5000392,4.9 C16.3000392,5.6 15.9000392,6 15.4000392,6.4 C15.9000392,6.3 16.4000392,6.2 16.8000392,6 C16.7,6.5 16.2,6.9 15.7,7.3 Z"
            id="Shape"
          ></path>
        </g>
      </g>
    </svg>
  )
}

export default Twitter
