import theme from "@mobilemind/common/src/theme/theme"

function Calendar() {
  return (
    <svg width="20px" height="20px" viewBox="0 0 10 10">
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="calendar-blue"
          fill={theme.palette.primary.main}
          fillRule="nonzero"
        >
          <circle id="Oval" cx="7.0390625" cy="3.6015625" r="1"></circle>
          <path
            d="M8.4375,0.78125 L7.9296875,0.78125 L7.9296875,0.390625 C7.9296875,0.174882813 7.75480469,0 7.5390625,0 C7.32332031,0 7.1484375,0.174882813 7.1484375,0.390625 L7.1484375,0.78125 L5.37109375,0.78125 L5.37109375,0.390625 C5.37109375,0.174882813 5.19621094,0 4.98046875,0 C4.76472656,0 4.58984375,0.174882813 4.58984375,0.390625 L4.58984375,0.78125 L2.83203125,0.78125 L2.83203125,0.390625 C2.83203125,0.174882813 2.65714844,0 2.44140625,0 C2.22566406,0 2.05078125,0.174882813 2.05078125,0.390625 L2.05078125,0.78125 L1.5625,0.78125 C0.7009375,0.78125 0,1.4821875 0,2.34375 L0,8.4375 C0,9.2990625 0.7009375,10 1.5625,10 L4.55078125,10 C4.76652344,10 4.94140625,9.82511719 4.94140625,9.609375 C4.94140625,9.39363281 4.76652344,9.21875 4.55078125,9.21875 L1.5625,9.21875 C1.13171875,9.21875 0.78125,8.86828125 0.78125,8.4375 L0.78125,2.34375 C0.78125,1.91296875 1.13171875,1.5625 1.5625,1.5625 L2.05078125,1.5625 L2.05078125,1.953125 C2.05078125,2.16886719 2.22566406,2.34375 2.44140625,2.34375 C2.65714844,2.34375 2.83203125,2.16886719 2.83203125,1.953125 L2.83203125,1.5625 L4.58984375,1.5625 L4.58984375,1.953125 C4.58984375,2.16886719 4.76472656,2.34375 4.98046875,2.34375 C5.19621094,2.34375 5.37109375,2.16886719 5.37109375,1.953125 L5.37109375,1.5625 L7.1484375,1.5625 L7.1484375,1.953125 C7.1484375,2.16886719 7.32332031,2.34375 7.5390625,2.34375 C7.75480469,2.34375 7.9296875,2.16886719 7.9296875,1.953125 L7.9296875,1.5625 L8.4375,1.5625 C8.86828125,1.5625 9.21875,1.91296875 9.21875,2.34375 L9.21875,4.5703125 C9.21875,4.78605469 9.39363281,4.9609375 9.609375,4.9609375 C9.82511719,4.9609375 10,4.78605469 10,4.5703125 L10,2.34375 C10,1.4821875 9.2990625,0.78125 8.4375,0.78125 Z"
            id="Path"
          ></path>
          <path
            d="M7.63671875,5.2734375 C6.33359375,5.2734375 5.2734375,6.33359375 5.2734375,7.63671875 C5.2734375,8.93984375 6.33359375,10 7.63671875,10 C8.93984375,10 10,8.93984375 10,7.63671875 C10,6.33359375 8.93984375,5.2734375 7.63671875,5.2734375 Z M7.63671875,9.21875 C6.76439453,9.21875 6.0546875,8.5090625 6.0546875,7.63671875 C6.0546875,6.764375 6.76439453,6.0546875 7.63671875,6.0546875 C8.50904297,6.0546875 9.21875,6.764375 9.21875,7.63671875 C9.21875,8.5090625 8.50904297,9.21875 7.63671875,9.21875 Z"
            id="Shape"
          ></path>
          <path
            d="M8.203125,7.24609375 L8.02734375,7.24609375 L8.02734375,6.8359375 C8.02734375,6.62019531 7.85246094,6.4453125 7.63671875,6.4453125 C7.42097656,6.4453125 7.24609375,6.62019531 7.24609375,6.8359375 L7.24609375,7.63671875 C7.24609375,7.85246094 7.42097656,8.02734375 7.63671875,8.02734375 L8.203125,8.02734375 C8.41886719,8.02734375 8.59375,7.85246094 8.59375,7.63671875 C8.59375,7.42097656 8.41886719,7.24609375 8.203125,7.24609375 Z"
            id="Path"
          ></path>
          <circle id="Oval" cx="5.33984375" cy="3.6015625" r="1"></circle>
          <circle id="Oval" cx="3.640625" cy="5.30078125" r="1"></circle>
          <circle id="Oval" cx="1.94140625" cy="3.6015625" r="1"></circle>
          <circle id="Oval" cx="1.94140625" cy="5.30078125" r="1"></circle>
          <circle id="Oval" cx="1.94140625" cy="7" r="1"></circle>
          <circle id="Oval" cx="3.640625" cy="7" r="1"></circle>
          <circle id="Oval" cx="3.640625" cy="3.6015625" r="1"></circle>
        </g>
      </g>
    </svg>
  )
}

export default Calendar
