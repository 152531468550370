import { Component } from "react"
import "../../styles/certificate.scss"

import Loading from "@mobilemind/common/src/components/Loading"
import moment from "moment"
import classNames from "classnames"
import { convertEstimatedTime } from "@mobilemind/common/src/functions/index"
import theme from "@mobilemind/common/src/theme/theme"
import TimeCapsule from "@mobilemind/common/src/components/TimeCapsule"

function LearningPathCertificate(props) {
  return <CertificateContent {...props} theme={theme} />
}

class CertificateContent extends Component {
  hideBrokenImage(event) {
    event.target.className = "hide"
  }

  render() {
    let { certificate, theme } = this.props
    let learningPathImageUrl, teacherName, name, created, timeSpent

    if (certificate) {
      if (certificate.data) {
        certificate = certificate.data
      }

      learningPathImageUrl = certificate.attributes.field_image_url
      teacherName = certificate.attributes.field_teacher_name
      name = certificate.attributes.name
      created = certificate.attributes.field_earned
      timeSpent = certificate.timeSpent
    }

    let totalEstimatedTime

    if (this.props.learningPath) {
      timeSpent = this.props.learningPath.field_ulp_time_spent
      totalEstimatedTime =
        this.props.learningPath &&
        this.props.learningPath.field_ulp_time_spent
          .map((cat) => Number(cat.field_time_spent))
          .reduce((a, b) => a + b, 0)
    } else {
      totalEstimatedTime =
        timeSpent &&
        timeSpent
          .map((cat) => cat.attributes.field_time_spent)
          .reduce((a, b) => a + b, 0)
    }

    const totalMinutes = Number(totalEstimatedTime)
    const hours =
      totalMinutes >= 60 ? Math.floor(totalMinutes / 60).toLocaleString() : 0
    const minutes = totalMinutes % 60

    return (
      <div
        id="certificateWrapper"
        className={classNames("certificateWrapper", !certificate && "print")}
      >
        <div className="mm-bg" />
        <div className="certificateLearningPath">
          {learningPathImageUrl && teacherName && name ? (
            <div className="inner">
              <h2>
                <span>
                  This certifies that on{" "}
                  {moment(created).format("MMMM D, YYYY")}
                </span>
                {teacherName}
                <span>
                  successfully completed all the required courses for the
                  Learning Path
                </span>
              </h2>
              <span className="title">{name}</span>
              <div className="flexRow categories">
                {timeSpent &&
                  timeSpent.map((category, index) => {
                    let image, time, label
                    if (this.props.learningPath) {
                      image = category.field_category_image
                      label = category.field_category_label
                      time = category.field_time_spent
                    } else {
                      image = category.attributes.field_category_image
                      label = category.attributes.field_category_label
                      time = category.attributes.field_time_spent
                    }

                    return (
                      <div key={index} className="category">
                        <img
                          onError={this.hideBrokenImage}
                          alt="category"
                          src={process.env.REACT_APP_API_URL + image}
                        />
                        <div className="time">
                          <strong>{label}</strong>
                          <span>{convertEstimatedTime(Number(time))}</span>
                        </div>
                      </div>
                    )
                  })}
              </div>

              <div
                className="learningPathImage"
                style={{
                  backgroundImage:
                    "url(" +
                    process.env.REACT_APP_API_URL +
                    learningPathImageUrl +
                    ")",
                }}
              />
              <img
                className="learningPathImage printBackground"
                src={process.env.REACT_APP_API_URL + learningPathImageUrl}
                alt={name}
              />

              <div className="logoWrapper">
                <img
                  alt="Logo"
                  className="logo"
                  src={theme.mixins.images.logo}
                />
              </div>

              {totalEstimatedTime > 0 && (
                <div className="totalEstimatedTime">
                  <header>Total Estimated Time Spent</header>
                  <TimeCapsule hours={hours} minutes={minutes} />
                  {window.location.href.includes("certificate") && (
                    <em>
                      Time may count towards PD credit, depending on district
                      policies.
                    </em>
                  )}
                </div>
              )}
            </div>
          ) : (
            <Loading
              color={theme.palette.secondary.main}
              message="Generating certificate..."
            />
          )}
        </div>
      </div>
    )
  }
}

export default LearningPathCertificate
