import { Component } from "react"
import { connect } from "react-redux"
import classNames from "classnames"

import { Link } from "react-router-dom"
import { Card } from "@mui/material"

import ButtonSmall from "../../components/ButtonSmall"
import PropTypes from "prop-types"

import { setSidebar } from "../../actions"

const mapStateToProps = ({ courses, categories }) => {
  return {
    courses: courses.courseData,
    categories,
  }
}

const mapDispatchToProps = {
  setSidebar,
}

class WidgetCourseList extends Component {
  handleClick = () => {
    const { targetPage, targetTab } = this.props.widget
    this.props.setSidebar(targetPage, targetTab)
  }

  getCourseFromAssessment = (assessment) => {
    if (this.props.courses) {
      return this.props.courses.find(
        (course) => assessment.relationships.field_course.data.id === course.id
      )
    }
  }

  generateMessage = (status) => {
    let message

    if (status === "in_progress") {
      if (this.props.widget.assessments.length === 1) {
        message = "Pick up where you left off with this course in progress."
      } else {
        message = "Move forward with these courses you've already started."
      }
    } else if (status === "saved_for_later") {
      if (this.props.widget.assessments.length === 1) {
        message = "Ready to start this course? Let's go!"
      } else {
        message = "You've picked out some good stuff, let's get to it!"
      }
    }

    return message
  }

  render() {
    const { label, assessments, targetRoute, targetTab } = this.props.widget
    return (
      <Card
        className="widget"
        style={{ animationDelay: this.props.animationDelay }}
      >
        <div
          className="widgetLabel success"
          style={{ backgroundColor: this.props.theme.palette.secondary.main }}
        >
          {label}
        </div>
        <div
          className={classNames(
            "widgetIcon icon",
            targetTab === "saved_for_later" ? "pin" : "laptop"
          )}
        />

        <p>{this.generateMessage(targetTab)}</p>

        <ul className="course-list-widget">
          {
            // Remove this slice, we should only provide up to 3 items anyway
            assessments &&
              assessments.map((assessment) => {
                let course = this.getCourseFromAssessment(assessment)

                if (course) {
                  let categoryId = course.relationships.field_category.data.id
                  let category = this.props.categories.data.find(
                    (cat) => cat.id === categoryId
                  )

                  return (
                    <li key={assessment.id}>
                      <Link
                        to={"course/" + course.attributes.drupal_internal__id}
                      >
                        <div className="category-icon-wrapper">
                          {category && (
                            <span
                              className={classNames("category-icon")}
                              style={{
                                backgroundImage:
                                  "url(" +
                                  process.env.REACT_APP_API_URL +
                                  category.image.attributes.uri.url +
                                  ")",
                              }}
                            />
                          )}
                        </div>
                        {course.attributes.name}
                      </Link>
                    </li>
                  )
                } else {
                  return false
                }
              })
          }
        </ul>
        <footer>
          <Link onClick={this.handleClick} to={"/" + targetRoute}>
            <ButtonSmall>View All</ButtonSmall>
          </Link>
        </footer>
      </Card>
    )
  }
}

WidgetCourseList.propTypes = {
  animationDelay: PropTypes.string,
  categories: PropTypes.object,
  courses: PropTypes.array,
  widget: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(WidgetCourseList)
