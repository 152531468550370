import { Component } from "react"
import { Link } from "react-router-dom"
import { connect } from "react-redux"

import classNames from "classnames"
import { Grid, Card, CardContent, Button } from "@mui/material"
import Rating from "@mobilemind/common/src/components/Rating"

import { generateTimestamp } from "@mobilemind/common/src/functions/index"

import { deleteAssessment } from "../../actions"

import trashImg from "../../img/trash.svg"

const mapStateToProps = ({ session }) => {
  return {
    session,
  }
}

const mapDispatchToProps = {
  deleteAssessment,
}

class CourseCard extends Component {
  state = {
    promptRemove: false,
  }

  remove = (id) => {
    this.setState({ removed: true })
    this.props.deleteAssessment(id, this.props.session)
  }

  render() {
    const { course } = this.props
    const { promptRemove, removed } = this.state

    let coursePath = "/course/" + course.id

    const isSales = this.props.session.roles.find(
      (role) => role.target_id === "mm_sales"
    )
    let isRemovable = isSales

    return (
      <>
        {!removed && (
          <Grid item xs={3}>
            <Link to={coursePath}>
              <Card
                variant={"outlined"}
                className={classNames(
                  "card small isCompleted",
                  course.field_user_assessment_comment && "hasFeedback"
                )}
                style={{ animationDelay: this.props.animationDelay }}
              >
                <div
                  className="card-image"
                  data-bg={
                    course.field_category_name &&
                    course.field_category_name.toLowerCase()
                  }
                />
                <CardContent>
                  <header>
                    <div className="category-icon-wrapper">
                      <span
                        className="category-icon"
                        style={{
                          backgroundImage:
                            "url(" + course.field_category_image_uri + ")",
                        }}
                      />
                    </div>

                    <div className="course-label">
                      <span className="category-label">
                        {course.field_category_name}
                      </span>
                      <div className="course-level">
                        <Rating level={course.field_level_value} />
                      </div>
                    </div>
                  </header>
                  <h2>{course.name}</h2>
                </CardContent>

                <footer className="timestamp">
                  <div className="flexRow">
                    <span className="icon check"></span>Completed{" "}
                    {generateTimestamp(
                      course.field_user_assessment_completed_date
                    )}
                  </div>
                  {course.field_user_assessment_comment && (
                    <div className="feedback">
                      <em>"{course.field_user_assessment_comment}"</em>
                    </div>
                  )}
                </footer>
              </Card>
            </Link>

            <div
              className={classNames(
                "actions",
                { removable: isRemovable },
                { prompt: promptRemove }
              )}
            >
              {promptRemove && (
                <>
                  <div className="flexRow" style={{ backgroundColor: "white" }}>
                    <Button
                      onClick={() => {
                        this.setState({ promptRemove: false })
                      }}
                      className="button small"
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={() => {
                        this.remove(course.field_user_assessment_uuid)
                      }}
                      className="button small"
                    >
                      Remove
                    </Button>
                  </div>
                </>
              )}
            </div>

            {isSales && (
              <Button
                onClick={() => this.setState({ promptRemove: true })}
                className="button small remove fixed"
              >
                <img src={trashImg} alt="remove" />
              </Button>
            )}
          </Grid>
        )}
      </>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CourseCard)
