import { Component } from "react"
import { Link } from "react-router-dom"
import { Card } from "@mui/material"
import { connect } from "react-redux"
import classNames from "classnames"
import ButtonSmall from "../../components/ButtonSmall"
import UserInitial from "@mobilemind/common/src/components/UserInitial"

const mapStateToProps = ({ categories }) => {
  return {
    categories,
  }
}

class WidgetFeedback extends Component {
  render() {
    const { course } = this.props.widget
    const { theme } = this.props
    let coursePath = "/course/" + course.id

    return (
      <Card
        className="widget feedback"
        style={{ animationDelay: this.props.animationDelay }}
      >
        <Link to={coursePath}>
          <div
            className="widgetLabel success"
            style={{ backgroundColor: theme.palette.secondary.main }}
          >
            Feedback
          </div>
          <div className="widgetIcon icon feedback" />

          <div className="flexRow">
            <div className="category-icon-wrapper">
              <span
                className={classNames("category-icon")}
                style={{
                  backgroundImage:
                    "url(" + course.field_category_image_uri + ")",
                }}
              />
            </div>
            <p>
              Check out this feedback on your challenge for{" "}
              <strong>{course.name}</strong>.
            </p>
          </div>

          <div className="content">
            {course.field_reviewer_profile_image ? (
              <span
                className="reviewerImage"
                style={{
                  backgroundImage:
                    "url(" + course.field_reviewer_profile_image + ")",
                }}
              />
            ) : (
              <>
                {course &&
                  course.field_reviewer_first_name &&
                  course.field_reviewer_last_name && (
                    <UserInitial
                      size={40}
                      initials={
                        course.field_reviewer_first_name
                          .substring(0, 1)
                          .toUpperCase() +
                        course.field_reviewer_last_name
                          .substring(0, 1)
                          .toUpperCase()
                      }
                    />
                  )}
              </>
            )}

            <p>
              <em>"{course.field_user_assessment_comment}"</em>
            </p>
          </div>

          <footer>
            <ButtonSmall>View Course</ButtonSmall>
          </footer>
        </Link>
      </Card>
    )
  }
}

export default connect(mapStateToProps)(WidgetFeedback)
