export const buddyDialogue = {
  congratulatory: [
    "Nice!",
    "Oh yeah!",
    "Great work!",
    "Nice work!",
    "Great job!",
    "Nice job!",
    "Way to go!",
    "Well done!",
    "Nailed it!",
    "Score!",
    "Bingo!",
    "Awesome!",
    "Woohoo!",
    "Sweet!",
    "Good deal!",
    "Congrats!",
    "Very nice!",
    "Fantastic!",
    "Bravo!",
    "Superb!",
    "Right on!",
    "Nice going!",
  ],
  ongoing: [
    "You're on a roll!",
    "Let's keep going!",
    "Keep it up!",
    "Keep going!",
    "Keep it coming!",
    "You're doing great!",
    "You're grooving now!",
    "You got this!",
    "Let's go!",
    "You're in the zone!",
  ],
  bummer: [
    "Aw, shucks.",
    "Uh oh!",
    "Bummer!",
    "Whoops!",
    "Darn!",
    "Wah wah...",
  ],
  encouragement: [
    "What's next?",
    "What should we learn next?",
    "Let's find something new to learn!",
    "I can't wait for what's next!",
  ],
}

// Generate random buddy messages
export function generateBuddyMessage(type) {
  return buddyDialogue[type][
    Math.floor(Math.random() * buddyDialogue[type].length)
  ]
}
