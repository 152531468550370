import { Component } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"

import {
  Select,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material"

import { isMobile } from "react-device-detect"
import classNames from "classnames"

import "../../styles/sidebar.scss"
import { updateBadgeFilters } from "../achievements/achievementsSlice"

import { updateFilters, getBadges } from "./badgesSlice"

const mapStateToProps = (state) => {
  return {
    sidebar: state.sidebar,
    navbar: state.navbar,
    isExpanded: state.sidebar.mobileFiltersExpanded,
    filters: state.achievements.badges.filters,
  }
}

const mapDispatchToProps = {
  updateFilters,
  getBadges,
  updateBadgeFilters,
}

class BadgeFiltersEarned extends Component {
  updateSort = (event) => {
    const filters = { ...this.props.filters }
    filters.sort = event.target.value
    this.props.updateBadgeFilters(filters)
  }

  updateSearch = (event) => {
    const filters = { ...this.props.filters }
    filters.search = event.target.value
    this.props.updateBadgeFilters(filters)
  }

  resetFilters = () => {
    this.props.updateBadgeFilters(
      {
        sort: "dateEarned",
      },
      "badgesEarned"
    )
  }

  toggleExpand = () => {
    this.props.updateFilters(this.props.isExpanded ? false : true, "mobile")
  }

  render() {
    const { sort, search } = this.props.filters

    return (
      <form
        className={classNames(
          "filters badgesEarned",
          this.props.sidebar.isCollapsed && "collapsed",
          isMobile && this.props.isExpanded && "expanded"
        )}
      >
        <header style={{ marginBottom: 0 }}>
          <strong>Filter Badges</strong>
        </header>

        <FormControl>
          <div className="flexRow inputSearch">
            <span className="icon search" />
            <TextField
              variant="outlined"
              label="Search"
              value={search}
              onChange={(event) => {
                this.updateSearch(event)
              }}
            />
          </div>
        </FormControl>

        <header style={{ marginBottom: 0 }}>
          <strong>Sort Badges</strong>
        </header>

        <FormControl>
          <InputLabel style={{ marginLeft: -15 }}>Sort By</InputLabel>
          <Select
            value={sort ? sort : "dateEarned"}
            onChange={(event) => {
              this.updateSort(event, "sort")
            }}
          >
            <MenuItem value={"dateNewest"}>Date Earned (Newest First)</MenuItem>
            <MenuItem value={"dateOldest"}>Date Earned (Oldest First)</MenuItem>
            <MenuItem value={"name"}>Badge Name</MenuItem>
          </Select>
        </FormControl>
        <div className="toggle" onClick={this.toggleExpand}>
          <span className="icon" />
        </div>
      </form>
    )
  }
}

BadgeFiltersEarned.propTypes = {
  filters: PropTypes.object,
  isExpanded: PropTypes.bool,
  sidebar: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(BadgeFiltersEarned)
