import theme from "@mobilemind/common/src/theme/theme"

function LinkedIn() {
  return (
    <svg width="20px" height="20px" viewBox="0 0 20 20">
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="linkedin" fill={theme.palette.primary.main} fillRule="nonzero">
          <path
            d="M18.5575867,-4.26325641e-15 L1.44241332,-4.26325641e-15 C0.645751954,-4.26325641e-15 5.68434189e-15,0.645751954 5.68434189e-15,1.44241332 L5.68434189e-15,18.5575867 C5.68434189e-15,19.354248 0.645751954,20 1.44241332,20 L18.5575867,20 C19.354248,20 20,19.354248 20,18.5575867 L20,1.44241332 C20,0.645751954 19.354248,-4.26325641e-15 18.5575867,-4.26325641e-15 Z M7.09411622,15.1171875 L4.6586609,15.1171875 L4.6586609,7.79006958 L7.09411622,7.79006958 L7.09411622,15.1171875 Z M5.87646484,6.78955078 L5.8605957,6.78955078 C5.04333496,6.78955078 4.5147705,6.22695922 4.5147705,5.52383422 C4.5147705,4.80484008 5.0595093,4.2578125 5.89263914,4.2578125 C6.72576906,4.2578125 7.23846438,4.80484008 7.25433352,5.52383422 C7.25433352,6.22695922 6.72576906,6.78955078 5.87646484,6.78955078 Z M15.8758545,15.1171875 L13.4407043,15.1171875 L13.4407043,11.1973572 C13.4407043,10.2122498 13.0880738,9.54040528 12.2068787,9.54040528 C11.5341187,9.54040528 11.1334229,9.99359132 10.9573364,10.4310608 C10.8929443,10.587616 10.8772278,10.806427 10.8772278,11.0253906 L10.8772278,15.1171875 L8.44192504,15.1171875 C8.44192504,15.1171875 8.47381594,8.47747804 8.44192504,7.79006958 L10.8772278,7.79006958 L10.8772278,8.82751464 C11.2008667,8.32824708 11.7799377,7.61810304 13.072052,7.61810304 C14.6743774,7.61810304 15.8758545,8.66531372 15.8758545,10.9158325 L15.8758545,15.1171875 Z"
            id="Shape"
          ></path>
        </g>
      </g>
    </svg>
  )
}

export default LinkedIn
