import "../../styles/certificate.scss"
import moment from "moment"
import classNames from "classnames"

import badgeCertificateBgImg from "../../img/badge-certificate-bg.png"
import "../../styles/certificate.scss"
import theme from "@mobilemind/common/src/theme/theme"

function BadgeCertificate(props) {
  const { certificate, badge, user } = props
  let badgeImageUrl, teacherName, name, created

  if (certificate) {
    badgeImageUrl = certificate.attributes.field_image_url
    teacherName = certificate.attributes.field_teacher_name
    name = certificate.attributes.name
    created = certificate.attributes.field_earned
  } else if (badge) {
    badgeImageUrl = badge.field_badge_image_uri
    teacherName =
      user.attributes.field_first_name + " " + user.attributes.field_last_name
    name = badge.name
    created = badge.field_badge_earned_date
  }

  return (
    <div className={classNames("certificateWrapper", !certificate && "print")}>
      {badgeImageUrl && teacherName && name ? (
        <div className="certificateBadge">
          {window.location.href.includes("mobilemind.io") ? (
            <img
              className="bg-badge"
              src={badgeCertificateBgImg}
              alt="You earned a badge!"
            />
          ) : (
            <div
              style={{
                opacity: 0.2,
                backgroundImage:
                  "url(\"data:image/svg+xml,%3Csvg width='100' height='20' viewBox='0 0 100 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21.184 20c.357-.13.72-.264 1.088-.402l1.768-.661C33.64 15.347 39.647 14 50 14c10.271 0 15.362 1.222 24.629 4.928.955.383 1.869.74 2.75 1.072h6.225c-2.51-.73-5.139-1.691-8.233-2.928C65.888 13.278 60.562 12 50 12c-10.626 0-16.855 1.397-26.66 5.063l-1.767.662c-2.475.923-4.66 1.674-6.724 2.275h6.335zm0-20C13.258 2.892 8.077 4 0 4V2c5.744 0 9.951-.574 14.85-2h6.334zM77.38 0C85.239 2.966 90.502 4 100 4V2c-6.842 0-11.386-.542-16.396-2h-6.225zM0 14c8.44 0 13.718-1.21 22.272-4.402l1.768-.661C33.64 5.347 39.647 4 50 4c10.271 0 15.362 1.222 24.629 4.928C84.112 12.722 89.438 14 100 14v-2c-10.271 0-15.362-1.222-24.629-4.928C65.888 3.278 60.562 2 50 2 39.374 2 33.145 3.397 23.34 7.063l-1.767.662C13.223 10.84 8.163 12 0 12v2z' fill='" +
                  theme.palette.secondary.main.replace("#", "%23") +
                  "' fill-opacity='1' fill-rule='evenodd'/%3E%3C/svg%3E\")",
              }}
              className="bg-badge"
            />
          )}

          <h2>
            {teacherName}
            <strong>Earned a Badge!</strong>
          </h2>

          <div className="badgeImageWrapper">
            <span
              className="badgeImage"
              style={{
                backgroundImage:
                  "url(" + process.env.REACT_APP_API_URL + badgeImageUrl + ")",
              }}
            />
            <img
              className="badgeImage printBadge"
              src={badgeImageUrl}
              alt={name}
            />
            <span className="badgePlaceholder" />
          </div>
          <footer>
            <span className="title">{name}</span>
            <span className="timestamp">
              {moment(created).format("MMMM D, YYYY")}
            </span>
            <img
              className="logo"
              src={theme.mixins.images.logo}
              alt="MobileMind"
            />
          </footer>
        </div>
      ) : (
        <div className="loadingWrapper">
          <div className="icon loading" />
          <span>Generating certificate...</span>
        </div>
      )}
    </div>
  )
}

export default BadgeCertificate
