import { Component } from "react"
import { connect } from "react-redux"
import { Dialog, Button, TextField, FormControl } from "@mui/material"
import { Link } from "react-router-dom"
import classNames from "classnames"

import { getJobTitles, getIntroCourse } from "../actions"
import PropTypes from "prop-types"

import {
  endOnboarding,
  setOnboardingFields,
  getLearningPathTypes,
  getSingleAssessment,
} from "../actions"
import {
  getUserLearningPaths,
  getCourses,
} from "@mobilemind/common/src/actions/course"
import {
  updateProfile,
  updateUserPicture,
  getUser,
  createUserLearningPath,
} from "@mobilemind/common/src/actions/user"

import CourseCard from "./CourseCard"
import SanitizedHTML from "@mobilemind/common/src/components/SanitizedHTML"
import ProfilePicture from "../features/profile/ProfilePicture"

import "../styles/profile.scss"
import { isMMContentHidden } from "@mobilemind/common/src/functions"
import LearningBuddyMessage from "@mobilemind/common/src/components/LearningBuddyMessage"
import LearningBuddySelect from "../features/learningBuddy/LearningBuddySelect"
import Loading from "@mobilemind/common/src/components/Loading"
import theme from "@mobilemind/common/src/theme/theme"
import ButtonSmall from "./ButtonSmall"
import ButtonLarge from "@mobilemind/common/src/components/ButtonLarge"

const mapStateToProps = ({
  jobTitles,
  assessments,
  onboarding,
  courses,
  categories,
  session,
}) => {
  // Filter out excluded categories if we have a group
  let filteredCategories = categories.data.filter((category) => {
    return session.excludedCategories
      ? !session.excludedCategories.find(
          (cat) => cat.target_id === category.attributes.drupal_internal__tid
        )
      : category
  })

  let availableCategories = filteredCategories.filter((category) => {
    let learningPathCategories = [
      "Calendar",
      "Classroom",
      "Docs",
      "Forms",
      "Drive",
      "Slides",
      "Sheets",
    ]
    return learningPathCategories.includes(category.attributes.name)
  })

  let microsoftCategories = filteredCategories.filter((category) => {
    let msCats = ["OneNote", "Sway", "PowerPoint"]
    return msCats.includes(category.attributes.name)
  })

  availableCategories.push({ id: "meet", attributes: { name: "Meet" } })

  let parentSchool = jobTitles.data.find(
    (title) => title.attributes.name === "School"
  )
  let parentDistrict = jobTitles.data.find(
    (title) => title.attributes.name === "District"
  )
  let schoolRoles = jobTitles.data.filter(
    (title) => title.relationships.parent.data[0].id === parentSchool.id
  )
  let districtRoles = jobTitles.data.filter(
    (title) => title.relationships.parent.data[0].id === parentDistrict.id
  )

  return {
    onboarding,
    schoolRoles,
    districtRoles,
    microsoftCategories,
    jobTitles,
    courses,
    filteredCategories,
    assessments,
    categories,
    availableCategories,
    session,
  }
}

const mapDispatchToProps = {
  endOnboarding,
  getJobTitles,
  createUserLearningPath,
  getLearningPathTypes,
  getUserLearningPaths,
  getUser,
  updateUserPicture,
  getCourses,
  getIntroCourse,
  updateProfile,
  getSingleAssessment,
  setOnboardingFields,
}

function Onboarding(props) {
  return <OnboardingContent {...props} theme={theme} />
}

class OnboardingContent extends Component {
  state = {
    open: true,
    pageIndex: 0,
    jobType: "",
    jobRole: "",
    jobRoleOther: "",
    goal: "",
    skillLevel: "",
    hasAssessment: false,
    started: true,
    selectedBuddy: null,
    pathTypes: [],
  }

  componentDidMount = async () => {
    !this.props.jobTitles.fetched && this.props.getJobTitles()

    let pathTypes = await this.props.getLearningPathTypes()

    let orderedTypes = pathTypes.filter((type) => {
      return (
        !type.attributes.name.includes("Google") &&
        !type.attributes.name.includes("Microsoft") &&
        !type.attributes.name.includes("Other")
      )
    })

    orderedTypes.push(
      pathTypes.find((type) => type.attributes.name === "Other")
    )

    this.setState({ pathTypes: orderedTypes })

    let hasAssessment = await this.props.getSingleAssessment()
    if (hasAssessment.length) {
      this.setState({ hasAssessment: true, started: false })
    }

    this.props.getIntroCourse().then((response) => {
      this.setState({ introCourse: response })
    })

    this.setState({ assessmentFetched: true })
  }

  componentDidUpdate(prevProps) {
    const { session } = this.props

    if (this.state.pageIndex === 2) {
      if (prevProps.session.userPicture !== session.userPicture) {
        this.setState({ pictureUploaded: true })

        setTimeout(() => {
          this.setState({ pageIndex: 3, hasUploaded: true })
        }, 1500)
      }
    }
  }

  selectJobType = (jobType) => {
    this.setState({
      jobType: jobType,
      jobRole: "",
      goal: "",
      jobRoleOther: "",
      skillLevel: "",
    })
    setTimeout(() => {
      this.setState({ pageIndex: 1 })
    }, 500)
  }

  selectJobRole = (role) => {
    const { session } = this.props

    let roleLabel = this.props.jobTitles.data.find((title) => title.id === role)
      .attributes.name
    this.setState({
      jobRole: role,
      goal: "",
      jobRoleOther: "",
      skillLevel: "",
    })

    setTimeout(() => {
      if (!roleLabel.includes("Other")) {
        // Skip the profile pic step if they already have one
        const nextStep = session.userPicture ? 3 : 2
        this.setState({ pageIndex: nextStep })
      }
    }, 500)

    this.props.updateProfile(
      {
        name:
          session.user.attributes.field_first_name +
          " " +
          session.user.attributes.field_last_name,
        jobTitle: role,
      },
      session
    )
  }

  setJobRoleOther = (event) => {
    this.setState({ jobRoleOther: event.target.value })
  }

  submitJobRoleOther = () => {
    const { session } = this.props
    // Skip the profile pic step if they already have one
    const nextStep = session.userPicture ? 3 : 2
    this.setState({ pageIndex: nextStep })

    this.props.updateProfile(
      {
        name:
          session.user.attributes.field_first_name +
          " " +
          session.user.attributes.field_last_name,
        jobTitle: this.state.jobRole,
        jobTitleOther: this.state.jobRoleOther,
      },
      session
    )
  }

  selectGoal = (goal) => {
    this.setState({ goal, skillLevel: "" })
    this.props.setOnboardingFields("field_initial_goal", goal)

    setTimeout(() => {
      this.setState({ pageIndex: 5 })
    }, 500)
  }

  goBack = () => {
    if (this.state.pageIndex === 6) {
      this.setState({ pageIndex: this.state.pageIndex - 2 })
    } else if (this.state.pageIndex === 3 && this.props.session.userPicture) {
      this.setState({ pageIndex: 1 })
    } else {
      this.setState({ pageIndex: this.state.pageIndex - 1 })
    }
  }

  finish = async (skillLevel) => {
    this.setState({ skillLevel: skillLevel })

    let field_computer_comfort = "low"
    if (skillLevel === 2) {
      field_computer_comfort = "medium"
    }
    if (skillLevel === 3) {
      field_computer_comfort = "high"
    }

    await this.props.setOnboardingFields(
      "field_computer_comfort",
      field_computer_comfort
    )

    this.setState({ isLoading: true })

    setTimeout(() => {
      if (
        this.props.session.group &&
        this.props.session.group.type[0].target_id === "partner"
      ) {
        this.exit()
      } else {
        this.setState({ pageIndex: 6 })
      }
    }, 750)
  }

  exit = async () => {
    await this.props.getUser(
      this.props.session.user.attributes.drupal_internal__uid
    )
    this.props.endOnboarding()
    this.setState({ open: false })
  }

  selectBuddy = (selectedBuddy) => {
    this.props.setOnboardingFields("field_learning_buddy", selectedBuddy)
    setTimeout(() => {
      this.setState({ selectedBuddy })
      this.setState({ pageIndex: 4 })
    }, 500)
  }

  render() {
    const {
      pageIndex,
      pathTypes,
      jobType,
      jobRole,
      jobRoleOther,
      goal,
      isLoading,
    } = this.state
    const { session, theme } = this.props

    let roles =
      jobType === "school" ? this.props.schoolRoles : this.props.districtRoles
    let roleLabel =
      roles &&
      jobRole &&
      roles.find((role) => role.id === jobRole).attributes.name
    let goalLabel =
      goal &&
      pathTypes.find((type) => type.id === this.state.goal).attributes.name

    let isOpen = false

    if (this.state.open && this.state.assessmentFetched) {
      isOpen = true
    }

    return (
      <div className={classNames("page onboarding", !isOpen && "hide")}>
        <div
          style={{
            backgroundSize: "cover",
            backgroundImage: "url(" + theme.mixins.images.background + ")",
          }}
          className="onboarding-background"
        />

        <div
          style={{
            background:
              "linear-gradient(120deg, " +
              theme.palette.secondary.main +
              " 0%, " +
              theme.palette.primary.main +
              " 70%, " +
              theme.palette.primary.main +
              " 100%)",
          }}
          className="onboarding-gradient"
        />
        <Dialog
          open={isOpen}
          id="onboarding"
          data-onboarding-started={this.props.onboarding.started}
        >
          <div className="dialog" data-page={pageIndex}>
            {pageIndex <= 5 && (
              <header className="history">
                {pageIndex >= 1 && (
                  <div className="response">
                    <div className={classNames("onboarding-icon", jobType)} />
                    <div className="title">
                      {jobType === "school" ? (
                        <p>
                          School
                          <br />
                          Employee
                        </p>
                      ) : (
                        <p>
                          District
                          <br />
                          Employee
                        </p>
                      )}
                    </div>
                  </div>
                )}
                {pageIndex >= 2 && (
                  <div className="response">
                    <div
                      className={classNames(
                        "onboarding-icon",
                        roleLabel.toLowerCase()
                      )}
                    />
                    <div className="title">
                      {jobRoleOther ? jobRoleOther : roleLabel}
                    </div>
                  </div>
                )}
                {pageIndex >= 5 && (
                  <div className="response">
                    <div
                      className={classNames(
                        "onboarding-icon",
                        goalLabel.toLowerCase()
                      )}
                    />
                    <div className="title">
                      <SanitizedHTML html={goalLabel} />
                    </div>
                  </div>
                )}
              </header>
            )}

            {
              // School or District
              this.state.assessmentFetched && pageIndex === 0 && (
                <>
                  <img
                    alt={"Background"}
                    src={theme.mixins.images.logo}
                    style={{
                      width: 300,
                      marginBottom: 25,
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  />
                  <div style={{ marginTop: 25 }}>
                    <LearningBuddyMessage
                      message={
                        <>
                          <strong>
                            Welcome{this.state.hasAssessment && <> back</>}!{" "}
                          </strong>
                          <>
                            Before you get started, we'd like to ask you a
                            couple of questions to ensure we deliver the best
                            possible experience for you.
                          </>
                          <br />
                          <br />
                          <strong>
                            Are you a School or District Employee?
                          </strong>
                        </>
                      }
                    />

                    <form style={{ marginTop: 15 }}>
                      <div className="flexRow selectGrid">
                        <Button
                          style={{ animationDelay: 0.1 + "s" }}
                          className={classNames(
                            "button small grid",
                            jobType === "school" && "active"
                          )}
                          onClick={() => {
                            this.selectJobType("school")
                          }}
                        >
                          <div className={"onboarding-icon school"} />
                          <div className="title">
                            School <br />
                            Employee
                          </div>
                        </Button>
                        <Button
                          style={{ animationDelay: 0.1 + "s" }}
                          className={classNames(
                            "button small grid",
                            jobType === "district" && "active"
                          )}
                          onClick={() => {
                            this.selectJobType("district")
                          }}
                        >
                          <div className={"onboarding-icon district"} />
                          <div className="title">
                            District <br />
                            Employee
                          </div>
                        </Button>
                      </div>
                    </form>
                  </div>
                </>
              )
            }

            {
              // Describe your role
              pageIndex === 1 && (
                <>
                  <LearningBuddyMessage
                    message={
                      <>
                        <strong>Great!</strong> Which of these best describes
                        your role?
                      </>
                    }
                  />
                  <div className="flexRow selectGrid roles">
                    {roles.map((role, index) => {
                      if (role.attributes.name !== "School - Other") {
                        return (
                          <Button
                            key={role.id}
                            style={{ animationDelay: index * 0.1 + "s" }}
                            className={classNames(
                              "button small grid",
                              jobRole === role.id && "active"
                            )}
                            onClick={() => {
                              this.selectJobRole(role.id)
                            }}
                          >
                            <div
                              className={classNames(
                                "onboarding-icon",
                                role.attributes.name.toLowerCase()
                              )}
                            />
                            <div className="title">
                              <SanitizedHTML html={role.attributes.name} />
                            </div>
                          </Button>
                        )
                      }
                      return null
                    })}
                    {roles.map((role, index) => {
                      if (role.attributes.name === "School - Other") {
                        return (
                          <Button
                            key={role.id}
                            style={{ animationDelay: index * 0.1 + "s" }}
                            className={classNames(
                              "button small grid",
                              jobRole === role.id && "active"
                            )}
                            onClick={() => {
                              this.selectJobRole(role.id)
                            }}
                          >
                            <div
                              className={classNames(
                                "onboarding-icon",
                                role.attributes.name.toLowerCase()
                              )}
                            />
                            <div className="title">
                              <SanitizedHTML html={role.attributes.name} />
                            </div>
                          </Button>
                        )
                      }
                      return null
                    })}

                    {roleLabel.includes("Other") && (
                      <FormControl>
                        <TextField
                          variant="standard"
                          label="Please specify your job title"
                          value={jobRoleOther}
                          onChange={this.setJobRoleOther}
                        />
                      </FormControl>
                    )}
                  </div>

                  <footer className="actions pager">
                    <div className="cancel-button">
                      <ButtonLarge onClick={this.goBack}>Back</ButtonLarge>
                    </div>
                    {roleLabel.includes("Other") && jobRoleOther && (
                      <ButtonLarge onClick={this.submitJobRoleOther}>
                        Continue
                      </ButtonLarge>
                    )}
                  </footer>
                </>
              )
            }

            {
              // Profile Picture
              pageIndex === 2 && (
                <>
                  <div className="profilePictureOnboarding">
                    <ProfilePicture />
                  </div>

                  <LearningBuddyMessage
                    message={
                      <>
                        {this.state.pictureUploaded ? (
                          <strong>Nice to meet you!</strong>
                        ) : (
                          <>
                            <strong>Introduce yourself!</strong>
                            <br />
                            Upload a photo to use as your profile picture.
                          </>
                        )}
                      </>
                    }
                  />

                  <footer
                    className="actions"
                    style={{ justifyContent: "space-between" }}
                  >
                    <ButtonSmall onClick={this.goBack}>Back</ButtonSmall>
                    <Link to="/">
                      {!this.state.hasUploaded ? (
                        <>
                          {isMMContentHidden(session) ? (
                            <ButtonSmall onClick={this.exit} classes={"black"}>
                              Skip for Now
                            </ButtonSmall>
                          ) : (
                            <ButtonSmall
                              onClick={() => {
                                this.setState({ pageIndex: 3 })
                              }}
                              classes={"black"}
                            >
                              Skip for Now
                            </ButtonSmall>
                          )}
                        </>
                      ) : (
                        <ButtonSmall
                          onClick={() => {
                            this.setState({ pageIndex: 3 })
                          }}
                        >
                          Continue
                        </ButtonSmall>
                      )}
                    </Link>
                  </footer>
                </>
              )
            }

            {
              // Learning buddy
              pageIndex === 3 && (
                <>
                  <form>
                    <LearningBuddyMessage
                      message={
                        <>
                          By the way, I'll be your{" "}
                          <strong>Learning Buddy.</strong> Would you like to
                          select someone else? You can change your learning
                          buddy at any time.
                        </>
                      }
                    />

                    <div className="flexRow selectGrid learningBuddy">
                      <div style={{ marginTop: 30 }}>
                        <LearningBuddySelect
                          onSelect={(buddy) => {
                            this.selectBuddy(buddy)
                          }}
                        />
                      </div>
                    </div>
                  </form>
                  <footer className="actions pager">
                    <div className="cancel-button">
                      <ButtonLarge onClick={this.goBack}>Back</ButtonLarge>
                    </div>
                  </footer>
                </>
              )
            }

            {
              // What you're looking to do first
              pageIndex === 4 && (
                <>
                  <form>
                    <LearningBuddyMessage
                      selectedBuddy={this.state.selectedBuddy}
                      message={<>What are you looking to do first?</>}
                    />
                    <div className="flexRow selectGrid goals">
                      {pathTypes.map((type, index) => {
                        if (type) {
                          return (
                            <Button
                              key={index}
                              style={{ animationDelay: index * 0.1 + "s" }}
                              className={classNames(
                                "button small grid",
                                this.state.goal === type.id && "active"
                              )}
                              onClick={() => {
                                this.selectGoal(type.id)
                              }}
                            >
                              <div
                                className={classNames(
                                  "onboarding-icon",
                                  type.attributes.name.toLowerCase()
                                )}
                              />
                              <div className="title">
                                <SanitizedHTML html={type.attributes.name} />
                              </div>
                            </Button>
                          )
                        }
                        return null
                      })}
                    </div>
                  </form>
                  <footer className="actions pager">
                    <div className="cancel-button">
                      <ButtonLarge onClick={this.goBack}>Back</ButtonLarge>
                    </div>
                  </footer>
                </>
              )
            }

            {
              //Comfort
              pageIndex === 5 && (
                <>
                  <form>
                    <>
                      <LearningBuddyMessage
                        selectedBuddy={this.state.selectedBuddy}
                        message={
                          <>
                            How would you rate your level of comfort with a
                            computer?
                          </>
                        }
                      />
                      <div className="flexRow selectGrid">
                        <Button
                          style={{ animationDelay: 0 + "s" }}
                          className={classNames(
                            "button small grid",
                            this.state.skillLevel === 1 && "active"
                          )}
                          onClick={() => {
                            this.finish(1)
                          }}
                        >
                          <div
                            className={classNames("onboarding-icon skill none")}
                          />
                          <div className="title">Low</div>
                        </Button>

                        <Button
                          style={{ animationDelay: 0.1 + "s" }}
                          className={classNames(
                            "button small grid",
                            this.state.skillLevel === 2 && "active"
                          )}
                          onClick={() => {
                            this.finish(2)
                          }}
                        >
                          <div
                            className={classNames("onboarding-icon skill some")}
                          />
                          <div className="title">Medium</div>
                        </Button>

                        <Button
                          style={{ animationDelay: 0.2 + "s" }}
                          className={classNames(
                            "button small grid",
                            this.state.skillLevel === 3 && "active"
                          )}
                          onClick={() => {
                            this.finish(3)
                          }}
                        >
                          <div
                            className={classNames("onboarding-icon skill alot")}
                          />
                          <div className="title">High</div>
                        </Button>
                      </div>
                    </>
                    {isLoading && (
                      <Loading color={theme.palette.secondary.main} />
                    )}
                  </form>
                  {!isLoading && (
                    <footer className="actions pager">
                      <div className="cancel-button">
                        <ButtonLarge onClick={this.goBack}>Back</ButtonLarge>
                      </div>
                    </footer>
                  )}
                </>
              )
            }

            {
              // Course recommendation
              pageIndex === 6 && (
                <>
                  <LearningBuddyMessage
                    selectedBuddy={this.state.selectedBuddy}
                    message={
                      <>
                        <strong>Let's get started!</strong> Kick off your
                        journey with this introductory course.
                      </>
                    }
                  />
                  <div className="suggestedCourseWrapper">
                    <div
                      style={{ display: "flex", justifyContent: "center" }}
                      onClick={this.exit}
                    >
                      <CourseCard course={this.state.introCourse} />
                    </div>
                    <footer
                      className="actions course"
                      style={{ justifyContent: "center" }}
                    >
                      <Link to="/">
                        <div className="cancel-button">
                          <ButtonLarge onClick={this.exit}>
                            No Thanks, I'll Explore on My Own
                          </ButtonLarge>
                        </div>
                      </Link>
                    </footer>
                  </div>
                </>
              )
            }
          </div>
        </Dialog>
      </div>
    )
  }
}

Onboarding.propTypes = {
  assessments: PropTypes.object,
  availableCategories: PropTypes.array,
  categories: PropTypes.object,
  courses: PropTypes.object,
  districtRoles: PropTypes.array,
  goals: PropTypes.array,
  jobTitles: PropTypes.object,
  learningPaths: PropTypes.array,
  onboarding: PropTypes.object,
  schoolRoles: PropTypes.array,
  session: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(Onboarding)
