import { Component } from "react"
import { connect } from "react-redux"
import "../styles/certificate.scss"
import { getCertificate } from "../actions"
import BadgeCertificate from "../features/badges/BadgeCertificate"
import LearningPathCertificate from "../features/learningPaths/LearningPathCertificate"
import theme from "@mobilemind/common/src/theme/theme"
import { withRouter } from "react-router-dom"
import EventCertificate from "@mobilemind/common/src/components/backpack/certificate/EventCertificate"

const mapStateToProps = ({ session }, ownProps) => {
  return {
    certificateId: ownProps.match.params.certificateId,
    type: ownProps.match.params.type,
    session,
    certificates: session.certificates.data,
  }
}

const mapDispatchToProps = {
  getCertificate,
}

class CertificateShareLayout extends Component {
  async componentDidMount() {
    const { certificateId, type } = this.props
    this.props.getCertificate(certificateId, type.replace("-", "_"))
  }

  render() {
    const props = {
      certificate: this.props.certificates[0],
      user: this.props.session.user,
      theme,
    }

    return (
      <>
        {this.props.type === "event" && this.props.certificates && (
          <EventCertificate {...props} />
        )}
        {this.props.type === "badge" && this.props.certificates && (
          <BadgeCertificate {...props} />
        )}
        {this.props.type === "learning-path" && this.props.certificates && (
          <LearningPathCertificate {...props} />
        )}
      </>
    )
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CertificateShareLayout)
)
