function SlideGoGetter(props) {
  const { rewind } = props

  const coursesCompleted = new Array(Number(rewind.goGetter.first_month))

  return (
    <div className="slide goGetter">
      <div className="background-tile rocket-bg" />
      <div className="part first">
        <p className="textLarge">
          You were a real go-getter from the very start!
        </p>
      </div>

      <div className="secondWrapper">
        <div className="part second">
          <p className="textLarge">
            You completed {rewind.goGetter.first_month} courses in your first
            month.
          </p>

          <div className="checks">
            {Array.from(coursesCompleted.keys()).map((check, index) => {
              return <span key={index} className="icon check" />
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SlideGoGetter
