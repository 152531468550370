import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import fetchWrapper from "@mobilemind/common/src/functions/fetchWrapper"
import qs from "qs"

export const getLocations = createAsyncThunk(
  "locationsSlice/getLocations",
  async (args, thunkAPI) => {
    let query = {
      filter: {
        "field_organization.id":
          thunkAPI.getState().session.group.uuid[0].value,
      },
    }

    let response = await fetchWrapper.get(
      "/api/mobile_mind_location/location_base?" + qs.stringify(query)
    )
    if (response.ok) {
      let data = await response.json()
      data.data.forEach((location) => (location.rooms = []))
      return data
    }
  }
)

export const locationsSlice = createSlice({
  name: "locationsSlice",
  initialState: {
    data: [],
    fetched: false,
  },
  extraReducers: {
    [getLocations.pending]: (state) => {
      state.fetched = false
    },
    [getLocations.fulfilled]: (state, action) => {
      state.data = action.payload.data
      state.fetched = true
    },
  },
})

export default locationsSlice.reducer
