import { Dialog, Button } from "@mui/material"
import "../styles/index.scss"

function BrowserModal() {
  return (
    <Dialog open={true} className="extensionModal">
      <div className="dialog">
        <h2>Whoops!</h2>
        <div className="flexRow">
          <p>
            It looks like you aren't using either <strong>Google Chrome</strong>{" "}
            or <strong>Microsoft Edge</strong>, so you won't be able to start or
            resume any micro-course challenges.
          </p>
        </div>
        <footer style={{ justifyContent: "center" }}>
          <a
            href={"https://www.google.com/chrome/"}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button className="button large" style={{ marginLeft: 0 }}>
              <span className="icon chrome-logo" />
              Download Chrome
            </Button>
          </a>
          <a
            href={"https://www.microsoft.com/en-us/edge"}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button className="button large">
              <span className="icon edge-logo" />
              Download Edge
            </Button>
          </a>
        </footer>
      </div>
    </Dialog>
  )
}

export default BrowserModal
