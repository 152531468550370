import Sidebar from "../../components/Sidebar"
import { Button } from "@mui/material"
import BackArrow from "../../icons/BackArrow"
import { LearningBuddyStatus } from "./LearningBuddyStatus"
import theme from "@mobilemind/common/src/theme/theme"
import moment from "moment"

import { convertMinutesToHoursMinutes } from "@mobilemind/common/src/functions"
import TimeCapsule from "@mobilemind/common/src/components/TimeCapsule"
import Rating from "@mobilemind/common/src/components/Rating"

export function CourseSidebar({
  activeCourse,
  categories,
  assessment,
  mailToString,
  courseBadges,
  relatedCourses,
  classNames,
  bummer,
  assessmentFetched,
  encouragement,
  congrats,
  outOfComplianceTraining,
  canRetakeOutOfCompliance,
  previousAssessments,
  goBack,
}) {
  if (activeCourse) {
    // Get category and skill level information
    let category = categories.data.find(
      (category) =>
        activeCourse.relationships.field_category.data.id === category.id
    )

    const parent =
      category &&
      categories.data.find(
        (cat) => cat.id === category.relationships.parent.data[0].id
      )
    const parentParent =
      parent &&
      categories.data.find(
        (cat) => cat.id === parent.relationships.parent.data[0].id
      )

    let categoryLabel = category && category.attributes.name.toLowerCase()
    let courseLevel = activeCourse.attributes.field_level.toLowerCase()

    return (
      <Sidebar
        theme={theme}
        currentPage="course"
        content={
          <div className="entity-meta course">
            <Button
              style={{ backgroundColor: "white" }}
              className="backButton"
              onClick={goBack}
            >
              <BackArrow />
              <span
                style={{ marginLeft: 10, color: theme.palette.primary.main }}
                className="buttonText"
              >
                Back
              </span>
            </Button>
            {category && (
              <span
                className="category-icon large"
                style={{
                  backgroundImage:
                    "url(" +
                    process.env.REACT_APP_API_URL +
                    category.image.attributes.uri.url +
                    ")",
                }}
              />
            )}
            <h3 style={{ backgroundColor: theme.palette.secondary.main }}>
              Micro-Course
            </h3>

            {assessment &&
              assessmentFetched &&
              assessment.attributes &&
              assessment.attributes.field_status !== "saved_for_later" && (
                <LearningBuddyStatus
                  outOfComplianceTraining={outOfComplianceTraining}
                  canRetakeOutOfCompliance={canRetakeOutOfCompliance}
                  mailToString={mailToString}
                  assessment={assessment}
                  activeCourse={activeCourse}
                  bummer={bummer}
                  encouragement={encouragement}
                  congrats={congrats}
                />
              )}

            {category && (
              <section>
                <header>Category</header>
                <div
                  style={{ textTransform: "capitalize" }}
                  className="flexRow"
                >
                  <div className="category-icon-wrapper">
                    <span
                      className="category-icon"
                      style={{
                        backgroundImage:
                          "url(" +
                          process.env.REACT_APP_API_URL +
                          category.image.attributes.uri.url +
                          ")",
                      }}
                    />
                  </div>
                  {categoryLabel}
                </div>

                <div className="category-breadcrumbs">
                  {parentParent && (
                    <span className="parent">
                      {parentParent.attributes.name}
                    </span>
                  )}
                  {parentParent && parent && <span className="caret" />}
                  {parent && (
                    <span className="parent">{parent.attributes.name}</span>
                  )}
                </div>
              </section>
            )}

            <section>
              <header>Skill Level</header>
              <div className="course-level">
                <Rating level={courseLevel} />
              </div>
            </section>

            {courseBadges && courseBadges.length > 0 && (
              <section>
                <header>Badge Opportunities</header>
                <ul>
                  {courseBadges.map((badge) => {
                    return (
                      <li key={badge.id}>
                        <span className="icon badge" />
                        <span className="itemLabel">{badge.name}</span>
                      </li>
                    )
                  })}
                </ul>
              </section>
            )}

            {activeCourse.attributes.field_course_estimated_time > 0 && (
              <section>
                <header>Estimated Time</header>
                <div className="flexRow course-time">
                  <TimeCapsule
                    hours={
                      convertMinutesToHoursMinutes(
                        activeCourse.attributes.field_course_estimated_time
                      ).hours
                    }
                    minutes={
                      convertMinutesToHoursMinutes(
                        activeCourse.attributes.field_course_estimated_time
                      ).minutes
                    }
                  />
                </div>
                <span className="disclaimer">
                  Time may count towards PD credit, depending on district
                  policies.
                </span>
              </section>
            )}

            {relatedCourses.length > 0 && (
              <section>
                <header>Related Courses</header>
                <ul className="related-courses">
                  {relatedCourses.map((course) => {
                    if (course) {
                      const courseCategory = categories.data.find(
                        (cat) =>
                          cat.id === course &&
                          course.relationships.field_category.data.id
                      )

                      let coursePath =
                        course &&
                        "/course/" + course.attributes.drupal_internal__id
                      return (
                        <li key={course.id}>
                          <a
                            href={
                              process.env.REACT_APP_TEACHER_URL + coursePath
                            }
                          >
                            <div className="category-icon-wrapper">
                              <span
                                style={{
                                  backgroundImage:
                                    courseCategory && courseCategory.image
                                      ? "url(" +
                                        process.env.REACT_APP_API_URL +
                                        courseCategory.image.attributes.uri
                                          .url +
                                        ")"
                                      : "none",
                                }}
                                className={classNames("category-icon")}
                              ></span>
                            </div>
                            <div className="title">
                              {course.attributes.name}
                            </div>
                          </a>
                        </li>
                      )
                    }
                    return null
                  })}
                </ul>
              </section>
            )}
            {activeCourse.tags && activeCourse.tags.length > 0 && (
              <div
                className="flexRow"
                style={{ padding: 10, flexWrap: "wrap" }}
              >
                {activeCourse.tags.map((tag) => {
                  return (
                    <div
                      style={{ pointerEvents: "none" }}
                      className="tag-wrapper"
                    >
                      {tag.attributes.name}
                    </div>
                  )
                })}
              </div>
            )}

            {activeCourse.sourceTemplate && (
              <section>
                <header>Community Contribution</header>
                <div className="flexRow created-by">
                  <span className="icon community" />
                  {activeCourse.sourceTemplate.attributes
                    .field_credit_organization ? (
                    <>{activeCourse.attributes.field_creator_org_name}</>
                  ) : (
                    <>Community Contributor</>
                  )}
                </div>
              </section>
            )}

            {previousAssessments.length > 0 && (
              <section>
                <header>Previously Completed</header>
                <ul>
                  {previousAssessments.map((assessment) => {
                    return (
                      <li
                        style={{
                          marginBottom: 15,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <span
                          className="icon check"
                          style={{
                            width: 20,
                            height: 20,
                            marginRight: 8,
                          }}
                        />
                        <strong>
                          {moment(
                            assessment.attributes.field_completed_date
                          ).format("MMMM Do, YYYY")}
                        </strong>
                      </li>
                    )
                  })}
                </ul>
              </section>
            )}
          </div>
        }
      />
    )
  }
  return null
}
