import { Card } from "@mui/material"
import SanitizedHTML from "@mobilemind/common/src/components/SanitizedHTML"

function WidgetQuickLinks(props) {
  const { quickLinks } = props.widget
  let label = "Quick Links"

  return (
    <Card
      className="widget announcement quickLinks"
      style={{ animationDelay: props.animationDelay }}
    >
      <div className="widgetLabel">{label}</div>
      <div className="widgetIcon icon quick-links" />
      <SanitizedHTML html={quickLinks.attributes.body.value} />
    </Card>
  )
}

export default WidgetQuickLinks
