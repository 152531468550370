import { Component } from "react"
import { Card, Button } from "@mui/material"

class WidgetMobileDownload extends Component {
  render() {
    const { collection } = this.props
    let shouldReturn = !collection

    let appName = "MobileMind"
    let playStoreLink =
      "https://play.google.com/store/apps/details?id=com.mobilemind&hl=en_US&gl=US"
    let appStoreLink = "https://apps.apple.com/us/app/mobilemind/id1612844956"

    // Add this back in when collections have an android app
    // if (
    //   collection &&
    //   collection.label[0] &&
    //   collection.field_chrome_extension_link[0] &&
    //   collection.field_edge_extension_link[0]
    // ) {
    //   appName = collection.label[0].value

    //   appStoreLink =
    //     collection.field_ios_app_link[0] &&
    //     collection.field_ios_app_link[0].value
    //   shouldReturn = appStoreLink
    // }

    if (shouldReturn) {
      return (
        <Card className="widget mobile-reminder">
          <div
            className="widgetLabel"
            style={{
              left: 20,
              backgroundColor: this.props.theme.palette.secondary.main,
              color: "black",
            }}
          >
            Get the App
          </div>
          <img
            className="mobileImg"
            alt="Download the MobileMind App"
            src={require("../../img/mobile-announcement.jpg")}
          />
          <p>
            {appName} has a mobile app that allows you to{" "}
            <strong>
              complete courses, earn badges, check into events, and so much
              more!
            </strong>
          </p>
          <p>
            Download it on any mobile device from either the iOS App Store or
            Google Play.
          </p>

          <div className="flexRow">
            <a style={{ flex: 1 }} href={appStoreLink}>
              <Button className="button large">
                <span className="icon app-store" />
                App Store
              </Button>
            </a>
            {!collection && (
              <a style={{ flex: 1 }} href={playStoreLink}>
                <Button className="button large">
                  <span className="icon play-store" />
                  Google Play
                </Button>
              </a>
            )}
          </div>
        </Card>
      )
    }

    return null
  }
}

export default WidgetMobileDownload
