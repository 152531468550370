import classNames from "classnames"
import pluralize from "pluralize"
import { useEffect, useState } from "react"
import { isTablet } from "react-device-detect"

function SlideCategoryResults(props) {
  const { topCategories, allCategories } = props.rewind

  const deg = 360 / allCategories.length
  const topCategory = topCategories[0]

  const [timeOut, setTimeOut] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setTimeOut(true)
    }, 8000)
  }, [setTimeOut])

  return (
    <div className="slide categoryResults">
      <div className="background-tile study-bg"></div>

      <div className="part first">
        <div className="textWrapper">
          <p className="textLarge">
            You dove right into various topics to better yourself!
          </p>
        </div>
        <div className={classNames("message", isTablet && "tablet")}>
          <p>Your favorite category this school year was...</p>
        </div>

        <div className="categoryWheel">
          <div className="rotateWrapper">
            <div className="favorite">
              <span
                className="favoriteImage"
                style={{
                  backgroundImage:
                    "url(" +
                    process.env.REACT_APP_API_URL +
                    topCategory.image +
                    ")",
                }}
              />
              <div className="favoriteStats">
                <strong>{topCategory.name}</strong>
              </div>
            </div>

            <div className="hub">
              {allCategories.map((category, index) => {
                let extend1 = index % 2 === 0
                return (
                  <div
                    key={index}
                    className={classNames("spokeWrapper", extend1 && "extend1")}
                    style={{ transform: "rotate(" + index * deg + "deg)" }}
                  >
                    <div className="spoke">
                      <span
                        className="categoryImage"
                        style={{
                          backgroundImage:
                            "url(" +
                            process.env.REACT_APP_API_URL +
                            category.image +
                            ")",
                        }}
                      />
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
      {timeOut && (
        <div className="part second">
          <p className="textLarge">Your top categories were:</p>
          <ol className="topFive">
            {topCategories.map((category, index) => {
              return (
                <li
                  key={index}
                  style={{ animationDelay: 2 + index * 0.2 + "s" }}
                >
                  <span
                    className="categoryImage"
                    style={{
                      backgroundImage:
                        "url(" +
                        process.env.REACT_APP_API_URL +
                        category.image +
                        ")",
                    }}
                  />
                  <strong>{category.name}</strong>
                  <span className="total">
                    <strong>{category.count}</strong>
                    {pluralize("Course", Number(category.count))}
                  </span>
                </li>
              )
            })}
          </ol>
        </div>
      )}
    </div>
  )
}

export default SlideCategoryResults
