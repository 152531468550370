function SlidePerfection(props) {
  const { rewind } = props

  return (
    <div className="slide perfection">
      <div className="background-tile check-bg" />
      <div className="part first">
        <div className="bounce">
          <div className="shotWrapper">
            <div className="basketball" />
            <div className="check"></div>
          </div>
        </div>

        <div className="hoopWrapper">
          <div className="backboard" />
          <div className="hoop" />
        </div>
      </div>

      <p className="textLarge">
        You strive for perfection!
        <br />
        You completed {rewind.firstAttemptCount} courses in a single attempt.
      </p>
    </div>
  )
}

export default SlidePerfection
