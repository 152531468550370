import { Component } from "react"
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import PropTypes from "prop-types"

import theme from "@mobilemind/common/src/theme/theme"
import classNames from "classnames"
import { Grid, Card, CardContent, Button } from "@mui/material"
import Rating from "@mobilemind/common/src/components/Rating"

import { generateTimestamp } from "@mobilemind/common/src/functions/index"

import { getActiveUserLearningPath } from "../functions"
import {
  updateAssessment,
  getUserLearningPaths,
  getCourse,
} from "@mobilemind/common/src/actions/course"
import { sendMessage, deleteAssessment } from "../actions"

import trashImg from "../img/trash.svg"
import ButtonSmall from "../components/ButtonSmall"

const mapStateToProps = ({ session, extension }) => {
  // Get the most recently updated User Learning Paths that's still in progress
  let activeUserLearningPath =
    session.userLearningPaths &&
    getActiveUserLearningPath(session.userLearningPaths)

  return {
    activeUserLearningPath,
    session,
    extension,
  }
}

const mapDispatchToProps = {
  updateAssessment,
  deleteAssessment,
  sendMessage,
  getUserLearningPaths,
  getCourse,
}
class CourseCard extends Component {
  state = {
    promptRemove: false,
  }

  remove = (id) => {
    this.setState({ removed: true })

    if (this.props.status === "completed") {
      this.props.deleteAssessment(id, this.props.session)
    } else {
      this.props.removeAssessment(id)
    }
  }

  render() {
    let { course, currentPage, status, badge } = this.props
    let { promptRemove, removed } = this.state

    let coursePath = "/course/" + course.id
    let categoryLabel =
      course.field_category_name && course.field_category_name.toLowerCase()
    let level = course.field_level_value
    let isCompleted = status === "completed"
    let isInProgress = status === "in_progress"
    let isSaved = status === "saved_for_later"
    let isSubmitted = status === "grading_in_progress" || status === "submitted"
    let feedback = course.field_user_assessment_comment

    let isRemovable = !feedback && isInProgress
    let updated = generateTimestamp(course.field_user_assessment_last_updated)

    if (isCompleted) {
      updated = generateTimestamp(course.field_user_assessment_completed_date)
    }

    if (feedback) {
      if (feedback.length > 190) {
        feedback = feedback.substring(0, 190) + "..."
      }
    }

    const isSales = this.props.session.roles.find(
      (role) => role.target_id === "mm_sales"
    )

    return (
      <>
        {!removed && (
          <Grid item xs={3}>
            <Link to={coursePath}>
              <Card
                variant={isCompleted ? "outlined" : "elevation"}
                className={classNames(
                  "card small",
                  { isSaved: isSaved },
                  { notStarted: !status },
                  { hasFeedback: feedback && !isSubmitted },
                  { inProgress: isInProgress },
                  { isCompleted: isCompleted }
                )}
                style={{ animationDelay: this.props.animationDelay }}
              >
                <div
                  className="card-image"
                  style={{
                    backgroundColor: course.field_hex_color_value
                      ? course.field_hex_color_value
                      : theme.palette.primary.main,
                  }}
                  data-bg={categoryLabel}
                />
                <CardContent>
                  <header>
                    <div className="category-icon-wrapper">
                      <span
                        className={classNames(
                          "category-icon",
                          !course.field_category_image_uri && "other"
                        )}
                        style={{
                          backgroundImage:
                            "url(" + course.field_category_image_uri + ")",
                        }}
                      />
                    </div>
                    <div className="course-label">
                      <span className="category-label">{categoryLabel}</span>
                      {level && (
                        <div className="course-level">
                          <Rating level={level.toLowerCase()} />
                        </div>
                      )}
                    </div>
                  </header>
                  <h2>{course.name}</h2>
                </CardContent>

                {!status && (
                  <footer className="course-details">
                    <p>{course.field_course_objective_value}</p>
                    <footer>
                      {badge && <span className="icon badge"></span>}
                    </footer>
                  </footer>
                )}
                {isInProgress && (
                  <footer
                    className={classNames("timestamp in-progress", {
                      prompt: promptRemove,
                    })}
                  >
                    {feedback && !isSubmitted && (
                      <div className="timestamp-inner">
                        <strong>Feedback {updated}</strong>:
                        <p className="feedback">
                          <em>"{feedback}"</em>
                        </p>
                      </div>
                    )}
                    {!feedback && !isSubmitted && (
                      <div className="timestamp-inner">Started {updated}</div>
                    )}
                  </footer>
                )}
                {isSaved && (
                  <footer className={classNames("timestamp saved")}>
                    {this.state.isStartingFromSave ? (
                      <>Starting Challenge...</>
                    ) : (
                      <div className="timestamp-inner">Saved {updated}</div>
                    )}
                  </footer>
                )}
                {isSubmitted && (
                  <footer className="timestamp in-progress">
                    Submitted {updated}
                  </footer>
                )}
                {isCompleted && (
                  <footer className="timestamp">
                    <div className="flexRow">
                      <span className="icon check"></span>Completed {updated}
                    </div>
                    {feedback && (
                      <p className="feedback">
                        <em>"{feedback}"</em>
                      </p>
                    )}
                  </footer>
                )}
              </Card>
            </Link>

            {currentPage !== "explore" && !this.state.isStartingFromSave && (
              <div
                className={classNames(
                  "actions",
                  { removable: isRemovable },
                  { prompt: promptRemove }
                )}
              >
                {isSaved && (
                  <ButtonSmall
                    onClick={() => {
                      this.remove(this.props.assessmentId)
                    }}
                    className="button small unsave"
                  >
                    <span className="icon pin" />
                    Unsave
                  </ButtonSmall>
                )}
                {isRemovable && !promptRemove && (
                  <Button
                    style={{
                      position: !this.props.extension.version && "absolute",
                      right: !this.props.extension.version && 0,
                      top: !this.props.extension.version && -32,
                    }}
                    onClick={() => this.setState({ promptRemove: true })}
                    className="button small remove"
                  >
                    <img src={trashImg} alt="remove" />
                  </Button>
                )}

                {promptRemove && (
                  <>
                    {(!isSales || isInProgress) && (
                      <p>Are you sure you want to restart this challenge?</p>
                    )}
                    <div className="flexRow">
                      <Button
                        onClick={() => {
                          this.setState({ promptRemove: false })
                        }}
                        className="button small"
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={() => {
                          this.remove(this.props.assessmentId)
                        }}
                        className="button small"
                      >
                        Remove
                      </Button>
                    </div>
                  </>
                )}
              </div>
            )}

            {isSales && !isInProgress && !promptRemove && (
              <Button
                onClick={() => this.setState({ promptRemove: true })}
                className="button small remove fixed"
              >
                <img src={trashImg} alt="remove" />
              </Button>
            )}
          </Grid>
        )}
      </>
    )
  }
}

CourseCard.propTypes = {
  activeUserLearningPath: PropTypes.object,
  animationDelay: PropTypes.string,
  category: PropTypes.object,
  course: PropTypes.object,
  currentPage: PropTypes.string,
  extension: PropTypes.object,
  learningPaths: PropTypes.object,
  session: PropTypes.object,
  status: PropTypes.string,
}

export default connect(mapStateToProps, mapDispatchToProps)(CourseCard)
