import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import fetchWrapper from "@mobilemind/common/src/functions/fetchWrapper"

export const getBuddies = createAsyncThunk(
  "learningBuddiesSlice/getBuddies",
  async () => {
    let response = await fetchWrapper.get("/api/taxonomy_term/learning_buddy")
    const data = await response.json()

    // Bring in level 1 buddies and up
    const allBuddies = data.data
    // In the future we can use thunkAPI.getState().session.user to see the user's level and restrict it to under that
    // let filteredBuddies = data.data.filter(buddy => buddy.attributes.field_level)

    return allBuddies
  }
)

type InitialState = {
  data: []
  fetched: boolean
}

const initialState: InitialState = {
  data: [],
  fetched: false,
}

export const learningBuddiesSlice = createSlice({
  name: "learningBuddies",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Waiting to get buddies
    builder.addCase(getBuddies.pending, (state) => {
      state.fetched = false
    })
    // Buddies sucessfully fetched
    builder.addCase(getBuddies.fulfilled, (state, action) => {
      state.data = action.payload
      state.fetched = true
    })
  },
})

export default learningBuddiesSlice.reducer
