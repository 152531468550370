import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import fetchWrapper from "@mobilemind/common/src/functions/fetchWrapper"

export const getUserName = createAsyncThunk(
  "rewindSlice/getUserName",
  async (userId) => {
    let response = await fetchWrapper.get(
      "/api/rewind/" + userId + "?type=user-name"
    )
    let data = await response.json()
    return data.first_name
  }
)

export const getTimeSpent = createAsyncThunk(
  "rewindSlice/getTimeSpent",
  async (userId) => {
    let response = await fetchWrapper.get(
      "/api/rewind/" + userId + "?type=time-spent"
    )
    let data = await response.json()
    return Number(data.time_spent[0].time_spent)
  }
)

export const getTopCategories = createAsyncThunk(
  "rewindSlice/getTopCategories",
  async (userId) => {
    let response = await fetchWrapper.get(
      "/api/rewind/" + userId + "?type=category"
    )
    let data = await response.json()
    return data
  }
)

export const getAllCategories = createAsyncThunk(
  "rewindSlice/getAllCategories",
  async (userId) => {
    let response = await fetchWrapper.get(
      "/api/rewind/" + userId + "?type=all-categories"
    )
    let data = await response.json()
    return data
  }
)

export const getAchievements = createAsyncThunk(
  "rewindSlice/getAchievements",
  async (userId) => {
    let response = await fetchWrapper.get(
      "/api/rewind/" + userId + "?type=achievement"
    )
    let data = await response.json()
    return data
  }
)

export const getGoGetter = createAsyncThunk(
  "rewindSlice/getGoGetter",
  async (userId) => {
    let response = await fetchWrapper.get(
      "/api/rewind/" + userId + "?type=go-getter"
    )
    let data = await response.json()
    return data
  }
)

export const getDedication = createAsyncThunk(
  "rewindSlice/getDedication",
  async (userId) => {
    let response = await fetchWrapper.get(
      "/api/rewind/" + userId + "?type=dedication"
    )
    let data = await response.json()
    return data
  }
)

export const getPerfect = createAsyncThunk(
  "rewindSlice/getPerfect",
  async (userId) => {
    let response = await fetchWrapper.get(
      "/api/rewind/" + userId + "?type=perfect"
    )
    let data = await response.json()
    return data
  }
)

export const rewindSlice = createSlice({
  name: "rewindSlice",
  initialState: {},
  extraReducers: {
    [getUserName.fulfilled]: (state, action) => {
      state.firstName = action.payload
    },
    [getTimeSpent.fulfilled]: (state, action) => {
      state.timeSpent = action.payload
    },
    [getTopCategories.fulfilled]: (state, action) => {
      state.topCategories = action.payload.categories
    },
    [getAchievements.fulfilled]: (state, action) => {
      state.achievements = action.payload.achievement
      if (Number(action.payload.completed_courses) < 7) {
        state.needsEncouragement = true
      }
    },
    [getAllCategories.fulfilled]: (state, action) => {
      state.allCategories = action.payload
    },
    [getGoGetter.fulfilled]: (state, action) => {
      state.goGetter = action.payload
    },
    [getDedication.fulfilled]: (state, action) => {
      let { course_months } = action.payload

      let monthCounts = [
        Number(course_months.January),
        Number(course_months.February),
        Number(course_months.March),
        Number(course_months.April),
        Number(course_months.May),
        Number(course_months.June),
        Number(course_months.July),
        Number(course_months.August),
        Number(course_months.September),
        Number(course_months.October),
        Number(course_months.November),
        Number(course_months.December),
      ]

      let consecutiveCount = 0
      let maxCount = 0

      monthCounts.forEach((coursesCompleted) => {
        coursesCompleted > 0 ? consecutiveCount++ : (consecutiveCount = 0)
        if (consecutiveCount > maxCount) {
          maxCount = consecutiveCount
        }
      })

      state.consecutiveMonths = maxCount

      let months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ]
      let seasons = monthCounts.map((count, index) => {
        return { month: months[index], count }
      })

      let highest = Math.max(...monthCounts)
      let highestMonth = seasons.find(
        (month) => Number(month.count) === highest
      ).month

      let learningSeason
      if (
        highestMonth === "December" ||
        highestMonth === "January" ||
        highestMonth === "February"
      ) {
        learningSeason = "Winter"
      }
      if (
        highestMonth === "March" ||
        highestMonth === "April" ||
        highestMonth === "May"
      ) {
        learningSeason = "Spring"
      }
      if (
        highestMonth === "June" ||
        highestMonth === "July" ||
        highestMonth === "August"
      ) {
        learningSeason = "Summer"
      }
      if (
        highestMonth === "September" ||
        highestMonth === "October" ||
        highestMonth === "November"
      ) {
        learningSeason = "Fall"
      }

      state.learningSeason = learningSeason
    },
    [getPerfect.fulfilled]: (state, action) => {
      state.firstAttemptCount = action.payload.first_attempt
    },
  },
})

export default rewindSlice.reducer
