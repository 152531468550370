import { useEffect, useState } from "react"
import { setFeedbackFormResponse } from "./activeEventSlice"

import {
  getUserFeedbackForm,
  createUserFeedbackForm,
} from "@mobilemind/common/src/actions/events"

import { TextField, Card, Box } from "@mui/material"
import ButtonLarge from "@mobilemind/common/src/components/ButtonLarge"
import theme from "@mobilemind/common/src/theme/theme"

import LearningBuddyMessage from "@mobilemind/common/src/components/LearningBuddyMessage"
import { useDispatch } from "react-redux"
import Loading from "@mobilemind/common/src/components/Loading"
import moment from "moment"
import { generateTimestamp } from "@mobilemind/common/src/functions"

function EventFeedback(props) {
  const { eventTypeLabel, activeEvent } = props

  const [hasCompleted, setHasCompleted] = useState(false)
  const [userFormFetched, setUserFormFetched] = useState(false)
  const [userForm, setUserForm] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!userFormFetched || hasCompleted) {
      dispatch(
        getUserFeedbackForm({
          id: activeEvent.id,
        })
      ).then((response) => {
        setUserFormFetched(true)
        if (response) {
          setUserForm(response)
          setHasCompleted(true)
        }
      })
    }
  }, [userFormFetched, activeEvent.id, hasCompleted, dispatch])

  const { field_anonymous_feedback, field_feedback_required_for_cred } =
    activeEvent.addOn.attributes
  const { field_scale_max, field_scale_min } =
    activeEvent.feedbackForm?.attributes
  const formQuestions = activeEvent.feedbackForm?.questions

  let buddyMessage = ""

  if (field_anonymous_feedback) {
    buddyMessage +=
      "Your form submission will remain anonymous to all event managers and administrators. "
  }
  if (field_feedback_required_for_cred) {
    buddyMessage +=
      "Please note that feedback is required to receive PD credit for this " +
      eventTypeLabel.toLowerCase() +
      "."
  }
  const hasMissingRequiredAnswers = formQuestions.find((question) => {
    const isMissing =
      question.attributes.field_required &&
      !question.userResponse &&
      question.userResponse !== 0
    return isMissing
  })

  return (
    <div>
      <section>
        <h3>{eventTypeLabel} Feedback</h3>
        {userFormFetched && (
          <>
            {!isLoading && !hasCompleted && (
              <Card sx={{ padding: 3 }}>
                {buddyMessage.length > 0 && (
                  <LearningBuddyMessage message={buddyMessage} />
                )}

                <ul style={{ marginTop: 15 }}>
                  {formQuestions &&
                    formQuestions.map((question) => {
                      const isLikert = question.type.includes("scale")

                      const {
                        field_maximum_label,
                        field_minimum_label,
                        field_question_name,
                      } = question.attributes

                      let numberArray = []

                      numberArray = Array.from(
                        {
                          length: Math.abs(
                            field_scale_max - field_scale_min + 1
                          ),
                        },
                        (_, i) => {
                          const modifier = Number(field_scale_min) ?? 0
                          return i + modifier
                        }
                      )

                      return (
                        <li component="li" style={styles.questionItem}>
                          <strong
                            style={{
                              fontSize: 16,
                              display: "block",
                              marginBottom: 10,
                            }}
                          >
                            {field_question_name}
                            {question.attributes.field_required && (
                              <span style={{ color: "red" }}>*</span>
                            )}
                          </strong>

                          {!isLikert && (
                            <TextField
                              style={{
                                width: "100%",
                              }}
                              onChange={(event) => {
                                dispatch(
                                  setFeedbackFormResponse({
                                    id: question.id,
                                    response: event.target.value,
                                  })
                                )
                              }}
                              placeholder={"Type your answer here"}
                              multiple={true}
                              variant="outlined"
                            />
                          )}

                          {isLikert && (
                            <div style={{ marginTop: 25 }}>
                              <div style={styles.numberArray}>
                                {numberArray.map((number, index) => {
                                  return (
                                    <Box
                                      component="li"
                                      onClick={() => {
                                        dispatch(
                                          setFeedbackFormResponse({
                                            id: question.id,
                                            response: number,
                                          })
                                        )
                                      }}
                                      sx={{
                                        ...styles.numberArray.number,

                                        backgroundColor:
                                          question.userResponse === number
                                            ? theme.palette.secondary.main
                                            : "transparent",

                                        "&:hover": {
                                          backgroundColor:
                                            question.userResponse === number
                                              ? theme.palette.secondary.main
                                              : "#ebebeb",
                                        },
                                      }}
                                    >
                                      {number}
                                    </Box>
                                  )
                                })}
                              </div>

                              <div style={styles.minMaxLabels}>
                                <span style={styles.minMaxLabels.span}>
                                  {field_minimum_label}
                                  <div
                                    style={{
                                      ...styles.minMaxTriangle,
                                      ...styles.minMaxTriangle.left,
                                    }}
                                  />
                                </span>
                                <span style={styles.minMaxLabels.span}>
                                  {field_maximum_label}
                                  <div
                                    style={{
                                      ...styles.minMaxTriangle,
                                      ...styles.minMaxTriangle.right,
                                    }}
                                  />
                                </span>
                              </div>
                            </div>
                          )}
                        </li>
                      )
                    })}
                </ul>

                <footer
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 35,
                    opacity: hasMissingRequiredAnswers ? 0.5 : 1,
                    pointerEvents: hasMissingRequiredAnswers ? "none" : "auto",
                  }}
                >
                  <ButtonLarge
                    onClick={async () => {
                      setIsLoading(true)
                      let response = await dispatch(createUserFeedbackForm())
                      if (response) {
                        setUserForm(response)
                      }
                      setIsLoading(false)
                      setHasCompleted(true)
                    }}
                  >
                    Submit Feedback
                  </ButtonLarge>
                </footer>
              </Card>
            )}

            {isLoading && (
              <>
                <LearningBuddyMessage
                  message={
                    <div>
                      <strong>Thanks!</strong> Submitting your feedback...
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: -15,
                          marginBottom: -10,
                        }}
                      >
                        <Loading />
                      </div>
                    </div>
                  }
                />
              </>
            )}

            {hasCompleted && userForm && (
              <>
                <Card sx={{ marginTop: 4, padding: 3 }}>
                  <LearningBuddyMessage
                    message={
                      <>
                        <strong>Thanks for sharing!</strong> You submitted your
                        response{" "}
                        {generateTimestamp(
                          moment(userForm.attributes.created).format()
                        ).toLowerCase()}
                        . Here's what you shared:
                      </>
                    }
                  />
                  <ul style={{ marginTop: 25 }}>
                    {userForm.responses &&
                      userForm.responses.map((response) => {
                        const targetQuestion = formQuestions.find(
                          (question) => {
                            return (
                              question.attributes.drupal_internal__id ===
                              response.attributes.field_paragraph_id
                            )
                          }
                        )

                        const isLikert = targetQuestion.type.includes("scale")

                        const {
                          field_maximum_label,
                          field_minimum_label,
                          field_question_name,
                        } = targetQuestion.attributes

                        return (
                          <li style={{ marginBottom: 20 }}>
                            <div
                              style={{
                                fontSize: 16,
                                marginBottom: 10,
                              }}
                            >
                              <strong>{field_question_name}</strong>
                            </div>
                            {isLikert && (
                              <>
                                <p
                                  style={{
                                    fontSize: 12,
                                    opacity: 0.8,
                                    marginTop: -10,
                                  }}
                                >
                                  On a scale of {field_scale_min} -{" "}
                                  {field_scale_max}, where {field_scale_min} is{" "}
                                  <strong style={{ color: "black" }}>
                                    {field_minimum_label}
                                  </strong>{" "}
                                  and {field_scale_max} is{" "}
                                  <strong style={{ color: "black" }}>
                                    {field_maximum_label}
                                  </strong>
                                  :
                                </p>
                                <div
                                  style={{
                                    backgroundColor:
                                      theme.palette.secondary.main,
                                    ...styles.numberArray.number,
                                  }}
                                >
                                  {response.attributes.field_likert_answer}
                                </div>
                              </>
                            )}
                            {!isLikert && (
                              <p style={{ marginTop: -5 }}>
                                "{response.attributes.field_open_ended}"
                              </p>
                            )}
                          </li>
                        )
                      })}
                  </ul>
                </Card>
              </>
            )}
          </>
        )}

        {!userFormFetched && (
          <Loading message={"Preparing event feedback form..."} />
        )}
      </section>
    </div>
  )
}

const styles = {
  questionItem: {
    marginLeft: -25,
    marginRight: -25,
    marginBottom: 15,
    paddingLeft: 25,
    paddingRight: 25,
    paddingTop: 20,
  },
  minMaxTriangle: {
    width: 0,
    height: 0,
    borderLeft: "6px solid transparent",
    borderRight: "6px solid transparent",
    borderBottom: "6px solid rgba(0,0,0,1)",
    position: "absolute",
    top: -6,
    left: {
      left: 14,
    },
    right: {
      right: 14,
    },
  },
  minMaxLabels: {
    position: "relative",
    flex: 1,
    display: "flex",
    justifyContent: "space-between",
    fontSize: 12,
    color: "white",
    marginTop: -15,

    span: {
      backgroundColor: "black",
      borderRadius: 8,
      display: "flex",
      fontSize: 12,
      justifyContent: "center",
      alignItems: "center",
      padding: "5px 10px",
    },
  },
  numberArray: {
    flex: 1,
    display: "flex",
    justifyContent: "space-between",
    marginTop: -10,
    marginBottom: 25,
    number: {
      transition: ".3s",
      cursor: "pointer",
      borderRadius: 40,
      border: "1px solid " + theme.palette.secondary.main,
      width: 40,
      height: 40,
      display: "flex",
      fontWeight: "bold",
      fontSize: 12,
      justifyContent: "center",
      alignItems: "center",
    },
  },
}

export default EventFeedback
