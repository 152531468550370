import { useState } from "react"
import { useDispatch } from "react-redux"
import { Button, Dialog } from "@mui/material"

import { initGoogleAuth } from "../../../actions/emailIntegration"

import {
  setIsGCalModalOpen,
  setIsOutlookModalOpen,
  setIntegrationAuthSuccess,
  setAuthenticationInProgress,
} from "../../../store/reducers/calendar"
import { useMsal } from "@azure/msal-react"
import { loginRequest } from "../../../graphAuthConfig"

import Loading from "@mobilemind/common/src/components/Loading"
import { useSelector } from "react-redux"
import { updateCalendarIntegrationSettings } from "../../../actions"
import LearningBuddyMessage from "@mobilemind/common/src/components/LearningBuddyMessage"
import classNames from "classnames"
import { integrationMessages } from "./integrationModalMessages"

const styles = {
  footer: { padding: 0, justifyContent: "center" },
}

/**
 * Renders a modal component for calendar integration.
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.open - Indicates whether the modal is open or not.
 * @param {string} props.integrationName - The name of the calendar integration.
 * @param {string} props.integrationKey - The key of the calendar integration.
 * @returns {JSX.Element} The rendered modal component.
 */
function CalendarIntegrationModal({ open, integrationName, integrationKey }) {
  const dispatch = useDispatch()
  const { session, calendar } = useSelector((state) => state)

  const { integrationAuthSuccess, isAuthenticationInProgress } = calendar

  const { instance } = useMsal()
  const [isLoading, setIsLoading] = useState(false)

  const [showRemovalInstructions, setShowRemovalInstructions] = useState(false)

  const { field_calendar_integration } = session.user.attributes
  const integrationSettings = field_calendar_integration ?? []
  const hasIntegrationActive = integrationSettings.includes(integrationKey)

  function onClose() {
    dispatch(setIsOutlookModalOpen(false))
    dispatch(setIsGCalModalOpen(false))
    setTimeout(() => {
      setShowRemovalInstructions(false)
      dispatch(setAuthenticationInProgress(false))
      dispatch(setIntegrationAuthSuccess(false))
    }, 800)
  }

  const authActions = {
    outlook: () => {
      instance.acquireTokenPopup(loginRequest).then((response) => {
        if (response && response.accessToken) {
          if (!hasIntegrationActive) {
            const settingsArray = [...integrationSettings, integrationKey]
            dispatch(updateCalendarIntegrationSettings({ settingsArray })).then(
              () => {
                dispatch(setIntegrationAuthSuccess(true))
                setIsLoading(false)
                dispatch(setAuthenticationInProgress(false))
              }
            )
          }
        }
      })
    },
    google: () => {
      dispatch(initGoogleAuth())
    },
  }

  return (
    <Dialog onClose={onClose} open={open} className="calendarIntegrationModal">
      <div className="dialog">
        <span onClick={onClose} className="icon close" />

        {/** If they HAVE NOT already added the integration, ask if they're sure */}
        {!hasIntegrationActive &&
          !showRemovalInstructions &&
          !integrationAuthSuccess && (
            <>
              <div className="animationWrapper">
                <div className="icon mm" />
                <div className="icon calendar" />
                <div className={classNames("icon color", integrationKey)} />
              </div>

              {isAuthenticationInProgress && (
                <LearningBuddyMessage
                  message={
                    <>
                      <strong>Great!</strong> Follow the prompts in the popup
                      that just appeared and I'll handle the rest.
                    </>
                  }
                />
              )}

              {!hasIntegrationActive && !isAuthenticationInProgress && (
                <LearningBuddyMessage
                  message={
                    <>
                      Do you want to authorize me to add events to your{" "}
                      <strong>{integrationName}?</strong> Only events you have
                      RSVP'd to will be added.
                    </>
                  }
                />
              )}

              <footer style={styles.footer}>
                {isLoading ? (
                  <Loading />
                ) : (
                  <Button
                    style={{ marginLeft: 0 }}
                    onClick={() => {
                      setIsLoading(true)
                      dispatch(setAuthenticationInProgress(true))
                      authActions[integrationKey]()
                    }}
                    className="button small"
                  >
                    Yes, Connect My {integrationName}
                  </Button>
                )}
              </footer>
            </>
          )}

        {/** Once an authorization attempt is successful, tell them they're good to go */}
        {integrationAuthSuccess && (
          <>
            <div className="animationWrapper tada">
              <div className={classNames("icon color", integrationKey)} />
            </div>
            <LearningBuddyMessage
              message={
                <>
                  <strong>You're all set!</strong> From now on, I'll
                  automatically copy all events you plan on attending to your{" "}
                  <strong>{integrationName}</strong>.
                </>
              }
            />
            <footer style={styles.footer}>
              <Button
                className="button small"
                style={{ marginLeft: 0 }}
                onClick={onClose}
              >
                Got it!
              </Button>
            </footer>
          </>
        )}

        {/** If they've already added the integration, prompt them to remove it */}
        {hasIntegrationActive &&
          !showRemovalInstructions &&
          !isAuthenticationInProgress &&
          !integrationAuthSuccess && (
            <>
              <div className="animationWrapper" style={{ marginBottom: 50 }}>
                <div className={classNames("icon color", integrationKey)} />
              </div>

              <LearningBuddyMessage
                message={
                  <>
                    Do you want me to stop automatically adding events to your{" "}
                    <strong>{integrationName}?</strong> You can re-enable this
                    at any time.
                  </>
                }
              />
              <footer style={styles.footer}>
                <Button
                  style={{ marginLeft: 0 }}
                  onClick={async () => {
                    const settingsArray = integrationSettings.filter(
                      (setting) => setting !== integrationKey
                    )
                    await dispatch(
                      updateCalendarIntegrationSettings({ settingsArray })
                    )
                    setShowRemovalInstructions(true)
                  }}
                  className="button small"
                >
                  Yes, Stop Adding Events to {integrationKey}
                </Button>
              </footer>
            </>
          )}

        {/** Upon removal, show the relevant permission removal instructions */}
        {showRemovalInstructions && (
          <>
            {integrationMessages[integrationKey]}
            <footer style={styles.footer}>
              <Button
                style={{ marginLeft: 0 }}
                onClick={() => {
                  onClose()
                }}
                className="button small"
              >
                Got it!
              </Button>
            </footer>
          </>
        )}
      </div>
    </Dialog>
  )
}

export default CalendarIntegrationModal
