import { createRef, Component, useEffect, useState } from "react"
import { connect } from "react-redux"
import { isMobile } from "react-device-detect"

import {
  changeNavbar,
  setSidebar,
  deleteAssessment,
  collapseSidebar,
} from "../../actions"

import { createUserLearningPath } from "@mobilemind/common/src/actions/user"
import SanitizedHTML from "@mobilemind/common/src/components/SanitizedHTML"
import {
  generateTimestamp,
  generateVideoEmbedURL,
  calculateGoalPace,
} from "@mobilemind/common/src/functions/index"
import Loading from "@mobilemind/common/src/components/Loading"
import Unauthorized from "@mobilemind/common/src/components/Unauthorized"
import ProgressBar from "@mobilemind/common/src/components/ProgressBar"

import ReactPlayer from "react-player"
import moment from "moment"
import classNames from "classnames"

import { Box, Button } from "@mui/material"
import theme from "@mobilemind/common/src/theme/theme"

import { isCourseCategoryExcluded } from "../../functions"

import CourseListItem from "../../components/CourseListItem"
import Sidebar from "../../components/Sidebar"
import ButtonSmall from "../../components/ButtonSmall"
import BackArrow from "../../icons/BackArrow"
import ButtonLarge from "@mobilemind/common/src/components/ButtonLarge"

import LearningBuddyMessage from "@mobilemind/common/src/components/LearningBuddyMessage"
import Padlock from "../../icons/Padlock"

import { getSingleLP, deleteULP } from "./learningPathsSlice"
import { Link, withRouter } from "react-router-dom"
import { useAppDispatch } from "../../store/store"

const mapStateToProps = (
  { session, sidebar, categories, learningPaths },
  ownProps
) => {
  let activePath = learningPaths.data.find(
    (path) => path.field_lp_id === ownProps.match.params.pathId
  )
  let coursesCompleted, nextPathCourse

  // // Get the next course that isn't submitted, being graded, or complete
  if (activePath && activePath.courses) {
    nextPathCourse =
      activePath.courses &&
      activePath.courses.filter((course) => {
        let isCompleted = course.field_user_assessment_status === "completed"
        let assessmentStatus = course.field_user_assessment_status
        let isNext =
          !assessmentStatus ||
          assessmentStatus === "saved_for_later" ||
          assessmentStatus === "in_progress"

        return !isCompleted && isNext
      })[0]

    coursesCompleted =
      activePath.courses &&
      activePath.courses.filter((course) => {
        return course.field_user_assessment_status === "completed"
      })
  }

  // @todo we can do this without waiting on activePath.courses
  let percentComplete = coursesCompleted
    ? (coursesCompleted.length / activePath.courses.length) * 100
    : 0
  const goal =
    activePath && activePath.lp_goal_info && activePath.lp_goal_info[0]

  let coursesRemaining =
    activePath &&
    activePath.courses &&
    activePath.courses.length - activePath.field_user_lp_completed_courses

  let description = activePath && activePath.field_video_description
  let timestamps =
    description &&
    description.split("-").filter((item) => !isNaN(Number(item[0])))
  let labels =
    description &&
    description.split("-").filter((item) => isNaN(Number(item[0])))

  // Convert to seconds
  let videoTimestamps =
    timestamps &&
    timestamps.map((time, index) => {
      let minutesAsSeconds = Number(time.substr(0, time.indexOf(":"))) * 60
      let seconds = Number(time.substr(time.indexOf(":"), 3).replace(":", ""))
      return { label: labels[index + 1], seconds: minutesAsSeconds + seconds }
    })

  let goalDeadline =
    goal && moment(activePath.lp_goal_info[0].lp_goal_due_date_value)
  let isGoalOverdue =
    goal &&
    goalDeadline.isBefore(moment()) &&
    activePath.field_user_lp_status !== "completed"

  const completedMinutes =
    activePath && Number(activePath.field_user_lp_total_time_spent)

  let totalMinutes = 0
  activePath &&
    activePath.courses &&
    activePath.courses.forEach((course) => {
      totalMinutes += Number(course.field_course_estimated_time_value)
    })

  let onTrack, trackWidth, dailyPace, differenceDays, daysElapsed
  const progressWidth =
    completedMinutes && (completedMinutes / totalMinutes) * 100

  if (activePath && activePath.lp_goal_info && activePath.lp_goal_info[0]) {
    //The base daily pace in minutes per day
    dailyPace = Math.ceil(
      Number(goal.lp_goal_est_time_value) / goal.lp_goal_duration_value
    )
    differenceDays = moment(goal.lp_goal_due_date_value).diff(moment(), "days")
    daysElapsed = goal.lp_goal_duration_value - differenceDays
    trackWidth = ((daysElapsed * dailyPace) / goal.lp_goal_est_time_value) * 100

    if (progressWidth >= trackWidth) {
      onTrack = true
    }
  }

  return {
    session,
    sidebar,
    categories,
    activePath,
    goal,
    goalDeadline,
    isGoalOverdue,
    coursesRemaining,
    percentComplete,
    coursesCompleted,
    timestamps,
    videoTimestamps,
    nextPathCourse,
    progressWidth,
    trackWidth,
    differenceDays,
    onTrack,
  }
}

class VideoPlayer extends Component {
  constructor(props) {
    super(props)
    this.player = createRef()
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.seek !== this.props.seek) {
      this.player.current.seekTo(this.props.seek, "seconds")
    }
  }

  render() {
    return (
      <ReactPlayer
        ref={this.player}
        url={this.props.url}
        controls={true}
        playing={this.props.seek ? true : false}
      />
    )
  }
}

function LearningPathLayout(props) {
  const {
    sidebar,
    session,
    categories,
    activePath,
    prerequisitePath,
    prerequisitePathComplete,
    goal,
    isGoalOverdue,
    coursesRemaining,
    percentComplete,
    coursesCompleted,
    timestamps,
    videoTimestamps,
    nextPathCourse,
    progressWidth,
    trackWidth,
    differenceDays,
    onTrack,
  } = props

  const [isStarted, setIsStarted] = useState(false)
  const [mount, setMount] = useState(false)
  const [videoSeek, setVideoSeek] = useState(0)

  const dispatch = useAppDispatch()

  const hasStarted = isStarted || (activePath && activePath.field_user_lp_id)
  const isLocked = !activePath || (!activePath.field_user_lp_id && !hasStarted)
  const showNextUp =
    coursesCompleted &&
    (hasStarted || activePath.field_user_lp_id) &&
    nextPathCourse
  const courseCategoriesAdded = []

  useEffect(() => {
    dispatch(changeNavbar("learn"))
    if (!isMobile || window.innerWidth > 667) {
      dispatch(collapseSidebar(true))
    }
    if (!mount) {
      dispatch(
        getSingleLP({ id: props.match.params.pathId, fullPath: activePath })
      ).then(() => {
        setMount(true)
      })
    }
    if (activePath && activePath.field_user_lp_status) {
      setIsStarted(true)
    }
  }, [mount, activePath, dispatch, props.match.params.pathId])

  function startLearningPath() {
    setIsStarted(true)
    dispatch(createUserLearningPath(activePath))
  }

  function goBack() {
    props.history.goBack()
  }

  function goToLearningPaths() {
    dispatch(setSidebar("learn", "learningPaths"))
    props.history.push("/")
  }

  function removeAssessment(id) {
    dispatch(deleteAssessment(id, this.props.session))
  }

  function generateMessage(percentCompleted, coursesRemaining) {
    if (percentCompleted === 0) {
      return (
        <>
          <strong>Ready for action!</strong>
        </>
      )
    }
    if (percentCompleted > 0 && percentCompleted <= 15) {
      return (
        <>
          <strong>You're off to a great start!</strong>
        </>
      )
    } else if (percentCompleted > 15 && percentCompleted <= 30) {
      return (
        <>
          <strong>You're doing great!</strong>
        </>
      )
    } else if (percentCompleted > 30 && percentCompleted <= 40) {
      return (
        <>
          <strong>Keep it up!</strong>
        </>
      )
    } else if (percentCompleted > 40 && percentCompleted < 50) {
      return (
        <>
          <strong>You got this!</strong>
        </>
      )
    } else if (percentCompleted === 50) {
      return (
        <>
          <strong>Whoa, we're halfway there!</strong>
        </>
      )
    } else if (percentCompleted > 50 && percentCompleted <= 70) {
      return (
        <>
          <strong>You're on a roll!</strong>
        </>
      )
    } else if (percentCompleted > 70 && percentCompleted <= 85) {
      return (
        <>
          <strong>You'll be finished in no time!</strong>
        </>
      )
    } else if (percentCompleted > 85 && percentCompleted > 100) {
      return (
        <>
          <strong>Wow, you're almost done!</strong>
        </>
      )
    } else if (percentCompleted === 100) {
      return (
        <>
          <strong>Way to go!</strong>
        </>
      )
    }
    if (coursesRemaining === 1) {
      return (
        <>
          <strong>One more course to go!</strong>
        </>
      )
    }
  }

  if (props.unauthorized) {
    return <Unauthorized />
  }

  if (activePath) {
    return (
      <div className="page learning-path-single">
        <Sidebar
          theme={theme}
          currentPage="course"
          content={
            <div className="entity-meta lp">
              <Button className="backButton" onClick={goBack}>
                <BackArrow />
                <span
                  className="buttonText"
                  style={{ marginLeft: 10, color: theme.palette.primary.main }}
                >
                  Back
                </span>
              </Button>

              {activePath && (
                <div className="learningPathCover">
                  {goal &&
                    percentComplete !== 100 &&
                    activePath.field_user_lp_status !== "completed" && (
                      <>
                        <span className="deadline">
                          <span className="icon goal" />
                          {isGoalOverdue ? (
                            <span>Overdue</span>
                          ) : (
                            <span>{differenceDays} Days Left</span>
                          )}
                        </span>
                      </>
                    )}

                  <div
                    style={{
                      backgroundImage:
                        "url(" + activePath.field_lp_image_uri + ")",
                    }}
                    className="lp-image"
                  />

                  <div className="capsule-container">
                    <div className="capsule">{activePath.field_lp_type}</div>
                  </div>
                </div>
              )}

              {!hasStarted && activePath && !activePath.field_user_lp_id && (
                <div className="courseCount">
                  {activePath.courses && (
                    <>
                      <span style={{ color: theme.palette.primary.main }}>
                        {activePath.courses.length}
                      </span>
                      Micro-Courses
                    </>
                  )}
                </div>
              )}

              {activePath && activePath.courses && (
                <div className={classNames("pathStatus")}>
                  <>
                    <div style={{ marginBottom: 5 }}>
                      {coursesCompleted.length ? coursesCompleted.length : 0} /{" "}
                      {activePath.courses.length}
                      {!sidebar.isCollapsed && <> Courses Completed </>}
                    </div>

                    {!goal && (
                      <ProgressBar
                        width={
                          (coursesCompleted.length /
                            activePath.courses.length) *
                          100
                        }
                      />
                    )}

                    {goal && percentComplete !== 100 && (
                      <>
                        <div className="progressBarGoal">
                          <div
                            className="goalProgress"
                            style={{
                              width: progressWidth + "%",
                              backgroundColor: theme.palette.secondary.main,
                            }}
                          ></div>
                          <div
                            className="track"
                            style={{
                              width: trackWidth + "%",
                              backgroundColor: theme.palette.primary.main,
                            }}
                          ></div>
                        </div>
                      </>
                    )}

                    {((!isLocked &&
                      activePath.field_user_lp_status === "completed") ||
                      (percentComplete === 100 && hasStarted)) && (
                      <>
                        <p style={{ marginBottom: 25 }} className="message">
                          <span className="icon check" />

                          <LearningBuddyMessage
                            message={
                              <>
                                <strong>Very nice!</strong> You completed this
                                learning path
                                {activePath.field_user_lp_updated ? (
                                  <>
                                    {" "}
                                    {generateTimestamp(
                                      activePath.field_user_lp_updated
                                    )}
                                    .{" "}
                                  </>
                                ) : (
                                  <strong>today. </strong>
                                )}
                                <strong>What's next?</strong>
                              </>
                            }
                          />
                        </p>
                        <ButtonSmall onClick={goToLearningPaths}>
                          Find More Learning Paths
                        </ButtonSmall>
                      </>
                    )}

                    {activePath &&
                      activePath.field_user_lp_status !== "completed" &&
                      !isLocked &&
                      hasStarted && (
                        <p className="message">
                          {goal ? (
                            <>
                              {onTrack ? (
                                <LearningBuddyMessage
                                  message={<strong>You're on track!</strong>}
                                />
                              ) : (
                                <LearningBuddyMessage
                                  message={
                                    <>
                                      <strong>You're not on track </strong>to
                                      complete this goal. Complete{" "}
                                      <strong>
                                        {calculateGoalPace(activePath).pace} /{" "}
                                        {calculateGoalPace(activePath).unit}
                                      </strong>{" "}
                                      to get back on track!
                                    </>
                                  }
                                />
                              )}
                            </>
                          ) : (
                            <LearningBuddyMessage
                              message={generateMessage(
                                percentComplete,
                                coursesRemaining
                              )}
                            />
                          )}
                        </p>
                      )}

                    {!hasStarted &&
                      coursesCompleted &&
                      coursesCompleted.length > 0 &&
                      (!prerequisitePath || prerequisitePathComplete) && (
                        <LearningBuddyMessage
                          message={
                            <>
                              {percentComplete === 100 ? (
                                <>
                                  <strong>
                                    Wow, you've already completed all these
                                    courses!{" "}
                                  </strong>
                                  Click <strong>Start Learning Path</strong> to
                                  instantly get credit for completing it.
                                </>
                              ) : (
                                <>
                                  <strong>
                                    You've already completed{" "}
                                    {coursesCompleted.length} of these courses.
                                  </strong>{" "}
                                  Start this Learning Path to unlock and track
                                  your progress!
                                </>
                              )}
                            </>
                          }
                        />
                      )}
                  </>
                </div>
              )}

              {goal && (
                <div className="goal">
                  {goal.user_lp_goal_status !== "completed" && (
                    <>
                      <p className="deadline">
                        Complete by{" "}
                        <strong style={{ color: theme.palette.primary.main }}>
                          {moment(goal.lp_goal_due_date_value).format(
                            "MMMM Do, YYYY"
                          )}
                        </strong>
                      </p>
                      <p>
                        On Track Pace{" "}
                        <strong style={{ color: theme.palette.primary.main }}>
                          {calculateGoalPace(activePath).pace} /{" "}
                          {calculateGoalPace(activePath).unit}{" "}
                        </strong>
                      </p>
                    </>
                  )}
                </div>
              )}

              {activePath && activePath.courses && (
                <section>
                  <header>Categories Included</header>
                  <ul className="learningPathCategories">
                    {activePath.courses.map((course) => {
                      let category = categories.data.find(
                        (cat) =>
                          cat.attributes.name === course.field_category_name
                      )

                      if (
                        course &&
                        category &&
                        !courseCategoriesAdded.includes(category.id)
                      ) {
                        courseCategoriesAdded.push(category.id)
                        return (
                          <li key={category.id} className="categoryHover">
                            <div className="popup">
                              {category.attributes.name}
                            </div>
                            {category && category.image && (
                              <div className="category-icon-wrapper">
                                <span
                                  className={classNames("category-icon")}
                                  style={{
                                    backgroundImage:
                                      "url(" +
                                      process.env.REACT_APP_API_URL +
                                      category.image.attributes.uri.url +
                                      ")",
                                  }}
                                />
                              </div>
                            )}
                          </li>
                        )
                      }
                      return null
                    })}
                  </ul>
                </section>
              )}
            </div>
          }
        />

        <div className="course-content">
          <section>
            <h2 style={{ display: "flex" }}>
              <span style={{ flex: 1 }}>{activePath.name}</span>
              {activePath &&
                !activePath.field_user_lp_status &&
                (!activePath.field_prerequisite_lp_name ||
                  activePath.field_lp_prereq_completed) && (
                  <div className={classNames(isStarted && "fade")}>
                    <ButtonLarge onClick={startLearningPath}>
                      <span className="icon nodes"></span>
                      Start Learning Path
                    </ButtonLarge>
                  </div>
                )}
            </h2>

            {activePath.field_prerequisite_lp_name &&
              !activePath.field_lp_prereq_completed && (
                <p className="prerequisiteMessage">
                  <span style={{ marginRight: 10 }}>
                    <Padlock />
                  </span>

                  <span>
                    Complete{" "}
                    <strong>{activePath.field_prerequisite_lp_name}</strong> to
                    unlock this Learning Path.
                  </span>
                </p>
              )}

            <div className="description">
              <SanitizedHTML html={activePath.field_description_value} />
            </div>
          </section>

          {showNextUp && (
            <section className="section-nextUp">
              <h3>Next Up</h3>
              <ul className="course-library course-list nextUp">
                <CourseListItem
                  learningPathId={activePath.field_lp_id}
                  isNextUp={true}
                  course={nextPathCourse}
                  assessmentId={nextPathCourse.field_user_assessment_uuid}
                  status={nextPathCourse.field_user_assessment_status}
                  category={categories.data.find(
                    (cat) =>
                      cat.attributes.name === nextPathCourse.field_category_name
                  )}
                  currentPage="learningPath"
                  removeAssessment={() => {
                    removeAssessment(nextPathCourse.field_user_assessment_uuid)
                  }}
                />
              </ul>
            </section>
          )}

          <section className="video-content">
            {activePath.field_learning_path_video_value && (
              <>
                <h3>What You'll Learn</h3>
                <div className="videoWrapper">
                  {activePath.field_learning_path_video_value.includes(
                    "drive"
                  ) ? (
                    <iframe
                      title="Learning Path Video"
                      width="675"
                      height="380"
                      src={generateVideoEmbedURL(
                        activePath.field_learning_path_video_value
                      )}
                      frameBorder="0"
                      allow="autoplay; encrypted-media"
                      allowFullScreen={true}
                    ></iframe>
                  ) : (
                    <VideoPlayer
                      url={activePath.field_learning_path_video_value}
                      seek={videoSeek}
                    />
                  )}
                </div>
                {videoTimestamps && (
                  <ul>
                    {videoTimestamps.map((timestamp, index) => {
                      return (
                        <li key={index}>
                          <ButtonSmall
                            onClick={() => {
                              setVideoSeek(timestamp.seconds)
                            }}
                          >
                            {timestamps[index]}
                          </ButtonSmall>
                          {timestamp.label}
                        </li>
                      )
                    })}
                  </ul>
                )}
              </>
            )}
          </section>

          <h3>Courses Included</h3>
          {activePath && activePath.courses ? (
            <ul
              className={classNames(
                "course-library course-list path-courses-list",
                isLocked && "disabled"
              )}
            >
              {activePath.courses.map((course, index) => {
                let categoryId = course.field_category_target_id
                const status = course.field_user_assessment_status
                let category = categories.data.find(
                  (cat) =>
                    cat.attributes.drupal_internal__id === Number(categoryId)
                )

                let hasComments = course.field_user_assessment_comment
                let isExcluded =
                  session.excludedCategories &&
                  isCourseCategoryExcluded(
                    course,
                    categories.data,
                    session.excludedCategories
                  )

                return (
                  <div
                    key={course.id}
                    className={classNames(
                      "courseWrapper",
                      activePath.courses.length === 1 && "single",
                      status === "completed" && "isCompleted",
                      !hasStarted && "unstarted",
                      isExcluded && "isExcluded"
                    )}
                  >
                    <div className="courseIndex">
                      <span
                        className={classNames(
                          "status icon",
                          hasComments && "hasComments",
                          status
                        )}
                      />
                    </div>
                    <CourseListItem
                      learningPathId={activePath.field_lp_id}
                      key={index}
                      animationDelay={0.035 * index + "s"}
                      course={course}
                      removeAssessment={() => {
                        removeAssessment(course.field_user_assessment_uuid)
                      }}
                      assessmentId={course.field_user_assessment_uuid}
                      category={category}
                      status={course.field_user_assessment_status}
                    />
                  </div>
                )
              })}
            </ul>
          ) : (
            <Loading message="Getting courses..." />
          )}

          <Box sx={{ display: "flex", justifyContent: "center", padding: 2 }}>
            {activePath.field_user_lp_uuid &&
              activePath.field_user_lp_status === "in_progress" && (
                <Link
                  onClick={() => {
                    dispatch(
                      deleteULP({ id: activePath.field_user_lp_uuid })
                    ).then(() => {
                      dispatch(setSidebar("learn", "learningPaths"))
                    })
                  }}
                  to={"/"}
                >
                  <Button size="small" className="discontinue">
                    Discontinue Learning Path
                  </Button>
                </Link>
              )}
          </Box>
        </div>
      </div>
    )
  }

  return (
    <Loading
      fullPage={true}
      message={"Getting learning path..."}
      theme={theme}
    />
  )
}

export default withRouter(connect(mapStateToProps)(LearningPathLayout))
