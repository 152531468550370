import { useEffect, useState } from "react"
import "../../styles/certificate.scss"

import Loading from "@mobilemind/common/src/components/Loading"
import { MandatedTrainingCertificate } from "@mobilemind/common/src/components/backpack/certificate/MandatedTrainingCertificate"
import { useDispatch } from "react-redux"
import { fetchSingleMandatedTrainingArchive } from "@mobilemind/common/src/actions/backpack"
import { MandatedTrainingHistory } from "@mobilemind/common/src/types/mandatedTraining"

type CertProps = {
  match: {
    isExact: boolean
    params: {
      trainingId: string
      path: string
      url: string
    }
  }
}

export function MandatedTrainingCertificateLayout(props: CertProps) {
  type TrainingState = MandatedTrainingHistory | null

  const [training, setTraining] = useState<TrainingState>(null)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!training) {
      dispatch(
        fetchSingleMandatedTrainingArchive(props.match.params.trainingId)
      ).then((response: MandatedTrainingHistory) => {
        setTraining(response)
      })
    }
  }, [training, dispatch, props.match.params.trainingId])

  return (
    <>
      {training ? (
        <MandatedTrainingCertificate training={training} />
      ) : (
        <Loading fullPage={true} message="Generating certificate..." />
      )}
    </>
  )
}
