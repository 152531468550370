import { Component } from "react"
import { connect } from "react-redux"

import BadgeItem from "./BadgeItem"
import PropTypes from "prop-types"
import pluralize from "pluralize"
import { isMobile } from "react-device-detect"

import { isMobileOnly } from "react-device-detect"
import { Grid, Button } from "@mui/material"
import BackArrow from "../../icons/BackArrow"

import classNames from "classnames"
import ExportLink from "../../components/ExportLink"
import BottomScrollListener from "react-bottom-scroll-listener"

import Loading from "@mobilemind/common/src/components/Loading"
import BadgeFilters from "./BadgeFilters"
import BadgeFiltersEarned from "./BadgeFiltersEarned"

import { getCertificates } from "../../actions"
import {
  getEarnedBadges,
  increaseBadgePage,
} from "../achievements/achievementsSlice"

import { getBadges, increasePage } from "./badgesSlice"
import "../../styles/cards.scss"
import theme from "@mobilemind/common/src/theme/theme"

const mapStateToProps = ({
  assessments,
  achievements,
  categories,
  courses,
  badges,
  navbar,
  session,
  sidebar,
}) => {
  let badgesBase = window.location.href.includes("backpack")
    ? achievements.badges
    : badges

  return {
    categories: categories.data,
    assessments,
    courses,
    sidebar,
    isExpanded: sidebar.mobileFiltersExpanded,
    navbar,
    badges: badgesBase,
    session,
    filters: badges.filters,
  }
}

const mapDispatchToProps = {
  increasePage,
  increaseBadgePage,
  getEarnedBadges,
  getBadges,
  getCertificates,
}

function BadgesLayout(props) {
  return <BadgesContent {...props} theme={theme} />
}

class BadgesContent extends Component {
  state = {
    activeBadgeId: null,
    fetched: false,
  }

  setActiveBadge = (id) => {
    this.setState({ activeBadgeId: id })
  }

  componentDidMount = async () => {
    const { session, badges } = this.props

    this.props.getCertificates(session.user, "badge")

    if (!badges.fetched) {
      if (window.location.href.includes("backpack")) {
        this.props.getEarnedBadges()
      } else {
        this.props.getBadges()
      }
    }
  }

  handleScroll = () => {
    const { currentPage, totalPages, isFetchingMore, isFetching } =
      this.props.badges

    // If we aren't already fetching more results
    if (!isFetching && !isFetchingMore) {
      // And if there are more pages to fetch
      if (currentPage < totalPages - 1) {
        if (window.location.href.includes("backpack")) {
          this.props.increaseBadgePage()
        } else {
          this.props.increasePage()
        }
      }
    }
  }

  render() {
    const { badges, navbar, sidebar, theme } = this.props

    const { activeBadgeId } = this.state
    const exportURL = process.env.REACT_APP_API_URL + "/badge-report/user"
    const fetched = badges.fetched
    const { isFetchingMore } = badges
    const onAchievements = window.location.href.includes("backpack")

    return (
      <div
        className={classNames(
          "badge-library",
          !fetched && "loading",
          this.props.isExpanded && "isExpanded",
          onAchievements && "earned"
        )}
      >
        {onAchievements && (
          <div id="backpack-sidebar">
            <Button
              className="backButton"
              onClick={() => this.props.history.push("/backpack")}
            >
              <BackArrow />
              <span
                style={{ marginLeft: 10, color: theme.palette.primary.main }}
                className="buttonText"
              >
                Back
              </span>
            </Button>
            <BadgeFiltersEarned />
          </div>
        )}

        <BottomScrollListener onBottom={this.handleScroll}>
          {!fetched && (
            <header className="pageLoader">
              <Loading
                color={theme.palette.secondary.main}
                message={
                  onAchievements
                    ? "Getting your badges..."
                    : "Getting badges..."
                }
              />
            </header>
          )}

          {isMobileOnly && (
            <div className="mobileFilters">
              {navbar.activeItem === "learn" && <BadgeFilters />}
              {onAchievements && <BadgeFiltersEarned />}
            </div>
          )}

          {onAchievements && fetched && (
            <div className="pageHeader">
              <div className={classNames("itemCount", isMobile && "mobile")}>
                <span style={{ color: theme.palette.primary.main }}>
                  {badges.total}
                </span>
                <>{pluralize("Badge", badges.total)} Earned</>
              </div>

              <ExportLink exportURL={exportURL} />
            </div>
          )}

          <Grid container spacing={3}>
            {navbar.activeItem === "learn" &&
              badges.data.map((badge, index) => {
                let animationDelay = index * 0.03 + "s"
                if (badges.currentPage) {
                  animationDelay =
                    (index - 25 * badges.currentPage) * 0.03 + "s"
                }

                return (
                  <BadgeItem
                    key={badge.id}
                    animationDelay={animationDelay}
                    badge={badge}
                    activeBadgeId={activeBadgeId}
                    setActiveBadge={this.setActiveBadge}
                  />
                )
              })}
            {onAchievements &&
              badges.data.map((badge, index) => {
                let animationDelay = index * 0.03 + "s"
                if (badges.currentPage) {
                  animationDelay =
                    (index - 25 * badges.currentPage) * 0.03 + "s"
                }

                if (badge.earned_date) {
                  return (
                    <BadgeItem
                      key={badge.id}
                      animationDelay={animationDelay}
                      badge={badge}
                      activeBadgeId={activeBadgeId}
                      setActiveBadge={this.setActiveBadge}
                    />
                  )
                }
                return null
              })}
          </Grid>

          {isFetchingMore && (
            <div
              className={classNames(
                "loadingMore",
                sidebar.isCollapsed && "collapsed"
              )}
            >
              <Loading
                color={theme.palette.secondary.main}
                message={"Getting more badges..."}
              />
            </div>
          )}
        </BottomScrollListener>
      </div>
    )
  }
}

BadgesLayout.propTypes = {
  assessments: PropTypes.object,
  availableBadges: PropTypes.array,
  badges: PropTypes.object,
  courses: PropTypes.object,
  filters: PropTypes.object,
  isExpanded: PropTypes.bool,
  navbar: PropTypes.object,
  session: PropTypes.object,
  sidebar: PropTypes.object,
  sortedBadges: PropTypes.array,
  sortedUserBadges: PropTypes.array,
  userBadges: PropTypes.array,
}

export default connect(mapStateToProps, mapDispatchToProps)(BadgesLayout)
