import React from "react"
import { ClickAwayListener, Fade, Box, Popper } from "@mui/material"

type PopperProps = {
  anchorEl?: Element
  open: boolean
  onClose: () => void
  children?: JSX.Element
}

export const CalendarFiltersPopper = (props: PopperProps) => {
  return (
    <Popper
      open={props.open}
      anchorEl={props.anchorEl}
      transition={true}
      keepMounted={false}
      placement={"bottom-start"}
      sx={{
        boxShadow: 1,
        zIndex: 40,
        borderRadius: 1,
        backgroundColor: "white",
      }}
    >
      {({ TransitionProps }) => (
        <ClickAwayListener onClickAway={props.onClose}>
          <Fade {...TransitionProps} timeout={100}>
            <Box
              sx={{
                padding: 2,
                margin: 2,
              }}
            >
              {props.children}
            </Box>
          </Fade>
        </ClickAwayListener>
      )}
    </Popper>
  )
}
