import { Component } from "react"
import { connect } from "react-redux"
import {
  updateProfile,
  getUser,
  updateUserPicture,
} from "@mobilemind/common/src/actions/user"
import { changeNavbar } from "../../actions"
import {
  TextField,
  Select,
  Button,
  MenuItem,
  InputLabel,
  FormHelperText,
  FormControl,
} from "@mui/material"
import classNames from "classnames"
import Loading from "@mobilemind/common/src/components/Loading"
import PropTypes from "prop-types"
import { Dialog } from "@mui/material"

import { getJobTitles, updateLearningBuddy } from "../../actions"

import ProfilePicture from "./ProfilePicture"
import "../../styles/profile.scss"
import LearningBuddyMessage from "@mobilemind/common/src/components/LearningBuddyMessage"
import LearningBuddySelect from "../learningBuddy/LearningBuddySelect"
import theme from "@mobilemind/common/src/theme/theme"
import ButtonLarge from "@mobilemind/common/src/components/ButtonLarge"
import ProfileCoverPhoto from "./ProfileCoverPhoto"

const mapStateToProps = ({ jobTitles, session }) => {
  return {
    session,
    jobTitles: jobTitles.data.filter((title) => {
      return (
        title.attributes.name !== "School" &&
        title.attributes.name !== "District"
      )
    }),
    user: session.user,
  }
}

const mapDispatchToProps = {
  updateProfile,
  getJobTitles,
  getUser,
  changeNavbar,
  updateLearningBuddy,
  updateUserPicture,
}

function ProfileLayout(props) {
  return <Profile {...props} theme={theme} />
}

class Profile extends Component {
  constructor(props) {
    super(props)
    let jobTitleOther = this.props.user.attributes.field_job_title_other

    this.state = {
      timezone: this.props.user.attributes.timezone,
      jobTitle: this.props.user.jobTitle && this.props.user.jobTitle.id,
      jobTitleOther: jobTitleOther ? jobTitleOther : "",
      isOther: jobTitleOther ? true : false,
      firstName: this.props.user.attributes.field_first_name,
      lastName: this.props.user.attributes.field_last_name,
      isSaving: false,
      bio: this.props.user.attributes.field_bio
        ? this.props.user.attributes.field_bio
        : "",
      isEditing: false,
      buddyModalOpen: false,
    }

    this.updateFirstName = this.updateFirstName.bind(this)
    this.updateLastName = this.updateLastName.bind(this)
  }

  componentDidMount = () => {
    this.props.changeNavbar(null)
    this.props.getJobTitles()
  }

  componentDidUpdate(prevProps) {
    // Once we get the user, determine if they have an "other" job title
    if (prevProps.user !== this.props.user) {
      if (this.props.user.attributes.field_job_title_other) {
        this.setState({ isOther: true })
      }
    }
  }

  updateFirstName = (event) => {
    const { user } = this.props
    let hasChanged =
      user.jobTitle &&
      (event.target.value !== user.attributes.field_first_name ||
        user.jobTitle.id !== this.state.jobTitle)
    this.setState({ firstName: event.target.value, hasChanged: hasChanged })
  }

  updateLastName = (event) => {
    const { user } = this.props
    let hasChanged =
      user.jobTitle &&
      (event.target.value !== user.attributes.field_last_name ||
        user.jobTitle.id !== this.state.jobTitle)
    this.setState({ lastName: event.target.value, hasChanged: hasChanged })
  }

  updateTimezone = (event) => {
    const { user } = this.props

    let hasChanged =
      user.timezone &&
      (event.target.value !== user.attributes.timezone ||
        user.attributes.timezone !== this.state.timezone)
    this.setState({ timezone: event.target.value, hasChanged: hasChanged })
  }

  updateJobTitle = (event) => {
    const { user, jobTitles } = this.props
    let hasChanged =
      user.jobTitle &&
      (user.jobTitle.id !== event.target.value ||
        user.attributes.field_first_name !== this.state.firstName ||
        user.attributes.field_last_name !== this.state.lastName)

    let jobTitle = jobTitles.find((title) => title.id === event.target.value)
    if (jobTitle.attributes.name.includes("Other")) {
      this.setState({ jobTitle: event.target.value, isOther: true })
    } else {
      this.setState({
        jobTitle: event.target.value,
        jobTitleOther: "",
        isOther: false,
        hasChanged: hasChanged,
      })
    }
  }

  updateJobTitleOther = (event) => {
    this.setState({ jobTitleOther: event.target.value, hasChanged: true })
  }

  updateBio = (event) => {
    this.setState({ bio: event.target.value, hasChanged: true })
  }

  removeImage = () => {
    let profile = {
      timezone: this.state.timezone,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      jobTitle: this.state.jobTitle,
      jobTitleOther: this.state.jobTitleOther,
    }

    this.props.updateUserPicture()
    this.setState({ userPicture: null })
    this.props.updateProfile(profile, this.props.session, true)
  }

  save = async () => {
    this.setState({ isSaving: true, isEditing: false })

    let profile = {
      bio: this.state.bio,
      timezone: this.state.timezone,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      jobTitle: this.state.jobTitle,
      jobTitleOther: this.state.jobTitleOther,
    }

    await this.props.updateProfile(profile, this.props.session)

    this.setState({
      isEditing: false,
      isSaving: false,
      hasChanged: false,
      isSaved: true,
    })
    setTimeout(() => {
      this.setState({ isSaved: false })
    }, 1800)
  }

  selectBuddy = (buddy) => {
    this.props.updateLearningBuddy(buddy)
    setTimeout(() => {
      this.setState({ buddyModalOpen: false })
    }, 500)
  }

  render() {
    const {
      firstName,
      lastName,
      jobTitle,
      isSaving,
      isSaved,
      isEditing,
      timezone,
      hasChanged,
    } = this.state
    const { session, theme } = this.props

    let locked = isSaving || isSaved
    let fullJobTitle =
      jobTitle && this.props.jobTitles.find((title) => title.id === jobTitle)

    let allRoles = session.orgRoles.concat(session.groupRoles).join()
    const showBio = allRoles.includes("personnel")

    return (
      <div className={classNames("page profile", locked && "locked")}>
        <Dialog
          open={this.state.buddyModalOpen}
          onClose={() => {
            this.setState({ buddyModalOpen: false })
          }}
        >
          <div className="buddyModal">
            <h2>Select Learning Buddy</h2>
            <LearningBuddySelect
              onSelect={(buddy) => {
                this.selectBuddy(buddy)
              }}
            />
          </div>
        </Dialog>

        <div className={classNames("flexRow profileWrapper")}>
          <div>
            <div
              className={classNames(
                "profileCard",
                session.user.userPicture && "hasPicture",
                "images"
              )}
            >
              <h3>
                <span>Profile Images</span>
              </h3>
              <ProfileCoverPhoto />
              <ProfilePicture removeImage={this.removeImage} />

              <div className="images-text">
                Click to Upload or Drag & Drop
                <span>256MB limit (png jpg jpeg)</span>
              </div>
            </div>

            <div className="profileCard buddy">
              <h3>
                <span>Learning Buddy</span>
                <Button
                  onClick={() => {
                    this.setState({ buddyModalOpen: true })
                  }}
                  className="button small"
                >
                  Edit
                </Button>
              </h3>
              <div
                className="inner"
                onClick={() => {
                  this.setState({ buddyModalOpen: true })
                }}
              >
                <LearningBuddyMessage />
                {session.user.learningBuddy && (
                  <strong>{session.user.learningBuddy.attributes.name}</strong>
                )}
              </div>
            </div>
          </div>

          <div>
            <div className={classNames("profileCard")}>
              <div
                className={classNames("profileContent", isEditing && "editing")}
              >
                <h3>
                  <span>Profile Information</span>
                  <Button
                    onClick={() => {
                      this.setState({ isEditing: true })
                    }}
                    className="button small"
                  >
                    Edit
                  </Button>
                </h3>

                <div
                  className={classNames("profileForm", isEditing && "enabled")}
                >
                  <FormControl>
                    <TextField
                      label="First Name"
                      value={firstName}
                      onChange={this.updateFirstName}
                    />
                  </FormControl>
                  <FormControl>
                    <TextField
                      label="Last Name"
                      value={lastName}
                      onChange={this.updateLastName}
                    />
                  </FormControl>

                  <FormControl className="timezone-select">
                    <InputLabel>Time Zone</InputLabel>
                    <Select value={timezone} onChange={this.updateTimezone}>
                      <MenuItem value={"America/New_York"}>
                        Eastern Standard Time
                      </MenuItem>
                      <MenuItem value={"America/Chicago"}>
                        Central Standard Time
                      </MenuItem>
                      <MenuItem value={"America/Denver"}>
                        Mountain Standard Time
                      </MenuItem>
                      <MenuItem value={"America/Los_Angeles"}>
                        Pacific Standard Time
                      </MenuItem>
                      <MenuItem value={"America/Anchorage"}>
                        Alaska Standard Time
                      </MenuItem>
                    </Select>
                  </FormControl>

                  <FormControl className="inputSelect jobTitle">
                    <InputLabel id="label-job-title-select">
                      Job Title
                    </InputLabel>
                    <Select
                      labelId="label-job-title-select"
                      id="job-title-select"
                      value={jobTitle}
                      onChange={this.updateJobTitle}
                    >
                      {this.props.jobTitles &&
                        this.props.jobTitles.map((jobTitle) => {
                          return (
                            <MenuItem key={jobTitle.id} value={jobTitle.id}>
                              {jobTitle.attributes.name}
                            </MenuItem>
                          )
                        })}
                    </Select>
                  </FormControl>

                  {fullJobTitle &&
                    fullJobTitle.attributes.name.includes("Other") && (
                      <FormControl>
                        <TextField
                          variant="outlined"
                          label="Please specify your job title"
                          value={this.state.jobTitleOther}
                          onChange={this.updateJobTitleOther}
                        />
                      </FormControl>
                    )}

                  {showBio && (
                    <FormControl className="bio">
                      <TextField
                        variant="outlined"
                        label="Presenter Bio"
                        value={this.state.bio}
                        multiline
                        onChange={this.updateBio}
                      />
                      <div style={{ marginTop: -30, marginBottom: 5 }}>
                        <FormHelperText>
                          This is displayed on events where you are listed as a
                          presenter.
                        </FormHelperText>
                      </div>
                    </FormControl>
                  )}

                  <footer style={{ marginTop: 15 }}>
                    {(hasChanged || isEditing) && (
                      <ButtonLarge onClick={this.save}>
                        Save Profile
                      </ButtonLarge>
                    )}
                  </footer>
                </div>
              </div>
            </div>

            {session.subgroups &&
              session.subgroups[0] &&
              !session.subgroups[0].data && (
                <div className={classNames("profileCard")}>
                  <div
                    className={classNames(
                      "profileContent groupList",
                      isEditing && "editing"
                    )}
                  >
                    <h3>
                      <span>Your Groups</span>
                    </h3>

                    <ul>
                      {session.subgroups[0].map((group) => {
                        const groupData = group[0]

                        return (
                          <li style={{ display: "flex", alignItems: "center" }}>
                            <span
                              style={{
                                width: 25,
                                height: 25,
                                marginRight: 10,
                              }}
                              className="icon school"
                            />
                            <strong>{groupData.label[0].value}</strong>
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                </div>
              )}
          </div>
        </div>

        {isSaving && (
          <Loading
            color={theme.palette.secondary.main}
            fullPage={true}
            message="Saving profile..."
          />
        )}
        {isSaved && (
          <div className="saved">
            {" "}
            <span className="icon check"></span>Profile Saved
          </div>
        )}
      </div>
    )
  }
}

ProfileLayout.propTypes = {
  jobTitles: PropTypes.array,
  session: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileLayout)
