import { Component } from "react"
import { connect } from "react-redux"
import "../../styles/certificate.scss"
import { getBadge } from "../../actions"
import BadgeCertificate from "@mobilemind/common/src/components/backpack/certificate/BadgeCertificate"
import Loading from "@mobilemind/common/src/components/Loading"
import { withRouter } from "react-router-dom"

const mapStateToProps = ({ badges, session }, ownProps) => {
  return {
    badgeId: ownProps.match.params.userBadgeId,
    session,
    userBadge: session.userBadges.find(
      (badge) => badge.id === ownProps.match.params.userBadgeId
    ),
    badge: badges.data[0],
  }
}

const mapDispatchToProps = {
  getBadge,
}

class BadgeCertificateLayout extends Component {
  async componentDidMount() {
    this.props.getBadge(this.props.badgeId)
  }

  render() {
    const props = {
      userBadge: this.props.userBadge,
      badge: this.props.badge,
      user: this.props.session.user,
    }
    return (
      <>
        {this.props.badge ? (
          <BadgeCertificate {...props} />
        ) : (
          <Loading fullPage={true} message="Generating certificate..." />
        )}
      </>
    )
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BadgeCertificateLayout)
)
