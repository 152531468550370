import { Button } from "@mui/material"
import { withStyles } from "@mui/styles"
import theme from "@mobilemind/common/src/theme/theme"
import classNames from "classnames"
import { hexToRgb } from "@mui/material"

function ButtonLarge(props) {
  const hoverColor = hexToRgb(theme.palette.secondary.main)
    .replace("rgb", "rgba")
    .replace(")", ",.8)")

  const StyledButton = withStyles({
    root: {
      backgroundColor: theme.palette.secondary.main,
      color: "#000",
      "&:hover": {
        backgroundColor: hoverColor,
        color: "#000",
      },
      "&:focus": {
        backgroundColor: hoverColor,
        color: "#000",
      },
    },
  })(Button)

  return (
    <StyledButton
      style={props.style}
      onClick={props.onClick}
      className={classNames("large button primary")}
    >
      {props.children}
    </StyledButton>
  )
}

export default ButtonLarge
